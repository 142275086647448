import * as THREE from "three";

export const createLastWallSegment = (
  wallStart: THREE.Vector2,
  startPosition: number,
  endPosition: number,
  wallDirection: THREE.Vector2,
  perpendicular: THREE.Vector2,
  lineWeight: number,
  wallWidth: number,
  bottomLeft: THREE.Vector2,
  bottomRight: THREE.Vector2,
  shapeType: string,
): THREE.Shape => {
  const shape = new THREE.Shape();
  const segmentStart = wallStart
    .clone()
    .add(wallDirection.clone().multiplyScalar(startPosition));
  const shiftDistance = wallWidth / 2;

  if (shapeType === "left") {
    shape.moveTo(
      segmentStart.x - perpendicular.x * shiftDistance,
      segmentStart.y - perpendicular.y * shiftDistance,
    ); //övre
    shape.lineTo(
      segmentStart.x - perpendicular.x * (shiftDistance + lineWeight),
      segmentStart.y - perpendicular.y * (shiftDistance + lineWeight),
    ); //Undre
  } else if (shapeType === "right") {
    shape.moveTo(
      segmentStart.x + perpendicular.x * (shiftDistance + lineWeight),
      segmentStart.y + perpendicular.y * (shiftDistance + lineWeight),
    );
    shape.lineTo(
      segmentStart.x + perpendicular.x * shiftDistance,
      segmentStart.y + perpendicular.y * shiftDistance,
    );
  } else {
    shape.moveTo(
      segmentStart.x + perpendicular.x * shiftDistance,
      segmentStart.y + perpendicular.y * shiftDistance,
    ); // Övre
    shape.lineTo(
      segmentStart.x - perpendicular.x * shiftDistance,
      segmentStart.y - perpendicular.y * shiftDistance,
    ); // Undre
  }
  shape.lineTo(bottomLeft.x, bottomLeft.y); // undre
  shape.lineTo(bottomRight.x, bottomRight.y); // övre
  shape.closePath();

  return shape;
};
