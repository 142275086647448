import React, { useRef, useState, useEffect, useContext, useCallback } from "react";
import * as THREE from "three";
import { ThreeEvent, useThree } from "@react-three/fiber";
import DraggableObject from "./DraggableObject";
import { TextType, zIndexEpsilon } from "../../types/wallTypes";
import { BoundingBox, SelectableSymbol } from "./SelectableSymbol";
import { FloorplannerStoreContext } from "../../store/floorplannerStore";
import { Html, Text } from "@react-three/drei";
import { editorStore } from "../../store/editorStore";
import { observer } from "mobx-react-lite";
import { SimpleText } from "../Text/SimpleText";

interface TextSymbolProps {
  text: TextType;
  onDragStart: (text: TextType, offset: [number, number]) => void;
  onDrag: (newPosition: [number, number]) => void;
  onDragEnd: (endPosition: [number, number]) => void;
}

const TextSymbol: React.FC<TextSymbolProps> = observer(({
  text,
  onDragStart,
  onDragEnd,
  onDrag,
}) => {
  const { camera } = useThree();
  const groupRef = useRef<THREE.Group>(null);
  const floorplannerStore = useContext(FloorplannerStoreContext);

  const [textBoxSize, setTextBoxSize] = useState({ width: text.textBoxWidth, height: text.textBoxHeight });
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState(text.text || floorplannerStore.text);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputWidth, setInputWidth] = useState(60);

  const handleTextClick = (textId: string) => {
    editorStore.setTextEditing(textId);
    setIsEditing(true);
  };

  const handleTextUpdate = () => {
    const finalText = editText.trim() === "" ? floorplannerStore.text : editText;
    setEditText(finalText);
    floorplannerStore.updateSymbolProperty(text.id, "text", finalText);
    setIsEditing(false);
    editorStore.setTextEditing(undefined);
  };
  // State to store the pivot dynamically
  const [pivot, setPivot] = useState<[number, number]>([
    textBoxSize.width / 2,
    textBoxSize.height / 2,
  ]);
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, text: TextType) => {
    if (event.key === "Enter") {
      handleTextUpdate();
    }
  };

  const handleInputBlur = () => {
    handleTextUpdate();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditText(event.target.value);
    updateInputWidth(event.target.value);
  };

  const updateInputWidth = (value: string) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (context) {
      context.font = "14px Arial";
      const textMetrics = context.measureText(value);
      setInputWidth(textMetrics.width + 20);
    }
  };

  const handleResize = (newWidth: number, newHeight: number) => {
  };

  const calculateBoundingBox = useCallback((): BoundingBox => {
    if (!groupRef.current) return { topLeft: [0, 0], topRight: [0, 0], bottomLeft: [0, 0], bottomRight: [0, 0], width: 0, height: 0, depth: 0 };

    const { width, height } = textBoxSize;

    const centerX = textBoxSize.width / 2;
    const centerY = textBoxSize.height / 2;
    setPivot([-width / 2, -height / 2]);
    const rotatePoint = (point: [number, number], angle: number): [number, number] => {
      const [x, y] = point;
      const cosAngle = Math.cos(angle);
      const sinAngle = Math.sin(angle);
      return [x * cosAngle - y * sinAngle, x * sinAngle + y * cosAngle];
    };

    const points = {
      topLeft: rotatePoint([-centerX, centerY], 0),
      topRight: rotatePoint([centerX, centerY], 0),
      bottomLeft: rotatePoint([-centerX, -centerY], 0),
      bottomRight: rotatePoint([centerX, -centerY], 0),
    };


    return { ...points, width, height, depth: 0 };
  }, [textBoxSize, text.rotation]);

  useEffect(() => {
    editorStore.updateGroupRef(text.id, groupRef.current);

  }, [groupRef.current, text.selected]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      updateInputWidth(editText);
    }
  }, [isEditing]);

  const handleSingleClick = (textId: string) => {
    editorStore.setTextEditing(undefined);
    floorplannerStore.selectSymbol(textId);
    editorStore.setSelections([text]); // Update the selections in editorStore
  };

  const handleDoubleClick = (textId: string) => {
    setIsEditing(true);
    editorStore.setTextEditing(textId);
  };

  return (
    <group
      position={[0, 0, 0.01 + (text.zIndex * zIndexEpsilon)]}
    >
      <DraggableObject
        position={[text.position.x, text.position.y]}
        onDragStart={(offset) => onDragStart(text, offset)}
        onDragEnd={(endPosition) => onDragEnd(endPosition)}
        onDrag={onDrag}
        selectable={true}
        attachmentId={text.id}
        attachmentType="doorAttachments"
      >
        <SelectableSymbol
          ref={groupRef}
          calculateBoundingBox={calculateBoundingBox}
          center={[0, 0]}
          rotation={text.rotation}
          symbol={text}
          drawHandles={true}
          handleSize={floorplannerStore.symbolHandleSize}
          onResize={handleResize}
          pivot={[0, 0]}
        >
          <group
            ref={groupRef}
            onClick={() => handleSingleClick(text.id)}
            onDoubleClick={(e: ThreeEvent<MouseEvent>) => {
              e.stopPropagation();
              handleDoubleClick(text.id);
            }}
            onPointerEnter={() => {
              if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.add("cursor-vector");
            }}
            onPointerLeave={() => {
              if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.remove("cursor-vector");
            }}
          >
            {isEditing ? (
              <Html center>
                <input
                  ref={inputRef}
                  type="text"
                  value={editText}
                  onChange={handleInputChange}
                  onKeyPress={(event) => handleKeyPress(event, text)}
                  onBlur={handleInputBlur}
                  autoFocus
                  style={{
                    background: "transparent",
                    border: "none",
                    color: "black",
                    fontSize: "12px",
                    textAlign: "start",
                    outline: "none",
                    width: "80px",
                    height: "25px",
                    zIndex: 10,  // Ensure it's rendered above everything else
                  }}
                  onFocus={(event) => event.target.select()}
                />
              </Html>
            ) : (
              <SimpleText
                position={[0, 0, -0.01]}
                fontSize={32}
                color="black"
                rotation={[0, 0, text.rotation || 0]}
              >
                {editText}
              </SimpleText>
            )}

          </group>
        </SelectableSymbol>
      </DraggableObject>
    </group>
  );
});

export default TextSymbol;
