import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { Mesh } from 'three';

interface SimpleTextProps {
    children: string;
    fontSize?: number;
    fontWeight?: string | number;
    color?: string;
    position?: [number, number, number];
    padding?: number; // Optional padding around the text
    rotation?: [number, number, number]; // Rotation angles in radians [x, y, z]
}

export const SimpleText: React.FC<SimpleTextProps> = ({
    children,
    fontSize = 18,
    fontWeight = 'normal',
    color = 'black',
    position = [0, 0, 0],
    padding = 10, // Default padding of 10 pixels
    rotation = [0, 0, 0] // Default rotation of [0, 0, 0] (no rotation)
}) => {
    const textMesh = useRef<Mesh>(null);
    const [planeSize, setPlaneSize] = useState<[number, number]>([1, 1]);
    const font = `${fontWeight} ${fontSize}px sans-serif`;

    useEffect(() => {
        const scale = 4; // High resolution scale factor
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) return;

        context.font = font;

        // Measure text width and estimate height
        const textMetrics = context.measureText(children);
        const textWidth = textMetrics.width;
        const textHeight = fontSize * 1.2; // Estimate text height with line spacing

        // Add padding to the canvas size
        const canvasWidth = (textWidth + padding * 2) * scale;
        const canvasHeight = (textHeight + padding * 2) * scale;

        // Set canvas size and scale
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        context.scale(scale, scale); // Apply scaling to draw text at high resolution

        // Clear the canvas and set the font properties
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.font = font;
        context.fillStyle = color;
        context.textAlign = 'center';
        context.textBaseline = 'middle';

        // Draw the text centered with padding
        context.fillText(
            children,
            (canvasWidth / scale) / 2,
            (canvasHeight / scale) / 2
        );

        // Create a texture from the canvas
        const texture = new THREE.CanvasTexture(canvas);
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter; // Ensure sharp scaling
        texture.generateMipmaps = false; // Avoid blurring from mipmaps

        // Update the plane size to match the text size
        setPlaneSize([(canvasWidth / scale) / 100, (canvasHeight / scale) / 100]); // Adjust scaling as needed

        // Apply the texture to the material of the mesh
        if (textMesh.current) {
            let material = textMesh.current.material as THREE.MeshBasicMaterial;
            if (!material) return;
            material.map = texture;
            material.transparent = true;
            material.needsUpdate = true;
        }
    }, [children, fontSize, fontWeight, color, padding]);

    return (
        <mesh ref={textMesh} position={position} rotation={new THREE.Euler(...rotation)}>
            <planeGeometry args={planeSize} />
            <meshBasicMaterial transparent />
        </mesh>
    );
};
