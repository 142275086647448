import { Line } from "@react-three/drei";
import { isWallType, RulerLineType, SingleLineType, WallType } from "../../types/wallTypes";

interface LeftRightArrowProps {
    wall: WallType | SingleLineType | RulerLineType;
    wallWidth: number;
    position: [number, number, number];
    color: string;
}

export const LeftRightArrow: React.FC<LeftRightArrowProps> = ({
    wall: wallData,
    wallWidth,
    position,
    color
}) => {
    const noopRaycast = () => null;
    const customHandleWidth = isWallType(wallData) ? wallData.wallWidth || wallWidth : wallWidth;
    return (
        <group position={position}>
            {/* Draw an left-right-arrow using Line */}
            <Line
                position={[0, 0, 0.002]}
                points={[
                    [customHandleWidth / 3, 0, 0],
                    [-customHandleWidth / 3, 0, 0],
                ]}
                color={color}
                lineWidth={1}
                raycast={noopRaycast}
            />
            <Line
                position={[0, 0, 0.002]}
                points={[
                    [customHandleWidth / 3, 0, 0],
                    [customHandleWidth / 3 - 0.02, 0.02, 0],
                ]}
                color={color}
                lineWidth={1}
                raycast={noopRaycast}
            />
            <Line
                position={[0, 0, 0.002]}
                points={[
                    [customHandleWidth / 3, 0, 0],
                    [customHandleWidth / 3 - 0.02, -0.02, 0],
                ]}
                color={color}
                lineWidth={1}
                raycast={noopRaycast}
            />
            <Line
                position={[0, 0, 0.002]}
                points={[
                    [-customHandleWidth / 3, 0, 0],
                    [-customHandleWidth / 3 + 0.02, -0.02, 0],
                ]}
                color={color}
                lineWidth={1}
                raycast={noopRaycast}
            />
            <Line
                position={[0, 0, 0.002]}
                points={[
                    [-customHandleWidth / 3, 0, 0],
                    [-customHandleWidth / 3 + 0.02, 0.02, 0],
                ]}
                color={color}
                lineWidth={1}
                raycast={noopRaycast}
            />
        </group>
    );
};