import { isRulerLineType, RulerLineType, SingleLineType, WallConnectionEnd, WallConnectionStart, WallType } from "../../types/wallTypes";
import * as THREE from 'three';
import { floorplannerStore } from '../../store/floorplannerStore';
import { editorStore } from "../../store/editorStore";
import { updateAlignmentLines } from "./updateAligmentLines";

const snapAngle = (angle: number, snapInterval: number) => {
    return Math.round(angle / snapInterval) * snapInterval;
};

/**
 * Drag the start handle of the object, it will move the start of the object maintaining connections and updates their positions
 * @param source The object to drag
 * @param startOffsetX The start x offset of the object
 * @param startOffsetY The start y offset of the object
 * @param newX The new x position of the object
 * @param newY The new y position of the object
 * @param dragEndFreeToConnect If the end is free to snap
 * @param isShiftPressed If the Shift key is pressed
 * @param delta The delta vector
 * @returns boolean If the wall was snapped
 */
export const dragStartHandle = (
    source: WallType | SingleLineType | RulerLineType,
    startOffsetX: number,
    startOffsetY: number,
    newX: number,
    newY: number,
    dragEndFreeToConnect: boolean,
    delta: THREE.Vector2,
): boolean => {
    const object = floorplannerStore.findObjectId(source.id);
    if (!object) {
        return false;
    }
    let snapped = false;
    let newDelta = new THREE.Vector2(
        newX - startOffsetX,
        newY - startOffsetY,
    ).sub(object.start);
    const newStartPosition = object.start.clone().add(newDelta);
    // Angle snapping
    if (!editorStore.isShiftPressed) {
        // Calculate the angle between the end and current mouse position
        const deltaX = newStartPosition.x - object.end.x;
        const deltaY = newStartPosition.y - object.end.y;
        const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI); // Convert to degrees

        // Get the current distance between the start and end position
        const currentDistance = newStartPosition.distanceTo(object.end);

        // Normalize the angle to a positive value between 0 and 360
        const normalizedAngle = (angle + 360) % 360;

        // Snap the angle to the nearest 45 degrees
        const snapAngles = [0, 45, 90, 135, 180, 225, 270, 315, 360];
        let closestSnapAngle = snapAngles.reduce((prev, curr) => Math.abs(curr - normalizedAngle) < Math.abs(prev - normalizedAngle) ? curr : prev);

        // Convert the snapped angle to radians
        const radianSnapAngle = closestSnapAngle * (Math.PI / 180);

        // Calculate the snapped start position (along the nearest 45-degree line)
        const snapStartX = object.end.x + currentDistance * Math.cos(radianSnapAngle);
        const snapStartY = object.end.y + currentDistance * Math.sin(radianSnapAngle);

        // Calculate the distance between the current mouse position and the snapped position
        const snapDistance = Math.sqrt(Math.pow(newStartPosition.x - snapStartX, 2) + Math.pow(newStartPosition.y - snapStartY, 2));

        // If the distance to the snapped angle is less than 0.2, snap to the closest 45-degree angle
        if (snapDistance < 0.2) {
            newStartPosition.x = snapStartX;
            newStartPosition.y = snapStartY;
        }
    }

    if (dragEndFreeToConnect && !isRulerLineType(object)) {
        // Always connect to other walls, even when Shift is held
        floorplannerStore.removeConnectionEnd(object.id, WallConnectionStart);
        snapped = floorplannerStore.snapToOtherObjects(object.id, WallConnectionStart, newStartPosition, false);
    }
    if (!snapped && !editorStore.isShiftPressed && !isRulerLineType(object)) {
        floorplannerStore.updateObjectPosition(object.id, newStartPosition, object.end);
        const updObject = floorplannerStore.findObjectId(object.id);
        if (updObject) {
            snapped = floorplannerStore.snapToAlignmentLines(object.id, WallConnectionStart, updObject.start);
            if (snapped) {
                const updObject = floorplannerStore.findObjectId(object.id);
                if (updObject) {
                    updateAlignmentLines(object.id, updObject.start.x, updObject.start.y, "start");
                }
            }
        }
    }

    if (!snapped) {
        floorplannerStore.updateObjectPosition(object.id, newStartPosition, object.end);
    }

    // Update the sourcePosition (the distance from the start of the wall) of the connected objects in the wall
    object.connections?.forEach((connection) => {
        if (connection.sourcePosition !== WallConnectionStart && connection.sourcePosition !== WallConnectionEnd) {
            const connectedObject = floorplannerStore.findObjectId(connection.id);
            if (connectedObject) {
                // Find the new source distance 
                const updObject = floorplannerStore.findObjectId(object.id);
                if (updObject) {
                    let newSourceDistance
                    if (connection.targetPosition === WallConnectionStart) {
                        newSourceDistance = connectedObject.start.distanceTo(updObject.start);
                    } else if (connection.targetPosition === WallConnectionEnd) {
                        newSourceDistance = connectedObject.end.distanceTo(updObject.start);
                    }
                    if (newSourceDistance && newSourceDistance !== connection.sourcePosition) {
                        connection.sourcePosition = newSourceDistance;
                    }
                }
            }
        }
    })

    const endConnectedToId = floorplannerStore.objectConnectedTo(object.id, WallConnectionEnd);
    if (endConnectedToId) {
        floorplannerStore.objectNeedsUpdate(endConnectedToId);
    }

    // Set property panel values for the new length
    editorStore.setWhichEndToUpdate("start");
    if (object.type === "singleLine") {
        const lineLength = floorplannerStore.singleLineLength(object.id);
        editorStore.setSingleLineEditingLengthPanel(lineLength);
    } else if (object.type === "rulerLine") {
        const lineLength = floorplannerStore.rulerLineLength(object.id);
        editorStore.setRulerEditingLengthPanel(lineLength);
    } else if (object.type === "wall") {
        const wallLength = floorplannerStore.wallOuterLength(object);
        editorStore.setWallEditingLengthPanel(wallLength);
    }

    delta.x = newDelta.x;
    delta.y = newDelta.y;
    return snapped;
}