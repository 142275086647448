import React, { useMemo } from 'react';
import * as THREE from 'three';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { LineSegments2 } from 'three/examples/jsm/lines/LineSegments2';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { extend, useFrame } from '@react-three/fiber';

interface SimpleLineSegmentsProps {
    segments: [number, number, number][];
    color?: THREE.Color | string | number;
    lineWidth?: number;
    dashed?: boolean;
    dashSize?: number;
    gapSize?: number;
}

// Extend three.js classes so they can be used in JSX
extend({ Line2, LineMaterial, LineSegments2, LineGeometry });

export function SimpleLineSegments({
    segments,
    color = 'black',
    lineWidth = 1,
    dashed = false,
    dashSize = 0.2,
    gapSize = 0.15,
}: SimpleLineSegmentsProps) {
    const noopRaycast = () => null;
    if (segments.length % 2 !== 0) {
        console.error('Segments array length must be even, each pair of points defines a segment.');
        return null;
    }

    const positions = useMemo(() => new Float32Array(segments.flat()), [segments]);

    const geometry = useMemo(() => {
        const geom = new THREE.BufferGeometry();
        geom.setAttribute('position', new THREE.BufferAttribute(positions, 3));
        return geom;
    }, [positions]);

    const material = useMemo(() => new THREE.LineBasicMaterial({
        color,
        linewidth: lineWidth,
    }), [color, lineWidth]);

    // Create the LineSegments object
    const lineSegments = useMemo(() => new THREE.LineSegments(geometry, material), [geometry, material]);

    // Exclude from raycasting
    lineSegments.raycast = noopRaycast;

    return <primitive object={lineSegments} />;
}
