import { useCallback } from "react";
import { RulerLineType, SingleLineType, WallType } from "../../types/wallTypes";
import * as THREE from 'three';

/**
 * Update the drag handles of the wall
 * @param handle The handle to update
 * @param wall The wall to update the drag handles
 * @param dragHandle The drag handle object a useRef
 * @returns void
 */
export const updateDragHandles = ((handle: string, object: WallType | SingleLineType | RulerLineType, dragHandle: React.RefObject<{
    handle: string;
    startPosition: THREE.Vector3;
    endPosition: THREE.Vector3;
}>) => {
    if (!dragHandle.current) {
        return;
    }
    const handleOffset = 0.035;
    const start = new THREE.Vector3(object.start.x, object.start.y, 0);
    const end = new THREE.Vector3(object.end.x, object.end.y, 0);
    const wallDirection = new THREE.Vector3()
        .subVectors(end, start)
        .normalize();
    const startHandle = new THREE.Vector3().addVectors(
        start,
        wallDirection.clone().multiplyScalar(-handleOffset),
    );
    const endHandle = new THREE.Vector3().addVectors(
        end,
        wallDirection.clone().multiplyScalar(handleOffset),
    );
    dragHandle.current.handle = handle;
    dragHandle.current.startPosition = startHandle;
    dragHandle.current.endPosition = endHandle;
});
