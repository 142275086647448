import { isDoorType, isWindowType } from "../types/wallTypes";
import { FloorplannerStore } from "./floorplannerStore";

export function checkFloorplannerStoreIntegrity(store: FloorplannerStore) {
    // Check that all keys are unique
    const wallKeys = Array.from(store.wallsMap.keys());
    const symbolKeys = Array.from(store.symbolsMap.keys());
    const allKeys = wallKeys.concat(symbolKeys);
    const uniqueKeys = new Set(allKeys);
    if (uniqueKeys.size !== allKeys.length) {
        console.error("Duplicate keys found in walls and symbols", allKeys);
    }
    // Check all symbols attachments are valid
    store.symbolsMap.forEach((symbol) => {
        if (symbol.attachedTo) {
            const wall = store.wallsMap.get(symbol.attachedTo);
            if (!wall) {
                console.error("Symbol is attached to a wall that does not exist", symbol.id, symbol.attachedTo);
            } else {
                if (!wall.symbolAttachments) {
                    console.error("Symbol is attached to a wall that does not have symbol attachments", symbol.id, symbol.attachedTo);
                } else {
                    const attachment = wall.symbolAttachments.find(attachment => attachment.symbolId === symbol.id);
                    if (!attachment) {
                        console.error("Symbol is attached to a wall that does not have the symbol attachment", symbol.id, symbol.attachedTo);
                    }
                }
            }
        }
    });
    // Check if all wall connections are valid
    Array.from(store.wallsMap.values()).forEach((wall) => {
        wall.connections?.forEach((connection) => {
            const connectedToWall = store.wallsMap.get(connection.id);
            if (!connectedToWall) {
                console.error("Wall connection is invalid", wall.id, connection.id);
            } else {
                if (!connectedToWall.connections?.find(connectedWallConnection => connectedWallConnection.id === wall.id)) {
                    console.error("Wall connection is not bidirectional", wall.id, connection.id);
                }
            }
        });
    });
    // Check if all symbols have all required properties of their type by looking at the type property
    store.symbolsMap.forEach((symbol) => {
        if (isDoorType(symbol)) {
            if (!symbol.doorWidth) {
                console.error("Door symbol does not have a door width", symbol.id);
            }
        } else if (isWindowType(symbol)) {
            if (!symbol.windowWidth) {
                console.error("Window symbol does not have a window width", symbol.id);
            }
            if (!symbol.windowLength) {
                console.error("Window symbol does not have a window length", symbol.id);
            }
        }
    });
}