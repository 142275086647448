import { AlignmentLineType, RulerLineType, SingleLineType, WallType } from "../../types/wallTypes";
import { floorplannerStore } from "../../store/floorplannerStore";
import { renderStore } from "../../store/renderStore";

const tolerance = 0.001; // Sensitivity for alignment
const toleranceApprox = 0.1; // Sensitivity for approximate alignment used for snapping

function findAlignmentLines(
    wall: WallType | SingleLineType | RulerLineType,
    otherWall: WallType | SingleLineType | RulerLineType,
    newX: number,
    newY: number,
    dragHandle: string,
) {
    if (otherWall.id !== wall?.id) {
        if (
            dragHandle === "wall" ||
            dragHandle === "middleV" ||
            dragHandle === "middleH"
        ) {
            // Align both start and end
            const wallStartX = wall.start.x;
            const wallStartY = wall.start.y;
            const wallEndX = wall.end.x;
            const wallEndY = wall.end.y;

            const alignedXStart =
                Math.abs(otherWall.start.x - wallStartX) <= tolerance ||
                Math.abs(otherWall.end.x - wallStartX) <= tolerance;
            const alignedYStart =
                Math.abs(otherWall.start.y - wallStartY) <= tolerance ||
                Math.abs(otherWall.end.y - wallStartY) <= tolerance;

            if (alignedXStart) {
                const alignmentLine: AlignmentLineType = {
                    id: `align-x-start-${otherWall.id}`,
                    hit: { x: wallStartX, y: 0 },
                    axis: "x",
                    color: "blue",
                    lineWidth: 0.5,
                    objectId: otherWall.id,
                };
                renderStore.addAlignmentLine(alignmentLine, false);
            }
            const alignedXStartStartApprox = Math.abs(otherWall.start.x - wallStartX) <= toleranceApprox
            if (alignedXStartStartApprox) {
                const alignmentLine: AlignmentLineType = {
                    id: `align-x-approx-start-start-${otherWall.id}`,
                    hit: { x: otherWall.start.x, y: 0 },
                    axis: "x",
                    color: "blue",
                    lineWidth: 0.5,
                    objectId: otherWall.id,
                };
                renderStore.addAlignmentLine(alignmentLine, true);
            }
            const alignedXStartEndApprox = Math.abs(otherWall.end.x - wallStartX) <= toleranceApprox;
            if (alignedXStartEndApprox) {
                const alignmentLine: AlignmentLineType = {
                    id: `align-x-approx-start-end-${otherWall.id}`,
                    hit: { x: otherWall.end.x, y: 0 },
                    axis: "x",
                    color: "blue",
                    lineWidth: 0.5,
                    objectId: otherWall.id,
                };
                renderStore.addAlignmentLine(alignmentLine, true);
            }

            if (alignedYStart) {
                const alignmentLine: AlignmentLineType = {
                    id: `align-y-start-${otherWall.id}`,
                    hit: { x: 0, y: wallStartY },
                    axis: "y",
                    color: "blue",
                    lineWidth: 0.5,
                    objectId: otherWall.id,
                };
                renderStore.addAlignmentLine(alignmentLine, false);
            } 
            const alignedYStartStartApprox = Math.abs(otherWall.start.y - wallStartY) <= toleranceApprox
            if (alignedYStartStartApprox) {
                const alignmentLine: AlignmentLineType = {
                    id: `align-y-approx-start-start-${otherWall.id}`,
                    hit: { x: 0, y: otherWall.start.y },
                    axis: "y",
                    color: "blue",
                    lineWidth: 0.5,
                    objectId: otherWall.id,
                };
                renderStore.addAlignmentLine(alignmentLine, true);
            }
            const alignedYStartEndApprox = Math.abs(otherWall.end.y - wallStartY) <= toleranceApprox;
            if (alignedYStartEndApprox) {
                const alignmentLine: AlignmentLineType = {
                    id: `align-y-approx-start-end-${otherWall.id}`,
                    hit: { x: 0, y: otherWall.end.y },
                    axis: "y",
                    color: "blue",
                    lineWidth: 0.5,
                    objectId: otherWall.id,
                };
                renderStore.addAlignmentLine(alignmentLine, true);
            }

            const alignedXEnd =
                Math.abs(otherWall.start.x - wallEndX) < tolerance ||
                Math.abs(otherWall.end.x - wallEndX) < tolerance;
            const alignedYEnd =
                Math.abs(otherWall.start.y - wallEndY) < tolerance ||
                Math.abs(otherWall.end.y - wallEndY) < tolerance;

            if (alignedXEnd) {
                const alignmentLine: AlignmentLineType = {
                    id: `align-x-end-${otherWall.id}`,
                    hit: { x: wallEndX, y: 0 },
                    axis: "x",
                    color: "blue",
                    lineWidth: 0.5,
                    objectId: otherWall.id,
                };
                renderStore.addAlignmentLine(alignmentLine, false);
            }
            const alignedXEndStartApprox = Math.abs(otherWall.start.x - wallEndX) < toleranceApprox
            if (alignedXEndStartApprox) {
                const alignmentLine: AlignmentLineType = {
                    id: `align-x-approx-end-start-${otherWall.id}`,
                    hit: { x: otherWall.start.x, y: 0 },
                    axis: "x",
                    color: "blue",
                    lineWidth: 0.5,
                    objectId: otherWall.id,
                };
                renderStore.addAlignmentLine(alignmentLine, true);
            }
            const alignedXEndEndApprox = Math.abs(otherWall.end.x - wallEndX) < toleranceApprox;
            if (alignedXEndEndApprox) {
                const alignmentLine: AlignmentLineType = {
                    id: `align-x-approx-end-end-${otherWall.id}`,
                    hit: { x: otherWall.end.x, y: 0 },
                    axis: "x",
                    color: "blue",
                    lineWidth: 0.5,
                    objectId: otherWall.id,
                };
                renderStore.addAlignmentLine(alignmentLine, true);
            }

            if (alignedYEnd) {
                const alignmentLine: AlignmentLineType = {
                    id: `align-y-end-${otherWall.id}`,
                    hit: { x: 0, y: wallEndY },
                    axis: "y",
                    color: "blue",
                    lineWidth: 0.5,
                    objectId: otherWall.id,
                };
                renderStore.addAlignmentLine(alignmentLine, false);
            }
            const alignedYEndStartApprox = Math.abs(otherWall.start.y - wallEndY) < toleranceApprox
            if (alignedYEndStartApprox) {
                const alignmentLine: AlignmentLineType = {
                    id: `align-y-approx-end-start-${otherWall.id}`,
                    hit: { x: 0, y: otherWall.start.y },
                    axis: "y",
                    color: "blue",
                    lineWidth: 0.5,
                    objectId: otherWall.id,
                };
                renderStore.addAlignmentLine(alignmentLine, true);
            }
            const alignedYEndEndApprox = Math.abs(otherWall.end.y - wallEndY) < toleranceApprox;
            if (alignedYEndEndApprox) {
                const alignmentLine: AlignmentLineType = {
                    id: `align-y-approx-end-end-${otherWall.id}`,
                    hit: { x: 0, y: otherWall.end.y },
                    axis: "y",
                    color: "blue",
                    lineWidth: 0.5,
                    objectId: otherWall.id,
                };
                renderStore.addAlignmentLine(alignmentLine, true);
            }

        } else {
            // When dragging start or end handle, align respective point
            const alignedX =
                Math.abs(otherWall.start.x - newX) < tolerance ||
                Math.abs(otherWall.end.x - newX) < tolerance;
            const alignedY =
                Math.abs(otherWall.start.y - newY) < tolerance ||
                Math.abs(otherWall.end.y - newY) < tolerance;

            if (alignedX) {
                const alignmentLine: AlignmentLineType = {
                    id: `align-x-${otherWall.id}`,
                    hit: { x: newX, y: 0 },
                    axis: "x",
                    color: "blue",
                    lineWidth: 0.5,
                    objectId: otherWall.id,
                };
                renderStore.addAlignmentLine(alignmentLine, false);
            }
            const alignedXApproxStart =
                Math.abs(otherWall.start.x - newX) < toleranceApprox
            if (alignedXApproxStart) {
                const alignmentLine: AlignmentLineType = {
                    id: `align-x-approx-start-${otherWall.id}`,
                    hit: { x: otherWall.start.x, y: 0 },
                    axis: "x",
                    color: "blue",
                    lineWidth: 0.5,
                    objectId: otherWall.id,
                };
                renderStore.addAlignmentLine(alignmentLine, true);
            }
            const alignedXApproxEnd =
                Math.abs(otherWall.end.x - newX) < toleranceApprox;
            if (alignedXApproxEnd) {
                const alignmentLine: AlignmentLineType = {
                    id: `align-x-approx-end-${otherWall.id}`,
                    hit: { x: otherWall.end.x, y: 0 },
                    axis: "x",
                    color: "blue",
                    lineWidth: 0.5,
                    objectId: otherWall.id,
                };
                renderStore.addAlignmentLine(alignmentLine, true);
            }
            if (alignedY) {
                const alignmentLine: AlignmentLineType = {
                    id: `align-y-${otherWall.id}`,
                    hit: { x: 0, y: newY },
                    axis: "y",
                    color: "blue",
                    lineWidth: 0.5,
                    objectId: otherWall.id,
                };
                renderStore.addAlignmentLine(alignmentLine, false);
            }
            const alignedYApproxStart =
                Math.abs(otherWall.start.y - newY) < toleranceApprox
            const alignedYApproxEnd =
                Math.abs(otherWall.end.y - newY) < toleranceApprox;
            if (alignedYApproxStart) {
                const alignmentLine: AlignmentLineType = {
                    id: `align-y-approx-start-${otherWall.id}`,
                    hit: { x: 0, y: otherWall.start.y },
                    axis: "y",
                    color: "blue",
                    lineWidth: 0.5,
                    objectId: otherWall.id,
                };
                renderStore.addAlignmentLine(alignmentLine, true);
            }
            if (alignedYApproxEnd) {
                const alignmentLine: AlignmentLineType = {
                    id: `align-y-approx-end-${otherWall.id}`,
                    hit: { x: 0, y: otherWall.end.y },
                    axis: "y",
                    color: "blue",
                    lineWidth: 0.5,
                    objectId: otherWall.id,
                };
                renderStore.addAlignmentLine(alignmentLine, true);
            }
        }
    }

}

export const updateAlignmentLines = (
    source: string,
    newX: number,
    newY: number,
    dragHandle: string
) => {
    const object = floorplannerStore.findObjectId(source);
    if (!object) {
        return;
    }

    renderStore.clearAlignmentLines();

    Array.from(floorplannerStore.wallsMap).forEach((otherWall) => {
        // Do not check if the other wall is the same as the wall being dragged or the other wall is connected to the wall being dragged
        object.id !== otherWall[1].id &&
            !object.connections?.some(
                (c) => c.id === otherWall[1].id
            ) &&
            findAlignmentLines(object, otherWall[1], newX, newY, dragHandle);
    });
    Array.from(floorplannerStore.singleLinesMap).forEach((otherLine) => {
        // Do not check if the other line is connected to the wall being dragged or the other line is the same as the wall being dragged
        !object.connections?.some(
            (c) => c.id === otherLine[1].id
        ) &&
            object.id !== otherLine[1].id &&
            findAlignmentLines(object, otherLine[1], newX, newY, dragHandle);
    });
    Array.from(floorplannerStore.rulerLinesMap).forEach((otherRuler) => {
        // Do not check if the other ruler is connected to the wall being dragged or the other ruler is the same as the wall being dragged
        !object.connections?.some(
            (c) => c.id === otherRuler[1].id
        ) &&
            object.id !== otherRuler[1].id &&
            findAlignmentLines(object, otherRuler[1], newX, newY, dragHandle);
    });
};
