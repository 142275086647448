import { RulerLineType, SingleLineType, WallConnectionEnd, WallConnectionStart, WallType } from "../../types/wallTypes";
import * as THREE from 'three';
import { closestPointOnLine } from "./closestPointOnLine";
import { floorplannerStore } from '../../store/floorplannerStore';
import { editorStore } from "../../store/editorStore";
import { updateAlignmentLines } from "./updateAligmentLines";

/**
 * Drag the middle handle of the object vertically
 * @param object The object to drag
 * @param startOffsetX The start x offset of the object
 * @param startOffsetY The start y offset of the object
 * @param newX The new x position of the object
 * @param newY The new y position of the object
 * @param delta The delta vector
 * @returns boolean If the object was snapped
 */
export const dragMiddleVHandle = (
    source: WallType | SingleLineType | RulerLineType,
    startOffsetX: number,
    startOffsetY: number,
    newX: number,
    newY: number,
    delta: THREE.Vector2,
): boolean => {
    const object = floorplannerStore.findObjectId(source.id);
    if (!object) {
        return false;
    }
    const midPoint = new THREE.Vector2(
        (object.start.x + object.end.x) / 2,
        (object.start.y + object.end.y) / 2,
    );
    let newDelta = new THREE.Vector2(
        newX - startOffsetX,
        newY - startOffsetY,
    ).sub(midPoint);
    const wallDirection = new THREE.Vector2()
        .subVectors(object.end, object.start)
        .normalize();
    const perpendicular = new THREE.Vector2(
        -wallDirection.y,
        wallDirection.x,
    );
    const distance = newDelta.dot(perpendicular);
    newDelta = perpendicular.clone().multiplyScalar(distance);

    // Apply delta to wall positions
    const movedStart = object.start.clone().add(newDelta);
    const movedEnd = object.end.clone().add(newDelta);

    // Store positions before snapping
    const originalMovedStart = movedStart.clone();
    const originalMovedEnd = movedEnd.clone();

    let snapped = false;
    let startSnapped = false;
    let endSnapped = false;

    if (!editorStore.isShiftPressed) {
        // Perform snapping to other objects
        startSnapped = floorplannerStore.snapToOtherObjects(
            object.id,
            WallConnectionStart,
            movedStart,
            true
        );
        if (!startSnapped) {
            endSnapped = floorplannerStore.snapToOtherObjects(
                object.id,
                WallConnectionEnd,
                movedEnd,
                true
            );
        }

        // If snapping occurred, adjust positions to only move along the perpendicular direction
        if (startSnapped || endSnapped) {
            snapped = true;

            // Retrieve the updated positions from the store
            const updObject = floorplannerStore.findObjectId(object.id);
            if (updObject) {
                // Calculate the adjustment vector from the original position to the snapped position
                const snappedStart = updObject.start.clone();
                const snappedEnd = updObject.end.clone();

                // Compute the net adjustment
                const adjustmentStart = snappedStart.clone().sub(originalMovedStart);
                const adjustmentEnd = snappedEnd.clone().sub(originalMovedEnd);

                // Use the average adjustment
                const netAdjustment = adjustmentStart
                    .clone()
                    .add(adjustmentEnd)
                    .multiplyScalar(0.5);

                // Project the net adjustment onto the perpendicular direction
                const projectionLength = netAdjustment.dot(perpendicular);
                const adjustedNetAdjustment = perpendicular
                    .clone()
                    .multiplyScalar(projectionLength);

                // Adjust positions
                movedStart.copy(originalMovedStart).add(adjustedNetAdjustment);
                movedEnd.copy(originalMovedEnd).add(adjustedNetAdjustment);

                // Update the wall's position
                floorplannerStore.updateObjectPosition(
                    object.id,
                    movedStart,
                    movedEnd
                );
            }
        } else {
            // Proceed with alignment snapping
            // Update the wall's position before alignment snapping
            floorplannerStore.updateObjectPosition(object.id, movedStart, movedEnd);

            const updObject = floorplannerStore.findObjectId(object.id);
            if (updObject) {
                startSnapped = floorplannerStore.snapToAlignmentLines(
                    object.id,
                    WallConnectionStart,
                    movedStart
                );
                if (startSnapped) {
                    snapped = true;

                    // Retrieve the updated position from the store
                    const updObject = floorplannerStore.findObjectId(object.id);
                    if (updObject) {
                        const snappedStart = updObject.start.clone();

                        // Compute the adjustment vector
                        const adjustment = snappedStart.clone().sub(originalMovedStart);

                        // Project the adjustment onto the perpendicular direction
                        const projectionLength = adjustment.dot(perpendicular);
                        const adjustedNetAdjustment = perpendicular
                            .clone()
                            .multiplyScalar(projectionLength);

                        // Adjust positions
                        movedStart.copy(originalMovedStart).add(adjustedNetAdjustment);
                        movedEnd.copy(originalMovedEnd).add(adjustedNetAdjustment);

                        // Update the wall's position
                        floorplannerStore.updateObjectPosition(object.id, movedStart, movedEnd);
                        updateAlignmentLines(object.id, movedStart.x, movedStart.y, "start");
                    }
                } else {
                    endSnapped = floorplannerStore.snapToAlignmentLines(
                        object.id,
                        WallConnectionEnd,
                        movedEnd
                    );
                    if (endSnapped) {
                        snapped = true;

                        // Retrieve the updated position from the store
                        const updObject = floorplannerStore.findObjectId(object.id);
                        if (updObject) {
                            const snappedEnd = updObject.end.clone();

                            // Compute the adjustment vector
                            const adjustment = snappedEnd.clone().sub(originalMovedEnd);

                            // Project the adjustment onto the perpendicular direction
                            const projectionLength = adjustment.dot(perpendicular);
                            const adjustedNetAdjustment = perpendicular
                                .clone()
                                .multiplyScalar(projectionLength);

                            // Adjust positions
                            movedStart.copy(originalMovedStart).add(adjustedNetAdjustment);
                            movedEnd.copy(originalMovedEnd).add(adjustedNetAdjustment);

                            // Update the wall's position
                            floorplannerStore.updateObjectPosition(object.id, movedStart, movedEnd);
                            updateAlignmentLines(object.id, movedEnd.x, movedEnd.y, "end");
                        }
                    }
                }
            }
        }
    } else {
        // If Shift is pressed, move without snapping
        floorplannerStore.updateObjectPosition(object.id, movedStart, movedEnd);
    }

    // Apply final positions
    if (!snapped) {
        floorplannerStore.updateObjectPosition(object.id, movedStart, movedEnd);
        if (!snapped && object.controlPoint) {
            const newControlPoint = object.controlPoint.clone().add(newDelta);
            floorplannerStore.setObjectProperty(object.id, 'controlPoint', newControlPoint);
        }
    }

    // Update connections
    const updObject = floorplannerStore.findObjectId(object.id);
    if (updObject) {
        updObject.connections?.forEach((connection) => {
            if (connection.sourcePosition === WallConnectionStart || connection.sourcePosition === WallConnectionEnd) {
                if (connection.targetPosition === WallConnectionStart) {
                    const connectedObject = floorplannerStore.findObjectId(connection.id);
                    if (connectedObject) {
                        // Update the sourcePosition (the distance from the start of the wall) of the connected objects in the wall
                        connectedObject.connections?.forEach((c) => {
                            if (c.sourcePosition !== WallConnectionStart && c.sourcePosition !== WallConnectionEnd) {
                                const connectedObject2 = floorplannerStore.findObjectId(c.id);
                                if (connectedObject2) {
                                    // Find the new source distance 
                                    let newSourceDistance
                                    if (c.targetPosition === WallConnectionStart) {
                                        newSourceDistance = connectedObject2.start.distanceTo(connection.sourcePosition === WallConnectionStart ? updObject.start : updObject.end);
                                    } else if (c.targetPosition === WallConnectionEnd) {
                                        newSourceDistance = connectedObject2.end.distanceTo(connection.sourcePosition === WallConnectionStart ? updObject.start : updObject.end);
                                    }
                                    if (newSourceDistance && newSourceDistance !== c.sourcePosition) {
                                        c.sourcePosition = newSourceDistance;
                                    }
                                }
                            }

                        })
                    }
                }
            }
        })
    }

    delta.x = newDelta.x;
    delta.y = newDelta.y;
    return snapped;
}