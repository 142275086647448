import React from 'react';
import { PiCrosshairSimpleLight } from "react-icons/pi";
import { PiPlus, PiMinus, PiHandGrabbing } from "react-icons/pi";
import { editorStore } from '../../store/editorStore';
import { observer } from 'mobx-react-lite';
import { Tooltip } from 'flowbite-react';

interface ZoomControlsProps {
    className?: string;  // Accepts an optional className prop
}

const ZoomControls: React.FC<ZoomControlsProps> = observer(({ className }) => {
    return (
        <div className={`flex flex-col gap-2 ${className}`}>
            <Tooltip content="Panning mode" placement='left'>
            <button
                onClick={() => { editorStore.setPanning(!editorStore.panning) }}
                className={`w-full p-2 text-center ${editorStore.panning ? "bg-[#082AC8] text-white" : "bg-white"} rounded-sm border border-gray-300 shadow-md`}>
                <PiHandGrabbing size={20} />
                </button>
            </Tooltip>
            <Tooltip content="Zoom in" placement='left'>
                <button onClick={() => editorStore.zoomIn()} className="w-full p-2 text-center bg-white hover:bg-gray-100 rounded-sm border border-gray-300 shadow-md">
                    <PiPlus size={20} />
                </button>
            </Tooltip>
            <Tooltip content="Zoom out" placement='left'>
                <button onClick={() => editorStore.zoomOut()} className="w-full p-2 text-center bg-white hover:bg-gray-100 rounded-sm border border-gray-300 shadow-md">
                    <PiMinus size={20} />
                </button>
            </Tooltip>
            <Tooltip content="Reset zoom" placement='left'>
            <button onClick={() => editorStore.resetZoom()} className="w-full p-2 text-center bg-white hover:bg-gray-100 rounded-sm border border-gray-300 shadow-md">
                <PiCrosshairSimpleLight size={20} />
                </button>
            </Tooltip>
        </div>
    );
});

export default ZoomControls;
