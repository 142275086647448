import React, { useImperativeHandle, useRef } from "react";
import * as THREE from "three";
import { useFloorplannerStore } from '../../store/floorplannerStore';
import { WallType } from "../../types/wallTypes";
import { FloorplannerStore } from "../../store/floorplannerStore";
import { ObservableMap } from "mobx";

interface AttachableSymbolProps {
  onAttachment: (attachPosition: [number, number]) => void;
  children: React.ReactNode;
  disableAttachment?: boolean;
  attachmentType: 'doorAttachments' | 'windowAttachments';
  attachmentId: string;
  store: FloorplannerStore;
}

const SNAP_THRESHOLD = 0.2; // Define the snap threshold for attachment

/**
 * A component that allows an object to snap to walls. Must be a child of DraggableObject.
 * 
 * @param onAttachment The function to call when the object snaps to a wall.
 * @param children The children to render.
 * @param disableAttachment Whether to disable attachment.
 * @param attachmentType The type of attachment.
 * @param attachmentId The ID of the attachment.
 * @param store The floorplanner store.
 * @returns The AttachableSymbol component.
 * 
 **/
const AttachableSymbol = React.forwardRef<unknown, AttachableSymbolProps>(
  ({ onAttachment, children, disableAttachment = false, attachmentType, attachmentId, store }, attachableRef) => {
    const groupRef = useRef<THREE.Group>(null);
    const { wallsMap } = useFloorplannerStore();

    /**
     * Finds the closest point on any wall to the given point within the SNAP_THRESHOLD.
     * @param point The point to check against walls.
     * @param walls The collection of walls to consider.
     * @returns The closest point if within threshold; otherwise, null.
     */
    const findClosestPointOnWalls = (
      point: THREE.Vector2,
      walls: ObservableMap<string, WallType>
    ): {
      point: THREE.Vector2,
      wall: WallType
    } | null => {
      let minDistance = Infinity;
      let closestPoint: {
        point: THREE.Vector2,
        wall: WallType
      } | null = null;

      Array.from(walls).forEach(([_, wall]) => {
        const wallStart = new THREE.Vector2(wall.start.x, wall.start.y);
        const wallEnd = new THREE.Vector2(wall.end.x, wall.end.y);

        const candidatePoint = closestPointOnLineSegment(point, wallStart, wallEnd);
        const distance = point.distanceTo(candidatePoint);

        if (distance < minDistance && distance <= SNAP_THRESHOLD) {
          minDistance = distance;
          closestPoint = {
            point: candidatePoint,
            wall: wall
          }
        }
      });

      return closestPoint;
    };

    /**
     * Calculates the closest point on a line segment to a given point.
     * @param point The external point.
     * @param start The start of the line segment.
     * @param end The end of the line segment.
     * @returns The closest point on the line segment.
     */
    const closestPointOnLineSegment = (
      point: THREE.Vector2,
      start: THREE.Vector2,
      end: THREE.Vector2
    ): THREE.Vector2 => {
      const line = end.clone().sub(start);
      const lengthSq = line.lengthSq();
      if (lengthSq === 0) return start.clone();

      const t = Math.max(0, Math.min(1, point.clone().sub(start).dot(line) / lengthSq));
      return start.clone().add(line.multiplyScalar(t));
    };

    useImperativeHandle(attachableRef, () => ({
      /**
       * Checks if the object is close enough to any wall to snap (attach) to it.
       * If so, invokes the attachToWall function.
       */
      checkForAttachment: (fromPosition: [number, number]) => {
        if (disableAttachment || !groupRef.current) return;

        const objectPosition2D = new THREE.Vector2(fromPosition[0], fromPosition[1]);
        const closestPoint = findClosestPointOnWalls(objectPosition2D, wallsMap);

        if (closestPoint) {
          attachToWall(closestPoint.point.toArray(), closestPoint.wall);
          onAttachment([closestPoint.point.x, closestPoint.point.y]);
        }
      },
    }));

    const attachToWall = (attachPosition: [number, number], wall: WallType) => {
      store.attachSymbolToWall(attachmentId, wall.id, attachPosition);
    };

    return <group ref={groupRef}>{children}</group>;
  }
);

export default AttachableSymbol;
