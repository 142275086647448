import { makeAutoObservable } from "mobx";
import { useContext } from "react";
import React from "react";
import { ApolloClient, gql } from "@apollo/client";
import {
  CurrentUserDocument,
  LogoutDocument,
  ValidateSessionDocument,
} from "../gql/graphql";

class UserStore {
  client: ApolloClient<any> | undefined;
  userId = undefined as string | undefined;
  userEmail: string | undefined;
  userRole: string | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  initialize(client: ApolloClient<any>) {
    this.client = client;
    this.validateSession();
  }

  get isAuthenticated() {
    return !!this.userId;
  }

  setUserId = (id: string | undefined) => {
    this.userId = id;
  };

  signOut = async () => {
    this.setUser(undefined);
    await this.client?.cache.reset();
    // Optionally, call an API endpoint to invalidate the session on the server
    try {
      await this.client?.mutate({
        mutation: LogoutDocument,
        fetchPolicy: "network-only",
      });
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  setUser = (user?: { id: string; email: string; role: string }) => {
    if (user) {
      this.userId = user.id;
      this.userEmail = user.email || undefined;
      this.userRole = user.role;
    } else {
      this.userId = undefined;
      this.userEmail = undefined;
      this.userRole = undefined;
    }
  };

  validateSession = async () => {
    if (!this.client) {
      return;
    }
    try {
      const { data } = await this.client.mutate({
        mutation: ValidateSessionDocument,
        fetchPolicy: "network-only",
      });
      if (data.validateSession) {
        this.userId = data.validateSession.id;
        this.userRole = data.validateSession.role;
      } else {
        this.setUser(undefined);
      }
    } catch (error) {
      console.error("Failed to validate current session", error);
      this.setUser(undefined);
    }
  };

  fetchCurrentUser = async () => {
    if (!this.client) {
      return;
    }
    try {
      const { data } = await this.client.query({
        query: CurrentUserDocument,
        fetchPolicy: "network-only", // Ensure the request is made to the server
      });
      this.setUser(data.currentUser);
    } catch (error) {
      console.error("Failed to fetch current user", error);
      this.setUser(undefined);
    }
  };
}

gql`
  mutation validateSession {
    validateSession {
      id
      role
    }
  }
`;

gql`
  query currentUser {
    currentUser {
      id
      email
      role
    }
  }
`;

gql`
  mutation logout {
    logout
  }
`;

export const userStore = new UserStore();
export const userStoreContext =
  React.createContext<UserStore>(userStore);
export const useUserStore = () => useContext(userStoreContext);
