import React, { useMemo } from 'react';
import * as THREE from 'three';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { extend, useFrame } from '@react-three/fiber';

interface SimpleLineProps {
    points: [number, number, number][];
    color?: THREE.Color | string | number;
    lineWidth?: number; 
    dashed?: boolean; 
    dashSize?: number;
    gapSize?: number; 
}

extend({ Line2, LineMaterial, LineGeometry });

export function SimpleLine({
    points,
    color = 'black',
    lineWidth = 1,
    dashed = false,
    dashSize = 0.2,
    gapSize = 0.15,
}: SimpleLineProps) {
    const positions = useMemo(() => new Float32Array(points.flat()), [points]);
    const geometry = useMemo(() => {
        const lineGeometry = new LineGeometry();
        lineGeometry.setPositions(positions);
        return lineGeometry;
    }, [positions]);

    useFrame(() => {
        // If needed, you can animate the dash offset here for dynamic effects
    });
    
    // return (
    //     <line>
    //         <bufferGeometry>
    //             <bufferAttribute
    //                 attach="attributes.position"
    //                 array={positions}
    //                 itemSize={3}
    //                 count={positions.length / 3}
    //             />
    //         </bufferGeometry>
    //         <lineBasicMaterial color={color} />
    //     </line>
    // );

    return (
        <line2>
            <primitive object={geometry} attach="geometry" />
            <lineMaterial
                attach="material"
                color={color}
                linewidth={lineWidth} // Set line width
                dashed={dashed} // Enable dashed lines
                dashSize={dashSize} // Set dash size
                gapSize={gapSize} // Set gap size
                resolution={[window.innerWidth, window.innerHeight]} // Required for LineMaterial
            />
        </line2>
    );
    
}
