import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import * as THREE from "three";
import { invalidate, useFrame, useThree } from "@react-three/fiber";
import DraggableObject from "./DraggableObject";
import { DoubleDoorType, WallType, zIndexEpsilon } from "../../types/wallTypes";
import { SelectableSymbol, BoundingBox } from "./SelectableSymbol";
import { projectToWorld } from "./projectToWorld";
import { FloorplannerStoreContext } from "../../store/floorplannerStore";
import AttachableSymbol from "./AttachableSymbol";
import { Line } from "@react-three/drei";
import { editorStore } from "../../store/editorStore";
import { observer } from "mobx-react-lite";
import { set, transaction } from "mobx";
import { is } from '@react-three/fiber/dist/declarations/src/core/utils';

export const defaultOpenAngle = Math.PI / 2;
export const defaultDoorWidth = 1;

interface DoubleDoorProps {
  doubleDoor: DoubleDoorType;
  attachedToWall?: WallType;
  onDragStart: (doubleDoor: DoubleDoorType, offset: [number, number]) => void;
  onDrag: (newPosition: [number, number]) => void;
  onDragEnd: (endPosition: [number, number]) => void;
}

const DoubleDoor: React.FC<DoubleDoorProps> = observer(({
  doubleDoor,
  attachedToWall,
  onDragStart,
  onDragEnd,
  onDrag,
}) => {
  const [currentOpenAngle, setCurrentOpenAngle] = useState(doubleDoor.openAngle);
  const currentOpenAngleRef = useRef(currentOpenAngle); // Ref for the current open angle in event listeners
  const { gl, camera } = useThree();
  const groupRef = useRef<THREE.Group>(null);
  const attachableRef = useRef<{ checkForAttachment: () => void }>(null);
  const floorplannerStore = React.useContext(FloorplannerStoreContext);

  const lineWeight = doubleDoor.lineWeight || floorplannerStore.doorLineWeight;
  const frameThickness = doubleDoor.doorFrameWidth || floorplannerStore.doorFrameWidth;
  const bladeThickness = doubleDoor.doorBladeThickness || floorplannerStore.doorBladeThickness;
  const [doubleDoorWidth, setDoubleDoorWidth] = useState(doubleDoor.doubleDoorWidth || floorplannerStore.doorWidth);
  const grabHandleSize = 0.12;
  const handleRadius = useMemo(() => grabHandleSize / editorStore.zoomLevelDivisor(), [editorStore.zoomLevel]);
  const handleDepth = 0.02;
  const flipX = 1;
  const flipY = doubleDoor.flipVertical ? -1 : 1;
  const [centerPosition, setCenterPosition] = useState<[number, number]>([
    doubleDoorWidth / 2,
    doubleDoorWidth / 2,
  ]);
  const lineColor = doubleDoor.lineColor || floorplannerStore.lineColor;
  const wallWidth = attachedToWall?.wallWidth || floorplannerStore.wallWidth;
  const wallLineWeight = floorplannerStore.convertLineWeightToWorld(attachedToWall?.lineWeight || floorplannerStore.wallLineWeight)
  const wallLineColor = attachedToWall?.lineColor || floorplannerStore.lineColor;
  const doorFrameWidth = doubleDoor.doorFrameWidth || floorplannerStore.doorFrameWidth;

  const hingePosition1 = useMemo(() => {
    return doubleDoor.flipHorizontal
      ? attachedToWall ? [doubleDoorWidth, (wallLineWeight / 2 + bladeThickness / 2) * flipY, 0] : [doubleDoorWidth, 0, 0]
      : attachedToWall ? [0, (wallLineWeight / 2 + bladeThickness / 2) * flipY, 0] : [0, 0, 0];
  }, [attachedToWall, bladeThickness, doubleDoor.flipHorizontal, doubleDoorWidth, flipY, wallLineWeight]);

  const hingePosition2 = useMemo(() => {
    return doubleDoor.flipHorizontal
      ? attachedToWall ? [doubleDoorWidth, (wallLineWeight / 2 + bladeThickness / 2) * flipY, 0] : [doubleDoorWidth, 0, 0]
      : attachedToWall ? [doubleDoorWidth * 2, (wallLineWeight / 2 + bladeThickness / 2) * flipY, 0] : [doubleDoorWidth * 2, 0, 0];
  }, [attachedToWall, bladeThickness, doubleDoor.flipHorizontal, doubleDoorWidth, flipY, wallLineWeight]);

  const openAngleGrabHandleX = useMemo(() => {
    const hingeOffsetX = 0;
    return hingeOffsetX + doubleDoorWidth * 0.6 * Math.cos(currentOpenAngle) * flipX;
  }, [currentOpenAngle, doubleDoorWidth, flipX]);

  const openAngleGrabHandleY = useMemo(() => {
    return doubleDoorWidth * 0.6 * Math.sin(currentOpenAngle) * flipY;
  }, [currentOpenAngle, doubleDoorWidth, flipY]);

  const [isHovered, setIsHovered] = useState(false);
  const [prevCameraZoom, setPrevCameraZoom] = useState(camera.zoom);
  const [isResizing, setIsResizing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [hoveringHandle, setHoveringHandle] = useState<string | null>(null);
  const noopRaycast = () => null;
  const aboutToModify = useRef(false);

  useFrame(() => {
    if (camera.zoom !== prevCameraZoom) {
      setPrevCameraZoom(camera.zoom);
      invalidate(); // Trigger a re-render
    }
  });

  const calculateBoundingBox = useCallback((): BoundingBox => {
    if (!groupRef.current) return { topLeft: [0, 0], topRight: [0, 0], bottomLeft: [0, 0], bottomRight: [0, 0], width: 0, height: 0, depth: 0 };

    const tempGroup = groupRef.current.clone();
    const originalRotation = tempGroup.rotation.clone();
    tempGroup.rotation.set(0, 0, 0);

    const boundingBox = new THREE.Box3().setFromObject(tempGroup);
    const size = new THREE.Vector3();
    boundingBox.getSize(size);
    tempGroup.rotation.copy(originalRotation);

    const topLeft = new THREE.Vector3(
      -(attachedToWall ? frameThickness + wallLineWeight : 0),
      size.y - (attachedToWall ? wallWidth / 2 + wallLineWeight : 0),
      0
    );
    const topRight = new THREE.Vector3(
      size.x - (attachedToWall ? frameThickness * 2 - wallLineWeight : 0),
      size.y - (attachedToWall ? wallWidth / 2 + wallLineWeight : 0),
      0
    );
    const bottomLeft = new THREE.Vector3(
      -(attachedToWall ? frameThickness + wallLineWeight : 0),
      -(attachedToWall ? wallWidth / 2 + wallLineWeight : doorFrameWidth),
      0
    );
    const bottomRight = new THREE.Vector3(
      size.x - (attachedToWall ? frameThickness * 2 - wallLineWeight : 0),
      -(attachedToWall ? wallWidth / 2 + wallLineWeight : doorFrameWidth),
      0
    );

    if (doubleDoor.flipVertical) {
      topLeft.y = -topLeft.y;
      topRight.y = -topRight.y;
      bottomLeft.y = -bottomLeft.y;
      bottomRight.y = -bottomRight.y;
      setCenterPosition([doubleDoorWidth + (attachedToWall ? frameThickness + wallLineWeight : 0) - size.x / 2, -(size.y / 2 - (attachedToWall ? wallWidth : 0))]);
    } else {
      setCenterPosition([size.x / 2 - (attachedToWall ? frameThickness + wallLineWeight : 0), size.y / 2 - (attachedToWall ? wallWidth : 0)]);
    }

    return {
      topLeft: [topLeft.x, topLeft.y],
      topRight: [topRight.x, topRight.y],
      bottomLeft: [bottomLeft.x, bottomLeft.y],
      bottomRight: [bottomRight.x, bottomRight.y],
      width: size.x,
      height: size.y,
      depth: size.z,
    };
  }, [attachedToWall, doubleDoor.flipVertical, doubleDoorWidth, wallLineWeight, frameThickness, wallWidth, currentOpenAngle]);

  const dragOffset = useRef({ x: 0, y: 0 });

  const onHandleDrag = useCallback((event: PointerEvent) => {
    if (aboutToModify.current) {
      floorplannerStore.pushToUndoStack();
      aboutToModify.current = false;
    }
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    const doorRotation = doubleDoor.rotation || 0;

    const mousePosition = new THREE.Vector2(worldX, worldY);
    const doorPosition = new THREE.Vector2(doubleDoor.position.x, doubleDoor.position.y);

    const offset = mousePosition.clone().sub(doorPosition);
    offset.rotateAround(new THREE.Vector2(0, 0), -doorRotation);

    const dx = offset.x - dragOffset.current.x;
    const dy = offset.y - dragOffset.current.y;

    const angle = Math.atan2(dy * flipY, dx * flipX);
    let newAngle = angle < 0 ? angle + 2 * Math.PI : angle;
    if (newAngle > Math.PI) newAngle = 0;
    else if (newAngle > Math.PI / 2) newAngle = Math.PI / 2;
    setCurrentOpenAngle(newAngle);
  }, [doubleDoor.position.x, doubleDoor.position.y, currentOpenAngle, flipX, flipY, gl, camera]);

  const onPointerDownHandle = useCallback((event: React.PointerEvent) => {
    event.stopPropagation();
    aboutToModify.current = true;
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    dragOffset.current = {
      x: worldX - (doubleDoorWidth * 0.75 * Math.cos(currentOpenAngle) * flipX + doubleDoor.position.x),
      y: worldY - (doubleDoorWidth * 0.75 * Math.sin(currentOpenAngle) * flipY + doubleDoor.position.y),
    };
    gl.domElement.addEventListener("pointermove", onHandleDrag);
    gl.domElement.addEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(true);
  }, [onHandleDrag, currentOpenAngle, doubleDoor.position.x, doubleDoor.position.y, doubleDoorWidth, flipX, flipY, gl]);

  const onPointerUpHandle = useCallback(() => {
    aboutToModify.current = false;
    if (doubleDoor.openAngle !== currentOpenAngleRef.current) {
      floorplannerStore.updateSymbolProperty(doubleDoor.id, "openAngle", currentOpenAngleRef.current);
    }
    gl.domElement.removeEventListener("pointermove", onHandleDrag);
    gl.domElement.removeEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.setDirty();
  }, [doubleDoor.openAngle, onHandleDrag, floorplannerStore, gl]);

  const handleDrag = useCallback((newPosition: [number, number]) => {
    onDrag(newPosition);
  }, [onDrag]);

  const generateArcPoints = useMemo(() => {
    const arcPoints = [];
    const segments = 64;
    for (let i = 0; i <= segments; i++) {
      const angle = (i / segments) * currentOpenAngle;
      arcPoints.push(new THREE.Vector3(
        doubleDoorWidth * Math.cos(angle) * flipX,
        doubleDoorWidth * Math.sin(angle) * flipY,
        0
      ));
    }
    return arcPoints;
  }, [currentOpenAngle, doubleDoorWidth, flipX, flipY]);

  const generateMirrorArcPoints = useMemo(() => {
    const arcPoints = [];
    const segments = 64;
    for (let i = 0; i <= segments; i++) {
      const angle = (i / segments) * currentOpenAngle;
      arcPoints.push(new THREE.Vector3(
        doubleDoorWidth * Math.cos(angle) * -flipX,
        doubleDoorWidth * Math.sin(angle) * flipY,
        0
      ));
    }
    return arcPoints;
  }, [currentOpenAngle, doubleDoorWidth, flipX, flipY]);

  const doorBladePoints = useMemo(() => [
    [0, -bladeThickness, 0] as [number, number, number],
    [doubleDoorWidth * flipX, -bladeThickness, 0] as [number, number, number],
    [doubleDoorWidth * flipX, bladeThickness * flipY - bladeThickness, 0] as [number, number, number],
    [0, bladeThickness * flipY - bladeThickness, 0] as [number, number, number],
    [0, -bladeThickness, 0] as [number, number, number],
  ], [bladeThickness, doubleDoorWidth, flipX, flipY]);

  const doorBladePointsFlipY = useMemo(() => [
    [0, bladeThickness, 0] as [number, number, number],
    [doubleDoorWidth * flipX, bladeThickness, 0] as [number, number, number],
    [doubleDoorWidth * flipX, bladeThickness * flipY + bladeThickness, 0] as [number, number, number],
    [0, bladeThickness * flipY + bladeThickness, 0] as [number, number, number],
    [0, bladeThickness, 0] as [number, number, number],
  ], [bladeThickness, doubleDoorWidth, flipX, flipY]);

  const doorBladePointsMirror = useMemo(() => [
    [0, 0, 0] as [number, number, number],
    [doubleDoorWidth * flipX, 0, 0] as [number, number, number],
    [doubleDoorWidth * flipX, bladeThickness * flipY, 0] as [number, number, number],
    [0, bladeThickness * flipY, 0] as [number, number, number],
    [0, 0, 0] as [number, number, number],
  ], [bladeThickness, doubleDoorWidth, flipX, flipY]);

  const generateFramePoints = useCallback((wallWidth: number, side: number): [number, number, number][] => {
    const offset = (side * (doubleDoorWidth) + doubleDoorWidth);
    return [
      [offset, -wallWidth / 2, 0],
      [offset + frameThickness * side, -wallWidth / 2, 0],
      [offset + frameThickness * side, wallWidth / 2, 0],
      [offset, wallWidth / 2, 0],
      [offset, -wallWidth / 2, 0], // Closing the frame
    ];
  }, [doubleDoorWidth, frameThickness]);

  useEffect(() => {
    setCurrentOpenAngle(doubleDoor.openAngle);
  }, [doubleDoor.openAngle]);

  useEffect(() => {
    setDoubleDoorWidth(doubleDoor.doubleDoorWidth);
  }, [doubleDoor.doubleDoorWidth]);

  // Update the ref whenever currentOpenAngle changes
  useEffect(() => {
    currentOpenAngleRef.current = currentOpenAngle;
  }, [currentOpenAngle]);

  useEffect(() => {
    editorStore.updateGroupRef(doubleDoor.id, groupRef.current);
  }, [doubleDoor.id, floorplannerStore]);

  return (
    <group
      position={[0, 0, (doubleDoor.zIndex * zIndexEpsilon)]}
    >
      <DraggableObject
        position={[doubleDoor.position.x, doubleDoor.position.y]}
        onDragStart={(offset) => {
          onDragStart(doubleDoor, offset);
          setIsDragging(true);
        }}
        onDragEnd={(endPosition) => {
          onDragEnd(endPosition)
          setIsDragging(false);
        }}
        onDrag={handleDrag}
        selectable={true}
        attachmentId={doubleDoor.id}
        attachmentType="doorAttachments"
        symbol={doubleDoor}
      >
        <AttachableSymbol
          attachmentId={doubleDoor.id}
          attachmentType="doorAttachments"
          onAttachment={(attachPosition) => {
            // Handle attachment logic
          }}
          store={floorplannerStore}
          ref={attachableRef}
        >
          <SelectableSymbol
            ref={groupRef}
            handleSize={floorplannerStore.symbolHandleSize}
            calculateBoundingBox={calculateBoundingBox}
            onResizeStart={() => setIsResizing(true)}
            onResizeEnd={() => setIsResizing(false)}
            onResize={(newWidth, newHeight, boundingBox, handle) => {
              newWidth -= newWidth / 2;
              newWidth -= attachedToWall ? frameThickness * 2 + wallLineWeight * 2 : frameThickness * 2;
              const widthDiff = newWidth - doubleDoorWidth;
              transaction(() => {
                floorplannerStore.updateSymbolProperty(doubleDoor.id, "doubleDoorWidth", newWidth);
                const position = [
                  (handle === "topLeft" || handle === "bottomLeft") ? doubleDoor.position.x - (widthDiff * 2) * Math.cos(doubleDoor.rotation || 0) : doubleDoor.position.x,
                  (handle === "topLeft" || handle === "bottomLeft") ? doubleDoor.position.y - (widthDiff * 2) * Math.sin(doubleDoor.rotation || 0) : doubleDoor.position.y,
                ]
                if (attachedToWall) {
                  floorplannerStore.detachSymbolFromWall(doubleDoor.id);
                  floorplannerStore.updateSymbolProperty(doubleDoor.id, "position", position);
                  floorplannerStore.attachSymbolToWall(doubleDoor.id, attachedToWall.id, [position[0], position[1]]);
                } else {
                  floorplannerStore.updateSymbolProperty(doubleDoor.id, "position", position);
                }
              });
            }}
            center={centerPosition}
            rotation={doubleDoor.rotation}
            symbol={doubleDoor}
            onPointerOver={() => setIsHovered(true)}
            onPointerOut={() => setIsHovered(false)}
            isDragging={isDragging}
          >
            <group
              ref={groupRef}
              rotation={[0, 0, doubleDoor.rotation || 0]}
              onPointerEnter={() => {
                if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.add("cursor-vector");
              }}
              onPointerLeave={() => {
                if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.remove("cursor-vector");
              }}
            >
              {/* Invisible box to catch events on any unfilled areas */}
              <mesh
                position={[
                  doubleDoorWidth,
                  doubleDoorWidth / 2,
                  -0.03
                ]}
                rotation={[0, 0, 0]}
                onPointerEnter={() => {
                  if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.add("cursor-vector");
                }}
                onPointerLeave={() => {
                  if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.remove("cursor-vector");
                }}
              >
                <boxGeometry args={[doubleDoorWidth * 2, doubleDoorWidth, 0.06]} />
                <meshBasicMaterial
                  //color="white"
                  transparent
                  opacity={0.0}
                />
              </mesh>
              {attachedToWall && (
                <>
                  {/* <mesh position={[-doorFrameWidth - wallLineWeight / 2, 0, 0]}>
                  <boxGeometry args={[wallLineWeight, wallWidth + wallLineWeight, 0.001]} />
                  <meshStandardMaterial color={wallLineColor} />
                </mesh>
                <mesh position={[doubleDoorWidth * 2 + doorFrameWidth + wallLineWeight / 2, 0, 0]}>
                  <boxGeometry args={[wallLineWeight, wallWidth + wallLineWeight, 0.001]} />
                  <meshStandardMaterial color={wallLineColor} />
                </mesh> */}
                </>
              )}

              <Line
                position={new THREE.Vector3(hingePosition1[0], hingePosition1[1], hingePosition1[2])}
                points={generateArcPoints.map((point) => [point.x, point.y, point.z])}
                color={isHovered || doubleDoor.selected ? 'blue' : lineColor}
                lineWidth={lineWeight / 2}
                {...(doubleDoor.lineType === "dashed" && {
                  dashed: true,
                  dashSize: 0.15,
                  gapSize: 0.1,
                })}
                raycast={noopRaycast}
              />

              <Line
                position={new THREE.Vector3(hingePosition2[0], hingePosition2[1], hingePosition2[2])}
                points={generateMirrorArcPoints.map((point) => [point.x, point.y, point.z])}
                color={isHovered || doubleDoor.selected ? 'blue' : lineColor}
                lineWidth={lineWeight / 2}
                {...(doubleDoor.lineType === "dashed" && {
                  dashed: true,
                  dashSize: 0.15,
                  gapSize: 0.1,
                })}
                raycast={noopRaycast}
              />

              <Line
                points={flipY === 1 ? doorBladePoints : doorBladePointsFlipY}
                color={isHovered || doubleDoor.selected ? 'blue' : lineColor}
                lineWidth={lineWeight}
                position={new THREE.Vector3(hingePosition1[0], hingePosition1[1], hingePosition1[2])}
                rotation={[0, 0, currentOpenAngle * flipX * flipY]}
                {...(doubleDoor.lineType === "dashed" && {
                  dashed: true,
                  dashSize: 0.15,
                  gapSize: 0.1,
                })}
                raycast={noopRaycast}
              />
              <Line
                points={doorBladePointsMirror}
                color={isHovered || doubleDoor.selected ? 'blue' : lineColor}
                lineWidth={lineWeight}
                position={new THREE.Vector3(hingePosition2[0], hingePosition2[1], hingePosition2[2])}
                rotation={[0, 0, Math.PI - currentOpenAngle * flipX * flipY]}
                {...(doubleDoor.lineType === "dashed" && {
                  dashed: true,
                  dashSize: 0.15,
                  gapSize: 0.1,
                })}
                raycast={noopRaycast}
              />

              {attachedToWall && (
                <>
                  <Line
                    points={generateFramePoints(wallWidth, -1)}
                    color={isHovered || doubleDoor.selected ? 'blue' : lineColor}
                    lineWidth={1}
                    {...(doubleDoor.lineType === "dashed" && {
                      dashed: true,
                      dashSize: 0.15,
                      gapSize: 0.1,
                    })}
                    raycast={noopRaycast}
                  />
                  <Line
                    points={generateFramePoints(wallWidth, 1)}
                    color={isHovered || doubleDoor.selected ? 'blue' : lineColor}
                    lineWidth={1}
                    {...(doubleDoor.lineType === "dashed" && {
                      dashed: true,
                      dashSize: 0.15,
                      gapSize: 0.1,
                    })}
                    raycast={noopRaycast}
                  />
                </>
              )}

              {(doubleDoor.selected && !isResizing && !isDragging && editorStore.selections.length === 1) && (
                <group
                  onPointerDown={onPointerDownHandle as unknown as (event: THREE.Event) => void}
                  onPointerUp={onPointerUpHandle as unknown as (event: THREE.Event) => void}
                  onPointerOver={() => setHoveringHandle("openAngle")}
                  onPointerOut={() => setHoveringHandle(null)}
                  position={[
                    openAngleGrabHandleX,
                    openAngleGrabHandleY,
                    0,
                  ]}
                >
                  <mesh>
                    <torusGeometry args={[handleRadius - 0.002, 0.005, 16, 100]} />
                    <meshBasicMaterial color="blue" />
                  </mesh>
                  <mesh
                    position={[0, 0, handleDepth / 2]}
                    rotation={[Math.PI / 2, 0, 0]}
                  >
                    <cylinderGeometry args={[handleRadius - 0.02, handleRadius - 0.006, handleDepth, 32]} />
                    <meshBasicMaterial color={hoveringHandle === "openAngle" ? "blue" : "white"} />
                  </mesh>
                </group>
              )}
            </group>
          </SelectableSymbol>
        </AttachableSymbol>
      </DraggableObject>
    </group>
  );
});

export default DoubleDoor;
