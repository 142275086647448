import { floorplannerStore } from '../../store/floorplannerStore';
import { WallConnectionEnd, WallConnectionStart, WallShapeType, WallType } from '../../types/wallTypes';
import { calculateLineVertices } from './calculateLineVertices';

export const composeLines = (
    filledLines: WallShapeType[],
    leftLines: WallShapeType[],
    rightLines: WallShapeType[],
    endCaps: WallShapeType[],
) => {
    const {
        wallsMap,
        wallWidth,
    } = floorplannerStore;
    //for (const wall of Object.values(wallsMap)) {
    Array.from(wallsMap.values()).forEach((wall) => {
        const customWallWidth = wall.wallWidth || wallWidth;
        const customWallWeight = floorplannerStore.convertLineWeightToWorld(wall.lineWeight || floorplannerStore.wallLineWeight)
        filledLines.push({
            wall: wall,
            paths: calculateLineVertices(
                wall.start,
                wall.end,
                0,
                customWallWeight,
                customWallWidth,
                wall.lineForm === "arc" ? "arc" : "straight",
                wall.controlPoint
            ),
            lineSide: "inner",
        });
        leftLines.push({
            wall: wall,
            paths: calculateLineVertices(
                wall.start,
                wall.end,
                -(customWallWidth + customWallWeight),
                customWallWeight,
                customWallWidth,
                wall.lineForm === "arc" ? "arcLeft" : "left",
                wall.controlPoint
            ),
            lineSide: "left",
        });
        rightLines.push({
            wall: wall,
            paths: calculateLineVertices(
                wall.start,
                wall.end,
                customWallWidth + customWallWeight,
                customWallWeight,
                customWallWidth,
                wall.lineForm === "arc" ? "arcRight" : "right",
                wall.controlPoint
            ),
            lineSide: "right",
        });
        let endCap: ("endCapBoth" | "endCapStart" | "endCapEnd" | "") = "";
        const endPoints = wall.connections?.filter(
            (c) =>
                c.sourcePosition === WallConnectionEnd ||
                c.sourcePosition === WallConnectionStart,
        );
        if (endPoints === undefined || endPoints.length === 0 ||
            endPoints?.filter(
                (c) =>
                    c.sourcePosition === WallConnectionStart ||
                    c.sourcePosition === WallConnectionEnd,
            ).length === 0
        ) {
            endCap = "endCapBoth";
        } else if (
            endPoints?.filter((c) => c.sourcePosition === WallConnectionStart)
                .length === 0
        ) {
            endCap = "endCapStart";
        } else if (
            endPoints?.filter((c) => c.sourcePosition === WallConnectionEnd)
                .length === 0
        ) {
            endCap = "endCapEnd";
        }
        if (endCap !== "") {
            endCaps.push({
                wall: wall,
                paths: calculateLineVertices(
                    wall.start,
                    wall.end,
                    0,
                    customWallWeight,
                    customWallWidth,
                    endCap,
                ),
                lineSide: "endcap",
            });
        } else {
            endCaps.push({
                wall: wall,
                paths: [],
                lineSide: "endcap",
            });
        }
    });
};

export const composePaths = (
    wallId: string,
    innerShapes: WallShapeType[],
    outerShapes1: WallShapeType[],
    outerShapes2: WallShapeType[],
    endCaps: WallShapeType[],
) => {
    const wall = floorplannerStore.wallsMap.get(wallId);
    if (!wall) {
        return;
    }
    const {
        wallWidth,
    } = floorplannerStore;
    const customWallWidth = wall.wallWidth || wallWidth;
    const customWallWeight = floorplannerStore.convertLineWeightToWorld(wall.lineWeight || floorplannerStore.wallLineWeight)
    innerShapes.push({
        wall: { ...wall },
        paths: calculateLineVertices(
            wall.start,
            wall.end,
            0,
            customWallWeight,
            customWallWidth,
            wall.lineForm === "arc" ? "arc" : "straight",
            wall.controlPoint
        ),
        lineSide: "inner",
    });
    outerShapes1.push({
        wall: { ...wall },
        paths: calculateLineVertices(
            wall.start,
            wall.end,
            -(customWallWidth + customWallWeight),
            customWallWeight,
            customWallWidth,
            wall.lineForm === "arc" ? "arcLeft" : "left",
            wall.controlPoint
        ),
        lineSide: "left",
    });
    outerShapes2.push({
        wall: { ...wall },
        paths: calculateLineVertices(
            wall.start,
            wall.end,
            customWallWidth + customWallWeight,
            customWallWeight,
            customWallWidth,
            wall.lineForm === "arc" ? "arcRight" : "right",
            wall.controlPoint
        ),
        lineSide: "right",
    });
    let endCap: ("endCapBoth" | "endCapStart" | "endCapEnd" | "") = "";
    const endPoints = wall.connections?.filter(
        (c) =>
            c.sourcePosition === WallConnectionEnd ||
            c.sourcePosition === WallConnectionStart,
    );
    if (endPoints === undefined || endPoints.length === 0 ||
        endPoints?.filter(
            (c) =>
                c.sourcePosition === WallConnectionStart ||
                c.sourcePosition === WallConnectionEnd,
        ).length === 0
    ) {
        endCap = "endCapBoth";
    } else if (
        endPoints?.filter((c) => c.sourcePosition === WallConnectionStart)
            .length === 0
    ) {
        endCap = "endCapStart";
    } else if (
        endPoints?.filter
            ((c) => c.sourcePosition === WallConnectionEnd)
            .length === 0
    ) {
        endCap = "endCapEnd";
    }
    if (endCap !== "") {
        endCaps.push({
            wall: { ...wall },
            paths: calculateLineVertices(
                wall.start,
                wall.end,
                0,
                customWallWeight,
                customWallWidth,
                endCap,
            ),
            lineSide: "endcap",
        });
    } else {
        endCaps.push({
            wall: { ...wall },
            paths: [],
            lineSide: "endcap",
        });
    }
}