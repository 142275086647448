import * as THREE from "three";
import { isWallType, RulerLineType, SingleLineType, WallType } from "../../types/wallTypes";
import { floorplannerStore } from "../../store/floorplannerStore";
import { editorStore } from "../../store/editorStore";

export const handleWidth = 0.08;
export const handleSize = 0.08;
export const tubeRadius = 0.02;

export const createMiddleDragHandle = (wall: WallType | SingleLineType | RulerLineType, wallWidth: number) => {
  const shape = new THREE.Shape();
  const extraHeight = floorplannerStore.convertLineWeightToWorld(wall.lineWeight || floorplannerStore.wallLineWeight) * 2 + 0.02;
  const customWallWidth = isWallType(wall) ? wall.wallWidth || wallWidth : wallWidth;
  const height = customWallWidth + extraHeight;
  const width = height;
  shape.moveTo(-width / 2, -height / 2);
  shape.lineTo(width / 2, -height / 2);
  shape.lineTo(width / 2, height / 2);
  shape.lineTo(-width / 2, height / 2);
  shape.lineTo(-width / 2, -height / 2);

  const hole = new THREE.Path();
  const holeWidth = width - (0.02 / editorStore.zoomLevelDivisor());
  const holeHeight = height - (0.02 / editorStore.zoomLevelDivisor());
  hole.moveTo(-holeWidth / 2, -holeHeight / 2);
  hole.lineTo(holeWidth / 2, -holeHeight / 2);
  hole.lineTo(holeWidth / 2, holeHeight / 2);
  hole.lineTo(-holeWidth / 2, holeHeight / 2);
  hole.lineTo(-holeWidth / 2, -holeHeight / 2);

  shape.holes.push(hole);

  return new THREE.ExtrudeGeometry(shape, {
    depth: 0.01,
    bevelEnabled: false,
  });
};
