import React, { useRef, useState, useEffect, useCallback } from "react";
import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import DraggableObject from "./DraggableObject";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { FloorplannerStoreContext } from "../../store/floorplannerStore";
import { Line, Text } from "@react-three/drei";
import { AreaSymbolType } from "../../types/wallTypes";
import { projectToWorld } from "./projectToWorld";
import { editorStore } from "../../store/editorStore";
import { reaction } from "mobx";
import { SimpleText } from "../Text/SimpleText";

interface AreaSymbolProps {
    symbol: AreaSymbolType;
    onDrag: (index: number, newPosition: [number, number]) => void;
    onDragStart: (event: React.PointerEvent) => void;
    onDragEnd: (event: React.PointerEvent) => void;
}

const AreaSymbol: React.FC<AreaSymbolProps> = observer(({ symbol, onDrag }) => {
    const { gl, camera } = useThree();
    const groupRef = useRef<THREE.Group>(null);
    const floorplannerStore = useContext(FloorplannerStoreContext);
    const [area, setArea] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const lineWeight = floorplannerStore.lineWeight;
    const noopRaycast = () => null;
    const areaTextFontSize = 14 / editorStore.zoomLevelDivisor();

    const [zoomDerivedValues, setZoomDerivedValues] = useState(() => ({
        textFontSize: 12 / editorStore.zoomLevelDivisor(),
        textBoxPadding: 0.13 / editorStore.zoomLevelDivisor(),
        textBoxHeight: 0.16 / editorStore.zoomLevelDivisor(),
        textBoxWidth: 0.6 / editorStore.zoomLevelDivisor(),
    }));

    // Check if the shape is closed
    const isClosedShape = (
        symbol.vertices.length > 1 &&
        areVerticesEqual(
            symbol.vertices[0],
            symbol.vertices[symbol.vertices.length - 1]
        )
    );

    // Calculate the centroid (center) of the polygon
    const calculateCentroid = useCallback(() => {
        if (symbol.vertices.length < 3) return new THREE.Vector3(0, 0, 0);

        let areaSum = 0;
        let cx = 0;
        let cy = 0;

        for (let i = 0; i < symbol.vertices.length; i++) {
            const j = (i + 1) % symbol.vertices.length;
            const xi = symbol.vertices[i].x;
            const yi = symbol.vertices[i].y;
            const xj = symbol.vertices[j].x;
            const yj = symbol.vertices[j].y;

            const crossProduct = xi * yj - xj * yi;
            areaSum += crossProduct;
            cx += (xi + xj) * crossProduct;
            cy += (yi + yj) * crossProduct;
        }

        const area = areaSum / 2;
        cx = cx / (6 * area);
        cy = cy / (6 * area);

        return new THREE.Vector3(cx, cy, 0);
    }, [symbol.vertices]);

    // Calculate the center position
    const centerPos = calculateCentroid();

    // Helper to calculate the area of the polygon
    const calculateArea = useCallback(() => {
        if (symbol.vertices.length < 3) return 0;
        let sum = 0;
        for (let i = 0; i < symbol.vertices.length; i++) {
            const j = (i + 1) % symbol.vertices.length;
            sum +=
                symbol.vertices[i].x * symbol.vertices[j].y -
                symbol.vertices[j].x * symbol.vertices[i].y;
        }
        return Math.abs(sum / 2);
    }, [symbol.vertices]);

    const onHandleDrag = (index: number, event: React.PointerEvent) => {
        const [worldX, worldY] = projectToWorld(
            event.clientX,
            event.clientY,
            gl,
            camera
        );
        onDrag(index, [worldX, worldY]);
    };

    // Utility function to compare vertices
    function areVerticesEqual(v1: { x: number; y: number }, v2: { x: number; y: number }) {
        return v1.x === v2.x && v1.y === v2.y;
    }

    // Convert plain vertices to THREE.Vector2 instances for ShapeGeometry
    const shapeVertices = symbol.vertices.map(
        (v) => new THREE.Vector2(v.x, v.y)
    );

    useEffect(() => {
        setArea(calculateArea());
    }, [symbol.vertices, calculateArea]);

    useEffect(() => {
        // Update zoom-derived values only when zoomLevel changes
        const dispose = reaction(
            () => editorStore.zoomLevel,
            () => {
                setZoomDerivedValues({
                    textFontSize: 0.115 / editorStore.zoomLevelDivisor(),
                    textBoxPadding: 0.13 / editorStore.zoomLevelDivisor(),
                    textBoxHeight: 0.16 / editorStore.zoomLevelDivisor(),
                    textBoxWidth: 0.6 / editorStore.zoomLevelDivisor(),
                });
            }
        );
        return () => dispose(); // Cleanup reaction on unmount
    }, []);

    return (
        <group
            ref={groupRef}
            position={[symbol.position.x, symbol.position.y, 0.02]}
        >
            {symbol.vertices.length > 1 ? (
                <>
                    {/* Filled mesh for the shape */}
                    {(editorStore.areaConstructionMode || symbol.selected) && (
                        <mesh
                        >
                            <shapeGeometry args={[new THREE.Shape(shapeVertices)]} />
                            <meshBasicMaterial
                                color="blue"
                                transparent={true}
                                opacity={0.3}
                                side={THREE.DoubleSide}
                            />
                        </mesh>
                    )}

                    {/* Outline */}
                    <Line
                        points={symbol.vertices.map((v) => [v.x, v.y, 0])}
                        color={(editorStore.areaConstructionMode || symbol.selected) ? "blue" : "black"}
                        lineWidth={0.5}
                        dashed={true}
                        dashSize={0.2}
                        gapSize={0.15}
                        raycast={noopRaycast}
                    />

                    {/* Interactive handles */}
                    {/* {symbol.vertices.map((vertex, index) => (
                            <mesh
                                key={index}
                                position={[vertex.x, vertex.y, 0.01]} // slight offset to ensure visibility above other elements
                                onPointerDown={(e) => {
                                    e.stopPropagation();
                                    const handlePointerMove = (event: PointerEvent) =>
                                        onHandleDrag(
                                            index,
                                            event as unknown as React.PointerEvent
                                        );
                                    gl.domElement.addEventListener(
                                        "pointermove",
                                        handlePointerMove
                                    );
                                    gl.domElement.addEventListener(
                                        "pointerup",
                                        () => {
                                            gl.domElement.removeEventListener(
                                                "pointermove",
                                                handlePointerMove
                                            );
                                        },
                                        { once: true }
                                    );
                                }}
                                onPointerOver={(e) => {
                                    e.stopPropagation();
                                    setIsHovered(true);
                                    ((e.object as THREE.Mesh).material as THREE.MeshBasicMaterial).color.set("blue");
                                }}
                                onPointerOut={(e) => {
                                    e.stopPropagation();
                                    setIsHovered(false);
                                    ((e.object as THREE.Mesh).material as THREE.MeshBasicMaterial).color.set("gray");
                                }}
                            >
                                <circleGeometry args={[0.05, 16]} />
                                <meshBasicMaterial color={isHovered ? "blue" : "gray"} />
                            </mesh>
                        ))} */}

                    {/* Area label */}
                    {(area > 0) && (
                        <group
                            position={[centerPos.x, centerPos.y, 0.02]} // Slightly above the shape
                            onClick={(e) => {
                                e.stopPropagation();
                                floorplannerStore.updateSymbolProperty(symbol.id, "selected", true);
                                editorStore.addSelection(symbol);
                            }}
                            onPointerEnter={() => {
                                if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.add("cursor-vector");
                            }}
                            onPointerLeave={() => {
                                if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.remove("cursor-vector");
                            }}
                        >
                            {/* Background Plane */}
                            <mesh position={[0, 0, -0.01]}>
                                <planeGeometry
                                    args={[
                                        zoomDerivedValues.textBoxWidth + zoomDerivedValues.textBoxPadding,
                                        zoomDerivedValues.textBoxHeight,
                                    ]}
                                />
                                <meshBasicMaterial color={"white"} />
                            </mesh>

                            {/* Text */}
                            <SimpleText
                                fontSize={areaTextFontSize}
                                color="#222222"
                            >
                                {`${area.toFixed(2)} m²`}
                            </SimpleText>
                        </group>
                    )}
                </>
            ) : (
                // Render default symbol when there are not enough vertices
                <DraggableObject
                    position={[symbol.position.x, symbol.position.y]}
                    onDrag={(event) => {
                        const [worldX, worldY] = projectToWorld(
                            event[0],
                            event[1],
                            gl,
                            camera
                        );
                        onDrag(0, [worldX, worldY]);
                    }}
                    onDragStart={() => setIsDragging(true)}
                    onDragEnd={() => setIsDragging(false)}
                    selectable={true}
                >
                    <mesh>
                        <boxGeometry args={[1, 1, 1]} />
                        <meshStandardMaterial color="gray" />
                    </mesh>
                </DraggableObject>
            )}
        </group>
    );
});

export default AreaSymbol;
