import { isRulerLineType, RulerLineType, SingleLineType, WallConnectionEnd, WallConnectionStart, WallType } from "../../types/wallTypes";
import * as THREE from 'three';
import { floorplannerStore } from '../../store/floorplannerStore';
import { editorStore } from "../../store/editorStore";
import { updateAlignmentLines } from "./updateAligmentLines";

const snapAngle = (angle: number, snapInterval: number) => {
    return Math.round(angle / snapInterval) * snapInterval;
};

/**
 * Drag the end handle of the object, it will move the end of the object maintaining connections and updates their positions
 * @param source The object to drag
 * @param endOffsetX The end x offset of the wall
 * @param endOffsetY The end y offset of the wall
 * @param newX The new x position of the wall
 * @param newY The new y position of the wall
 * @param dragEndFreeToConnect If the end is free to snap
 * @param delta The delta vector
 * @returns boolean If the wall was snapped
 */
export const dragEndHandle = (
    source: WallType | SingleLineType | RulerLineType,
    endOffsetX: number,
    endOffsetY: number,
    newX: number,
    newY: number,
    dragEndFreeToConnect: boolean,
    delta: THREE.Vector2,  // Add this new argument
): boolean => {
    const object = floorplannerStore.findObjectId(source.id);
    if (!object) {
        return false;
    }
    let snapped = false;
    let newDelta = new THREE.Vector2(newX - endOffsetX, newY - endOffsetY).sub(
        object.end,
    );
    const newEndPosition = object.end.clone().add(newDelta);
    // Angle snapping when Shift is not pressed
    if (!editorStore.isShiftPressed) {  // Correctly check `isShiftPressed`
        const deltaX = newEndPosition.x - object.start.x;
        const deltaY = newEndPosition.y - object.start.y;
        const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI); // Convert to degrees

        const currentDistance = newEndPosition.distanceTo(object.start);

        const normalizedAngle = (angle + 360) % 360;
        const snapAngles = [0, 45, 90, 135, 180, 225, 270, 315, 360];
        let closestSnapAngle = snapAngles.reduce((prev, curr) => Math.abs(curr - normalizedAngle) < Math.abs(prev - normalizedAngle) ? curr : prev);

        const radianSnapAngle = closestSnapAngle * (Math.PI / 180);
        const snapEndX = object.start.x + currentDistance * Math.cos(radianSnapAngle);
        const snapEndY = object.start.y + currentDistance * Math.sin(radianSnapAngle);

        const snapDistance = Math.sqrt(Math.pow(newEndPosition.x - snapEndX, 2) + Math.pow(newEndPosition.y - snapEndY, 2));

        // If within snapping tolerance, snap to the nearest 45-degree angle
        if (snapDistance < 0.2) {
            newEndPosition.x = snapEndX;
            newEndPosition.y = snapEndY;
        }
    } 

    // Handle wall connections and snapping
    if (dragEndFreeToConnect && !isRulerLineType(object)) {
        floorplannerStore.removeConnectionEnd(object.id, WallConnectionEnd);
        snapped = floorplannerStore.snapToOtherObjects(object.id, WallConnectionEnd, newEndPosition, false);
    }

    // Snap to alignment lines and update position if no Shift is pressed
    if (!snapped && !editorStore.isShiftPressed && !isRulerLineType(object)) {
        floorplannerStore.updateObjectPosition(object.id, object.start, newEndPosition);
        const updObject = floorplannerStore.findObjectId(object.id);
        if (updObject) {
            snapped = floorplannerStore.snapToAlignmentLines(object.id, WallConnectionEnd, updObject.end);
            if (snapped) {
                const updObject = floorplannerStore.findObjectId(object.id);
                if (updObject) {
                    updateAlignmentLines(object.id, updObject.end.x, updObject.end.y, "end");
                }
            }
        }
    }

    if (!snapped) {
        floorplannerStore.updateObjectPosition(object.id, object.start, newEndPosition);
    }

    const startConnectedToId = floorplannerStore.objectConnectedTo(object.id, WallConnectionStart);
    if (startConnectedToId) {
        floorplannerStore.objectNeedsUpdate(startConnectedToId);
    }

    editorStore.setWhichEndToUpdate("end");
    if (object.type === "singleLine") {
        const lineLength = floorplannerStore.singleLineLength(object.id);
        editorStore.setSingleLineEditingLengthPanel(lineLength);
    } else if (object.type === "rulerLine") {
        const lineLength = floorplannerStore.rulerLineLength(object.id);
        editorStore.setRulerEditingLengthPanel(lineLength);
    } else if (object.type === "wall") {
        const wallLength = floorplannerStore.wallOuterLength(object);
        editorStore.setWallEditingLengthPanel(wallLength);
    }

    delta.x = newDelta.x;
    delta.y = newDelta.y;
    return snapped;
}