import {
  RoomType,
} from "../../types/wallTypes";
import { observer } from "mobx-react-lite";
import { floorplannerStore } from "../../store/floorplannerStore";
import { RoomLabel } from "./RoomLabel";

const RoomLabelStructure = observer(() => {
  return (
    <>
      {/* <RoomLabels rooms={rooms} /> */}
      {
        Array.from(floorplannerStore.roomsMap.values()).map((room: RoomType) => (
          <RoomLabel key={room.id} room={room} />
        ))
      }
    </>

  );
});

export default RoomLabelStructure;
