import * as THREE from "three";
import { runInAction } from "mobx";
import { floorplannerStore } from "./floorplannerStore";
import { WallConnectionEnd, WallConnectionStart, WallType } from "../types/wallTypes";
import { renderStore } from "./renderStore";
import { editorStore } from "./editorStore";

export const snapToOtherObjects = (
  sourceObject: string,
  snappingEnd: number,
  edge: THREE.Vector2,
  updateOtherEnd: boolean = true,
): boolean => {
  const object = floorplannerStore.findObjectId(sourceObject);
  if (object) {
    // Make sure that the snappingEnd is free from any connections
    if (object.connections) {
      const connection = object.connections.find(
        (c) => c.sourcePosition === snappingEnd,
      );
      if (connection) {
        return false;
      }
    }
    for (const targetWall of floorplannerStore.wallsMap.values()) {
      const snappedWall = checkAgainstObject(
        object.id,
        targetWall.id,
        snappingEnd,
        edge,
        updateOtherEnd,
      );
      if (snappedWall) {
        return true;
      }
    }
    for (const targetLine of floorplannerStore.singleLinesMap.values()) {
      const snappedWall = checkAgainstObject(
        object.id,
        targetLine.id,
        snappingEnd,
        edge,
        updateOtherEnd,
      );
      if (snappedWall) {
        return true;
      }
    };
    for (const targetRuler of floorplannerStore.rulerLinesMap.values()) {
      const snappedWall = checkAgainstObject(
        object.id,
        targetRuler.id,
        snappingEnd,
        edge,
        updateOtherEnd,
      );
      if (snappedWall) {
        return true;
      }
    };
  }
  return false;
}

const checkAgainstObject = (
  source: string,
  target: string,
  snappingEnd: number,
  edge: THREE.Vector2,
  updateOtherEnd: boolean = true,
): any => {
  const sourceObject = floorplannerStore.findObjectId(source);
  const targetObject = floorplannerStore.findObjectId(target);
  if (sourceObject && targetObject) {
    // Make sure that the target object is not already connected to the source object on any end
    const alreadyConnected = targetObject.connections?.find(
      (c: { id: string; }) => c.id === sourceObject.id,
    );
    if (!alreadyConnected) {
      // Check if the target object is not the same as the source object
      if (targetObject.id !== sourceObject.id) {
        if (!targetObject.start || !targetObject.end) {
          return null;
        }
        // Create a line from the target object to calculate the closest point against
        const targetLine = new THREE.Line3(
          new THREE.Vector3(targetObject.start.x, targetObject.start.y, 0),
          new THREE.Vector3(targetObject.end.x, targetObject.end.y, 0),
        );
        if (!edge || !edge.x || !edge.y) {
          return null;
        }
        const closestEdge = new THREE.Vector3();
        targetLine.closestPointToPoint(
          new THREE.Vector3(edge.x, edge.y, 0),
          true,
          closestEdge,
        );
        // Check if the closest point is within the snap threshold
        if (
          edge.distanceTo(
            new THREE.Vector2(closestEdge.x, closestEdge.y),
          ) < editorStore.snappingTolerance
        ) {
          runInAction(() => {
            // If the closestEdge is near the target object start, we snap to the start
            if (closestEdge.distanceTo(new THREE.Vector3(targetObject.start.x, targetObject.start.y, 0)) < editorStore.snappingTolerance) {
              closestEdge.set(targetObject.start.x, targetObject.start.y, 0);
            }
            // If the closestEdge is near the target object end, we snap to the end
            if (closestEdge.distanceTo(new THREE.Vector3(targetObject.end.x, targetObject.end.y, 0)) < editorStore.snappingTolerance) {
              closestEdge.set(targetObject.end.x, targetObject.end.y, 0);
            }
            // Save the original object length and angle
            const originalObjectLength = sourceObject.start.distanceTo(sourceObject.end);
            const originalObjectAngle = Math.atan2(
              sourceObject.end.y - sourceObject.start.y,
              sourceObject.end.x - sourceObject.start.x,
            );
            // Since we are in snapping distance, we need to move the whole object (to snap)
            if (snappingEnd === WallConnectionStart) {
              const start = new THREE.Vector2(closestEdge.x, closestEdge.y);
              const end = sourceObject.end.clone();
              if (updateOtherEnd) {
                end.set(
                  start.x + originalObjectLength * Math.cos(originalObjectAngle),
                  start.y + originalObjectLength * Math.sin(originalObjectAngle),
                );
              }
              floorplannerStore.updateObjectPosition(sourceObject.id, start, end);
            } else {
              const start = sourceObject.start.clone();
              const end = new THREE.Vector2(closestEdge.x, closestEdge.y);
              if (updateOtherEnd) {
                start.set(
                  end.x - originalObjectLength * Math.cos(originalObjectAngle),
                  end.y - originalObjectLength * Math.sin(originalObjectAngle),
                );
              }
              floorplannerStore.updateObjectPosition(sourceObject.id, start, end);
            }
            // Get the position of the connection on the other object, always from the start
            let connectionPos = targetObject.start.distanceTo(
              new THREE.Vector2(closestEdge.x, closestEdge.y),
            );
            // If connectionPos is the same as the wall end (or larger), we make sure it is the wall end
            if (connectionPos + editorStore.snappingTolerance >= targetObject.start.distanceTo(targetObject.end)) {
              connectionPos = WallConnectionEnd;
            }
            // If connectionPos is the same as the wall start (or smaller), we make sure it is the wall start
            if (connectionPos - editorStore.snappingTolerance <= 0) {
              connectionPos = WallConnectionStart;
            }
            // Update the connection 
            floorplannerStore.addConnection(sourceObject.id,
              targetObject.id,
              snappingEnd,
              connectionPos,
            );
          });
          return targetObject;
        }
      }
    }
  }

}

export const snapToAlignmentLines = (
  source: string,
  snappingEnd: number,
  edge: THREE.Vector2,
): boolean => {
  const object = floorplannerStore.findObjectId(source);
  if (object) {
    for (const [key, alignmentLine] of renderStore.alignmentLinesApproxMap) {
      const snappedLine = checkAgainstAlignmentLine(
        object.id,
        alignmentLine.id,
        snappingEnd,
        edge,
      );
      if (snappedLine) {
        return true;
      }
    };
  }
  return false
}

const checkAgainstAlignmentLine = (
  source: string,
  target: string,
  snappingEnd: number,
  edge: THREE.Vector2,
) => {
  const sourceObject = floorplannerStore.findObjectId(source);
  const targetObject = renderStore.alignmentLinesApproxMap.get(target);
  if (sourceObject && targetObject) {
    // Create a line from the target object to calculate the closest point against
    const targetLine = new THREE.Line3(
      targetObject.axis === "x" ? new THREE.Vector3(targetObject.hit.x, -1000, 0) : new THREE.Vector3(-1000, targetObject.hit.y, 0),
      targetObject.axis === "x" ? new THREE.Vector3(targetObject.hit.x, 1000, 0) : new THREE.Vector3(1000, targetObject.hit.y, 0),
    );
    const closestEdge = new THREE.Vector3();
    targetLine.closestPointToPoint(
      new THREE.Vector3(edge.x, edge.y, 0),
      true,
      closestEdge,
    );
    // Check if the closest point is within the snap threshold
    if (
      edge.distanceTo(
        new THREE.Vector2(closestEdge.x, closestEdge.y),
      ) < editorStore.snappingTolerance
    ) {
      runInAction(() => {
        // Since we are in snapping distance, we need to move the whole object (to snap)
        if (snappingEnd === WallConnectionStart) {
          const start = targetObject.axis === "x" ? new THREE.Vector2(targetObject.hit.x, sourceObject.start.y) : new THREE.Vector2(sourceObject.start.x, targetObject.hit.y);
          const end = sourceObject.end.clone();
          floorplannerStore.updateObjectPosition(sourceObject.id, start, end);
        } else {
          const start = sourceObject.start.clone();
          const end = targetObject.axis === "x" ? new THREE.Vector2(targetObject.hit.x, sourceObject.end.y) : new THREE.Vector2(sourceObject.end.x, targetObject.hit.y);
          floorplannerStore.updateObjectPosition(sourceObject.id, start, end);
        }
      });
      return targetObject;
    }
  }

}

export const getSnappingPoint = (
  sourceObjectId: string,
  snappingEnd: number,
  edge: THREE.Vector2,
  updateOtherEnd: boolean = true,
): THREE.Vector2 | null => {
  // Attempt to get snapping point from other objects
  const snappingPoint = getSnappingPointFromOtherObjects(
    sourceObjectId,
    snappingEnd,
    edge,
    updateOtherEnd
  );
  if (snappingPoint) {
    return snappingPoint;
  }

  // Attempt to get snapping point from alignment lines
  const snappingPointAlignment = getSnappingPointFromAlignmentLines(
    sourceObjectId,
    snappingEnd,
    edge
  );
  if (snappingPointAlignment) {
    return snappingPointAlignment;
  }

  // No snapping occurred
  return null;
};

const getSnappingPointFromOtherObjects = (
  sourceObjectId: string,
  snappingEnd: number,
  edge: THREE.Vector2,
  updateOtherEnd: boolean = true,
): THREE.Vector2 | null => {
  const object = floorplannerStore.findObjectId(sourceObjectId);
  if (object) {
    // Ensure the snappingEnd is free from any connections
    if (object.connections) {
      const connection = object.connections.find(
        (c) => c.sourcePosition === snappingEnd,
      );
      if (connection) {
        return null;
      }
    }

    // Check against walls
    for (const targetWall of floorplannerStore.wallsMap.values()) {
      const snappingPoint = getSnappingPointAgainstObject(
        object.id,
        targetWall.id,
        snappingEnd,
        edge,
        updateOtherEnd,
      );
      if (snappingPoint) {
        return snappingPoint;
      }
    }

    // Check against single lines
    for (const targetLine of floorplannerStore.singleLinesMap.values()) {
      const snappingPoint = getSnappingPointAgainstObject(
        object.id,
        targetLine.id,
        snappingEnd,
        edge,
        updateOtherEnd,
      );
      if (snappingPoint) {
        return snappingPoint;
      }
    }

    // Check against ruler lines
    for (const targetRuler of floorplannerStore.rulerLinesMap.values()) {
      const snappingPoint = getSnappingPointAgainstObject(
        object.id,
        targetRuler.id,
        snappingEnd,
        edge,
        updateOtherEnd,
      );
      if (snappingPoint) {
        return snappingPoint;
      }
    }
  }
  return null;
};

const getSnappingPointAgainstObject = (
  sourceId: string,
  targetId: string,
  snappingEnd: number,
  edge: THREE.Vector2,
  updateOtherEnd: boolean = true,
): THREE.Vector2 | null => {
  const sourceObject = floorplannerStore.findObjectId(sourceId);
  const targetObject = floorplannerStore.findObjectId(targetId);
  if (sourceObject && targetObject) {
    // Ensure the target object is not already connected to the source object
    const alreadyConnected = targetObject.connections?.find(
      (c: { id: string }) => c.id === sourceObject.id,
    );
    if (!alreadyConnected && targetObject.id !== sourceObject.id) {
      // Create a line from the target object to calculate the closest point
      const targetLine = new THREE.Line3(
        new THREE.Vector3(targetObject.start.x, targetObject.start.y, 0),
        new THREE.Vector3(targetObject.end.x, targetObject.end.y, 0),
      );
      const closestEdge = new THREE.Vector3();
      targetLine.closestPointToPoint(
        new THREE.Vector3(edge.x, edge.y, 0),
        true,
        closestEdge,
      );
      // Check if the closest point is within the snap threshold
      if (
        edge.distanceTo(new THREE.Vector2(closestEdge.x, closestEdge.y)) <
        editorStore.snappingTolerance
      ) {
        // Snap to the closest end if within tolerance
        if (
          closestEdge.distanceTo(
            new THREE.Vector3(targetObject.start.x, targetObject.start.y, 0),
          ) < editorStore.snappingTolerance
        ) {
          closestEdge.set(targetObject.start.x, targetObject.start.y, 0);
        } else if (
          closestEdge.distanceTo(
            new THREE.Vector3(targetObject.end.x, targetObject.end.y, 0),
          ) < editorStore.snappingTolerance
        ) {
          closestEdge.set(targetObject.end.x, targetObject.end.y, 0);
        }
        // Return the snapping point
        return new THREE.Vector2(closestEdge.x, closestEdge.y);
      }
    }
  }
  return null;
};


const getSnappingPointFromAlignmentLines = (
  sourceId: string,
  snappingEnd: number,
  edge: THREE.Vector2,
): THREE.Vector2 | null => {
  const sourceObject = floorplannerStore.findObjectId(sourceId);
  if (sourceObject) {
    for (const [key, alignmentLine] of renderStore.alignmentLinesApproxMap) {
      const snappingPoint = getSnappingPointAgainstAlignmentLine(
        sourceId,
        key,
        snappingEnd,
        edge,
      );
      if (snappingPoint) {
        return snappingPoint;
      }
    }
  }
  return null;
};

const getSnappingPointAgainstAlignmentLine = (
  sourceId: string,
  targetId: string,
  snappingEnd: number,
  edge: THREE.Vector2,
): THREE.Vector2 | null => {
  const sourceObject = floorplannerStore.findObjectId(sourceId);
  const targetObject = renderStore.alignmentLinesApproxMap.get(targetId);
  if (sourceObject && targetObject) {
    // Create a line representing the alignment line
    const targetLine = new THREE.Line3(
      targetObject.axis === 'x'
        ? new THREE.Vector3(targetObject.hit.x, -1000, 0)
        : new THREE.Vector3(-1000, targetObject.hit.y, 0),
      targetObject.axis === 'x'
        ? new THREE.Vector3(targetObject.hit.x, 1000, 0)
        : new THREE.Vector3(1000, targetObject.hit.y, 0),
    );
    const closestEdge = new THREE.Vector3();
    targetLine.closestPointToPoint(
      new THREE.Vector3(edge.x, edge.y, 0),
      true,
      closestEdge,
    );
    // Check if the closest point is within the snap threshold
    if (
      edge.distanceTo(new THREE.Vector2(closestEdge.x, closestEdge.y)) <
      editorStore.snappingTolerance
    ) {
      // Return the snapping point
      return new THREE.Vector2(closestEdge.x, closestEdge.y);
    }
  }
  return null;
};
