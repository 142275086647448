import { RoomType } from "../../types/wallTypes";
import { Text } from "@react-three/drei";
import { convertWorld } from "../../utils/conversions";
import { editorStore } from "../../store/editorStore";
import React, { useState, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { reaction } from "mobx";
import { SimpleText } from "../Text/SimpleText";

export const RoomLabel: React.FC<{ room: RoomType }> = observer(({ room }) => {
  const [zoomDerivedValues, setZoomDerivedValues] = useState(() => ({
    textFontSize: 0.5 / editorStore.zoomLevelDivisor(),
    textBoxPadding: 0.13 / editorStore.zoomLevelDivisor(),
    textBoxHeight: 0.16 / editorStore.zoomLevelDivisor(),
    textBoxWidth: 0.6 / editorStore.zoomLevelDivisor(),
  }));

  useEffect(() => {
    // Update zoom-derived values only when zoomLevel changes
    const dispose = reaction(
      () => editorStore.zoomLevel,
      () => {
        setZoomDerivedValues({
          textFontSize: 0.5 / editorStore.zoomLevelDivisor(),
          textBoxPadding: 0.13 / editorStore.zoomLevelDivisor(),
          textBoxHeight: 0.16 / editorStore.zoomLevelDivisor(),
          textBoxWidth: 0.6 / editorStore.zoomLevelDivisor(),
        });
      }
    );
    return () => dispose(); // Cleanup reaction on
  }, [editorStore.zoomLevel]);

  return (
    <group position={[room.centroid.x, room.centroid.y, 0.01]}>
      <mesh position={[0, 0, -0.01]}>
        <planeGeometry
          args={[
            zoomDerivedValues.textBoxWidth + zoomDerivedValues.textBoxPadding,
            zoomDerivedValues.textBoxHeight,
          ]}
        />
        <meshBasicMaterial color={"white"} />
      </mesh>
      {/* <Text
        position={[0, 0, 0]}
        fontSize={zoomDerivedValues.textFontSize}
        color="#000000"
        fontWeight={300}
      >
        {`${convertWorld(room.areaWorldUnits).toLocaleString()} m² `}
      </Text> */}
      <SimpleText
        position={[0, 0, 0]}
        fontSize={zoomDerivedValues.textFontSize * 30}
        color="#000000"
      >
        {`${convertWorld(room.areaWorldUnits).toLocaleString()} m² `}
      </SimpleText>
    </group>
  );
});
