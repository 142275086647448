import React from 'react';
import { floorplannerStore } from '../../store/floorplannerStore';


import { SingleLineType, WallType } from '../../types/wallTypes';
import { Vector2 } from 'three';
import { editorStore } from '../../store/editorStore';
import { PiLineSegmentsLight } from "react-icons/pi";
import { IoClose } from 'react-icons/io5';
import { observer } from 'mobx-react-lite';
import drawElevation from '../../assets/Draw-elevation ny-01.png'
import drawFloor from '../../assets/Draw-walls-01.png'
import singleLine from '../../assets/symbols/Single line_Rityta 1.png'
import singleWall from '../../assets/symbols/Single wall_Rityta 1.png'
import DefaultIcon from '../../assets/defaultIcon.png';
interface DropdownProps {
  position: { top: number; left: number };
  onClose: () => void;
  dropdownRef: React.RefObject<HTMLDivElement>;
}

export const WallsPanel: React.FC<DropdownProps> = observer(({ position, onClose, dropdownRef }) => {

  // Prevent clicks inside the panel from closing it
  const handlePanelClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();  // Prevents the event from bubbling up
  };

  const handleIconClick = (label: string, props?: any) => {
    // Retrieve the visible center from the editor store
    const [centerX, centerY] = editorStore.getVisibleCenter();
    if (label === "Wall") {
      // Set a default wall length, for example 5 units (adjust based on your needs)
      const wallLength = 2;

      // Calculate the start and end points of the wall, centered around the visible center
      const startX = centerX - wallLength / 2;
      const endX = centerX + wallLength / 2;
      const startY = centerY;
      const endY = centerY;

      const wall: WallType = {
        id: floorplannerStore.generateId(),
        type: "wall",
        start: new Vector2(startX, startY), // Start point to the left of the center
        end: new Vector2(endX, endY), // End point to the right of the center

      }
      floorplannerStore.addWall(wall);
    } else if (label === "SingleLine") {
      // Set a default line length, for example 5 units (adjust based on your needs)
      const singleLineLength = 2;
      // Calculate the start and end points of the wall, centered around the visible center
      const startX = centerX - singleLineLength / 2;
      const endX = centerX + singleLineLength / 2;
      const startY = centerY;
      const endY = centerY;

      const line: SingleLineType = {
        id: floorplannerStore.generateId(),
        type: "singleLine",
        start: new Vector2(startX, startY), // Start point to the left of the center
        end: new Vector2(endX, endY), // End point to the right of the center
      }
      floorplannerStore.addSingleLine(line);
    }  else if (label === "WallConstructionMode") {
      editorStore.setWallConstructionMode(!editorStore.wallConstructionMode);
      onClose();
    } else if (label === "LineConstructionMode") {
      editorStore.setLineConstructionMode(!editorStore.lineConstructionMode);
      onClose();
    } 
  };

  return (
    <div
      className="fixed z-50 w-[398px] bg-white rounded shadow-lg mb-8 overflow-y-auto scrollbar"
      style={{ top: position.top, left: position.left, maxHeight: '70vh' }}
      ref={dropdownRef}
      onClick={handlePanelClick}
    >
      <div className="flex flex-cols items-center justify-between border-b border-[#edebe6] p-2 px-4 pr-2">
        <div className="text-sm font-normal">Wall library</div>
        <div onClick={onClose} className="text-sm text-gray-500"><IoClose style={{ strokeWidth: 0.5 }} size={24} /></div>
      </div>
     
      {/* Group 1 */}
      <div className="mb-4 p-2 px-4">
        <div className=" border-gray-300 pb-2 mb-2 mt-4 text-sm font-medium">Walls</div>
        <div className="w-[364px] h-[259px] pr-2 grid grid-cols-3 gap-2">
       
          <div
            className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("Wall")}
          >       
            <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={singleWall} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Single wall</div>
          </div>
         
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
             onClick={() => handleIconClick("SingleLine")}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={singleLine} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Single line</div>
         
          </div>
          
          <div
            className={`flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6] `}
            //${editorStore.wallConstructionMode ? "bg-gray-400" : "bg-gray-300"}
            onClick={() => handleIconClick("WallConstructionMode")}
          >
           
            <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={drawFloor} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Draw floor plan</div>
          </div>
          <div
            className={`flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6] `}
            //${editorStore.lineConstructionMode ? "bg-gray-400" : "bg-gray-300"}
            onClick={() => handleIconClick("LineConstructionMode")}
          >
           
            <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={drawElevation} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Draw elevation</div>
          </div>
         
       
        </div>
      </div>

    </div>

  );
});