import React from 'react';
import { FaUndo, FaRedo } from "react-icons/fa";
import { editorStore } from '../../store/editorStore';
import { observer } from 'mobx-react-lite';
import { Tooltip } from 'flowbite-react';

interface HistoryControlsProps {
    className?: string;
}

const HistoryControls: React.FC<HistoryControlsProps> = observer(({ className }) => {
    return (
        <div className={`flex flex-col gap-2 ${className}`}>
            <Tooltip content="Undo" placement='right'>
                <button
                    onClick={() => editorStore.undo()}
                    className={"w-full p-2 text-center rounded-sm border border-gray-300 shadow-md " + (editorStore.canUndo ? "bg-white hover:bg-gray-100 text-gray-600" : "bg-gray-200 text-gray-400")}
                >
                    <FaUndo size={14} />
                </button>
            </Tooltip>
            <Tooltip content="Redo" placement='right'>
                <button
                    onClick={() => editorStore.redo()}
                    className={"w-full p-2 text-center rounded-sm border border-gray-300 shadow-md " + (editorStore.canRedo ? "bg-white hover:bg-gray-100 text-gray-600" : "bg-gray-200 text-gray-400")}
                >
                    <FaRedo size={14} />
                </button>
            </Tooltip>
        </div>
    );
});

export default HistoryControls;
