import { ClippingPolygonType, WallShapeType, WallType } from "../../types/wallTypes";
import { createShapes } from "./createShapes";
import * as polygonClipping from 'polygon-clipping';
import { FloorplannerStore } from '../../store/floorplannerStore';
import { checkLineIntersection } from "./checkLineIntersections";
import { editorStore } from "../../store/editorStore";

import intersect from '@turf/intersect';
import { polygon } from '@turf/helpers';
import * as THREE from 'three';
import {
    LineGeometry,
    LineMaterial,
    Line2,
    LineSegments2,
} from 'three-stdlib'
import {
    Feature,
    Polygon as GeoJSONPolygon,
    MultiPolygon as GeoJSONMultiPolygon,
    Geometry,
    Position,
    GeoJsonProperties,
} from 'geojson';
import kinks from '@turf/kinks';
import cleanCoords from '@turf/clean-coords';
import simplify from '@turf/simplify';
import buffer from '@turf/buffer';
import booleanIntersects from '@turf/boolean-intersects';
import { renderStore } from "../../store/renderStore";

export const fillMeshWall = (
    wall: WallType,
    filledLines: WallShapeType[],
    leftLines: WallShapeType[],
    rightLines: WallShapeType[],
    endCaps: WallShapeType[],
    newMeshWalls: THREE.Group,
    floorplannerStore: FloorplannerStore,
    scene: THREE.Scene,
) => {
    const {
        setWalls,
        wallWidth,
        lineColor,
        fillColor,
        hoverColor,
    } = floorplannerStore;

    type Pair = [number, number];
    type Ring = Pair[];
    type Polygon = Ring[];
    type MultiPolygon = Polygon[];
    type Coord = number;
    type Point = [Coord, Coord];
    const noopRaycast = () => null;

    // Function to merge nearly coincident points (points closer than tolerance)
    const mergeClosePoints = (polygon: THREE.Vector2[], tolerance: number) => {
        return polygon.filter((p, i, arr) => {
            if (i === 0) return true;
            const prev = arr[i - 1];
            return p.distanceTo(prev) > tolerance;
        });
    };

    // Function to inset a polygon
    const insetPolygon = (polygon: THREE.Vector2[], insetAmount: number) => {
        const center = polygon.reduce((sum, p) => sum.add(p), new THREE.Vector2(0, 0)).multiplyScalar(1 / polygon.length);
        return polygon.map(p => p.clone().sub(center).multiplyScalar(1 - insetAmount).add(center));
    };

    function pointsToGeoJSONPolygon(points: [number, number][]): Feature<GeoJSONPolygon> {
        // Ensure the polygon is closed
        const firstPoint = points[0];
        const lastPoint = points[points.length - 1];
        if (firstPoint[0] !== lastPoint[0] || firstPoint[1] !== lastPoint[1]) {
            points = [...points, firstPoint];
        }
        return polygon([points]);
    }

    const addHolesToShapes = (
        shapes: THREE.Shape[],
        clippingPolygons: ClippingPolygonType[]
    ) => {
        const tolerance = 0.01; // Small tolerance to merge close points
        const insetAmount = 0.00001; // Small inset to avoid floating point issues

        shapes.forEach((shape) => {
            const simplifiedShapePoints = shape.getPoints();
            const shapePointsArray: [number, number][] = simplifiedShapePoints.map((p) => [p.x, p.y]);

            if (shapePointsArray.length < 3) return; // Skip small shapes

            clippingPolygons.forEach((clippingPolygon) => {
                const polygonPointsArray: [number, number][] = convertToPolygonClippingFormat(
                    clippingPolygon.polygon
                );

                // Close the clipping polygon if it's not closed
                const firstClipPoint = polygonPointsArray[0];
                const lastClipPoint = polygonPointsArray[polygonPointsArray.length - 1];
                if (
                    firstClipPoint[0] !== lastClipPoint[0] ||
                    firstClipPoint[1] !== lastClipPoint[1]
                ) {
                    polygonPointsArray.push(firstClipPoint);
                }
                if (shapePointsArray.length < 4) {
                    console.error('Invalid shape polygon: less than 4 points');
                    return;
                }

                if (polygonPointsArray.length < 4) {
                    console.error('Invalid clipping polygon: less than 4 points');
                    return;
                }

                let result: polygonClipping.MultiPolygon | null = null;

                try {
                    // Use polygon-clipping as the primary method
                    const shapePolygon = [shapePointsArray];
                    const clippingPolygonShape = [polygonPointsArray];
                    result = polygonClipping.intersection(shapePolygon, clippingPolygonShape);
                } catch (error) {
                    // Fallback to Turf.js if polygon-clipping fails
                    try {
                        // Convert points to GeoJSON Polygons
                        let shapeGeoJSON: Feature<GeoJSONPolygon | GeoJSONMultiPolygon> = pointsToGeoJSONPolygon(
                            shapePointsArray
                        );
                        let clippingGeoJSON: Feature<GeoJSONPolygon | GeoJSONMultiPolygon> =
                            pointsToGeoJSONPolygon(polygonPointsArray);

                        // Round coordinates to reduce numerical precision issues
                        shapeGeoJSON.geometry = roundCoordinates(shapeGeoJSON.geometry, 6);
                        clippingGeoJSON.geometry = roundCoordinates(clippingGeoJSON.geometry, 6);

                        // Clean and simplify the polygons
                        shapeGeoJSON = cleanCoords(shapeGeoJSON) as Feature<GeoJSONPolygon | GeoJSONMultiPolygon>;
                        clippingGeoJSON = cleanCoords(
                            clippingGeoJSON
                        ) as Feature<GeoJSONPolygon | GeoJSONMultiPolygon>;

                        shapeGeoJSON = simplify(shapeGeoJSON, { tolerance: 0.0001, highQuality: true }) as Feature<
                            GeoJSONPolygon | GeoJSONMultiPolygon
                        >;
                        clippingGeoJSON = simplify(
                            clippingGeoJSON,
                            { tolerance: 0.0001, highQuality: true }
                        ) as Feature<GeoJSONPolygon | GeoJSONMultiPolygon>;

                        // Buffer the polygons slightly
                        let bufferedShapeGeoJSON = buffer(shapeGeoJSON, 0.0001, { units: 'meters' });
                        if (!bufferedShapeGeoJSON) {
                            console.error('Buffering failed for shapeGeoJSON');
                            return;
                        }
                        shapeGeoJSON = bufferedShapeGeoJSON as Feature<GeoJSONPolygon | GeoJSONMultiPolygon>;

                        let bufferedClippingGeoJSON = buffer(clippingGeoJSON, -0.0001, { units: 'meters' });
                        if (!bufferedClippingGeoJSON) {
                            console.error('Buffering failed for clippingGeoJSON');
                            return;
                        }
                        clippingGeoJSON = bufferedClippingGeoJSON as Feature<GeoJSONPolygon | GeoJSONMultiPolygon>;

                        // Check if polygons intersect
                        const doTheyIntersect = booleanIntersects(shapeGeoJSON, clippingGeoJSON);

                        if (!doTheyIntersect) {
                            console.error('Polygons do not intersect, skipping intersection.');
                            return;
                        }

                        // Perform the intersection using Turf.js
                        const intersection = intersect(shapeGeoJSON, clippingGeoJSON);

                        if (intersection) {
                            processTurfIntersection(intersection, shape, tolerance, insetAmount);
                        } else {
                            console.error('No intersection result from Turf.js');
                        }
                    } catch (turfError) {
                        console.error('Error during Turf.js intersection', turfError);
                    }
                    return; // Skip processing the result from polygon-clipping
                }

                // Process the result from polygon-clipping
                if (result && result.length > 0) {
                    result.forEach((polygon) => {
                        polygon.forEach((ring) => {
                            let trimmedPolygon = ring.map(([x, y]) => new THREE.Vector2(x, y));

                            if (trimmedPolygon.length >= 3) {
                                // Merge close points to avoid nearly coincident issues
                                trimmedPolygon = mergeClosePoints(trimmedPolygon, tolerance);

                                // Ensure the first and last points are connected
                                if (!trimmedPolygon[0].equals(trimmedPolygon[trimmedPolygon.length - 1])) {
                                    trimmedPolygon.push(trimmedPolygon[0].clone());
                                }

                                // Apply a small inset to avoid floating point precision issues
                                trimmedPolygon = insetPolygon(trimmedPolygon, insetAmount);

                                // Create the hole and add it to the shape
                                const hole = new THREE.Path().setFromPoints(trimmedPolygon);
                                shape.holes.push(hole);
                            }
                        });
                    });
                }
            });
        });
    };

    function processTurfIntersection(
        intersection: Feature<Geometry, GeoJsonProperties>,
        shape: THREE.Shape,
        tolerance: number,
        insetAmount: number
    ) {
        if (!intersection) {
            console.error('Intersection is null');
            return;
        }

        const geom = intersection.geometry;

        if (!geom) {
            console.error('Intersection geometry is null');
            return;
        }

        if (geom.type === 'Polygon') {
            const coordinates = geom.coordinates as Position[][];
            processRings([coordinates], shape, tolerance, insetAmount);
        } else if (geom.type === 'MultiPolygon') {
            const coordinates = geom.coordinates as Position[][][];
            processRings(coordinates, shape, tolerance, insetAmount);
        } else {
            console.error('Unsupported geometry type from Turf.js intersection:', geom.type);
        }
    }

    function processRings(
        polygons: Position[][][],
        shape: THREE.Shape,
        tolerance: number,
        insetAmount: number
    ) {
        polygons.forEach((rings) => {
            rings.forEach((ring) => {
                if (ring.length >= 3) {
                    const ringPoints = ring.map((coord) => {
                        const [x, y] = coord as [number, number];
                        return new THREE.Vector2(x, y);
                    });

                    // Merge close points to avoid nearly coincident issues
                    let trimmedPolygon = mergeClosePoints(ringPoints, tolerance);

                    // Ensure the first and last points are connected
                    if (!trimmedPolygon[0].equals(trimmedPolygon[trimmedPolygon.length - 1])) {
                        trimmedPolygon.push(trimmedPolygon[0].clone());
                    }

                    // Apply a small inset to avoid floating point precision issues
                    trimmedPolygon = insetPolygon(trimmedPolygon, insetAmount);

                    // Create the hole and add it to the shape
                    const hole = new THREE.Path().setFromPoints(trimmedPolygon);
                    shape.holes.push(hole);
                }
            });
        });
    }

    // Helper function to convert your polygon data to [number, number][] format
    function convertToPolygonClippingFormat(
        polygon: { x: number; y: number }[]
    ): Point[] {
        return polygon.map((point) => [point.x, point.y]);
    }

    function roundCoordinates<T extends Geometry>(geometry: T, decimals: number): T {
        function round(value: number): number {
            return parseFloat(value.toFixed(decimals));
        }

        if (geometry.type === 'Polygon') {
            const newCoordinates = geometry.coordinates.map((ring) =>
                ring.map((coord) => [round(coord[0]), round(coord[1])])
            );
            return { ...geometry, coordinates: newCoordinates } as T;
        } else if (geometry.type === 'MultiPolygon') {
            const newCoordinates = geometry.coordinates.map((polygon) =>
                polygon.map((ring) => ring.map((coord) => [round(coord[0]), round(coord[1])]))
            );
            return { ...geometry, coordinates: newCoordinates } as T;
        } else {
            // For other geometry types, return the geometry unchanged or handle accordingly
            return geometry;
        }
    }

    // Function to create meshes from shapes
    const createMeshFromShapes = (
        shapes: THREE.Shape[],
        material: THREE.Material,
        userDataType: string,
        parentGroup: THREE.Group,
    ) => {
        const geometry = new THREE.ShapeGeometry(shapes);
        const meshPart = new THREE.Mesh(geometry, material);
        meshPart.userData.type = userDataType;
        parentGroup.add(meshPart);
    };

    const createOutlines = (
        shapes: THREE.Shape[],
        clippingPolygons: ClippingPolygonType[] | undefined,
        color: number,
        dashed: boolean,
        dashSize: number,
        gapSize: number,
        parentGroup: THREE.Group,
        lineWidth: number = 1
    ) => {
        shapes.forEach((shape) => {
            const points = shape.getPoints();

            if (clippingPolygons && clippingPolygons.length > 0) {
                const start = points[0];
                const end = points[points.length - 1];
                const intersectionPoints: THREE.Vector2[] = [];

                // Collect intersection points with clipping polygons
                clippingPolygons.forEach((clippingPolygon) => {
                    const polygonPoints = clippingPolygon.polygon;
                    for (let i = 0; i < polygonPoints.length; i++) {
                        const p1 = polygonPoints[i];
                        const p2 = polygonPoints[(i + 1) % polygonPoints.length];
                        const intersection = checkLineIntersection(start, end, p1, p2);
                        if (intersection.intersecting && intersection.point) {
                            intersectionPoints.push(intersection.point);
                        }
                    }
                });

                intersectionPoints.push(start);
                intersectionPoints.push(end);
                intersectionPoints.sort((a, b) => a.distanceTo(start) - b.distanceTo(start));

                // Create separate line geometries for each segment
                for (let i = 0; i < intersectionPoints.length - 1; i += 2) {
                    const x1 = intersectionPoints[i].x;
                    const y1 = intersectionPoints[i].y;
                    const x2 = intersectionPoints[i + 1].x;
                    const y2 = intersectionPoints[i + 1].y;

                    // Separate geometry for each segment
                    const geometry = new LineGeometry();
                    geometry.setPositions([x1, y1, 0, x2, y2, 0]);

                    const material = new LineMaterial({
                        color: color,
                        linewidth: lineWidth,
                        dashed: dashed,
                        dashSize: dashSize,
                        gapSize: gapSize,
                    });

                    const lineSegment = new LineSegments2(geometry, material);
                    lineSegment.raycast = noopRaycast;

                    // Compute line distances for dashes if needed
                    if (dashed) {
                        lineSegment.computeLineDistances();
                    }

                    // Ensure the line segment is scaled correctly if needed
                    lineSegment.scale.set(1, 1, 1);

                    // Add to parent group
                    parentGroup.add(lineSegment);
                }
            } else {
                // If no clipping polygons, create individual line segments from shape points
                for (let i = 0; i < points.length - 1; i++) {
                    const x1 = points[i].x;
                    const y1 = points[i].y;
                    const x2 = points[i + 1].x;
                    const y2 = points[i + 1].y;

                    // Separate geometry for each segment
                    const geometry = new LineGeometry();
                    geometry.setPositions([x1, y1, 0, x2, y2, 0]);

                    const material = new LineMaterial({
                        color: color,
                        dashSize: dashSize,
                        gapSize: gapSize,
                        linewidth: lineWidth,
                        dashed: dashed,
                    });

                    const lineSegment = new LineSegments2(geometry, material);
                    lineSegment.raycast = noopRaycast

                    // Compute line distances for dashes if needed
                    if (dashed) {
                        lineSegment.computeLineDistances();
                    }

                    // Ensure the line segment is scaled correctly
                    lineSegment.scale.set(1, 1, 1);

                    // Add to parent group
                    parentGroup.add(lineSegment);
                }
            }
        });
    };


    //const wall = wallsMap.get(wallId);
    const lineWeight = wall.lineWeight || floorplannerStore.wallLineWeight;
    const lineWeightWorld = floorplannerStore.convertLineWeightToWorld(lineWeight);

    // Create shapes for different parts of the wall
    const innerShapes = createShapes(
        filledLines[0],
        "inner",
        wall.lineWeight || floorplannerStore.wallLineWeight,
        wall.wallWidth || floorplannerStore.wallWidth,
    );
    const leftShapes = createShapes(
        leftLines[0],
        "left",
        wall.lineWeight || floorplannerStore.wallLineWeight,
        wall.wallWidth || floorplannerStore.wallWidth,
    );
    const rightShapes = createShapes(
        rightLines[0],
        "right",
        wall.lineWeight || floorplannerStore.wallLineWeight,
        wall.wallWidth || floorplannerStore.wallWidth,
    );
    const endCapsShapes = createShapes(
        endCaps[0],
        "endCaps",
        wall.lineWeight || floorplannerStore.wallLineWeight,
        wall.wallWidth || floorplannerStore.wallWidth,
    );

    const customFillColor = wall?.fillColor || fillColor;
    const customLineColor = wall?.lineColor || lineColor;

    // Materials
    const filledMaterial = new THREE.MeshBasicMaterial({
        color: customFillColor,
        side: THREE.DoubleSide,
    });
    const lineMaterial = new THREE.MeshBasicMaterial({
        color: customLineColor,
        side: THREE.DoubleSide,
    });

    newMeshWalls.userData.id = wall.id;

    const clippingPolygons = renderStore.wallClippingsMap.get(wall.id)
    if (clippingPolygons) {
        addHolesToShapes(innerShapes, clippingPolygons);
    }

    // Create meshes for different parts of the wall
    try {
        createMeshFromShapes(innerShapes, filledMaterial, "fill", newMeshWalls);
        createOutlines(leftShapes, clippingPolygons, customLineColor, wall.lineType === "dashed", 0.15, 0.1, newMeshWalls, lineWeight);
        createOutlines(rightShapes, clippingPolygons, customLineColor, wall.lineType === "dashed", 0.15, 0.1, newMeshWalls, lineWeight);
        createOutlines(endCapsShapes, [], customLineColor, false, 0.15, 0.1, newMeshWalls, lineWeight);
    } catch (error) {
        console.error('Error creating wall meshes:', error);        
    }

}
