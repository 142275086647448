import { useEffect, useRef } from 'react';
import { useThree, useFrame, invalidate, render, ThreeEvent } from '@react-three/fiber';
import * as THREE from 'three';
import { editorStore } from '../../store/editorStore';
import { observer } from 'mobx-react-lite';
import { projectToWorld } from '../FloorPlan/projectToWorld';
import { floorplannerStore } from '../../store/floorplannerStore';
import { AreaSymbolType } from '../../types/wallTypes';
import { updateAlignmentLines } from '../FloorPlan/updateAligmentLines';

const AreaConstructionPlane = observer(() => {
  const { areaConstructionMode, areaConstructionModeAddPoint, view3D } = editorStore;
  const { gl, camera, scene } = useThree();
  const areaSymbol = useRef(undefined as string | undefined);
  const lastClickPosition = useRef(undefined as THREE.Vector2 | undefined);

  const handlePointerDown = (event: ThreeEvent<PointerEvent>) => {
    if (editorStore.areaConstructionMode) {
      const { clientX, clientY } = event;
      const pos = projectToWorld(clientX, clientY, gl, camera);
      const posVector = new THREE.Vector2(pos[0], pos[1]);
      if (areaSymbol.current) {
        addNewPoint(posVector);
      } else {
        createNewAreaSymbol(pos);
      }
      lastClickPosition.current = posVector
    }
  };

  const handlePointerMove = (event: ThreeEvent<PointerEvent>) => {
    if (editorStore.areaConstructionMode && !editorStore.view3D) {
      document.body.classList.remove("cursor-vector")
      document.body.style.cursor = 'crosshair';
      const { clientX, clientY } = event;
      const pos = projectToWorld(clientX, clientY, gl, camera);

      if (areaSymbol.current) {
        const area = floorplannerStore.symbolsMap.get(areaSymbol.current) as AreaSymbolType;
        if (area) {
          const start = area.vertices[area.vertices.length - 2];
          let end = new THREE.Vector2(pos[0], pos[1]).sub(area.position);
          
          // Check if Shift is pressed
          if (!editorStore.isShiftPressed) {
            // Calculate the angle between the start and current mouse position
            const deltaX = end.x - start.x;
            const deltaY = end.y - start.y;
            const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI); // Convert to degrees

            // Get the current distance between the start and end position
            const currentDistance = start.distanceTo(end);

            // Normalize the angle to a positive value between 0 and 360
            const normalizedAngle = (angle + 360) % 360;

            // Snap the angle to the nearest 45 degrees
            const snapAngles = [0, 45, 90, 135, 180, 225, 270, 315, 360];
            let closestSnapAngle = snapAngles.reduce((prev, curr) => Math.abs(curr - normalizedAngle) < Math.abs(prev - normalizedAngle) ? curr : prev);

            // Convert the snapped angle to radians
            const radianSnapAngle = closestSnapAngle * (Math.PI / 180);

            // Calculate the snapped end position (along the nearest 45-degree line)
            const snapEndX = start.x + currentDistance * Math.cos(radianSnapAngle);
            const snapEndY = start.y + currentDistance * Math.sin(radianSnapAngle);

            // Calculate the distance between the current mouse position and the snapped position
            const snapDistance = end.distanceTo(new THREE.Vector2(snapEndX, snapEndY));

            // If the distance to the snapped angle is less than 0.2, snap to the closest 45-degree angle
            if (snapDistance < 0.2) {
              end.set(snapEndX, snapEndY);
            }
          }

          const vertices = [...area.vertices];
          vertices[vertices.length - 1] = end;
          floorplannerStore.updateSymbolProperty(areaSymbol.current, "vertices", vertices);

          updateAlignmentLines(area.id, end.x, end.y, "end");
        }
      }
    }
  };

  const handleDoubleClick = (event: ThreeEvent<MouseEvent>) => {
    if (editorStore.areaConstructionMode) {
      // Get the click position in world coordinates
      const { clientX, clientY } = event;
      const pos = projectToWorld(clientX, clientY, gl, camera);
      const clickPosition = new THREE.Vector2(pos[0], pos[1]);

      if (areaSymbol.current) {
        const area = floorplannerStore.symbolsMap.get(areaSymbol.current) as AreaSymbolType;
        if (area) {
          const areaPosition = area.position;
          const vertices = area.vertices;

          // Get the last vertex adjusted for the area's position
          const lastVertex = vertices[vertices.length - 1].clone().add(areaPosition as THREE.Vector2);

          // Calculate the distance between the click and the first vertex
          const distance = clickPosition.distanceTo(lastVertex);

          // If the click is close enough to the first vertex, exit construction mode
          if (distance < editorStore.snappingTolerance) {
            // Exit area construction mode
            editorStore.setAreaConstructionMode(false);
            // Additional cleanup if necessary
            // Optionally, close the area by ensuring the first and last vertices are the same
            floorplannerStore.updateSymbolProperty(areaSymbol.current, "vertices", [...vertices.slice(0, vertices.length - 1), vertices[0]]);
          }
        }
      }
    }
  };

  const createNewAreaSymbol = (pos: [number, number]) => {
    if (!areaSymbol.current) {
      const start = new THREE.Vector2(0, 0);
      const end = new THREE.Vector2(0, 0);
      areaSymbol.current = floorplannerStore.addSymbol("area", [pos[0], pos[1]], {
        vertices: [start, end],
      });
    }
  }
    
  const addNewPoint = (pos: THREE.Vector2) => {
    if (areaSymbol.current) {
      const area = floorplannerStore.symbolsMap.get(areaSymbol.current) as AreaSymbolType;
      if (area) {
        const deltaPos = pos.clone().sub(area.position);
        const vertices = area.vertices;
        const lastVertex = vertices[vertices.length - 2];
        const distance = lastVertex.distanceTo(deltaPos);
        if (distance > 0.1) {
          floorplannerStore.updateSymbolProperty(areaSymbol.current, "vertices", [...vertices, deltaPos]);
        }
        // If the added point is near the first point, exit the area construction mode
        if (vertices[0].distanceTo(area.vertices[vertices.length - 1]) < 0.1) {
          editorStore.setAreaConstructionMode(false);
        }
      }
    }
  };

  useEffect(() => {
    if (areaSymbol.current && areaConstructionModeAddPoint) {
      editorStore.setAreaConstructionModeAddPoint(false);
      const area = floorplannerStore.symbolsMap.get(areaSymbol.current) as AreaSymbolType;
      if (area) {
        lastClickPosition.current = area.vertices[area.vertices.length - 1].clone();
      }
    }
  }, [areaConstructionModeAddPoint]);

  useEffect(() => {
    // Do some checking when we exit the area construction mode
    if (!areaConstructionMode && areaSymbol.current) {
      const area = floorplannerStore.symbolsMap.get(areaSymbol.current) as AreaSymbolType;
      if (area) {
        // Remove last vertex (temporary point used during construction)
        const vertices = area.vertices.slice(0, area.vertices.length - 1);
        floorplannerStore.updateSymbolProperty(areaSymbol.current, "vertices", vertices);
        if (vertices.length < 3) {
          // If the area has less than 3 points, remove it
          floorplannerStore.removeSymbol(areaSymbol.current);
        } else {
          // Close the area by connecting the last point to the first point
          if (vertices[0].distanceTo(vertices[vertices.length - 1]) > 0.01) {
            floorplannerStore.updateSymbolProperty(areaSymbol.current, "vertices", [...vertices, vertices[0]]);
          } else {
            // Snap last point to the first point
            vertices[vertices.length - 1] = vertices[0];
            floorplannerStore.updateSymbolProperty(areaSymbol.current, "vertices", vertices);
          }
        }
      }
    }
    editorStore.setAreaEditingLength(undefined);
    areaSymbol.current = undefined;
    lastClickPosition.current = undefined;
    if (areaConstructionMode) {
      document.body.classList.remove("cursor-vector")
      document.body.style.cursor = 'crosshair';
      floorplannerStore.setBlockDirty(true);
    } else {
      document.body.style.cursor = 'auto';
      floorplannerStore.setBlockDirty(false);
    }
  }, [areaConstructionMode]);

  return (
    <mesh
      position={[0, 0, .2]}
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      //onDoubleClick={handleDoubleClick}
      userData={{ id: "area-construction-plane" }}
    >
      <planeGeometry args={[1000, 1000]} />
      <meshBasicMaterial transparent opacity={0} />
    </mesh>
  );
});

export default AreaConstructionPlane;
