import { Line, Text } from "@react-three/drei";
import { floorplannerStore } from "../../store/floorplannerStore";
import { editorStore } from "../../store/editorStore";
import { Html } from "@react-three/drei";
import * as THREE from "three";
import { observer } from "mobx-react-lite";
import { isRulerLineType, isSingleLineType, isWallType, RulerLineType, SingleLineType, WallType } from "../../types/wallTypes";
import { calculateObjectPositionAndRotation } from "./calculateObjectPositionAndRotation";
import { convertMillimeterToWorld, convertWorldToMillimeter } from "../../utils/conversions";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { SimpleLineSegments } from "./SimpleLineSegments";
import { renderStore } from "../../store/renderStore";
import { SimpleText } from "../Text/SimpleText";

interface MeasurementLineProps {
  object: WallType | SingleLineType | RulerLineType;
}

const MeasurementLineComponent: React.FC<MeasurementLineProps> = observer(({ object }) => {
  const [tempLength, setTempLength] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const innerPerpendicular = floorplannerStore.objectPerpendicularInner(object);
  const outerPerpendicular = floorplannerStore.objectPerpendicularOuter(object);

  const rulerLineOffset =
    0.2 + (isWallType(object) ? (object.wallWidth || floorplannerStore.wallWidth) / 2 : 0);
  const rulerOuterTextOffset =
    0.2 + (isWallType(object) ? (object.wallWidth || floorplannerStore.wallWidth) / 2 : 0);
  const rulerInnerTextOffset =
    0.13 + (isWallType(object) ? (object.wallWidth || floorplannerStore.wallWidth) / 2 : 0);

  const rulerTextBoxPadding = 0.13 / editorStore.zoomLevelDivisor();
  const rulerTextBoxHeight = 0.16 / editorStore.zoomLevelDivisor();
  const rulerTextBoxWidth = 0.3 / editorStore.zoomLevelDivisor();
  const rulerTextFontSize = 14 / editorStore.zoomLevelDivisor();
  const stopLineLength = 0.1;

  const wallDirection = floorplannerStore.objectDirection(object);
  const wallOppositeDirection = floorplannerStore.objectOppositeDirection(object);

  const wallStartCapLength =
    isWallType(object) ? floorplannerStore.wallStartCapLength(object) : 0;
  const wallEndCapLength =
    isWallType(object) ? floorplannerStore.wallEndCapLength(object) : 0;

  const { position, rotation, textRotation } = calculateObjectPositionAndRotation(object.start, object.end);

  // Compute all line segment points
  const segments = useMemo(() => {
    const segmentPoints: [number, number, number][] = [];

    // Measurement line parallel offset from the wall (Main measurement line)
    segmentPoints.push([
      object.start.x +
      outerPerpendicular.x * rulerLineOffset -
      wallOppositeDirection.x * wallStartCapLength,
      object.start.y +
      outerPerpendicular.y * rulerLineOffset -
      wallOppositeDirection.y * wallStartCapLength,
      0,
    ]);
    segmentPoints.push([
      object.end.x +
      outerPerpendicular.x * rulerLineOffset -
      wallDirection.x * wallEndCapLength,
      object.end.y +
      outerPerpendicular.y * rulerLineOffset -
      wallDirection.y * wallEndCapLength,
      0,
    ]);

    // Start cap line
    segmentPoints.push([
      object.start.x +
      outerPerpendicular.x * stopLineLength +
      rulerLineOffset * outerPerpendicular.x -
      wallOppositeDirection.x * wallStartCapLength,
      object.start.y +
      outerPerpendicular.y * stopLineLength +
      rulerLineOffset * outerPerpendicular.y -
      wallOppositeDirection.y * wallStartCapLength,
      0,
    ]);
    segmentPoints.push([
      object.start.x -
      outerPerpendicular.x * stopLineLength +
      rulerLineOffset * outerPerpendicular.x -
      wallOppositeDirection.x * wallStartCapLength,
      object.start.y -
      outerPerpendicular.y * stopLineLength +
      rulerLineOffset * outerPerpendicular.y -
      wallOppositeDirection.y * wallStartCapLength,
      0,
    ]);

    // End cap line
    segmentPoints.push([
      object.end.x +
      outerPerpendicular.x * stopLineLength +
      rulerLineOffset * outerPerpendicular.x -
      wallDirection.x * wallEndCapLength,
      object.end.y +
      outerPerpendicular.y * stopLineLength +
      rulerLineOffset * outerPerpendicular.y -
      wallDirection.y * wallEndCapLength,
      0,
    ]);
    segmentPoints.push([
      object.end.x -
      outerPerpendicular.x * stopLineLength +
      rulerLineOffset * outerPerpendicular.x -
      wallDirection.x * wallEndCapLength,
      object.end.y -
      outerPerpendicular.y * stopLineLength +
      rulerLineOffset * outerPerpendicular.y -
      wallDirection.y * wallEndCapLength,
      0,
    ]);

    return segmentPoints;
  }, [
    object.start.x,
    object.start.y,
    object.end.x,
    object.end.y,
    outerPerpendicular,
    wallOppositeDirection,
    wallDirection,
    rulerLineOffset,
    wallStartCapLength,
    wallEndCapLength,
    stopLineLength,
  ]);

  const handleLengthClick = (wallId: string, measure: string) => {
    if (measure === "outer") editorStore.setWallEditingOuterLength(wallId);
    else if (measure === "inner") editorStore.setWallEditingInnerLength(wallId);
    const currentLength = convertWorldToMillimeter(
      isWallType(object)
        ? (measure === "outer" ? floorplannerStore.wallOuterLength(object) : floorplannerStore.wallInnerLength(object))
        : isRulerLineType(object)
          ? floorplannerStore.rulerLineLength(object.id)
          : isSingleLineType(object)
            ? floorplannerStore.singleLineLength(object.id)
              : 0 // Fallback value if none of the conditions match
    );
    
    setTempLength(currentLength.toString());
  };

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
    object: WallType | SingleLineType | RulerLineType,
    measure: "outer" | "inner"
  ) => {
    if (editorStore.wallEditingOuterLength === object.id || editorStore.wallEditingInnerLength === object.id || editorStore.singleLineEditingLength === object.id || editorStore.rulerLineEditingLength === object.id || editorStore.areaEditingLength === object.id) {
      if (event.key === "Enter") {
        const newLength = parseFloat(tempLength);
        if (!isNaN(newLength)) {
          if (isWallType(object)) {
            floorplannerStore.setWallLength(object.id, convertMillimeterToWorld(newLength), null, measure);
            // We have to do another update since it could recalculate at hard angles
            floorplannerStore.setWallLength(object.id, convertMillimeterToWorld(newLength), null, measure);
            if (measure === "outer") editorStore.setWallEditingOuterLength(undefined);
            else if (measure === "inner") editorStore.setWallEditingInnerLength(undefined);
          } else if (isSingleLineType(object)) {
            floorplannerStore.setSingleLineLength(
              object.id,
              convertMillimeterToWorld(newLength),
              "end"
            );
            editorStore.setSingleLineEditingLength(undefined);
          }
          else if (isRulerLineType(object)) {
            floorplannerStore.setRulerLineLength(
              object.id,
              convertMillimeterToWorld(newLength),
              "end"
            );
            editorStore.setRulerLineEditingLength(undefined);
          }
        }
      }
    }
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempLength(event.target.value);
  };
  // Close input if clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        if (editorStore.wallEditingOuterLength === object.id) {
          editorStore.setWallEditingOuterLength(undefined);
        }
        if (editorStore.wallEditingInnerLength === object.id) {
          editorStore.setWallEditingInnerLength(undefined);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [object.id]);
  return (
    <group
      position={[0, 0, 0.001]}
      userData={{
        isWallLength: true,
      }}
      onPointerEnter={() => {
        if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.add("cursor-vector");
      }}
      onPointerLeave={() => {
        if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.remove("cursor-vector");
      }}
    >
      {/* A Measurement line parallel offset from the wall */}
      <SimpleLineSegments segments={segments} color="black" lineWidth={0.1} />

      {/* Group for outer ruler measure */}
      <group
        position={new THREE.Vector3(
          position.x + outerPerpendicular.x * rulerOuterTextOffset,
          position.y + outerPerpendicular.y * rulerOuterTextOffset,
          0.02
        )}
        rotation={[0, 0, textRotation]}
      >
        {editorStore.wallEditingInnerLength !== object.id && (
          <mesh position={[0, 0, -0.01]}>
            <planeGeometry
              args={[rulerTextBoxWidth + rulerTextBoxPadding, rulerTextBoxHeight]}
            />
            <meshBasicMaterial
              color={object?.selected ? 0xe5eaff : renderStore.labelBackgroundColor}
              //depthWrite={false}
              //depthTest={true}
              //blending={THREE.NormalBlending}
            />
          </mesh>
        )}
        <group
          position={[0, 0, -0.02]}
          onClick={() => handleLengthClick(object.id, "inner")}
        >
          {editorStore.wallEditingInnerLength === object.id ? (
            <Html
              center
              style={{
                position: "absolute",
                top: "-3px",
              }}
            >
              <input
              ref={inputRef}
                type="text"
                value={tempLength}
                onKeyPress={(event) => handleKeyPress(event, object, "inner")}
                onChange={handleInputChange}
                autoFocus
                style={{
                  background: "white",
                  border: "none",
                  color: "black",
                  fontSize: "10px",
                  textAlign: "center",
                  outline: "none",
                  width: "60px",
                  height: "15px",
                }}
                onFocus={(event) => event.target.select()}
              />
            </Html>
          ) : (
              <SimpleText
                position={[0, 0, 0.01]}
                color="black"
                fontSize={rulerTextFontSize}
              >
                {`${convertWorldToMillimeter(
                  isWallType(object)
                    ? floorplannerStore.wallInnerLength(object)
                    : isRulerLineType(object)
                      ? floorplannerStore.rulerLineLength(object.id)
                      : isSingleLineType(object)
                        ? floorplannerStore.singleLineLength(object.id)
                        : 0 // Fallback value if none of the conditions match
                ).toLocaleString()}`}
              </SimpleText>

          )}
          </group>
      </group>

      {/* Group for inner ruler measure */}
      {/* {isWallType(object) &&
        floorplannerStore.wallIsConnected(object as WallType, true) && (
          <group
            position={new THREE.Vector3(
              position.x + innerPerpendicular.x * rulerInnerTextOffset,
              position.y + innerPerpendicular.y * rulerInnerTextOffset,
              0.011
            )}
            rotation={[0, 0, textRotation]}
          >
            <mesh position={[0, 0, -0.01]}>
              <planeGeometry
                args={[
                  rulerTextBoxWidth + rulerTextBoxPadding,
                  rulerTextBoxHeight ,
                ]}
              />
              <meshBasicMaterial color={"darkblue"} />
            </mesh>
            <Text
              position={[0, 0, 0]}
              fontSize={rulerTextFontSize }
              color="white"
              onClick={() => handleLengthClick(object.id, "inner")}
          >
            {editorStore.wallEditingInnerLength === object.id ? (
              <Html
                center
                style={{
                  position: "absolute",
                  top: "-3px",
                }}
              >
                <input
                ref={inputRef}
                  type="text"
                  value={tempLength}
                  onKeyPress={(event) => handleKeyPress(event, object, "inner")}
                  onChange={handleInputChange}
                  autoFocus
                  style={{
                    background: "white",
                    border: "none",
                    color: "black",
                    fontSize: "10px",
                    textAlign: "center",
                    outline: "none",
                    width: "60px",
                    height: "15px",
                  }}
                  onFocus={(event) => event.target.select()}
                />
              </Html>
            ) : (
              `${convertWorldToMillimeter(
                isWallType(object)
                  ? floorplannerStore.wallInnerLength(object as WallType)
                  : isSingleLineType(object) ? floorplannerStore.singleLineLength((object as SingleLineType).id) : 0
                ).toLocaleString()}`
              )}
            </Text>
          </group>
        )} */}
    </group>
  );
});

export const MeasurementLine = React.memo(MeasurementLineComponent, (prevProps, nextProps) => {
  return (
    prevProps.object.lastUpdate === nextProps.object.lastUpdate &&
    prevProps.object.start.x === nextProps.object.start.x &&
    prevProps.object.start.y === nextProps.object.start.y &&
    prevProps.object.end.x === nextProps.object.end.x &&
    prevProps.object.end.y === nextProps.object.end.y &&
    prevProps.object.controlPoint?.x === nextProps.object.controlPoint?.x &&
    prevProps.object.controlPoint?.y === nextProps.object.controlPoint?.y
  );
});
