import React, { useEffect, useRef } from 'react';
import { useThree, useFrame, invalidate, render, ThreeEvent } from '@react-three/fiber';
import * as THREE from 'three';
import { editorStore } from '../../store/editorStore';
import { observer } from 'mobx-react-lite';
import { projectToWorld } from '../FloorPlan/projectToWorld';
import { floorplannerStore } from '../../store/floorplannerStore';
import { SingleLineType, WallConnectionEnd, WallConnectionStart, WallType } from '../../types/wallTypes';
import { runInAction } from 'mobx';
import { updateAlignmentLines } from '../FloorPlan/updateAligmentLines';
import { renderStore } from '../../store/renderStore';

const LineConstructionPlane = observer(() => {
  const {
    lineConstructionMode,
    lineConstructionModeAddNewLine,
    view3D,
  } = editorStore;
  const { gl, camera } = useThree();
  const addNewLine = useRef(false);
  const currentLine = useRef(undefined as string | undefined);
  const lastClickPosition = useRef(undefined as THREE.Vector2 | undefined);

  const handlePointerDown = (event: ThreeEvent<PointerEvent>) => {
    if (editorStore.lineConstructionMode) {
      // Get the click position of the pointer in the world
      const { clientX, clientY } = event;
      const pos = projectToWorld(clientX, clientY, gl, camera);
      lastClickPosition.current = new THREE.Vector2(pos[0], pos[1]);
      addNewLine.current = true;
      // If the current line end is connected to another line, exit the construction mode
      if (currentLine.current) {
        const line = floorplannerStore.singleLinesMap.get(currentLine.current);
        if (line) {
          const connection = line.connections?.find((c) => c.sourcePosition === WallConnectionEnd);
          if (connection) {
            floorplannerStore.objectNeedsUpdate(connection.id);
            editorStore.setLineConstructionMode(false);
            return;
          }
        }
      }

    }
  };

  const handlePointerMove = (event: ThreeEvent<PointerEvent>) => {
    if (editorStore.lineConstructionMode && !editorStore.view3D) {
      document.body.classList.remove("cursor-vector")
      document.body.style.cursor = 'crosshair';
      const { clientX, clientY } = event;
      const pos = projectToWorld(clientX, clientY, gl, camera);

      // Get the distance between the current mouse position and the last clicked position
      const distance = lastClickPosition.current?.distanceTo(new THREE.Vector2(pos[0], pos[1])) ?? 0;

      if (addNewLine.current && distance > 0.1 && lastClickPosition.current) {
        createNewLineSegment(pos);
      }

      // Update the line's position if it's already been created
      if (!addNewLine.current && currentLine.current) {
        const line = floorplannerStore.singleLinesMap.get(currentLine.current);
        if (!line || !line.start || !line.end) {
          return;
        }
        const start = line.start;
        let end = new THREE.Vector2(pos[0], pos[1]);
        // Check if Shift is pressed
        if (!event.shiftKey) {
          // Calculate the angle between the start and current mouse position
          const deltaX = end.x - start.x;
          const deltaY = end.y - start.y;
          const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI); // Convert to degrees

          // Get the current distance between the start and end position
          const currentDistance = start.distanceTo(end);

          // Normalize the angle to a positive value between 0 and 360
          const normalizedAngle = (angle + 360) % 360;

          // Snap the angle to the nearest 45 degrees
          const snapAngles = [0, 45, 90, 135, 180, 225, 270, 315, 360];
          let closestSnapAngle = snapAngles.reduce((prev, curr) => Math.abs(curr - normalizedAngle) < Math.abs(prev - normalizedAngle) ? curr : prev);

          // Convert the snapped angle to radians
          const radianSnapAngle = closestSnapAngle * (Math.PI / 180);

          // Calculate the snapped end position (along the nearest 45-degree line)
          const snapEndX = start.x + currentDistance * Math.cos(radianSnapAngle);
          const snapEndY = start.y + currentDistance * Math.sin(radianSnapAngle);

          // Calculate the distance between the current mouse position and the snapped position
          const snapDistance = end.distanceTo(new THREE.Vector2(snapEndX, snapEndY));

          // If the distance to the snapped angle is less than the tolerance, snap to the closest 45-degree angle
          if (snapDistance < editorStore.snappingTolerance) {
            end.set(snapEndX, snapEndY);
          }
        }

        // Test to snap the line to other lines if distance is more than 0.2
        let snapped = false;
        if (distance > 0.2) {
          runInAction(() => {
            floorplannerStore.removeConnectionEnd(line.id, WallConnectionEnd);
            snapped = floorplannerStore.snapToOtherObjects(line.id, WallConnectionEnd, end, false);
            if (!snapped && !editorStore.isShiftPressed) {
              floorplannerStore.updateObjectPosition(line.id, start, end);
              const updObject = floorplannerStore.findObjectId(line.id);
              if (updObject) {
                snapped = floorplannerStore.snapToAlignmentLines(line.id, WallConnectionEnd, updObject.end);
                if (snapped) {
                  const updObject = floorplannerStore.findObjectId(line.id);
                  if (updObject) {
                    updateAlignmentLines(line.id, updObject.end.x, updObject.end.y, "end");
                  }
                }
              }
            }
          });
        }
        if (!snapped) {
          floorplannerStore.updateObjectPosition(currentLine.current, start, end);
          updateAlignmentLines(line.id, end.x, end.y, "end");
        }
        const startConnectedToId = floorplannerStore.objectConnectedTo(line.id, WallConnectionStart);
        if (startConnectedToId) {
          floorplannerStore.objectNeedsUpdate(startConnectedToId);
        }
        const lineLength = start.distanceTo(end);
        // Set the length edit box with id="wallLength" in focus in the property panel
        editorStore.setSingleLineEditingLengthPanel(lineLength);
      }
    }
  };

  const handlePointerUp = () => {
  };

  const handleDoubleClick = (event: ThreeEvent<MouseEvent>) => {
    if (editorStore.lineConstructionMode) {
      // Get the click position in world coordinates
      const { clientX, clientY } = event;
      const pos = projectToWorld(clientX, clientY, gl, camera);
      const clickPosition = new THREE.Vector2(pos[0], pos[1]);

      // Check if there's a current line being constructed
      if (currentLine.current) {
        const line = floorplannerStore.singleLinesMap.get(currentLine.current);
        if (line) {
          const end = line.end;

          // Calculate the distance between the click and the line's endpoint
          const distance = clickPosition.distanceTo(end);

          // If the click is close enough to the endpoint, exit construction mode
          if (distance < editorStore.snappingTolerance) {
            // Exit line construction mode
            editorStore.setLineConstructionMode(false);
            // Perform any additional cleanup if necessary
            // You may not need to mark the line for room update
            // Optionally, clear selections or update alignment lines
            editorStore.clearSelections();
            renderStore.clearAlignmentLines();
          }
        }
      }
    }
  };

  const createNewLineSegment = (pos: [number, number]) => {
    if (lastClickPosition.current && currentLine) {
      const start = currentLine.current ?
        floorplannerStore.singleLinesMap.get(currentLine.current)?.end.clone() as THREE.Vector2 :
        lastClickPosition.current.clone();
      const end = new THREE.Vector2(pos[0], pos[1]);
      // Create a new line
      const newLine: SingleLineType = {
        id: floorplannerStore.generateId(),
        type: "singleLine",
        start: start,
        end: end,
      };
      floorplannerStore.addSingleLine(newLine);
      //editorStore.setSingleLineEditingLength(newLine.id);  // This will make the line width editable while constructing
      // Pause mobx observer updates for this operation
      runInAction(() => {
        if (currentLine.current) {
          const line = floorplannerStore.singleLinesMap.get(currentLine.current);
          if (!line) {
            return;
          }
          const snappedStart = floorplannerStore.snapToOtherObjects(newLine.id, WallConnectionStart, start, true);
          const snappedEnd = floorplannerStore.snapToOtherObjects(line.id, WallConnectionEnd, end, true);
        }
      });
      // Select the new line
      editorStore.clearSelections();
      editorStore.addSelection(newLine);
      invalidate();

      currentLine.current = newLine.id;
      addNewLine.current = false;
    }
  };

  // When the user press Enter key (like inside Edit field), editorStore.lineConstructionModeAddNewLine will signal to add a new line
  useEffect(() => {
    if (currentLine.current && lineConstructionModeAddNewLine) {
      editorStore.setLineConstructionModeAddNewLine(false);
      const line = floorplannerStore.singleLinesMap.get(currentLine.current);
      if (line) {
        lastClickPosition.current = line.end.clone();
        addNewLine.current = true;
      }
    }
  }, [lineConstructionModeAddNewLine]);

  useEffect(() => {
    if (currentLine.current) {
      const line = floorplannerStore.singleLinesMap.get(currentLine.current);
      if (line) {
        if (!addNewLine.current) {
          editorStore.clearSelections();
          // Get the line previous connection
          const previousLineId = floorplannerStore.objectConnectedTo(line.id, WallConnectionStart);
          // Remove current constructed line since it is not click confirmed yet
          floorplannerStore.removeSingleLine(currentLine.current);
          // If there is a previous line, snap the previous line to any possible connections at the end
          if (previousLineId) {
            runInAction(() => {
              const previousLine = floorplannerStore.singleLinesMap.get(previousLineId);
              if (previousLine) {
                const snapped = floorplannerStore.snapToOtherObjects(previousLine.id, WallConnectionEnd, previousLine.end, true);
              }
            });
          }
        } else if (addNewLine.current) {
          // Snap the current line to any possible connections at the start
          runInAction(() => {
            if (currentLine.current) {
              const snapped = floorplannerStore.snapToOtherObjects(line.id, WallConnectionEnd, line.end, true);
              if (snapped) {
                // Select the line if it is snapped to another line
                floorplannerStore.selectLine(currentLine.current);
              }
            }
          });
        }
      }
    }
    renderStore.clearAlignmentLines();
    editorStore.setSingleLineEditingLength(undefined);
    addNewLine.current = false;
    currentLine.current = undefined;
    lastClickPosition.current = undefined;
    if (lineConstructionMode || view3D) {
      document.body.classList.remove("cursor-vector")
      document.body.style.cursor = 'crosshair';
      floorplannerStore.setBlockDirty(true);
    } else {
      document.body.style.cursor = 'auto';
      floorplannerStore.setBlockDirty(false);
    }
  }, [lineConstructionMode]);

  return (
    <mesh
      position={[0, 0, -0.01]} // Ensure the plane is in the background
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
      //onDoubleClick={handleDoubleClick}
      userData={{ id: "line-construction-plane" }}
    >
      <planeGeometry args={[1000, 1000]} />
      <meshBasicMaterial transparent opacity={0} />
    </mesh>
  );
});

export default LineConstructionPlane;
