import React, { useEffect } from 'react';
import { floorplannerStore } from '../../store/floorplannerStore';

import SpotlightIcon from '../../assets/symbols/belysning2_alt.svg';
import LightFixIcon from '../../assets/symbols/belysning2.svg';
import DoubleDoorIcon from '../../assets/symbols/Dubbeldörr.svg';
import Sofa2seatIcon from '../../../src/assets/symbols/2-sist soffa-01.svg';
import Sofa3seatIcon from '../../../src/assets/symbols/3 sists soffa-01.svg';
import ModularSofaIcon from '../../../src/assets/symbols/modulsoffa-01.svg';
import ChaiseSofaIcon from '../../../src/assets/symbols/schäslong-01.svg';
import LoungeChair1Icon from '../../../src/assets/symbols/fåtölj  2-01.svg';
import LoungeChair2Icon from '../../../src/assets/symbols/fåtölj 3-01.svg';
import ArmChairIcon from '../../../src/assets/symbols/Fåtölj standard-01.svg';
import OctagonalCoffTabIcon from '../../../src/assets/symbols/poly 1-01.svg';
import PentagonalCoffTabIcon from '../../../src/assets/symbols/poly 2-01.svg'
import HexagonalCoffTabIcon from '../../../src/assets/symbols/poly 3-01.svg'
import OrganicCoffTabIcon from '../../../src/assets/symbols/soffbord 2-01.svg'
import OvalCoffTabIcon from '../../../src/assets/symbols/matbord-01.svg'
import Chair6TableIcon from '../../../src/assets/symbols/6 bord ny-01.svg'
import Chair4TableIcon from '../../../src/assets/symbols/4-bord ny-01.svg'
import Chair2TableIcon from '../../../src/assets/symbols/2-bord ny-01.svg'
import RoundTableIcon from '../../../src/assets/symbols/runt bord ny-01.svg'
import ArmChairDRIcon from '../../../src/assets/symbols/stol 2-01.svg'
import ChairIcon from '../../../src/assets/symbols/stol ny-01.svg'
import DishWasherIcon from '../../../src/assets/symbols/dishwasher.svg'
import BasinIcon from '../../../src/assets/symbols/handfat ny-01.svg'
import BathtubIcon from '../../../src/assets/symbols/badkar 1 ny-01.svg'
import ToiletIcon from '../../../src/assets/symbols/toa ny-01.svg'
import ShowerIcon from '../../../src/assets/symbols/dusch.svg'
import KingBedIcon from '../../../src/assets/symbols/dubbelsäng ny-01.svg'
import SingleBedIcon from '../../../src/assets/symbols/enkelsäng ny-01.svg'
import NoSVGIcon from '../../../src/assets/symbols/TransparentImage.png'
import PlantIcon from '../../../src/assets/symbols/växt.svg'
import Curtain1Icon from '../../../src/assets/symbols/Gardin.svg'
import Curtain2Icon from '../../../src/assets/symbols/Gardin 2.svg'
import Hanger1Icon from '../../../src/assets/symbols/galgar 1 ny-01.svg'
import Hanger2Icon from '../../../src/assets/symbols/galgar 3 ny-01.svg'
import Hanger3Icon from '../../../src/assets/symbols/galgar 4 ny-01.svg'
import Hanger4Icon from '../../../src/assets/symbols/galgar 2 ny-01.svg'

import twoSeatSofa from '../../assets/symbolsIcons/2 Seat sofa_Rityta 1.png'
import threeSeatSofa from '../../assets/symbolsIcons/3 Seat sofa_Rityta 1.png'
import modularSofa from '../../assets/symbolsIcons/Modular sofa_Rityta 1.png'
import chaiseSofa from '../../assets/symbolsIcons/Chaise sofa_Rityta 1.png'
import loungeChair1 from '../../assets/symbolsIcons/Lounge chair 1_Rityta 1.png'
import loungeChair2 from '../../assets/symbolsIcons/Lounge chair 2_Rityta 1.png'
import armChair1 from '../../assets/symbolsIcons/Armchair 1_Rityta 1.png'
import armChair2 from '../../assets/symbolsIcons/Armchair 2_Rityta 1.png'
import window from '../../assets/symbolsIcons/Window_Rityta 1.png'
import singleDoor from '../../assets/symbolsIcons/Single door_Rityta 1.png'
import octagonal from '../../assets/symbolsIcons/Octagon_Rityta 1.png'
import pentagonal from '../../assets/symbolsIcons/Pentagon_Rityta 1.png'
import hexagonal from '../../assets/symbolsIcons/Hexagon_Rityta 1.png'
import lightingFixture from '../../assets/symbolsIcons/Lighting fixture_Rityta 1.png'
import coffeeTable1 from '../../assets/symbolsIcons/Coffee table 1_Rityta 1.png'
import spotlight from '../../assets/symbolsIcons/Spotlight_Rityta 1.png'
import square from '../../assets/symbolsIcons/Square_Rityta 1.png'
import toilet from '../../assets/symbolsIcons/Toilet_Rityta 1.png'
import triangle from '../../assets/symbolsIcons/Triangle_Rityta 1.png'
import circle from '../../assets/symbolsIcons/Circle_Rityta 1.png'
import shower from '../../assets/symbolsIcons/Shower_Rityta 1.png'
import kingBed from '../../assets/symbolsIcons/Double bed 2_Rityta 1.png'
import singleBed from '../../assets/symbolsIcons/Single bed_Rityta 1.png'
import rectangulrStair from '../../assets/symbolsIcons/Rectangular stair_Rityta 1.png'
import plant from '../../assets/symbolsIcons/Plant 2_Rityta 1.png'
import curtain1 from '../../assets/symbolsIcons/Curtain 1_Rityta 1.png'
import curtain2 from '../../assets/symbolsIcons/Curtain 2_Rityta 1.png'
import hanger1 from '../../assets/symbolsIcons/Hangers 4_Rityta 1.png'
import hanger2 from '../../assets/symbolsIcons/Hangers 3_Rityta 1.png'
import hanger3 from '../../assets/symbolsIcons/Hangers 2_Rityta 1.png'
import hanger4 from '../../assets/symbolsIcons/Hangers 1_Rityta 1.png'
import doubleDoor from '../../assets/symbolsIcons/Double door_Rityta 1.png'
import basin from '../../assets/symbolsIcons/Basin_Rityta 1.png'
import bathtub from '../../assets/symbolsIcons/Bathtub_Rityta 1.png'
import chair from '../../assets/symbolsIcons/Chair_Rityta 1.png'
import circleStairs from '../../assets/symbolsIcons/Circular stair_Rityta 1.png'
import coffeeTable2 from '../../assets/symbolsIcons/Oval coffee table2_Rityta 1.png'

import DefaultIcon from '../../assets/defaultIcon.png';

import { RulerLineType, SingleLineType, WallType } from '../../types/wallTypes';
import { Vector2 } from 'three';
import { editorStore } from '../../store/editorStore';
import { PiLineSegmentsLight } from "react-icons/pi";
import { IoClose } from 'react-icons/io5';
import { observer } from 'mobx-react-lite';
import CircleStairs from '../FloorPlan/CircleStairs';

interface DropdownProps {
  position: { top: number; left: number };
  onClose: () => void;
  dropdownRef: React.RefObject<HTMLDivElement>;
}

export const SymbolsPanel: React.FC<DropdownProps> = observer(({ position, onClose, dropdownRef }) => {

  // Prevent clicks inside the panel from closing it
  const handlePanelClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();  // Prevents the event from bubbling up
  };

  const handleIconClick = (label: string, props?: any) => {
    // Retrieve the visible center from the editor store
    const [centerX, centerY] = editorStore.getVisibleCenter();
  /*   if (label === "Wall") {
      // Set a default wall length, for example 5 units (adjust based on your needs)
      const wallLength = 2;

      // Calculate the start and end points of the wall, centered around the visible center
      const startX = centerX - wallLength / 2;
      const endX = centerX + wallLength / 2;
      const startY = centerY;
      const endY = centerY;

      const wall: WallType = {
        id: floorplannerStore.generateId(),
        type: "wall",
        start: new Vector2(startX, startY), // Start point to the left of the center
        end: new Vector2(endX, endY), // End point to the right of the center

      }
      floorplannerStore.addWall(wall);
    } else if (label === "SingleLine") {
      // Set a default line length, for example 5 units (adjust based on your needs)
      const singleLineLength = 2;
      // Calculate the start and end points of the wall, centered around the visible center
      const startX = centerX - singleLineLength / 2;
      const endX = centerX + singleLineLength / 2;
      const startY = centerY;
      const endY = centerY;

      const line: SingleLineType = {
        id: floorplannerStore.generateId(),
        type: "singleLine",
        start: new Vector2(startX, startY), // Start point to the left of the center
        end: new Vector2(endX, endY), // End point to the right of the center
      }
      floorplannerStore.addSingleLine(line);
    } else if (label === "RulerLine") {
      // Set a default line length, for example 5 units (adjust based on your needs)
      const rulerLineLength = 2;
      // Calculate the start and end points of the wall, centered around the visible center
      const startX = centerX - rulerLineLength / 2;
      const endX = centerX + rulerLineLength / 2;
      const startY = centerY;
      const endY = centerY;

      const line: RulerLineType = {
        id: floorplannerStore.generateId(),
        type: "rulerLine",
        start: new Vector2(startX, startY), // Start point to the left of the center
        end: new Vector2(endX, endY), // End point to the right of the center
      }
      floorplannerStore.addRulerLine(line);
    } else if (label === "WallConstructionMode") {
      editorStore.setWallConstructionMode(!editorStore.wallConstructionMode);
      onClose();
    } else if (label === "LineConstructionMode") {
      editorStore.setLineConstructionMode(!editorStore.lineConstructionMode);
      onClose();
    } else if (label === "RulerConstructionMode") {
      editorStore.setRulerConstructionMode(!editorStore.rulerConstructionMode);
      onClose();
    }  else if (label === "AreaConstructionMode") {
      editorStore.setAreaConstructionMode(!editorStore.areaConstructionMode);
      onClose();
    }  */
    if (label === "Door") {
      floorplannerStore.addSymbol("door", [0, 0]);
    }
    else if (label === "DoubleDoor") {
      floorplannerStore.addSymbol("doubleDoor", [0, 0]);
    } else if (label === "CircleStairs") {
      floorplannerStore.addSymbol("circleStairs", [0, 0]);
    } else if (label === "RectStairs") {
      floorplannerStore.addSymbol("rectStairs", [0, 0]);
    } else if (label === "SquareSymbol") {
      floorplannerStore.addSymbol("square", [0, 0]);
    } else if (label === "CircleSymbol") {
      floorplannerStore.addSymbol("circle", [0, 0]);
    } else if (label === "TriangleSymbol") {
      floorplannerStore.addSymbol("triangle", [0, 0]);
    } else if (label === "SvgSymbol") {
      floorplannerStore.addSymbol("svg", [0, 0], props);
    } 
    /* else if (label === "TextSymbol") {
      floorplannerStore.addSymbol("text", [0, 0]);
    }  */
    else if (label === "Window") {
      floorplannerStore.addSymbol("window", [0, 0]);
    } /* else if (label === "AreaSymbol") {
      floorplannerStore.addSymbol("area", [0, 0], {
        vertices: [
          new Vector2(0, 0),
          new Vector2(1, 0),
          new Vector2(1.5, 0.5),
          new Vector2(1, 1),
          new Vector2(0, 1),
        ],
      });
    } */
  };

  return (
    <div
      className="fixed z-50 w-[398px] bg-white rounded shadow-lg mb-8 overflow-y-auto scrollbar"
      style={{ top: position.top, left: position.left, maxHeight: '70vh' }}
      ref={dropdownRef}
      onClick={handlePanelClick}
    >
      <div className="flex flex-cols items-center justify-between  border-b border-[#edebe6] p-2 px-4 pr-2">
        <div className="text-sm font-normal">Symbol library</div>
        <div onClick={onClose} className="text-sm  text-gray-500"><IoClose style={{ strokeWidth: 0.5 }} size={24} /></div>
      </div>
     
      {/* Group 1 */}
      {/* <div className="mb-4">
        <div className=" border-gray-300 pb-2 mb-2 mt-4 text-sm font-semibold">Walls</div>
        <div className="grid grid-cols-3 gap-4">
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("Wall")}
          >
            <div className="w-12 h-12 bg-gray-300 rounded"></div>
            <div className="text-xs mt-1">Single wall</div>
          </div>
         
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SingleLine")}
          >
            <div className="w-12 h-12 bg-gray-300 rounded"></div>
            <div className="text-xs mt-1">Single line</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("RulerLine")}
          >
            <div className="w-12 h-12 bg-gray-300 rounded"></div>
            <div className="text-xs mt-1">Ruler line</div>
          </div>
          {/* <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("AreaSymbol")}
          >
            <div className="w-12 h-12 bg-gray-300 rounded"></div>
            <div className="text-xs mt-1">Area Component</div>
          </div> */}
         {/* <div
            className={`flex flex-col items-center cursor-pointer`}
            onClick={() => handleIconClick("WallConstructionMode")}
          >
            <div className={`w-12 h-12 rounded flex justify-center items-center ${editorStore.wallConstructionMode ? "bg-gray-400" : "bg-gray-300"}`}>
              <PiLineSegmentsLight className="w-full h-full object-contain p-2" />
            </div>
            <div className="text-xs mt-1 text-center">Wall construction mode</div>
          </div>
          <div
            className={`flex flex-col items-center cursor-pointer`}
            onClick={() => handleIconClick("LineConstructionMode")}
          >
            <div className={`w-12 h-12 rounded flex justify-center items-center ${editorStore.lineConstructionMode ? "bg-gray-400" : "bg-gray-300"}`}>
              <PiLineSegmentsLight className="w-full h-full object-contain p-2" />
            </div>
            <div className="text-xs mt-1 text-center">Line construction mode</div>
          </div>
          <div
            className={`flex flex-col items-center cursor-pointer`}
            onClick={() => handleIconClick("AreaConstructionMode")}
          >
            <div className={`w-12 h-12 rounded flex justify-center items-center ${editorStore.areaConstructionMode ? "bg-gray-400" : "bg-gray-300"}`}>
              <PiLineSegmentsLight className="w-full h-full object-contain p-2" />
            </div>
            <div className="text-xs mt-1 text-center">Area construction mode</div>
          </div>
          <div
            className={`flex flex-col items-center cursor-pointer`}
            onClick={() => handleIconClick("RulerConstructionMode")}
          >
            <div className={`w-12 h-12 rounded flex justify-center items-center ${editorStore.rulerConstructionMode ? "bg-gray-400" : "bg-gray-300"}`}>
              <PiLineSegmentsLight className="w-full h-full object-contain p-2" />
            </div>
            <div className="text-xs mt-1 text-center">Ruler construction mode</div>
          </div>
        </div>
      </div> */}

      {/* Group 2 */}
      <div className="mb-4 p-2 px-4">
        <div className="  mb-2 mt-4 text-sm font-medium">Doors & Windows</div>
        <div className="w-[364px] pr-2 grid grid-cols-3 gap-2">
        <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
             onClick={() => handleIconClick("Door")}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={singleDoor} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Single door</div>
         
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
             onClick={() => handleIconClick("DoubleDoor")}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={doubleDoor} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Double door</div>
         
          </div> 
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
             onClick={() => handleIconClick("Window")}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={window} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Window</div>
         
          </div>  
        </div>
      </div>

      {/* Group 3 */}
      <div className="mb-4 p-2 px-4">
        <div className="  mb-2 mt-4 text-sm font-medium">Stairs</div>
        <div className="w-[364px] pr-2 grid grid-cols-3 gap-2">
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("CircleStairs")}
          >
            <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={circleStairs} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Circular stairs</div>
        
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("RectStairs")}
          >
            <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={rectangulrStair} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Rectangular stairs </div>
        
          </div>
        </div>
      </div>
      {/* Group 3 */}
     {/*  <div className="mb-4">
        <div className="border-b border-gray-300 pb-2 mb-2 text-sm font-semibold">Scale rulers & Measuring sticks</div>
        <div className="grid grid-cols-3 gap-4">
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 2, svgHeight: 1.2, name: "Measuring stick" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Measuring stick</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 2, svgHeight: 1.2, name: "Measuring arrow" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Measuring arrow</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 2, svgHeight: 1, name: "Scale ruler" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Scale ruler</div>
          </div>
        </div>
      </div> */}
      {/* Group 4 */}
      <div className='p-2 px-4'>
        <div className=" mt-4 mb-2 text-sm font-medium">Lounge furniture</div>
        <div className="w-[364px] pr-2 grid grid-cols-3 gap-2">
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Sofa2seatIcon, svgLength: 1.5, svgHeight: 1, name: "2 seat sofa" })}
          >
            <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={twoSeatSofa} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">2 seat sofa</div>
        
           
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Sofa3seatIcon, svgLength: 2, svgHeight: 1, name: "3 seat sofa" })}
          >
            <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={threeSeatSofa} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">3 seat sofa</div>
        
           
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: ModularSofaIcon, svgLength: 2.4, svgHeight: 2.136, name: "Modular sofa" })}
          >
            <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={modularSofa} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Modular sofa</div>
        
            
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: ChaiseSofaIcon, svgLength: 1.8, svgHeight: 0.867, name: "Chaise sofa" })}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={chaiseSofa} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Chaise sofa</div>
        
          </div>
          <div
            className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: LoungeChair1Icon, svgLength: 0.699, svgHeight: 0.703, name: "Lounge chair" })}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={loungeChair2} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Lounge chair</div>
        
            
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: LoungeChair2Icon, svgLength: 0.801, svgHeight: 0.743, name: "Lounge chair" })}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={loungeChair1} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Lounge chair</div>
        
            
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: ArmChairIcon, svgLength: 0.6, svgHeight: 0.614, name: "Armchair" })}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={armChair1} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Armchair</div>
        
          </div>

         {/*  <div
            className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: OctagonalCoffTabIcon, svgLength: 0.9, svgHeight: 0.884, name: "Octagonal coffee table" })}
          >
            <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={OctagonalCoffTabIcon} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Octagonal coffee table</div>
        
           
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: PentagonalCoffTabIcon, svgLength: 0.946, svgHeight: 0.939, name: "Pentagonal coffee table" })}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={PentagonalCoffTabIcon} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Pentagonal  coffee table</div>
                  
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: HexagonalCoffTabIcon, svgLength: 1.039, svgHeight: 0.906, name: "Hexagonal coffee table" })}
          >
            <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={HexagonalCoffTabIcon} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Hexagonal  coffee table</div>
             
           
          </div> */}
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: OrganicCoffTabIcon, svgLength: 1.092, svgHeight: 0.771, name: "Coffee table 1" })}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={coffeeTable1} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Coffee table 1</div>
            
            
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: OvalCoffTabIcon, svgLength: 1.2, svgHeight: 0.56, name: "Coffee table 2" })}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={coffeeTable2} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Coffee table 2</div>
            
            
          </div>
        </div>
      </div>
      {/* Group 4 */}
      <div className='p-2 px-4'>
        <div className="  mb-2 mt-6 text-sm font-medium">Dining room</div>
        <div className="w-[364px] pr-2 grid grid-cols-3 gap-2">
       {/*    <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Chair6TableIcon, svgLength: 1.913, svgHeight: 1.8, name: "6 chair table" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={Chair6TableIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">6 chair table</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Chair4TableIcon, svgLength: 2.051, svgHeight: 1.4, name: "4 chair table" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={Chair4TableIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">4 chair table</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Chair2TableIcon, svgLength: 1.934, svgHeight: 0.9, name: "2 chair table" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={Chair2TableIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">2 chair table</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: RoundTableIcon, svgLength: 2.4, svgHeight: 2.284, name: "Round table" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={RoundTableIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Round table</div>
          </div> */}
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: ChairIcon, svgLength: 0.5, svgHeight: 0.403, name: "Chair" })}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={chair} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Chair</div>
            
           
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: ArmChairDRIcon, svgLength: 0.450, svgHeight: 0.450, name: "Armchair" })}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={armChair2} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Armchair</div>
            
            
          </div>
        </div>
      </div>
      {/* Group 4 */}
      <div className='p-2 px-4'>
        <div className=" pb-2 mb-2 mt-4 text-sm font-medium">Bathroom</div>
        <div className="grid grid-cols-3 gap-4">
          <div
            className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: BasinIcon, svgLength: 0.6, svgHeight: 0.663, name: "Basin" })}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={basin} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Basin</div>
            
            
          </div>
          <div
            className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: BathtubIcon, svgLength: 1.828, svgHeight: 1.1496, name: "Bathtub" })}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={bathtub} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Bathtub</div>
            
          
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: ToiletIcon, svgLength: 0.73, svgHeight: 0.458, name: "Toilet" })}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={toilet} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Toilet</div>
            
            
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: ShowerIcon, svgLength: 0.9, svgHeight: 0.9, name: "Shower" })}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px]  absolute rounded-sm" src={shower} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Shower</div>
            
           
          </div>
        </div>
      </div>
      {/* Group 4 */}
      <div className=' p-2 px-4'>
        <div className=" pb-2 mb-2 mt-4 text-sm font-medium">Bedroom</div>
        <div className="grid grid-cols-3 gap-4">
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: KingBedIcon, svgLength: 2.1, svgHeight: 1.736, name: "King bed" })}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px]  absolute rounded-sm" src={kingBed} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">King bed</div>
            
           
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: SingleBedIcon, svgLength: 2.1, svgHeight: 1.53, name: "Single bed" })}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px]  absolute rounded-sm" src={singleBed} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Single bed</div>
            
          
          </div>

        </div>
      </div>
      {/* Group 4 */}
      <div className=' p-2 px-4'>
        <div className="pb-2 mb-2 mt-4 text-sm font-medium">Symbols & signs</div>
        <div className="w-[364px] pr-2 grid grid-cols-3 gap-2">
          <div
          className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: SpotlightIcon, svgLength: 0.2, svgHeight: 0.5, name: "Spotlight" })}
          >
              <img className="w-[108px] h-[87px] left-[3px] top-[3px]  absolute rounded-sm" src={spotlight} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Spotlight</div>
            
            
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: LightFixIcon, svgLength: 0.2, svgHeight: 0.5, name: "Lighting fixture", lineWeight : 1.5,
            })}
          >
              <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={lightingFixture} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Lighting fixture</div>
           
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Curtain1Icon, svgLength: 1, svgHeight: 0.2, name: "Curtain" })}
          >
              <img className="w-[108px] h-[87px] left-[3px] top-[3px]  absolute rounded-sm" src={curtain1} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Curtain</div>
            
           
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Curtain2Icon, svgLength: 1, svgHeight: 0.1, name: "Curtain" })}
          >
              <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={curtain2} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Curtain</div>
            
          </div>
          {/* <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 0.07, svgHeight: 0.2, name: "Power outlet" })}
          >
              <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={DefaultIcon} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Power outlet</div>
            
          </div> */}
       {/*    <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 1, svgHeight: 0.35, name: "North sign" })}
          >
              <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={DefaultIcon} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">North sign</div>
            
          </div> */}
          <div
            className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Hanger1Icon, svgLength: 0.782, svgHeight: 0.521, name: "Hangers" })}
          >
              <img className="w-[108px] h-[87px] left-[3px] top-[3px]  absolute rounded-sm" src={hanger1} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Hangers</div>
           
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Hanger2Icon, svgLength: 1.044, svgHeight: 0.438, name: "Hangers" })}
          >
              <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={hanger2} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Hangers</div>
            
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Hanger3Icon, svgLength: 1.304, svgHeight: 0.438, name: "Hangers" })}
          >
              <img className="w-[108px] h-[87px] left-[3px] top-[3px]  absolute rounded-sm" src={hanger3}/> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Hangers</div>
            
          
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Hanger4Icon, svgLength: 1.566, svgHeight: 0.323, name: "Hangers" })}
          >
              <img className="w-[108px] h-[87px] left-[3px] top-[3px]  absolute rounded-sm" src={hanger4} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Hangers</div>
            
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: PlantIcon, svgLength: 0.712, svgHeight: 0.689, name: "Plant" })}
          >
              <img className="w-[108px] h-[87px] left-[3px] top-[3px]  absolute rounded-sm" src={plant} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Plant</div>
            
          </div>



        </div>
      </div>
      {/* Group 4 */}
    {/*   <div>
      <div className=" border-gray-300 pb-2 mb-2 mt-4 text-sm font-medium ">Shapes</div>
       
       <div className="w-[364px] h-[239px] pr-2 grid grid-cols-3 gap-2">
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("TriangleSymbol")}
          >
            
             <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={triangle} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Triangle</div>
         
            
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("CircleSymbol")}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={circle} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Circle</div>
         
          
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SquareSymbol")}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={square} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Square</div>
          </div> 
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: OctagonalCoffTabIcon, svgLength: 0.9, svgHeight: 0.884, name: "Octagon" })}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px]  absolute rounded-sm" src={octagonal} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Octagon</div>
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: PentagonalCoffTabIcon, svgLength: 0.946, svgHeight: 0.939, name: "Pentagon" })}
          >
            
            <img className="w-[108px] h-[87px] left-[3px] top-[3px]  absolute rounded-sm" src={pentagonal} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Pentagon</div>
          </div>
          <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: HexagonalCoffTabIcon, svgLength: 1.039, svgHeight: 0.906, name: "Hexagon" })}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px]  absolute rounded-sm" src={hexagonal} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Hexagon</div>
          </div>
         {/*  <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 2, svgHeight: 2, name: "Arrow" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={ArrowIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Arrow</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: DoubbleArrowIcon, svgLength: 2, svgHeight: 0.5, name: "Double arrow" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Double arrow</div>
          </div> 


        </div>
      </div> */}
      
    </div>

  );
});