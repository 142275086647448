import { Line } from "@react-three/drei";
import { AlignmentLineType } from "../../types/wallTypes";
import { observer } from "mobx-react-lite";

interface AlignmentLineProps {
    line: AlignmentLineType;
}
const AlignmentLine: React.FC<AlignmentLineProps> = observer(({ line }) => {
    const noopRaycast = () => null;

    return (
        line.axis === "x" ? (
            <Line
                key={`align-x-${line.id}`}
                points={[[line.hit.x, -1000, 0], [line.hit.x, 1000, 0]]}
                color="blue"
                lineWidth={0.5}
                raycast={noopRaycast}
            />
        ) : (
            <Line
                key={`align-y-${line.id}`}
                points={[[-1000, line.hit.y, 0], [1000, line.hit.y, 0]]}
                color="blue"
                lineWidth={0.5}
                raycast={noopRaycast}
            />
        )
    );
})

export default AlignmentLine;