import React, { useRef, useState, useEffect, useCallback } from "react";
import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import DraggableObject from "./DraggableObject";
import { RectStairsType, SquareSymbolType, zIndexEpsilon } from "../../types/wallTypes";
import { SelectableSymbol, BoundingBox } from "./SelectableSymbol";
import { projectToWorld } from "./projectToWorld";
import { FloorplannerStoreContext } from "../../store/floorplannerStore";
import { Line } from "@react-three/drei";
import { editorStore } from "../../store/editorStore";
import { observer } from "mobx-react-lite";
import { set } from "mobx";
import { is } from '@react-three/fiber/dist/declarations/src/core/utils';

interface SquareSymbolProps {
  square: SquareSymbolType;
  onDragStart: (square: SquareSymbolType, offset: [number, number]) => void;
  onDrag: (newPosition: [number, number]) => void;
  onDragEnd: (endPosition: [number, number]) => void;
}

const SquareSymbol: React.FC<SquareSymbolProps> = observer(({
  square,
  onDragStart,
  onDragEnd,
  onDrag,
}) => {
  const { gl, camera } = useThree();
  const groupRef = useRef<THREE.Group>(null);
  const floorplannerStore = React.useContext(FloorplannerStoreContext);

  const lineWeight = square.lineWeight || floorplannerStore.symbolLineWeight;
  const squareWidth = square.squareWidth;
  const squareHeight = square.squareHeight;

  const grabHandleSize = 0.1; // Variable to control the size of the grab handle
  const flipX = square.flipHorizontal ? -1 : 1; // Flip horizontally
  const flipY = square.flipVertical ? -1 : 1; // Flip vertically
  const [centerPosition, setCenterPosition] = useState<[number, number]>([
    squareWidth / 2,
    squareHeight / 2,
  ]);
  const lineColor = square.lineColor || floorplannerStore.lineColor;
  // State to store the pivot dynamically
  const [pivot, setPivot] = useState<[number, number]>([
    -squareWidth / 2,
    -squareHeight / 2,
  ]);
  const [isHovered, setIsHovered] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const noopRaycast = () => null;

  // Calculate the bounding box of the stairs
  const calculateBoundingBox = useCallback((): BoundingBox => {
    if (!groupRef.current) {
      return {
        topLeft: [0, 0],
        topRight: [0, 0],
        bottomLeft: [0, 0],
        bottomRight: [0, 0],
        width: 0,
        height: 0,
        depth: 0,
      };
    }

    // Clone the object
    const tempGroup = groupRef.current.clone();

    // Save the original rotation
    const originalRotation = tempGroup.rotation.clone();

    // Temporarily reset the rotation to 0
    tempGroup.rotation.set(0, 0, 0);

    // Calculate the bounding box as if the object had no rotation
    const boundingBox = new THREE.Box3().setFromObject(tempGroup);
    const size = new THREE.Vector3();
    boundingBox.getSize(size);

    // Restore the original rotation
    tempGroup.rotation.copy(originalRotation);

    // Calculate the corners of the bounding box
    const topLeft = new THREE.Vector3(
      0,
      size.y + (lineWeight / 100),
      0
    );
    const topRight = new THREE.Vector3(
      size.x + (lineWeight / 100),
      size.y + (lineWeight / 100),
      0
    );
    const bottomLeft = new THREE.Vector3(
      0,
      0,
      0
    );
    const bottomRight = new THREE.Vector3(
      size.x + (lineWeight / 100),
      0,
      0
    );

    const width = topRight.x - topLeft.x;
    const height = topLeft.y - bottomLeft.y;
    setPivot([-width / 2, -height / 2]);
    centerPosition[0] = size.x / 2;
    centerPosition[1] = size.y / 2;

    return {
      topLeft: [topLeft.x, topLeft.y],
      topRight: [topRight.x, topRight.y],
      bottomLeft: [bottomLeft.x, bottomLeft.y],
      bottomRight: [bottomRight.x, bottomRight.y],
      width: boundingBox.max.x - boundingBox.min.x,
      height: boundingBox.max.y - boundingBox.min.y,
      depth: 0,
    };
  }, [square.position.x, square.position.y, square.flipHorizontal, square.flipVertical, squareWidth, squareHeight]);

  const dragOffset = useRef({ x: 0, y: 0 });

  const onHandleDrag = (event: PointerEvent) => {
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    const dy = worldY - square.position.y - dragOffset.current.y;

    // Update the handle's Y position
    setCenterPosition([square.position.x, square.position.y + dy]);
  };

  const onPointerUpHandle = () => {
    gl.domElement.removeEventListener("pointermove", onHandleDrag);
    gl.domElement.removeEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.setDirty();
  };

  const handleDrag = (newPosition: [number, number]) => {
    onDrag(newPosition);
  };
  // Call the function and destructure the returned values
  //const { rectPoints, extraLines } = generateRectPointsAndLines();

  // Handle Drag Right (Green Line)
  const dragOffsetRight = useRef({ x: 0, y: 0 });

  const onHandleDragRight = (event: PointerEvent) => {
    const [worldX] = projectToWorld(event.clientX, event.clientY, gl, camera);
    const dx = worldX - square.position.x - dragOffsetRight.current.x;
    floorplannerStore.updateSymbolProperty(square.id, "squareWidth", Math.max(0.1, squareWidth + dx));
    setPivot([-squareWidth / 2, -squareHeight / 2]);
  };

  const onPointerUpHandleRight = () => {
    gl.domElement.removeEventListener("pointermove", onHandleDragRight);
    gl.domElement.removeEventListener("pointerup", onPointerUpHandleRight);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.updateSymbolProperty(square.id, "squareWidth", squareWidth);
  };

  // Handle Drag Top (Red Line)
  const dragOffsetTop = useRef({ x: 0, y: 0 });

  const onHandleDragTop = (event: PointerEvent) => {
    const [, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    const dy = worldY - square.position.y - dragOffsetTop.current.y;
    floorplannerStore.updateSymbolProperty(square.id, "squareHeight", Math.max(0.1, squareHeight + dy * flipY));
    setPivot([-squareWidth / 2, -squareHeight / 2]);
  };

  const onPointerUpHandleTop = () => {
    gl.domElement.removeEventListener("pointermove", onHandleDragTop);
    gl.domElement.removeEventListener("pointerup", onPointerUpHandleTop);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.updateSymbolProperty(square.id, "squareHeight", squareHeight);
  };

  useEffect(() => {
    editorStore.updateGroupRef(square.id, groupRef.current);
  }, [groupRef.current]);

  // Redraw when the symbol.selected changes
  useEffect(() => {
  }, [square.selected]);

  return (
    <group
      position={[0, 0, (square.zIndex * zIndexEpsilon)]}
    >
      <DraggableObject
        position={[square.position.x, square.position.y]}
        onDragStart={(offset) => {
          setIsDragging(true);
          onDragStart(square, offset)
        }}
        onDragEnd={(endPosition) => {
          onDragEnd(endPosition)
          setIsDragging(false);
        }}
        onDrag={handleDrag}
        selectable={true}
        attachmentId={square.id}
        attachmentType="doorAttachments"
        symbol={square}
      >
        <SelectableSymbol
          handleSize={floorplannerStore.symbolHandleSize}
          calculateBoundingBox={calculateBoundingBox}
          onResize={(newWidth, newHeight, boundingBox, handle) => {
            floorplannerStore.updateSymbolProperty(square.id, "squareWidth", newWidth);
            floorplannerStore.updateSymbolProperty(square.id, "squareHeight", newHeight);
            const widthDiff = newWidth - squareWidth;
            const heightDiff = newHeight - squareHeight;
            if (handle === "midRight") {
              square.position.x += widthDiff / 2;
            } else if (handle === "midLeft") {
              square.position.x -= widthDiff / 2;
            } else if (handle === "midTop") {
              square.position.y += heightDiff / 2;
            } else if (handle === "midBottom") {
              square.position.y -= heightDiff / 2;
            }
            setPivot([-newWidth / 2, -newHeight / 2]);
          }}
          center={centerPosition}
          rotation={square.rotation}
          symbol={square}
          drawHandles={true}
          pivot={pivot}
          onPointerOver={() => setIsHovered(true)}
          onPointerOut={() => setIsHovered(false)}
          isDragging={isDragging}
        >
          <group
            ref={groupRef}
            rotation={[0, 0, square.rotation || 0]}
            onPointerEnter={() => {
              if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.add("cursor-vector");
            }}
            onPointerLeave={() => {
              if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.remove("cursor-vector");
            }}
          >
            <mesh>
              <planeGeometry args={[squareWidth, squareHeight]} />
              <meshBasicMaterial color={square.fillColor || ""} />
            </mesh>
            {/* Render top horizontal line */}
            <Line
              points={[
                [-squareWidth / 2 * flipX, squareHeight / 2, 0],
                [squareWidth / 2, squareHeight / 2, 0]
              ]}
              color={isHovered || square.selected ? 'blue' : lineColor}
              lineWidth={lineWeight}
              {...(square.lineType === "dashed" && {
                dashed: true,
                dashSize: 0.15,
                gapSize: 0.1,
              })}
              raycast={noopRaycast}
            />

            {/* Render bottom horizontal line */}
            <Line
              points={[
                [-squareWidth / 2, -squareHeight / 2, 0],
                [squareWidth / 2, -squareHeight / 2, 0]
              ]
              }
              color={isHovered || square.selected ? 'blue' : lineColor}
              lineWidth={lineWeight}
              {...(square.lineType === "dashed" && {
                dashed: true,
                dashSize: 0.15,
                gapSize: 0.1,
              })}
              raycast={noopRaycast}
            />

            {/* Render left vertical line */}
            <Line
              points={[
                [-squareWidth / 2, squareHeight / 2, 0],
                [-squareWidth / 2, -squareHeight / 2, 0]
              ]
              }
              color={isHovered || square.selected ? 'blue' : lineColor}
              lineWidth={lineWeight}
              {...(square.lineType === "dashed" && {
                dashed: true,
                dashSize: 0.15,
                gapSize: 0.1,
              })}
              raycast={noopRaycast}
            />

            {/* Render right vertical line */}
            <Line
              points={[
                [squareWidth / 2, squareHeight / 2, 0],
                [squareWidth / 2, -squareHeight / 2, 0]
              ]
              }
              color={isHovered || square.selected ? 'blue' : lineColor}
              lineWidth={lineWeight}
              {...(square.lineType === "dashed" && {
                dashed: true,
                dashSize: 0.15,
                gapSize: 0.1,
              })}
              raycast={noopRaycast}
            />
          </group>
        </SelectableSymbol>
      </DraggableObject>
    </group>
  );
});

export default SquareSymbol;
