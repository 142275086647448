import * as THREE from "three";
import { LineSegments2 } from "three/examples/jsm/lines/LineSegments2";

export const disposeMeshes = (group: THREE.Group) => {
    group.children.forEach((child) => {
        if (child instanceof LineSegments2) {
            // Dispose geometry and material for LineSegments2
            const line = child as LineSegments2;

            // Dispose geometry
            if (line.geometry) line.geometry.dispose();

            // Dispose material(s)
            if (Array.isArray(line.material)) {
                line.material.forEach((material) => material.dispose());
            } else {
                line.material.dispose();
            }
        } else if (child instanceof THREE.Mesh) {
            // Dispose geometry and material for Mesh
            const mesh = child as THREE.Mesh;
            if (mesh.geometry) mesh.geometry.dispose();
            if (Array.isArray(mesh.material)) {
                mesh.material.forEach((material) => material.dispose());
            } else {
                mesh.material.dispose();
            }
        }

        // If there are children, recursively dispose them
        if (child.children.length > 0) {
            disposeMeshes(child as THREE.Group);
        }
    });
};
