import React, { useRef, useState, useEffect } from 'react';
import { FiDownload } from 'react-icons/fi';
import { IoClose } from "react-icons/io5";
import { floorplannerStore } from '../../store/floorplannerStore';
import { renderStore } from '../../store/renderStore';
import { Tooltip } from 'flowbite-react';
import { PiMinus, PiPlus } from 'react-icons/pi';
import { observer } from 'mobx-react-lite';
import * as THREE from 'three';
import { image } from 'html2canvas/dist/types/css/types/image';
import { editorStore } from '../../store/editorStore';
import Clarity from '@microsoft/clarity';

interface DropdownProps {
    position: { top: number; left: number };
    onClose: () => void;
    dropdownRef: React.RefObject<HTMLDivElement>;
}

export const DownloadProject: React.FC<DropdownProps> = observer(({ position, onClose, dropdownRef }) => {
    const [zoom, setZoom] = useState(1); // Default zoom level
    const [pan, setPan] = useState({ x: 0, y: 0 }); // Pan offset
    const [containerSize, setContainerSize] = useState({ width: 300, height: 300 }); // Default container size (calculated)

    const maxDimension = 400; // Maximum width or height

    const imgRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    // Calculate the width and height of the image container based on the selected size's aspect ratio
    const handleSizeChange = (selectedWidth: number, selectedHeight: number) => {
        const aspectRatio = selectedWidth / selectedHeight;

        // Adjust width and height but ensure the larger dimension does not exceed maxDimension (400px)
        let calculatedWidth = maxDimension;
        let calculatedHeight = maxDimension;

        if (aspectRatio > 1) {
            // Landscape: width is greater than height
            calculatedHeight = maxDimension / aspectRatio;
        } else {
            // Portrait: height is greater than width
            calculatedWidth = maxDimension * aspectRatio;
        }

        setContainerSize({ width: calculatedWidth, height: calculatedHeight });
    };

    // Handle zoom with mouse wheel
    const handleWheelZoom = (e: React.WheelEvent,) => {
        const zoomFactor = e.deltaY > 0 ? 0.9 : 1.1; // Zoom out or in
        setZoom((prevZoom) => Math.max(0.5, Math.min(prevZoom * zoomFactor, 5))); // Clamp zoom between 0.5 and 5
    };

    // Handle zoom in/out using buttons
    const handleZoomChange = (factor: number) => {
        setZoom((prevZoom) => Math.max(0.5, Math.min(prevZoom + factor, 5))); // Clamp zoom
    };

    // Handle pan movement
    const handlePan = (e: React.MouseEvent) => {
        if (e.buttons !== 1) return; // Only allow panning with left mouse button
        setPan({
            x: pan.x + e.movementX,
            y: pan.y + e.movementY,
        });
    };

    // Capture snapshot with selected area and coordinates
    const handleDownload = (e: React.FormEvent) => {
        e.preventDefault();
        const target = e.currentTarget as any;
        const fileType = target.format.value;
        const [orientationStr, formatStr, widthStr, heightStr] = target.resolution.value.split(",");
        const targetFormat = {
            orientation: orientationStr as 'portrait' | 'landscape',
            format: formatStr,
            width: parseInt(widthStr),
            height: parseInt(heightStr),
        }

        renderStore.setRendering(true);
        setTimeout(() => {
            if (renderStore.scene) {
                const sceneBox = renderStore.computeBoundingBoxForScene(renderStore.scene);
                const sceneCenter = new THREE.Vector3();
                sceneBox.getCenter(sceneCenter);
                const size = new THREE.Vector3();
                sceneBox.getSize(size);

                // Calculate a single unitsPerPixel value for both axes
                const aspectX = size.x / containerSize.width;
                const aspectY = size.y / containerSize.height;
                const unitsPerPixel = Math.max(aspectX, aspectY) / zoom;

                // Calculate capture dimensions in 3D space
                const captureWidth = containerSize.width * unitsPerPixel;
                const captureHeight = containerSize.height * unitsPerPixel;

                // Adjust pan values to 3D space
                const adjustX = pan.x * unitsPerPixel;
                const adjustY = pan.y * unitsPerPixel;

                // Define the capture box centered on the scene and adjusted by pan and zoom
                const captureBox = new THREE.Box3(
                    new THREE.Vector3(
                        sceneCenter.x - captureWidth / 2 - adjustX,
                        sceneCenter.y - captureHeight / 2 + adjustY,
                        sceneBox.min.z
                    ),
                    new THREE.Vector3(
                        sceneCenter.x + captureWidth / 2 - adjustX,
                        sceneCenter.y + captureHeight / 2 + adjustY,
                        sceneBox.max.z
                    )
                );

                Clarity.event("download_project");
                renderStore.captureSnapshot(
                    fileType === 'pdf' ? 'downloadPDF' : 'downloadPNG',
                    targetFormat,
                    captureBox,
                );
            }
            renderStore.setRendering(false);
            onClose();
        }, 100);
    };

    useEffect(() => {
        editorStore.resetZoom();
        setTimeout(() => {
            const width = 1240;
            const height = 1754;
            renderStore.captureSnapshot('preview', {
                orientation: 'landscape',
                format: 'a4',
                width,
                height,
            }); // Reset snapshot
            // Initial size change based on default aspect ratio (A4 portrait)
            handleSizeChange(width, height);
        }, 100);
    }, []);

    return (
        <div
            className="fixed z-50 w-[600px] bg-[#EDEBE6] border border-gray-300 rounded shadow-md overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            ref={dropdownRef}
        >
            <div className="flex flex-cols items-center justify-between p-2">
                <div className="text-sm font-normal">Download project</div>
                <div onClick={onClose} className="text-sm text-gray-500 cursor-pointer"><IoClose style={{ strokeWidth: 0.5 }} size={24} /></div>
            </div>
            <div className="border-b border-gray-300 w-full" />

            {/* Image holder with zoom and pan */}
            <div className="flex flex-col items-center justify-between px-10 w-full mt-2">
                <div className="text-md font-normal w-full">Select area</div>
                <div className="text-sm text-gray-500 w-full">(Zoom and drag into the area you want to download)</div>

                <div className="flex flex-row items-center justify-center w-full mt-2 select-none">
                    <div
                        ref={containerRef}
                        className="relative border bg-white rounded overflow-hidden rounded-sm border border-gray-300 shadow-md mt-4 select-none"
                        style={{ cursor: 'grab', width: `${containerSize.width}px`, height: `${containerSize.height}px` }}
                        onWheel={handleWheelZoom}
                        onMouseMove={handlePan}
                    >
                        <div
                            className="absolute select-none"
                            ref={imgRef}
                            style={{
                                transform: `translate(${pan.x}px, ${pan.y}px) scale(${zoom})`,
                                transformOrigin: 'center center',
                                width: containerSize.width,
                                height: containerSize.height,
                            }}
                        >
                            <img
                                src={renderStore.imageData}
                                alt={floorplannerStore.name}
                                className="object-contain w-full h-full"
                                draggable={false}  // Disable default image drag
                            />
                        </div>
                    </div>

                    {/* Zoom controls */}
                    <div className="flex flex-col space-y-2 ml-2">
                        <Tooltip content="Zoom in" placement='right'>
                            <button onClick={() => handleZoomChange(0.1)} className="w-full p-2 text-center bg-white hover:bg-gray-100 rounded-sm border border-gray-300 shadow-md">
                                <PiPlus size={20} />
                            </button>
                        </Tooltip>
                        <Tooltip content="Zoom out" placement='right'>
                            <button onClick={() => handleZoomChange(-0.1)} className="w-full p-2 text-center bg-white hover:bg-gray-100 rounded-sm border border-gray-300 shadow-md">
                                <PiMinus size={20} />
                            </button>
                        </Tooltip>
                    </div>
                </div>
            </div>

            <form className="flex flex-col items-center justify-between px-10 w-full h-full" onSubmit={handleDownload}>
                <div className="flex flex-cols items-center justify-between p-2 w-full">
                    <div className="text-sm font-normal w-60">
                        <label htmlFor="format">File type:</label>
                        <br />
                        <select id="format" name="format" className="mt-2 w-full">
                            <option value="pdf">PDF</option>
                            <option value="png">PNG</option>
                        </select>
                    </div>
                    <div className="text-sm text-gray-500 w-50">
                        <label htmlFor="resolution">Format:</label>
                        <br />
                        <select id="resolution" name="resolution" className="mt-2 w-full" onChange={(e) => {
                            const [orientation, format, width, height] = e.target.value.split(",");
                            renderStore.captureSnapshot('preview', {
                                orientation: orientation as 'portrait' | 'landscape',
                                format: format,
                                width: parseInt(width),
                                height: parseInt(height),
                            }); // Reset snapshot
                            handleSizeChange(parseInt(width), parseInt(height));  // Update container width and height based on aspect ratio
                        }}>
                            <option value="portrait,a4,1240,1754">A4 portrait</option>
                            <option value="landscape,a4,1754,1240">A4 landscape</option>
                            <option value="portrait,a3,1754,2480">A3 portrait</option>
                            <option value="landscape,a3,2480,1754">A3 landscape</option>
                        </select>
                    </div>
                </div>

                <button
                    type="submit"
                    className={
                        "text-white p-4 rounded-md w-full mt-4 mb-4 " +
                        (renderStore.rendering ? "bg-gray-900" : "bg-black")
                    }
                    disabled={renderStore.rendering}
                >
                    <div className="flex flex-row items-center justify-center">
                        {renderStore.rendering &&
                            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3" />}
                        {!renderStore.rendering && <>
                            Download {floorplannerStore.name}
                        </>}
                        &nbsp; <FiDownload className="text-xl transform scale-90" />
                    </div>
                </button>
            </form>
        </div>
    );
});
