import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Html, Line } from "@react-three/drei";
import * as THREE from "three";
import { RulerLineType } from "../../types/wallTypes";
import { ThreeEvent } from "@react-three/fiber";
import { editorStore } from "../../store/editorStore";
import { floorplannerStore } from "../../store/floorplannerStore";
import { convertMillimeterToWorld, convertWorldToMillimeter } from "../../utils/conversions";
import { SimpleText } from "../Text/SimpleText";
import { renderStore } from "../../store/renderStore";

interface RulerLineProps {
    ruler: RulerLineType;
    color: string;
    onLinePointerDown: (event: ThreeEvent<PointerEvent>, id: string) => void;
    onLinePointerEnter: (event: ThreeEvent<PointerEvent>, id: string) => void;
    onLinePointerLeave: (event: ThreeEvent<PointerEvent>, id: string) => void;
    handleKeyPress: (event: React.KeyboardEvent<HTMLInputElement>, ruler: RulerLineType) => void;
    handleLengthClick: (lineId: string, newLength: number) => void;
}

const RulerLine: React.FC<RulerLineProps> = observer(({
    ruler,
    color,
    onLinePointerDown,
    onLinePointerEnter,
    onLinePointerLeave,
    handleKeyPress,
    handleLengthClick
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const rulerTextBoxPadding = 0.13 / editorStore.zoomLevelDivisor();
    const rulerTextBoxHeight = 0.16 / editorStore.zoomLevelDivisor();
    const rulerTextBoxWidth = 0.3 / editorStore.zoomLevelDivisor();
    const rulerTextFontSize = 14 / editorStore.zoomLevelDivisor();

    const midPoint = new THREE.Vector3(
        (ruler.start.x + ruler.end.x) / 2,
        (ruler.start.y + ruler.end.y) / 2,
        0
    );
    // Utility function to format the area in square millimeters
    const formatLength = (lengthInMillimeters: number): string => {
        return lengthInMillimeters.toLocaleString(); // Adds commas depending on locale
    };
    const startVector = new THREE.Vector2(ruler.start.x, ruler.start.y);
    const endVector = new THREE.Vector2(ruler.end.x, ruler.end.y);
    const worldDistance = startVector.distanceTo(endVector);

    let length = convertWorldToMillimeter(worldDistance).toFixed(0);

    const directionVector = new THREE.Vector2();
    directionVector.subVectors(endVector, startVector).normalize();

    const perpendicularVector = new THREE.Vector2(-directionVector.y, directionVector.x);
    const capLength = 0.1;
    // Get the perpendicular angle of the ruler line
    let rulerAngle = Math.atan2(perpendicularVector.y, perpendicularVector.x) - Math.PI / 2;
    // If angle is between PI/2 and -PI/2, then add 180 degrees to make the label readable
    rulerAngle = rulerAngle <= -Math.PI / 2 ? rulerAngle + Math.PI : rulerAngle;
    // Make the angle in degree btw 0 and 360
    const rulerAngleInDegree = -(rulerAngle <= -Math.PI / 2 ? (rulerAngle * 180) / Math.PI + 180 : (rulerAngle * 180) / Math.PI);
    const noopRaycast = () => null;

    const handleSubmit = () => {
        const newLength = parseFloat(inputValue); // Always use the latest input value
        if (!isNaN(newLength) && newLength !== parseFloat(length)) {
            length = newLength.toFixed(0);
            handleLengthClick(ruler.id, newLength);
            floorplannerStore.setRulerLineLength(
                ruler.id,
                convertMillimeterToWorld(newLength),
                "end"
            );
            editorStore.setRulerLineEditingLength(undefined);
        }
        setIsEditing(false); // Close the editing state
    };

    // Modify onChange handler to ensure real-time updates
    const onKeyPressHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    };


    return (
        <group
            onPointerDown={(e) => onLinePointerDown(e, ruler.id)}
            onPointerEnter={(e) => onLinePointerEnter(e, ruler.id)}
            onPointerLeave={(e) => onLinePointerLeave(e, ruler.id)}
            position={[0, 0, 0.01]}
        >
            {/* Invisible click area as a mesh along the ruler start all the way to the line end with a width of 0.1 */}
            <mesh
                position={[midPoint.x, midPoint.y, 0]}
                rotation={[0, 0, rulerAngle]}
            >
                <planeGeometry args={[worldDistance, 0.15]} />
                <meshBasicMaterial
                    // color="white"
                    transparent
                    opacity={0.0}
                />
            </mesh>
            <Line
                key={`group-ruler-${ruler.id}`}
                points={[
                    new THREE.Vector3(ruler.start.x, ruler.start.y, 0),
                    new THREE.Vector3(ruler.end.x, ruler.end.y, 0)
                ]}
                color={color}
                lineWidth={ruler.lineWeight || floorplannerStore.rulerLineWeight}
                {...(ruler.lineType === "dashed" && {
                    dashed: true,
                    dashSize: 0.15,
                    gapSize: 0.1,
                })}
                raycast={noopRaycast}
            />

            <Line
                points={[
                    new THREE.Vector3(
                        ruler.start.x + perpendicularVector.x * capLength,
                        ruler.start.y + perpendicularVector.y * capLength,
                        0
                    ),
                    new THREE.Vector3(
                        ruler.start.x - perpendicularVector.x * capLength,
                        ruler.start.y - perpendicularVector.y * capLength,
                        0
                    ),
                ]}
                color={color}
                lineWidth={ruler.lineWeight || floorplannerStore.rulerLineWeight}
                raycast={noopRaycast}
            />

            <Line
                points={[
                    new THREE.Vector3(
                        ruler.end.x + perpendicularVector.x * capLength,
                        ruler.end.y + perpendicularVector.y * capLength,
                        0
                    ),
                    new THREE.Vector3(
                        ruler.end.x - perpendicularVector.x * capLength,
                        ruler.end.y - perpendicularVector.y * capLength,
                        0
                    ),
                ]}
                color={color}
                lineWidth={ruler.lineWeight || floorplannerStore.rulerLineWeight}
                raycast={noopRaycast}
            />

            {!ruler.hideMeasurement && (editorStore.showMeasures || ruler?.selected || (editorStore.selections.length && editorStore.selections[0].id === ruler.id)) ? (
                <group
                    onClick={
                        () => {
                            setIsEditing(true);
                            setInputValue(length);
                        }
                    }
                >
                    {isEditing ? (
                        <Html position={[midPoint.x, midPoint.y, 0]} center style={{ position: "absolute", top: "-1px" }}>
                            <input
                                type="text"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                onKeyPress={onKeyPressHandler}
                                onBlur={handleSubmit}
                                style={{
                                    backgroundColor: "white",
                                    whiteSpace: "nowrap",
                                    textAlign: "center",
                                    border: "none",
                                    color: "black",
                                    fontSize: "10px",
                                    outline: "none",
                                    width: "60px",
                                    height: "15px",
                                    paddingBottom: "9px",
                                    transform: "translate(-50%, -50%)", // Center horizontally and vertically
                                    position: "absolute",
                                }}
                                autoFocus
                            />
                        </Html>
                    ) : (
                        <group
                                position={[midPoint.x, midPoint.y, 0.01]}
                                rotation={[0, 0, rulerAngle]}
                        >
                            <mesh position={[0, 0, -0.001]}>
                                <planeGeometry
                                    args={[rulerTextBoxWidth + rulerTextBoxPadding, rulerTextBoxHeight]}
                                />
                                <meshBasicMaterial
                                    color={renderStore.labelBackgroundColor}
                                    //depthWrite={false}
                                    //depthTest={true}
                                    //blending={THREE.NormalBlending}
                                />
                            </mesh>

                            <SimpleText
                                position={[0, 0, 0.001]}
                                fontSize={rulerTextFontSize}
                                color="black"
                            >
                                {`${formatLength(parseFloat(length))}`}
                            </SimpleText>
                        </group>
                    )}
                </group>
            ) : null}
        </group>
    );
});

export default React.memo(RulerLine, (prevProps, nextProps) => {
    return (
        prevProps.ruler.start.x === nextProps.ruler.start.x &&
        prevProps.ruler.start.y === nextProps.ruler.start.y &&
        prevProps.ruler.end.x === nextProps.ruler.end.x &&
        prevProps.ruler.end.y === nextProps.ruler.end.y &&
        prevProps.ruler.selected === nextProps.ruler.selected &&
        prevProps.color === nextProps.color &&
        prevProps.onLinePointerDown === nextProps.onLinePointerDown &&
        prevProps.onLinePointerEnter === nextProps.onLinePointerEnter &&
        prevProps.onLinePointerLeave === nextProps.onLinePointerLeave &&
        prevProps.handleKeyPress === nextProps.handleKeyPress &&
        prevProps.handleLengthClick === nextProps.handleLengthClick
    );
});
