import { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { editorStore } from "../../store/editorStore";
import { floorplannerStore } from "../../store/floorplannerStore";
import { HiOutlinePlus, HiChevronDown } from "react-icons/hi";
import boniLogo from "../../../src/assets/boni_logo2.png";
import React from 'react';
import { SymbolsPanel } from "./SymbolsPanel";
import { BsLayoutSidebar } from "react-icons/bs";
import { FiLayout } from "react-icons/fi";
import { FaRegCircle } from "react-icons/fa";
import { PiRuler } from "react-icons/pi";
import { PiWall } from "react-icons/pi";
import { TbSofa } from "react-icons/tb";
import { Tooltip } from "flowbite-react";
import { TemplatesPanel } from "./TemnplatesPanel";
import { WallsPanel } from "./WallsPanel";
import { MeasurePanel } from "./MeasurePanel";
import { renderStore } from "../../store/renderStore";
import { Scene } from "three";
import { useThree } from "@react-three/fiber";
import { ShapesPanel } from "./ShapesPanel";

const LeftPanel = observer(() => {

  //For Templets
  const [isTemplatesPanelVisible, setTemplatesPanelVisible] = useState(false);
  const [templatesPanelPosition, setTemplatesPanelPosition] = useState({ top: 0, left: 0 });
  const templatesPanelRef = useRef<HTMLDivElement>(null);
  const templatesInitiatorRef = useRef<HTMLDivElement>(null);

  // For Shapes
  const [isShapesPanelVisible, setShapesPanelVisible] = useState(false);
  const [shapesPanelPosition, setShapesPanelPosition] = useState({ top: 0, left: 0 });
  const shapesPanelRef = useRef<HTMLDivElement>(null);
  const shapeInitiatorRef = useRef<HTMLDivElement>(null);

  // For Symbols
  const [isSymbolsPanelVisible, setSymbolsPanelVisible] = useState(false);
  const [symbolsPanelPosition, setSymbolsPanelPosition] = useState({ top: 0, left: 0 });
  const symbolsPanelRef = useRef<HTMLDivElement>(null);
  const symbolInitiatorRef = useRef<HTMLDivElement>(null);

 // For Walls
  const [isWallsPanelVisible, setWallsPanelVisible] = useState(false);
  const [wallsPanelPosition, setWallsPanelPosition] = useState({ top: 0, left: 0 });
  const wallsPanelRef = useRef<HTMLDivElement>(null);
  const wallInitiatorRef = useRef<HTMLDivElement>(null);
 // For Measure & Tools
 const [isMeasurePanelVisible, setMeasurePanelVisible] = useState(false);
 const [measurePanelPosition, setMeasurePanelPosition] = useState({ top: 0, left: 0 });
 const measurePanelRef = useRef<HTMLDivElement>(null);
 const measureInitiatorRef = useRef<HTMLDivElement>(null);


  const [systemMenuOpen, setSystemMenuOpen] = useState(false);
  const systemMenuRef = useRef<HTMLDivElement | null>(null);

  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(floorplannerStore.name);

  const handleNameClick = () => {
    setIsEditing(true);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
  };

  const handleBlurOrSubmit = () => {
    floorplannerStore.setName(newName);
    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleBlurOrSubmit();
    }
  };


  const openShapesPanel = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    setShapesPanelPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setShapesPanelVisible(true);
  };

  const closeShapesPanel = () => {
    setShapesPanelVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // If click target is outside the SymbolsPanel and not the initiator
      if (
        shapesPanelRef.current &&
        !shapesPanelRef.current.contains(event.target as Node) &&
        shapeInitiatorRef.current &&
        !shapeInitiatorRef.current.contains(event.target as Node)
      ) {
        closeShapesPanel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [shapesPanelRef, shapeInitiatorRef]);


  const openSymbolsPanel = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    setSymbolsPanelPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setSymbolsPanelVisible(true);
  };

  const closeSymbolsPanel = () => {
    setSymbolsPanelVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // If click target is outside the SymbolsPanel and not the initiator
      if (
        symbolsPanelRef.current &&
        !symbolsPanelRef.current.contains(event.target as Node) &&
        symbolInitiatorRef.current &&
        !symbolInitiatorRef.current.contains(event.target as Node)
      ) {
        closeSymbolsPanel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [symbolsPanelRef, symbolInitiatorRef]);

  const openWallsPanel = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    setWallsPanelPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setWallsPanelVisible(true);
  };

  const closeWallsPanel = () => {
    setWallsPanelVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // If click target is outside the SymbolsPanel and not the initiator
      if (
        wallsPanelRef.current &&
        !wallsPanelRef.current.contains(event.target as Node) &&
        wallInitiatorRef.current &&
        !wallInitiatorRef.current.contains(event.target as Node)
      ) {
        closeWallsPanel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wallsPanelRef, wallInitiatorRef]);

  const openMeasurePanel = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    setMeasurePanelPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setMeasurePanelVisible(true);
  };

  const closeMeasurePanel = () => {
    setMeasurePanelVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // If click target is outside the SymbolsPanel and not the initiator
      if (
        measurePanelRef.current &&
        !measurePanelRef.current.contains(event.target as Node) &&
        measureInitiatorRef.current &&
        !measureInitiatorRef.current.contains(event.target as Node)
      ) {
        closeMeasurePanel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [measurePanelRef, measureInitiatorRef]);


  const openTemplatesPanel = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    setTemplatesPanelPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setTemplatesPanelVisible(true);
  };

  const closeTemplatesPanel = () => {
    setTemplatesPanelVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // If click target is outside the TemplatesPanel and not the initiator
      if (
        templatesPanelRef.current &&
        !templatesPanelRef.current.contains(event.target as Node) &&
        templatesInitiatorRef.current &&
        !templatesInitiatorRef.current.contains(event.target as Node)
      ) {
        closeTemplatesPanel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [templatesPanelRef, templatesInitiatorRef]);

  
  // Handle click outside systemMenu to close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (systemMenuRef.current && !systemMenuRef.current.contains(event.target as Node)) {
        setSystemMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [systemMenuRef]);

  useEffect(() => {
    setNewName(floorplannerStore.name);
  }, [floorplannerStore.name]);

  const TitleEditBox = () => {
    return (
      <>
        {/* Floorplan title edit box */}
        < div className="relative inline-flex w-full items-left rounded-sm p-3 pt-0 cursor-text" >
          {
            isEditing ? (
              <input
                type="text"
                value={newName}
                onChange={handleNameChange}
                onBlur={handleBlurOrSubmit}
                onKeyDown={handleKeyDown}
                autoFocus
                className="w-full h-full text-zinc-950 font-['DM Sans'] text-sm font-normal bg-stone-100 border-none outline-none"
                style={{ boxSizing: "border-box" }} // Ensures padding and borders are considered in width calculation
              />
            ) : (
              <div
                className="text-zinc-950 font-['DM Sans'] text-sm font-medium cursor-pointer text-ellipsis overflow-hidden whitespace-nowrap"
                onClick={handleNameClick}
                style={{ boxSizing: "border-box" }}
              >
                {floorplannerStore.name}
              </div>
            )}
        </div >
      </>
    );
  }


  return (
    <div
      className="sidebar-left select-none flex flex-col items-left justify-start gap-1"
      onClick={(event) => event.stopPropagation()} // Prevent clicks inside the sidebar from affecting SymbolsPanel visibility
    >
      {/* Logo section */}
      <div className="flex items-col justify-between pb-2">
        <div
          className="flex items-center ml-3 mt-3 cursor-pointer"
          onClick={() => setSystemMenuOpen(!systemMenuOpen)}
          ref={systemMenuRef}
        >
          <div
            className="flex items-center bg-bonigold"
            style={{ width: 34, height: 34 }}
          >
            <img
              src={boniLogo}
              alt="boni logo black"
              className="w-6 lg:w-12"
              style={{ width: 34 }}
            />
          </div>
          <HiChevronDown className="h-4 w-4" />
          {/* System Menu */}
          {systemMenuOpen && (
            <div className="fixed left-10 top-16 w-36 bg-black text-white border border-gray-300 shadow-lg rounded-lg py-2 z-20">
              <div
                className="px-4 py-2 cursor-pointer flex items-center justify-center gap-2 flex-row hover:bg-[#66645F]"
                onClick={() => {
                  renderStore.captureSnapshot('upload');
                  window.parent.location.href = process.env.REACT_APP_CLIENT_URI + "/mypage/projects/myprojects";
                }}
              >
                My Page
              </div>
              <div className="border-b border-[#66645F]" />
              <div
                className="px-4 py-2 cursor-pointer flex items-center justify-center gap-2 flex-row hover:bg-[#66645F]"
                onClick={() => {
                  renderStore.captureSnapshot('upload');
                  window.parent.location.href = process.env.REACT_APP_CLIENT_URI + "/search-products";
                }}
              >
                Products
              </div>
              <div className="border-b border-[#66645F]" />
              <div
                className="px-4 py-2 cursor-pointer flex items-center justify-center gap-2 flex-row hover:bg-[#66645F]"
                onClick={() => {
                  renderStore.captureSnapshot('upload');
                  window.parent.location.href = process.env.REACT_APP_CLIENT_URI + "/gallery";
                }}
              >
                Gallery
              </div>
              <div className="border-b border-[#66645F]" />
              <div
                className="px-4 py-2 cursor-pointer flex items-center justify-center gap-2 flex-row hover:bg-[#66645F]"
                onClick={() => {
                  renderStore.captureSnapshot('upload');
                  window.parent.location.href = process.env.REACT_APP_CLIENT_URI + "/designers";
                }}
              >
                Designers
              </div>
              <div className="border-b border-[#66645F]" />
              <div
                className="px-4 py-2 cursor-pointer flex items-center justify-center gap-2 flex-row hover:bg-[#66645F]"
                onClick={() => {
                  renderStore.captureSnapshot('upload');
                  window.parent.location.href = process.env.REACT_APP_CLIENT_URI + "/tutorials";
                }}
              >
                Tutorials
              </div>
            </div>
          )}
        </div>
        <Tooltip content={editorStore.minimized ? "Maximize" : "Minimize"} placement="bottom">
        <div
          className={`flex items-center mr-3 mt-2 cursor-pointer`}
          onClick={() => editorStore.setMinimized(!editorStore.minimized)}
        >
          <div className={`flex items-center justify-center rounded-sm w-[24px] h-[24px] ${editorStore.minimized ? " bg-eggshell300" : ""}`}>
            <BsLayoutSidebar style={{ strokeWidth: 0.5 }} />
          </div>
          </div>
        </Tooltip>
      </div>

      {!editorStore.minimized && (
        <>
          <TitleEditBox />
          {/* Divider line 100% width */}
          <div className="border-b border-stone-200 w-full" />
          {/* Links */}
          <div className="font-dm text-xs font-normal text-black p-1 pt-1 mb-2">
            {/* Elements */}
            <div className="inline-flex w-full flex-col items-start justify-start gap-2 p-2 pb-1">
            Building blocks
            </div>
            <div
              className={`inline-flex w-full flex-rows items-center justify-start gap-2 p-2 cursor-pointer ${isTemplatesPanelVisible ? "bg-eggshell100" : ""}`}
              onClick={isTemplatesPanelVisible ? closeTemplatesPanel : openTemplatesPanel}
              ref={templatesInitiatorRef} // Ref for the initiator
            >
              {/* Floorplan Templates */}
              <FiLayout style={{ strokeWidth: 2 }} size={19} />
              Floorplan templates
              {/* TemplatesPanel */}
              {isTemplatesPanelVisible && (
                <TemplatesPanel
                  position={templatesPanelPosition}
                  onClose={closeTemplatesPanel}
                  dropdownRef={templatesPanelRef}
                />
              )}
            </div>
            <div
              className={`inline-flex w-full flex-rows items-center justify-start gap-2 p-2 cursor-pointer ${isWallsPanelVisible ? "bg-eggshell100" : ""}`}
              onClick={isWallsPanelVisible ? closeWallsPanel : openWallsPanel}
              ref={wallInitiatorRef} // Ref for the initiator
            >
              <PiWall style={{ strokeWidth: 2 }} size={19} />
              Walls
              {/* WallsPanel */}
              {isWallsPanelVisible && (
                <WallsPanel
                  position={wallsPanelPosition}
                  onClose={closeWallsPanel}
                  dropdownRef={wallsPanelRef}
                />
              )}
            </div>
            <div
              className={`inline-flex w-full flex-rows items-center justify-start gap-2 p-2 cursor-pointer ${isShapesPanelVisible ? "bg-eggshell100" : ""}`}
              onClick={isShapesPanelVisible ? closeShapesPanel : openShapesPanel}
              ref={shapeInitiatorRef} // Ref for the initiator
            >
              <FaRegCircle style={{ strokeWidth: 2 }} size={19} />
              Shapes
              {/* ShapesPanel */}
              {isShapesPanelVisible && (
                <ShapesPanel
                  position={shapesPanelPosition}
                  onClose={closeShapesPanel}
                  dropdownRef={shapesPanelRef}
                />
              )}
            </div>
            <div
              className={`inline-flex w-full flex-rows items-center justify-start gap-2 p-2 cursor-pointer ${isSymbolsPanelVisible ? "bg-eggshell100" : ""}`}
              onClick={isSymbolsPanelVisible ? closeSymbolsPanel : openSymbolsPanel}
              ref={symbolInitiatorRef} // Ref for the initiator
            >
              <TbSofa style={{ strokeWidth: 2 }} size={19} />
              Symbols
              {/* SymbolsPanel */}
              {isSymbolsPanelVisible && (
                <SymbolsPanel
                  position={symbolsPanelPosition}
                  onClose={closeSymbolsPanel}
                  dropdownRef={symbolsPanelRef}
                />
              )}
            </div>
            <div
              className={`inline-flex w-full flex-rows items-center justify-start gap-2 p-2 cursor-pointer ${isMeasurePanelVisible ? "bg-eggshell100" : ""}`}
              onClick={isMeasurePanelVisible ? closeMeasurePanel : openMeasurePanel}
              ref={measureInitiatorRef} // Ref for the initiator
            >
              <PiRuler style={{ strokeWidth: 2 }} size={19} />
              Measuring tools
              {/* MeasurePanel */}
              {isMeasurePanelVisible && (
                <MeasurePanel
                  position={measurePanelPosition}
                  onClose={closeMeasurePanel}
                  dropdownRef={measurePanelRef}
                />
              )}
            </div>            
          </div>
        </>
      )}

    </div>
  );
});

export default LeftPanel;
