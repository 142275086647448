import React, { useRef, useState, useEffect, useCallback } from "react";
import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import DraggableObject from "./DraggableObject";
import { RectStairsType, TriangleSymbolType, zIndexEpsilon } from "../../types/wallTypes";
import { SelectableSymbol, BoundingBox } from "./SelectableSymbol";
import { projectToWorld } from "./projectToWorld";
import { FloorplannerStoreContext } from "../../store/floorplannerStore";
import { Line } from "@react-three/drei";
import { runInAction, set } from "mobx";
import { editorStore } from "../../store/editorStore";
import { observer } from "mobx-react-lite";

interface TriangleSymbolProps {
  triangle: TriangleSymbolType;
  onDragStart: (triangle: TriangleSymbolType, offset: [number, number]) => void;
  onDrag: (newPosition: [number, number]) => void;
  onDragEnd: (endPosition: [number, number]) => void;
}

const TriangleSymbol: React.FC<TriangleSymbolProps> = observer(({
  triangle,
  onDragStart,
  onDragEnd,
  onDrag,
}) => {
  const { gl, camera } = useThree();
  const groupRef = useRef<THREE.Group>(null);
  const floorplannerStore = React.useContext(FloorplannerStoreContext);

  const lineWeight = triangle.lineWeight || floorplannerStore.symbolLineWeight;
  const triangleWidth = triangle.triangleWidth;
  const triangleHeight = triangle.triangleHeight;

  const grabHandleSize = 0.1; // Variable to control the size of the grab handle
  const flipX = triangle.flipHorizontal ? -1 : 1; // Flip horizontally
  const flipY = triangle.flipVertical ? -1 : 1; // Flip vertically
  const [centerPosition, setCenterPosition] = useState<[number, number]>([
    triangleWidth / 2,
    triangleHeight / 2,
  ]);
  const lineColor = triangle.lineColor || floorplannerStore.lineColor;
  // State to store the pivot dynamically
  const [pivot, setPivot] = useState<[number, number]>([
    -triangleWidth / 2,
    -triangleHeight / 2,
  ]);
  const [isHovered, setIsHovered] = useState(false);
  const [isDragging, setIsDragging] = useState(false)
  const noopRaycast = () => null;

  // Calculate the bounding box of the triangle
  const calculateBoundingBox = useCallback((): BoundingBox => {
    if (!groupRef.current) {
      return {
        topLeft: [0, 0],
        topRight: [0, 0],
        bottomLeft: [0, 0],
        bottomRight: [0, 0],
        width: 0,
        height: 0,
        depth: 0,
      };
    }

    // Clone the object
    const tempGroup = groupRef.current.clone();

    // Save the original rotation
    const originalRotation = tempGroup.rotation.clone();

    // Temporarily reset the rotation to 0
    tempGroup.rotation.set(0, 0, 0);

    // Calculate the bounding box as if the object had no rotation
    const boundingBox = new THREE.Box3().setFromObject(tempGroup);
    const size = new THREE.Vector3();
    boundingBox.getSize(size);

    // Restore the original rotation
    tempGroup.rotation.copy(originalRotation);

    // Calculate the corners of the bounding box
    const topLeft = new THREE.Vector3(
      0,
      size.y + (lineWeight / 100),
      0
    );
    const topRight = new THREE.Vector3(
      size.x + (lineWeight / 100),
      size.y + (lineWeight / 100),
      0
    );
    const bottomLeft = new THREE.Vector3(
      0,
      0,
      0
    );
    const bottomRight = new THREE.Vector3(
      size.x + (lineWeight / 100),
      0,
      0
    );

    const width = topRight.x - topLeft.x;
    const height = topLeft.y - bottomLeft.y;
    setPivot([-width / 2, -height / 2])
    centerPosition[0] = width / 2;
    centerPosition[1] = height / 2;

    return {
      topLeft: [topLeft.x, topLeft.y],
      topRight: [topRight.x, topRight.y],
      bottomLeft: [bottomLeft.x, bottomLeft.y],
      bottomRight: [bottomRight.x, bottomRight.y],
      width: boundingBox.max.x - boundingBox.min.x,
      height: boundingBox.max.y - boundingBox.min.y,
      depth: 0,
    };
  }, [triangle.position.x, triangle.position.y, triangle.flipHorizontal, triangle.flipVertical, triangleWidth, triangleHeight]);

  const dragOffset = useRef({ x: 0, y: 0 });

  const onHandleDrag = (event: PointerEvent) => {
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    const dy = worldY - triangle.position.y - dragOffset.current.y;

    // Update the handle's Y position
    setCenterPosition([triangle.position.x, triangle.position.y + dy]);
  };

  const onPointerUpHandle = () => {
    gl.domElement.removeEventListener("pointermove", onHandleDrag);
    gl.domElement.removeEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.setDirty();
  };

  const handleDrag = (newPosition: [number, number]) => {
    onDrag(newPosition);
  };
  // Call the function and destructure the returned values
  //const { rectPoints, extraLines } = generateRectPointsAndLines();

  // Handle Drag Top (Red Line)
  const dragOffsetTop = useRef({ x: 0, y: 0 });

  const onHandleDragTop = (event: PointerEvent) => {
    const [, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    const dy = worldY - triangle.position.y - dragOffsetTop.current.y;
    floorplannerStore.updateSymbolProperty(triangle.id, "triangleHeight", Math.max(0.1, triangleHeight + dy * flipY));
    setPivot([-triangleWidth / 2, -triangleHeight / 2]);
  };

  const onPointerUpHandleTop = () => {
    gl.domElement.removeEventListener("pointermove", onHandleDragTop);
    gl.domElement.removeEventListener("pointerup", onPointerUpHandleTop);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.updateSymbolProperty(triangle.id, "triangleHeight", triangleHeight);
  };
  const [geometry, setGeometry] = useState<THREE.BufferGeometry | undefined>(undefined);

  // Function to create the triangle geometry
  const createTriangleGeometry = () => {
    const vertices = new Float32Array([
      0, triangleHeight / 2, 0,                     // Top vertex
      -triangleWidth / 2, -triangleHeight / 2, 0,   // Bottom-left vertex
      triangleWidth / 2, -triangleHeight / 2, 0,    // Bottom-right vertex
    ]);

    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

    return geometry;
  };

  // Update geometry whenever triangleWidth or triangleHeight changes
  useEffect(() => {
    setGeometry(createTriangleGeometry());
  }, [triangleWidth, triangleHeight]);

  useEffect(() => {
    editorStore.updateGroupRef(triangle.id, groupRef.current);
  }, [editorStore, triangle.id]);

  // Redraw when the symbol.selected changes
  useEffect(() => {
  }, [triangle.selected]);

  return (
    <group position={[0, 0, (triangle.zIndex * zIndexEpsilon)]}>
      <DraggableObject
        position={[triangle.position.x, triangle.position.y]}
        onDragStart={(offset) => {
          setIsDragging(true);
          onDragStart(triangle, offset)
        }}
        onDragEnd={(endPosition) => {
          onDragEnd(endPosition)
          setIsDragging(false);
        }}
        onDrag={handleDrag}
        selectable={true}
        attachmentId={triangle.id}
        attachmentType="doorAttachments"
        symbol={triangle}
      >
        <SelectableSymbol
          handleSize={floorplannerStore.symbolHandleSize}
          calculateBoundingBox={calculateBoundingBox}
          onResize={(newWidth, newHeight, boundingBox, handle) => {
            floorplannerStore.updateSymbolProperty(triangle.id, "triangleWidth", newWidth);
            floorplannerStore.updateSymbolProperty(triangle.id, "triangleHeight", newHeight);
            const widthDiff = newWidth - triangleWidth;
            const heightDiff = newHeight - triangleHeight;
            if (handle === "midRight") {
              triangle.position.x += widthDiff / 2;
            } else if (handle === "midLeft") {
              triangle.position.x -= widthDiff / 2;
            } else if (handle === "midTop") {
              triangle.position.y += heightDiff / 2;
            } else if (handle === "midBottom") {
              triangle.position.y -= heightDiff / 2;
            }
            setPivot([-newWidth / 2, -newHeight / 2])
          }}
          center={centerPosition}
          rotation={triangle.rotation}
          symbol={triangle}
          drawHandles={true}
          pivot={pivot}
          onPointerOver={() => setIsHovered(true)}
          onPointerOut={() => setIsHovered(false)}
          isDragging={isDragging}
        >
          <group
            ref={groupRef}
            rotation={[0, 0, triangle.rotation || 0]}
            onPointerEnter={() => {
              if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.add("cursor-vector");
            }}
            onPointerLeave={() => {
              if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.remove("cursor-vector");
            }}
          >
            {/* Draw triangle */}
            <mesh geometry={geometry}>
              <meshBasicMaterial
                attach="material"
                color={triangle.fillColor || '#ffffff'} // Ensure the fillColor is applied
                side={THREE.DoubleSide}                 // Render both sides of the triangle
              />
            </mesh>

            {/* Render triangle outline */}
            <Line
              points={[
                [0, triangleHeight / 2, 0], // Top
                [-triangleWidth / 2, -triangleHeight / 2, 0], // Bottom-left
                [triangleWidth / 2, -triangleHeight / 2, 0], // Bottom-right
                [0, triangleHeight / 2, 0], // Close the triangle (back to Top)
              ]}
              color={isHovered || triangle.selected ? 'blue' : lineColor}
              lineWidth={lineWeight}
              {...(triangle.lineType === "dashed" && {
                dashed: true,
                dashSize: 0.15,
                gapSize: 0.1,
              })}
              raycast={noopRaycast}
            />
          </group>
        </SelectableSymbol>
      </DraggableObject>
    </group>
  );
});

export default TriangleSymbol;
