import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import * as THREE from "three";
import { invalidate, useFrame, useThree } from "@react-three/fiber";
import DraggableObject from "./DraggableObject";
import { DoorType, WallType, zIndexEpsilon } from "../../types/wallTypes";
import { SelectableSymbol, BoundingBox } from './SelectableSymbol';
import { projectToWorld } from "./projectToWorld";
import { FloorplannerStoreContext } from "../../store/floorplannerStore";
import AttachableSymbol from "./AttachableSymbol";
import { Line } from "@react-three/drei";
import { transaction } from "mobx";
import { editorStore } from "../../store/editorStore";
import { observer } from "mobx-react-lite";

export const defaultOpenAngle = Math.PI / 2;
export const defaultDoorWidth = 1;

interface DoorProps {
  door: DoorType;
  attachedToWall?: WallType;
  onDragStart: (door: DoorType, offset: [number, number]) => void;
  onDrag: (newPosition: [number, number]) => void;
  onDragEnd: (endPosition: [number, number]) => void;
}

const Door: React.FC<DoorProps> = observer(({
  door,
  attachedToWall,
  onDragStart,
  onDragEnd,
  onDrag,
}) => {
  const [currentOpenAngle, setCurrentOpenAngle] = useState(door.openAngle);
  const currentOpenAngleRef = useRef(currentOpenAngle); // Ref for the current open angle in event listeners
  const { gl, camera } = useThree();
  const groupRef = useRef<THREE.Group>(null);
  const attachableRef = useRef<{ checkForAttachment: () => void }>(null);
  const floorplannerStore = React.useContext(FloorplannerStoreContext);

  const lineWeight = door.lineWeight || floorplannerStore.doorLineWeight; // Thickness of the lines
  const frameThickness = door.doorFrameWidth || floorplannerStore.doorFrameWidth; // Thickness of the door frame
  const bladeThickness = door.doorBladeThickness || floorplannerStore.doorBladeThickness; // Thickness of the door blade
  const [doorWidth, setDoorWidth] = useState(door.doorWidth); // Width of the door
  const grabHandleSize = 0.12;
  const handleRadius = useMemo(() => grabHandleSize / editorStore.zoomLevelDivisor(), [editorStore.zoomLevel]);
  const handleDepth = 0.02; // Depth of the handles

  const flipX = door.flipHorizontal ? -1 : 1;
  const flipY = door.flipVertical ? -1 : 1;
  const [centerPosition, setCenterPosition] = useState<[number, number]>([
    doorWidth / 2,
    doorWidth / 2,
  ]);
  const lineColor = door.lineColor || floorplannerStore.lineColor;
  const wallWidth = attachedToWall?.wallWidth || floorplannerStore.wallWidth;
  const wallLineWeight = floorplannerStore.convertLineWeightToWorld(attachedToWall?.lineWeight || floorplannerStore.lineWeight);

  const wallLineColor = attachedToWall?.lineColor || floorplannerStore.lineColor;
  const doorFrameWidth = door.doorFrameWidth || floorplannerStore.doorFrameWidth;
  const hingePosition = useMemo(() => {
    return door.flipHorizontal
      ? attachedToWall ? [doorWidth, (wallLineWeight / 2 + bladeThickness / 2) * flipY, 0] : [doorWidth, 0, 0]
      : attachedToWall ? [0, (wallLineWeight / 2 + bladeThickness / 2) * flipY, 0] : [0, 0, 0];
  }, [attachedToWall, bladeThickness, door.flipHorizontal, doorWidth, flipY, wallLineWeight]);

  const hingeOffsetX = useMemo(() => (flipX === -1 ? doorWidth : 0), [doorWidth, flipX]);
  const openAngleGrabHandleX = useMemo(() => hingeOffsetX + doorWidth * 0.6 * Math.cos(currentOpenAngle) * flipX, [hingeOffsetX, doorWidth, currentOpenAngle, flipX]);
  const openAngleGrabHandleY = useMemo(() => doorWidth * 0.6 * Math.sin(currentOpenAngle) * flipY, [doorWidth, currentOpenAngle, flipY]);
  const [isResizing, setIsResizing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [hoveringHandle, setHoveringHandle] = useState<string | null>(null);
  const [prevCameraZoom, setPrevCameraZoom] = useState(camera.zoom);
  const noopRaycast = () => null;
  const aboutToModify = useRef(false);

  useFrame(() => {
    if (camera.zoom !== prevCameraZoom) {
      setPrevCameraZoom(camera.zoom);
      invalidate(); // Trigger a re-render
    }
  });

  const calculateBoundingBox = useCallback((): BoundingBox => {
    if (!groupRef.current) return { topLeft: [0, 0], topRight: [0, 0], bottomLeft: [0, 0], bottomRight: [0, 0], width: 0, height: 0, depth: 0 };

    const tempGroup = groupRef.current.clone();
    const originalRotation = tempGroup.rotation.clone();
    tempGroup.rotation.set(0, 0, 0);

    const boundingBox = new THREE.Box3().setFromObject(tempGroup);
    const size = new THREE.Vector3();
    boundingBox.getSize(size);
    tempGroup.rotation.copy(originalRotation);

    const topLeft = new THREE.Vector3(
      -(attachedToWall ? frameThickness + wallLineWeight : 0),
      size.y - (attachedToWall ? wallWidth / 2 + wallLineWeight : 0),
      0
    );
    const topRight = new THREE.Vector3(
      size.x - (attachedToWall ? frameThickness + wallLineWeight : 0),
      size.y - (attachedToWall ? wallWidth / 2 + wallLineWeight : 0),
      0
    );
    const bottomLeft = new THREE.Vector3(
      -(attachedToWall ? frameThickness + wallLineWeight : 0),
      -(attachedToWall ? wallWidth / 2 + wallLineWeight : doorFrameWidth),
      0
    );
    const bottomRight = new THREE.Vector3(
      size.x - (attachedToWall ? frameThickness + wallLineWeight : 0),
      -(attachedToWall ? wallWidth / 2 + wallLineWeight : doorFrameWidth),
      0
    );

    if (door.flipVertical) {
      topLeft.y = -topLeft.y;
      topRight.y = -topRight.y;
      bottomLeft.y = -bottomLeft.y;
      bottomRight.y = -bottomRight.y;
      setCenterPosition([doorWidth + (attachedToWall ? frameThickness + wallLineWeight : 0) - size.x / 2, -(size.y / 2 - (attachedToWall ? wallWidth : 0))]);
    } else {
      setCenterPosition([size.x / 2 - (attachedToWall ? frameThickness + wallLineWeight : 0), size.y / 2 - (attachedToWall ? wallWidth : 0)]);
    }

    return {
      topLeft: [topLeft.x, topLeft.y],
      topRight: [topRight.x, topRight.y],
      bottomLeft: [bottomLeft.x, bottomLeft.y],
      bottomRight: [bottomRight.x, bottomRight.y],
      width: size.x,
      height: size.y,
      depth: size.z,
    };
  }, [attachedToWall, door.flipVertical, doorWidth, wallLineWeight, frameThickness, wallWidth, currentOpenAngle]);

  const dragOffset = useRef({ x: 0, y: 0 });

  const onHandleDrag = (event: PointerEvent) => {
    if (aboutToModify.current) {
      floorplannerStore.pushToUndoStack();
      aboutToModify.current = false;
    }
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    const doorRotation = door.rotation || 0;
    const mousePosition = new THREE.Vector2(worldX, worldY);
    const doorPosition = new THREE.Vector2(door.position.x, door.position.y);
    const offset = mousePosition.clone().sub(doorPosition);
    offset.rotateAround(new THREE.Vector2(0, 0), -doorRotation);

    const dx = offset.x - dragOffset.current.x;
    const dy = offset.y - dragOffset.current.y;
    const angle = Math.atan2(dy * flipY, dx * flipX);
    let newAngle = angle < 0 ? angle + 2 * Math.PI : angle;
    if (newAngle > Math.PI) newAngle = 0;
    else if (newAngle > Math.PI / 2) newAngle = Math.PI / 2;
    setCurrentOpenAngle(newAngle);
  };

  const onPointerDownHandle = (event: React.PointerEvent) => {
    event.stopPropagation();
    aboutToModify.current = true;
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    dragOffset.current = {
      x: worldX - (doorWidth * 0.75 * Math.cos(currentOpenAngle) * flipX + door.position.x),
      y: worldY - (doorWidth * 0.75 * Math.sin(currentOpenAngle) * flipY + door.position.y),
    };
    gl.domElement.addEventListener("pointermove", onHandleDrag);
    gl.domElement.addEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(true);
  };

  const onPointerUpHandle = () => {
    aboutToModify.current = false;
    if (door.openAngle !== currentOpenAngleRef.current) {
      floorplannerStore.updateSymbolProperty(door.id, "openAngle", currentOpenAngleRef.current);
    }
    gl.domElement.removeEventListener("pointermove", onHandleDrag);
    gl.domElement.removeEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.setDirty();
  };

  const handleDrag = (newPosition: [number, number]) => {
    onDrag(newPosition);
  };

  const generateArcPoints = useMemo(() => {
    const arcPoints = [];
    const segments = 64;
    for (let i = 0; i <= segments; i++) {
      const angle = (i / segments) * currentOpenAngle;
      arcPoints.push(new THREE.Vector3(
        doorWidth * Math.cos(angle) * flipX,
        doorWidth * Math.sin(angle) * flipY,
        0
      ));
    }
    return arcPoints;
  }, [currentOpenAngle, doorWidth, flipX, flipY]);

  const doorBladePoints = useMemo(() => [
    [0, -bladeThickness, 0] as [number, number, number],
    [doorWidth * flipX, -bladeThickness, 0] as [number, number, number],
    [doorWidth * flipX, bladeThickness * flipY - bladeThickness, 0] as [number, number, number],
    [0, bladeThickness * flipY - bladeThickness, 0] as [number, number, number],
    [0, -bladeThickness, 0] as [number, number, number],
  ], [bladeThickness, doorWidth, flipX, flipY]);

  const doorBladePointsFlipY = useMemo(() => [
    [0, bladeThickness, 0] as [number, number, number],
    [doorWidth * flipX, bladeThickness, 0] as [number, number, number],
    [doorWidth * flipX, bladeThickness * flipY + bladeThickness, 0] as [number, number, number],
    [0, bladeThickness * flipY + bladeThickness, 0] as [number, number, number],
    [0, bladeThickness, 0] as [number, number, number],
  ], [bladeThickness, doorWidth, flipX, flipY]);

  const generateFramePoints = useCallback((wallWidth: number, side: number): [number, number, number][] => {
    const offset = (side * (doorWidth / 2) + doorWidth / 2);
    return [
      [offset, -wallWidth / 2, 0],
      [offset + frameThickness * side, -wallWidth / 2, 0],
      [offset + frameThickness * side, wallWidth / 2, 0],
      [offset, wallWidth / 2, 0],
      [offset, -wallWidth / 2, 0], // Closing the frame
    ];
  }, [doorWidth, frameThickness]);

  const handleResize = (newWidth: number, newHeight: number, boundingBox: BoundingBox, handle: string) => {
    newWidth -= attachedToWall ? frameThickness * 2 + wallLineWeight * 2 : frameThickness * 2;
    const widthDiff = newWidth - door.doorWidth;
    transaction(() => {
      floorplannerStore.updateSymbolProperty(door.id, "doorWidth", newWidth);
      const position = [
        (handle === "topLeft" || handle === "bottomLeft") ? door.position.x - widthDiff * Math.cos(door.rotation || 0) : door.position.x,
        (handle === "topLeft" || handle === "bottomLeft") ? door.position.y - widthDiff * Math.sin(door.rotation || 0) : door.position.y,
      ]
      if (attachedToWall) {
        floorplannerStore.detachSymbolFromWall(door.id);
        floorplannerStore.updateSymbolProperty(door.id, "position", position);
        floorplannerStore.attachSymbolToWall(door.id, attachedToWall.id, [position[0], position[1]]);
      } else {
        floorplannerStore.updateSymbolProperty(door.id, "position", position);
      }
    });
  }

  const handleAttachments = () => {
    // Handle attachment logic
  }

  const handlePointerOver = (handle: string) => {
    setHoveringHandle(handle);
  }

  const handlePointerOut = () => {
    setHoveringHandle(null);
  }

  // Update local state of the openAngle if the prop changes
  useEffect(() => {
    setCurrentOpenAngle(door.openAngle);
  }, [door.openAngle]);

  // Update local state of the doorWidth if the prop changes
  useEffect(() => {
    setDoorWidth(door.doorWidth);
  }, [door.doorWidth]);

  // Update the ref whenever currentOpenAngle changes
  useEffect(() => {
    currentOpenAngleRef.current = currentOpenAngle;
  }, [currentOpenAngle]);

  useEffect(() => {
    editorStore.updateGroupRef(door.id, groupRef.current);
  }, [groupRef.current]);

  return (
    <group
      position={[0, 0, (door.zIndex * zIndexEpsilon)]}
    >
      <DraggableObject
        position={[door.position.x, door.position.y]}
        onDragStart={(offset) => {
          setIsDragging(true);
          onDragStart(door, offset)
        }}
        onDragEnd={(endPosition) => {
          onDragEnd(endPosition)
          setIsDragging(false);
        }}
        onDrag={handleDrag}
        selectable={true}
        attachmentId={door.id}
        attachmentType="doorAttachments"
        symbol={door}
      >
        <AttachableSymbol
          attachmentId={door.id}
          attachmentType="doorAttachments"
          onAttachment={handleAttachments}
          store={floorplannerStore}
          ref={attachableRef}
        >
          <SelectableSymbol
            ref={groupRef}
            handleSize={floorplannerStore.symbolHandleSize}
            calculateBoundingBox={calculateBoundingBox}
            onResizeStart={() => setIsResizing(true)}
            onResize={handleResize}
            onResizeEnd={() => setIsResizing(false)}
            center={centerPosition}
            rotation={door.rotation}
            symbol={door}
            onPointerOver={() => {
              handlePointerOver('symbol');
            }}
            onPointerOut={handlePointerOut}
            isDragging={isDragging}
          >
            <group
              ref={groupRef}
              rotation={[0, 0, door.rotation || 0]}
              onPointerEnter={() => {
                if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.add("cursor-vector");
              }}
              onPointerLeave={() => {
                if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.remove("cursor-vector");
              }}
            >
              {/* Invisible box to catch events on any unfilled areas */}
              <mesh
                position={[
                  doorWidth / 2,
                  doorWidth / 2,
                  -0.03
                ]}
                rotation={[0, 0, 0]}
                onPointerEnter={() => {
                  if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.add("cursor-vector");
                }}
                onPointerLeave={() => {
                  if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.remove("cursor-vector");
                }}
              >
                <boxGeometry args={[doorWidth, doorWidth, 0.06]} />
                <meshBasicMaterial
                  //color="white"
                  transparent
                  opacity={0.0}
                />
              </mesh>
              {/* If door is attached to a wall, close the endpoints by drawing a line mesh across the opening with the wall properties */}
              {attachedToWall && (
                <>
                  {/* <mesh position={[(-doorFrameWidth - wallLineWeight), 0, 0]}>
                  <boxGeometry args={[wallLineWeight, wallWidth + wallLineWeight / 2, 0.001]} />
                  <meshStandardMaterial color={wallLineColor} />
                </mesh>
                <mesh position={[(doorWidth + doorFrameWidth + wallLineWeight), 0, 0]}>
                  <boxGeometry args={[wallLineWeight, wallWidth + wallLineWeight / 2, 0.001]} />
                  <meshStandardMaterial color={wallLineColor} />
                </mesh> */}
                </>
              )}
              {/* Render the arc using Line */}
              <Line
                position={new THREE.Vector3(hingePosition[0], hingePosition[1], hingePosition[2])}
                points={generateArcPoints.map((point) => [point.x, point.y, point.z])}
                color={hoveringHandle === 'symbol' || door.selected ? 'blue' : lineColor}
                lineWidth={lineWeight / 2} // Fixed pixel size
                {...(door.lineType === "dashed" && {
                  dashed: true,
                  dashSize: 0.15,
                  gapSize: 0.1,
                })}
                raycast={noopRaycast}
              />

              {/* Render door blade using Line */}
              <Line
                points={flipY === 1 ? doorBladePoints : doorBladePointsFlipY}
                color={hoveringHandle === 'symbol' || door.selected ? 'blue' : lineColor}
                lineWidth={lineWeight} // Fixed pixel size
                position={new THREE.Vector3(hingePosition[0], hingePosition[1], hingePosition[2])}
                rotation={[0, 0, currentOpenAngle * flipX * flipY]}
                {...(door.lineType === "dashed" && {
                  dashed: true,
                  dashSize: 0.15,
                  gapSize: 0.1,
                })}
                raycast={noopRaycast}
              />

              {/* Render frames if attached to a wall */}
              {attachedToWall && (
                <>
                  {/* Left Frame */}
                  <Line
                    points={generateFramePoints(wallWidth, -1)}
                    color={hoveringHandle === 'symbol' || door.selected ? 'blue' : lineColor}
                    lineWidth={1}
                    {...(door.lineType === "dashed" && {
                      dashed: true,
                      dashSize: 0.15,
                      gapSize: 0.1,
                    })}
                    raycast={noopRaycast}
                  />
                  {/* Right Frame */}
                  <Line
                    points={generateFramePoints(wallWidth, 1)}
                    color={hoveringHandle === 'symbol' || door.selected ? 'blue' : lineColor}
                    lineWidth={1}
                    {...(door.lineType === "dashed" && {
                      dashed: true,
                      dashSize: 0.15,
                      gapSize: 0.1,
                    })}
                    raycast={noopRaycast}
                  />
                </>
              )}

              {/* Handle for adjusting open angle */}
              {(door.selected && !isDragging && !isResizing && editorStore.selections.length === 1) && (
                <group
                  onPointerDown={onPointerDownHandle as unknown as (event: THREE.Event) => void}
                  onPointerUp={onPointerUpHandle as unknown as (event: THREE.Event) => void}
                  onPointerOver={() => handlePointerOver('openHandle')}
                  onPointerOut={handlePointerOut}
                  position={[
                    openAngleGrabHandleX,
                    openAngleGrabHandleY,
                    0,
                  ]}
                >
                  <mesh>
                    <torusGeometry args={[(handleRadius - 0.002), 0.005, 16, 100]} />
                    <meshBasicMaterial color="blue" />
                  </mesh>
                  <mesh
                    position={[0, 0, handleDepth / 2]}
                    rotation={[Math.PI / 2, 0, 0]}
                  >
                    <cylinderGeometry args={[(handleRadius - 0.02), (handleRadius - 0.006), handleDepth, 32]} />
                    <meshBasicMaterial color={hoveringHandle === "openHandle" ? "blue" : "white"} />
                  </mesh>
                </group>
              )}
            </group>
          </SelectableSymbol>
        </AttachableSymbol>
      </DraggableObject>
    </group>
  );
});

export default Door;
