import React, { useEffect } from "react";
import { useThree } from "@react-three/fiber";
import { observer } from "mobx-react-lite";
import { useEditorStore } from "../../store/editorStore";
import * as THREE from "three";

/**
 * A component that sets up the camera (from inside Canvas component) for the editorStore.
 * 
 * @returns The CameraSetup component.
 */
const CameraSetup: React.FC = observer(() => {
    const { camera } = useThree();
    const editorStore = useEditorStore();

    useEffect(() => {
        if (camera instanceof THREE.PerspectiveCamera || camera instanceof THREE.OrthographicCamera) {
            editorStore.setCamera(camera);
        }
    }, [camera, editorStore]);

    return null;
});

export default CameraSetup;