import React, { useEffect } from 'react';
import { floorplannerStore } from '../../store/floorplannerStore';

import SpotlightIcon from '../../assets/symbols/belysning2_alt.svg';
import LightFixIcon from '../../assets/symbols/belysning2.svg';
import DoubleDoorIcon from '../../assets/symbols/Dubbeldörr.svg';
import Sofa2seatIcon from '../../../src/assets/symbols/2-sist soffa-01.svg';
import Sofa3seatIcon from '../../../src/assets/symbols/3 sists soffa-01.svg';
import ModularSofaIcon from '../../../src/assets/symbols/modulsoffa-01.svg';
import ChaiseSofaIcon from '../../../src/assets/symbols/schäslong-01.svg';
import LoungeChair1Icon from '../../../src/assets/symbols/fåtölj  2-01.svg';
import LoungeChair2Icon from '../../../src/assets/symbols/fåtölj 3-01.svg';
import ArmChairIcon from '../../../src/assets/symbols/Fåtölj standard-01.svg';
import OctagonalCoffTabIcon from '../../../src/assets/symbols/poly 1-01.svg';
import PentagonalCoffTabIcon from '../../../src/assets/symbols/poly 2-01.svg'
import HexagonalCoffTabIcon from '../../../src/assets/symbols/poly 3-01.svg'
import OrganicCoffTabIcon from '../../../src/assets/symbols/soffbord 2-01.svg'
import OvalCoffTabIcon from '../../../src/assets/symbols/matbord-01.svg'
import Chair6TableIcon from '../../../src/assets/symbols/6 bord ny-01.svg'
import Chair4TableIcon from '../../../src/assets/symbols/4-bord ny-01.svg'
import Chair2TableIcon from '../../../src/assets/symbols/2-bord ny-01.svg'
import RoundTableIcon from '../../../src/assets/symbols/runt bord ny-01.svg'
import ArmChairDRIcon from '../../../src/assets/symbols/stol 2-01.svg'
import ChairIcon from '../../../src/assets/symbols/stol ny-01.svg'
import DishWasherIcon from '../../../src/assets/symbols/dishwasher.svg'
import BasinIcon from '../../../src/assets/symbols/handfat ny-01.svg'
import BathtubIcon from '../../../src/assets/symbols/badkar 1 ny-01.svg'
import ToiletIcon from '../../../src/assets/symbols/toa ny-01.svg'
import ShowerIcon from '../../../src/assets/symbols/dusch.svg'
import KingBedIcon from '../../../src/assets/symbols/dubbelsäng ny-01.svg'
import SingleBedIcon from '../../../src/assets/symbols/enkelsäng ny-01.svg'
import NoSVGIcon from '../../../src/assets/symbols/TransparentImage.png'
import PlantIcon from '../../../src/assets/symbols/växt.svg'
import Curtain1Icon from '../../../src/assets/symbols/Gardin.svg'
import Curtain2Icon from '../../../src/assets/symbols/Gardin 2.svg'
import Hanger1Icon from '../../../src/assets/symbols/galgar 1 ny-01.svg'
import Hanger2Icon from '../../../src/assets/symbols/galgar 3 ny-01.svg'
import Hanger3Icon from '../../../src/assets/symbols/galgar 4 ny-01.svg'
import Hanger4Icon from '../../../src/assets/symbols/galgar 2 ny-01.svg'

import twoSeatSofa from '../../assets/symbolsIcons/2 Seat sofa_Rityta 1.png'
import threeSeatSofa from '../../assets/symbolsIcons/3 Seat sofa_Rityta 1.png'
import modularSofa from '../../assets/symbolsIcons/Modular sofa_Rityta 1.png'
import chaiseSofa from '../../assets/symbolsIcons/Chaise sofa_Rityta 1.png'
import loungeChair1 from '../../assets/symbolsIcons/Lounge chair 1_Rityta 1.png'
import loungeChair2 from '../../assets/symbolsIcons/Lounge chair 2_Rityta 1.png'
import armChair1 from '../../assets/symbolsIcons/Armchair 1_Rityta 1.png'
import armChair2 from '../../assets/symbolsIcons/Armchair 2_Rityta 1.png'
import window from '../../assets/symbolsIcons/Window_Rityta 1.png'
import singleDoor from '../../assets/symbolsIcons/Single door_Rityta 1.png'
import octagonal from '../../assets/symbolsIcons/Octagon_Rityta 1.png'
import pentagonal from '../../assets/symbolsIcons/Pentagon_Rityta 1.png'
import hexagonal from '../../assets/symbolsIcons/Hexagon_Rityta 1.png'
import singleLine from '../../assets/symbolsIcons/Line_Rityta 1.png'
import lightingFixture from '../../assets/symbolsIcons/Lighting fixture_Rityta 1.png'
import coffeeTable1 from '../../assets/symbolsIcons/Coffee table 1_Rityta 1.png'
import spotlight from '../../assets/symbolsIcons/Spotlight_Rityta 1.png'
import square from '../../assets/symbolsIcons/Square_Rityta 1.png'
import toilet from '../../assets/symbolsIcons/Toilet_Rityta 1.png'
import triangle from '../../assets/symbolsIcons/Triangle_Rityta 1.png'
import circle from '../../assets/symbolsIcons/Circle_Rityta 1.png'
import shower from '../../assets/symbolsIcons/Shower_Rityta 1.png'
import kingBed from '../../assets/symbolsIcons/Double bed 2_Rityta 1.png'
import singleBed from '../../assets/symbolsIcons/Single bed_Rityta 1.png'
import rectangulrStair from '../../assets/symbolsIcons/Rectangular stair_Rityta 1.png'
import plant from '../../assets/symbolsIcons/Plant 2_Rityta 1.png'
import curtain1 from '../../assets/symbolsIcons/Curtain 1_Rityta 1.png'
import curtain2 from '../../assets/symbolsIcons/Curtain 2_Rityta 1.png'
import hanger1 from '../../assets/symbolsIcons/Hangers 4_Rityta 1.png'
import hanger2 from '../../assets/symbolsIcons/Hangers 3_Rityta 1.png'
import hanger3 from '../../assets/symbolsIcons/Hangers 2_Rityta 1.png'
import hanger4 from '../../assets/symbolsIcons/Hangers 1_Rityta 1.png'
import doubleDoor from '../../assets/symbolsIcons/Double door_Rityta 1.png'
import basin from '../../assets/symbolsIcons/Basin_Rityta 1.png'
import bathtub from '../../assets/symbolsIcons/Bathtub_Rityta 1.png'
import chair from '../../assets/symbolsIcons/Chair_Rityta 1.png'
import circleStairs from '../../assets/symbolsIcons/Circular stair_Rityta 1.png'
import coffeeTable2 from '../../assets/symbolsIcons/Oval coffee table2_Rityta 1.png'

import DefaultIcon from '../../assets/defaultIcon.png';

import { RulerLineType, SingleLineType, WallType } from '../../types/wallTypes';
import { Vector2 } from 'three';
import { editorStore } from '../../store/editorStore';
import { PiLineSegmentsLight } from "react-icons/pi";
import { IoClose } from 'react-icons/io5';
import { observer } from 'mobx-react-lite';
import CircleStairs from '../FloorPlan/CircleStairs';

interface DropdownProps {
  position: { top: number; left: number };
  onClose: () => void;
  dropdownRef: React.RefObject<HTMLDivElement>;
}

export const ShapesPanel: React.FC<DropdownProps> = observer(({ position, onClose, dropdownRef }) => {

  // Prevent clicks inside the panel from closing it
  const handlePanelClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();  // Prevents the event from bubbling up
  };

  const handleIconClick = (label: string, props?: any) => {
    // Retrieve the visible center from the editor store
    const [centerX, centerY] = editorStore.getVisibleCenter();
    /*   if (label === "Wall") {
        // Set a default wall length, for example 5 units (adjust based on your needs)
        const wallLength = 2;
  
        // Calculate the start and end points of the wall, centered around the visible center
        const startX = centerX - wallLength / 2;
        const endX = centerX + wallLength / 2;
        const startY = centerY;
        const endY = centerY;
  
        const wall: WallType = {
          id: floorplannerStore.generateId(),
          type: "wall",
          start: new Vector2(startX, startY), // Start point to the left of the center
          end: new Vector2(endX, endY), // End point to the right of the center
  
        }
        floorplannerStore.addWall(wall);
      } else if (label === "SingleLine") {
        // Set a default line length, for example 5 units (adjust based on your needs)
        const singleLineLength = 2;
        // Calculate the start and end points of the wall, centered around the visible center
        const startX = centerX - singleLineLength / 2;
        const endX = centerX + singleLineLength / 2;
        const startY = centerY;
        const endY = centerY;
  
        const line: SingleLineType = {
          id: floorplannerStore.generateId(),
          type: "singleLine",
          start: new Vector2(startX, startY), // Start point to the left of the center
          end: new Vector2(endX, endY), // End point to the right of the center
        }
        floorplannerStore.addSingleLine(line);
      } else if (label === "RulerLine") {
        // Set a default line length, for example 5 units (adjust based on your needs)
        const rulerLineLength = 2;
        // Calculate the start and end points of the wall, centered around the visible center
        const startX = centerX - rulerLineLength / 2;
        const endX = centerX + rulerLineLength / 2;
        const startY = centerY;
        const endY = centerY;
  
        const line: RulerLineType = {
          id: floorplannerStore.generateId(),
          type: "rulerLine",
          start: new Vector2(startX, startY), // Start point to the left of the center
          end: new Vector2(endX, endY), // End point to the right of the center
        }
        floorplannerStore.addRulerLine(line);
      } else if (label === "WallConstructionMode") {
        editorStore.setWallConstructionMode(!editorStore.wallConstructionMode);
        onClose();
      } else if (label === "LineConstructionMode") {
        editorStore.setLineConstructionMode(!editorStore.lineConstructionMode);
        onClose();
      } else if (label === "RulerConstructionMode") {
        editorStore.setRulerConstructionMode(!editorStore.rulerConstructionMode);
        onClose();
      }  else if (label === "AreaConstructionMode") {
        editorStore.setAreaConstructionMode(!editorStore.areaConstructionMode);
        onClose();
      }  */
    if (label === "Door") {
      floorplannerStore.addSymbol("door", [0, 0]);
    } else if (label === "DoubleDoor") {
      floorplannerStore.addSymbol("doubleDoor", [0, 0]);
    } else if (label === "CircleStairs") {
      floorplannerStore.addSymbol("circleStairs", [0, 0]);
    } else if (label === "RectStairs") {
      floorplannerStore.addSymbol("rectStairs", [0, 0]);
    } else if (label === "SquareSymbol") {
      floorplannerStore.addSymbol("square", [0, 0]);
    } else if (label === "CircleSymbol") {
      floorplannerStore.addSymbol("circle", [0, 0]);
    } else if (label === "TriangleSymbol") {
      floorplannerStore.addSymbol("triangle", [0, 0]);
    } else if (label === "SvgSymbol") {
      floorplannerStore.addSymbol("svg", [0, 0], props);
    } else if (label === "SingleLine") {
      // Set a default line length, for example 5 units (adjust based on your needs)
      const singleLineLength = 2;
      // Calculate the start and end points of the wall, centered around the visible center
      const startX = centerX - singleLineLength / 2;
      const endX = centerX + singleLineLength / 2;
      const startY = centerY;
      const endY = centerY;

      const line: SingleLineType = {
        id: floorplannerStore.generateId(),
        type: "singleLine",
        start: new Vector2(startX, startY), // Start point to the left of the center
        end: new Vector2(endX, endY), // End point to the right of the center
        hideMeasurement: props?.hideMeasures,
      }
      floorplannerStore.addSingleLine(line);
    } else if (label === "Window") {
      floorplannerStore.addSymbol("window", [0, 0]);
    }

    /* else if (label === "TextSymbol") {
      floorplannerStore.addSymbol("text", [0, 0]);
    }  */
    /* else if (label === "AreaSymbol") {
         floorplannerStore.addSymbol("area", [0, 0], {
           vertices: [
             new Vector2(0, 0),
             new Vector2(1, 0),
             new Vector2(1.5, 0.5),
             new Vector2(1, 1),
             new Vector2(0, 1),
           ],
         });
       } */
  };

  return (
    <div
      className="fixed z-50 w-[398px] bg-white rounded shadow-lg mb-8 overflow-y-auto scrollbar"
      style={{ top: position.top, left: position.left, maxHeight: '70vh' }}
      ref={dropdownRef}
      onClick={handlePanelClick}
    >
      <div className="flex flex-cols items-center justify-between  border-b border-[#edebe6] p-2 px-4 pr-2">
        <div className="text-sm font-normal">Shapes library</div>
        <div onClick={onClose} className="text-sm  text-gray-500"><IoClose style={{ strokeWidth: 0.5 }} size={24} /></div>
      </div>


      {/* Group 4 */}
      <div className='p-2 px-4 '>
        <div className=" border-gray-300 pb-2 mb-2 mt-4 text-sm font-medium ">Shapes</div>

        <div className="w-[364px] pr-2 grid grid-cols-3 gap-2">
          <div
            className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SingleLine", { hideMeasures: true })}
          >
            <img className="w-[108px] h-[87px] left-[3px] top-[3px]  absolute rounded-sm" src={singleLine} />
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Single line</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("TriangleSymbol")}
          >
            <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={triangle} />
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Triangle</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("CircleSymbol")}
          >
            <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={circle} />
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Circle</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SquareSymbol")}
          >
            <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={square} />
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Square</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: OctagonalCoffTabIcon, svgLength: 0.9, svgHeight: 0.884, name: "Octagon" })}
          >
            <img className="w-[108px] h-[87px] left-[3px] top-[3px]  absolute rounded-sm" src={octagonal} />
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Octagon</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: PentagonalCoffTabIcon, svgLength: 0.946, svgHeight: 0.939, name: "Pentagon" })}
          >
            <img className="w-[108px] h-[87px] left-[3px] top-[3px]  absolute rounded-sm" src={pentagonal} />
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Pentagon</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: HexagonalCoffTabIcon, svgLength: 1.039, svgHeight: 0.906, name: "Hexagon" })}
          >
            <img className="w-[108px] h-[87px] left-[3px] top-[3px]  absolute rounded-sm" src={hexagonal} />
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Hexagon</div>
          </div>
          {/*  <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 2, svgHeight: 2, name: "Arrow" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={ArrowIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Arrow</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: DoubbleArrowIcon, svgLength: 2, svgHeight: 0.5, name: "Double arrow" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Double arrow</div>
          </div> */}


        </div>
      </div>

    </div>

  );
});