import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Line } from "@react-three/drei";
import * as THREE from "three";
import { SingleLineType } from "../../types/wallTypes";
import { ThreeEvent } from "@react-three/fiber";
import { editorStore } from "../../store/editorStore";
import { MeasurementLine } from "./MeasurementLine";
import { floorplannerStore } from "../../store/floorplannerStore";
import { object } from "prop-types";

interface SingleLineProps {
    line: SingleLineType;
    color: string;
    onLinePointerDown: (event: ThreeEvent<PointerEvent>, id: string) => void;
    onLinePointerEnter: (event: ThreeEvent<PointerEvent>, id: string) => void;
    onLinePointerLeave: (event: ThreeEvent<PointerEvent>, id: string) => void;
    handleKeyPress: (event: React.KeyboardEvent<HTMLInputElement>, line: SingleLineType) => void;
    handleLengthClick: (lineId: string) => void;
}

const SingleLine: React.FC<SingleLineProps> = observer(({
    line,
    color,
    onLinePointerDown,
    onLinePointerEnter,
    onLinePointerLeave,
    handleKeyPress,
    handleLengthClick
}) => {
    const zoomLevel = editorStore.zoomLevel; // Rerenender when zoom level changes
    const noopRaycast = () => null;

    // Return null if line or start or end is null
    if (!line || !line.start || !line.end) {
        return null;
    }

    return (
        <group
            onPointerDown={(e) => onLinePointerDown(e, line.id)}
            onPointerEnter={(e) => onLinePointerEnter(e, line.id)}
            onPointerLeave={(e) => onLinePointerLeave(e, line.id)}
        >
            {/* Invisible click area as a mesh along the ruler start all the way to the line end with a width of 0.1 */}
            <mesh
                position={[
                    (line.start.x + line.end.x) / 2,
                    (line.start.y + line.end.y) / 2,
                    0
                ]}
                rotation={[
                    0,
                    0,
                    new THREE.Vector2(line.end.x - line.start.x, line.end.y - line.start.y).angle()
                ]}
            >
                <planeGeometry args={[line.start.distanceTo(line.end), 0.15]} />
                <meshBasicMaterial
                    // color="white"
                    transparent
                    opacity={0.0}
                />
            </mesh>
            <Line
                key={`group-line-${line.id}`}
                points={[
                    new THREE.Vector3(line.start.x, line.start.y, 0),
                    new THREE.Vector3(line.end.x, line.end.y, 0)
                ]}
                color={color}
                lineWidth={line.lineWeight || floorplannerStore.singleLineWeight}
                {...(line.lineType === "dashed" && {
                    dashed: true,
                    dashSize: zoomLevel < 0.5 ? 0 : 0.15,
                    gapSize: zoomLevel < 0.5 ? 0 : 0.1,
                })}
                raycast={noopRaycast}
            />
            {!line.hideMeasurement && (editorStore.showMeasures || line?.selected || (editorStore.selections.length && editorStore.selections[0].id === line.id)) && (
                <MeasurementLine
                    object={line}
                />
            )}
        </group>
    );
});

export default React.memo(SingleLine, (prevProps, nextProps) => {
    // Only re-render if any of these props change
    return (
        prevProps.line.lastUpdate === nextProps.line.lastUpdate &&
        prevProps.line.start.x === nextProps.line.start.x &&
        prevProps.line.start.y === nextProps.line.start.y &&
        prevProps.line.end.x === nextProps.line.end.x &&
        prevProps.line.end.y === nextProps.line.end.y &&
        prevProps.line.selected === nextProps.line.selected &&
        prevProps.color === nextProps.color &&
        prevProps.onLinePointerDown === nextProps.onLinePointerDown &&
        prevProps.onLinePointerEnter === nextProps.onLinePointerEnter &&
        prevProps.onLinePointerLeave === nextProps.onLinePointerLeave &&
        prevProps.handleKeyPress === nextProps.handleKeyPress &&
        prevProps.handleLengthClick === nextProps.handleLengthClick
    );
});
