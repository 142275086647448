import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import * as THREE from "three";
import { useThree, render } from '@react-three/fiber';
import DraggableObject from "./DraggableObject";
import { SvgType, zIndexEpsilon } from "../../types/wallTypes";
import { BoundingBox, SelectableSymbol } from './SelectableSymbol';
import { FloorplannerStoreContext } from "../../store/floorplannerStore";
import { Svg } from "./Svg";
import { editorStore } from "../../store/editorStore";
import { observer } from "mobx-react-lite";
interface SvgSymbolProps {
  svg: SvgType;
  onDragStart: (svg: SvgType, offset: [number, number]) => void;
  onDrag: (newPosition: [number, number]) => void;
  onDragEnd: (endPosition: [number, number]) => void;
  renderOrder?: number;
}

const SvgSymbol: React.FC<SvgSymbolProps> = observer(({
  svg,
  onDragStart,
  onDragEnd,
  onDrag,
  renderOrder = 0
}) => {
  const { gl, camera } = useThree();
  const groupRef = useRef<THREE.Group>(null);
  const floorplannerStore = React.useContext(FloorplannerStoreContext);

  const [svgLength, setSvgLength] = useState(svg.svgLength);
  const [svgHeight, setSvgHeight] = useState(svg.svgHeight);
  const aspectRatio = svg.svgRatio || 1;
  const flipX = svg.flipHorizontal ? -1 : 1;
  const flipY = svg.flipVertical ? -1 : 1;
  const lineColor = svg.lineColor || floorplannerStore.lineColor;
  const [pivot, setPivot] = useState<[number, number]>([0, 0]);
  const dragOffset = useRef({ x: 0, y: 0 });

  const lineWeight = svg.lineWeight || floorplannerStore.symbolLineWeight;

  const lineWeightInWorld = useMemo(() => floorplannerStore.convertLineWeightToWorld(lineWeight), [lineWeight, floorplannerStore]);
  const [centerPosition] = useState<[number, number]>([0, 0]);
  const [isResizing, setIsResizing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const epsilon = 0.0;

  const calculateBoundingBox = useCallback((): BoundingBox => {
    if (!groupRef.current) return { topLeft: [0, 0], topRight: [0, 0], bottomLeft: [0, 0], bottomRight: [0, 0], width: 0, height: 0, depth: 0 };

    const tempObject = groupRef.current.clone();
    tempObject.rotation.set(0, 0, 0);

    const box = new THREE.Box3().setFromObject(tempObject);
    const width = box.max.x - box.min.x;
    const height = box.max.y - box.min.y;
    const adjustedWidth = width + lineWeightInWorld;
    const adjustedHeight = height + lineWeightInWorld;

    return {
      topLeft: [-width / 2 - lineWeightInWorld / 2, height / 2 + lineWeightInWorld / 2],
      topRight: [width / 2 + lineWeightInWorld / 2, height / 2 + lineWeightInWorld / 2],
      bottomLeft: [-width / 2 - lineWeightInWorld / 2, -height / 2 - lineWeightInWorld / 2],
      bottomRight: [width / 2 + lineWeightInWorld / 2, -height / 2 - lineWeightInWorld / 2],
      width: adjustedWidth,
      height: adjustedHeight,
      depth: 0,
    };
  }, [lineWeightInWorld, svgLength, svgHeight, svg.lockAspectRatio]);

  useEffect(() => {
    setSvgLength(svg.svgLength);
    setSvgHeight(svg.svgHeight);
  }, [svg.svgLength, svg.svgHeight]);

  useEffect(() => {
    editorStore.updateGroupRef(svg.id, groupRef.current);
  }, [groupRef.current]);

  // Redraw when the symbol.selected changes
  useEffect(() => {
  }, [svg.selected]);

  return (
    <group
      position={[0, 0, svg.zIndex * zIndexEpsilon]}
    >
      <DraggableObject
        position={[svg.position.x, svg.position.y]}
        onDragStart={(offset) => {
          setIsDragging(true);
          onDragStart(svg, offset)
        }}
        onDragEnd={(endPosition) => {
          onDragEnd(endPosition)
          setIsDragging(false);
        }}
        onDrag={onDrag}
        selectable={true}
        attachmentId={svg.id}
        symbol={svg}
      >
        <SelectableSymbol
          ref={groupRef}
          handleSize={floorplannerStore.symbolHandleSize}
          calculateBoundingBox={calculateBoundingBox}
          onResizeStart={() => setIsResizing(true)}
          onResize={(newWidth, newHeight, boundingBox, handle) => {
            let calculatedHeight
            if (svg.lockAspectRatio) {
              calculatedHeight = newWidth / aspectRatio;
            } else {
              calculatedHeight = newHeight;
            }

            setSvgLength(newWidth);
            setSvgHeight(calculatedHeight);
            floorplannerStore.updateSymbolProperty(svg.id, "svgLength", newWidth);
            floorplannerStore.updateSymbolProperty(svg.id, "svgHeight", calculatedHeight);
          }}
          onResizeEnd={() => setIsResizing(false)}
          center={centerPosition}
          rotation={svg.rotation}
          symbol={svg}
          drawHandles={true}
          lockAspectRatio={svg.lockAspectRatio}
          isDragging={isDragging}
          onPointerOver={() => setIsHovered(true)}
          onPointerOut={() => setIsHovered(false)}
        >
          <group
            ref={groupRef}
            rotation={[0, 0, svg.rotation || 0]}
            onPointerEnter={() => {
              if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.add("cursor-vector");
            }}
            onPointerLeave={() => {
              if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.remove("cursor-vector");
            }}
          >
            <Svg
              svgId={svg.id}
              src={svg.svgPath}
              fillMaterial={{
                color: svg.fillColor || "",
              }}
              lineColor={isHovered || svg.selected ? "blue" : lineColor || "black"}
              lineWeight={lineWeight}
              width={svgLength}
              height={svgHeight}
              lineType={svg.lineType || "solid"}
              lockAspectRatio={svg.lockAspectRatio}
              renderOrder={renderOrder}
            />
          </group>
        </SelectableSymbol>
      </DraggableObject>
    </group>
  );
});

export default SvgSymbol;
