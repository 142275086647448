import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
  Token: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type ActivationAndVerificationStatusInput = {
  addTrialActivatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  addTrialEndViewShownAt?: InputMaybe<Scalars['Boolean']['input']>;
  updateVerificationLinkEmailedAt?: InputMaybe<Scalars['Boolean']['input']>;
  updateVerificationLinkShownAt?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Affiliate = {
  __typename?: 'Affiliate';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export enum AuthScope {
  Admin = 'ADMIN',
  Client = 'CLIENT'
}

export type BoardProSettings = {
  __typename?: 'BoardProSettings';
  brands?: Maybe<Array<Maybe<ProBrand>>>;
  currency?: Maybe<Scalars['String']['output']>;
  customExpenses?: Maybe<Array<Maybe<CustomExpense>>>;
  descriptions?: Maybe<Array<Maybe<ProDesc>>>;
  hideBrands?: Maybe<Scalars['Boolean']['output']>;
  hideClientPrices?: Maybe<Scalars['Boolean']['output']>;
  hideDescriptions?: Maybe<Scalars['Boolean']['output']>;
  hideOriginalPrices?: Maybe<Scalars['Boolean']['output']>;
  hideStoreLinks?: Maybe<Scalars['Boolean']['output']>;
  hideSuppliers?: Maybe<Scalars['Boolean']['output']>;
  order?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  paintVisibilities?: Maybe<Array<Maybe<PaintVisibility>>>;
  prices?: Maybe<Array<Maybe<ProPrice>>>;
  suppliers?: Maybe<Array<Maybe<ProSupplier>>>;
  visibilities?: Maybe<Array<Maybe<ProVisibility>>>;
};

export type BoardProSettingsInput = {
  brands?: InputMaybe<Array<InputMaybe<ProBrandInput>>>;
  currency?: InputMaybe<Scalars['String']['input']>;
  customExpenses?: InputMaybe<Array<InputMaybe<CustomExpenseInput>>>;
  descriptions?: InputMaybe<Array<InputMaybe<ProDescInput>>>;
  hideBrands?: InputMaybe<Scalars['Boolean']['input']>;
  hideClientPrices?: InputMaybe<Scalars['Boolean']['input']>;
  hideDescriptions?: InputMaybe<Scalars['Boolean']['input']>;
  hideOriginalPrices?: InputMaybe<Scalars['Boolean']['input']>;
  hideStoreLinks?: InputMaybe<Scalars['Boolean']['input']>;
  hideSuppliers?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paintVisibilities?: InputMaybe<Array<InputMaybe<PaintVisibilityInput>>>;
  prices?: InputMaybe<Array<InputMaybe<ProPriceInput>>>;
  suppliers?: InputMaybe<Array<InputMaybe<ProSupplierInput>>>;
  visibilities?: InputMaybe<Array<InputMaybe<ProVisibilityInput>>>;
};

export type BoardSetting = {
  __typename?: 'BoardSetting';
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  json?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type BoardSettingEditInput = {
  height?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  json?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type Category = {
  __typename?: 'Category';
  children?: Maybe<Array<Maybe<Category>>>;
  id: Scalars['ID']['output'];
  keywords?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  outsideCategories?: Maybe<Scalars['JSONObject']['output']>;
  parent?: Maybe<Category>;
};

export type CategoryInput = {
  id: Scalars['String']['input'];
  keywords?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  outsideCategories?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type CategoryMeta = {
  __typename?: 'CategoryMeta';
  children?: Maybe<Array<Maybe<CategoryMeta>>>;
  count?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  keywords?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  outsideCategories?: Maybe<Scalars['JSONObject']['output']>;
  parent?: Maybe<CategoryMeta>;
};

export type Color = {
  __typename?: 'Color';
  hex: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CropInput = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
  x: Scalars['Int']['input'];
  y: Scalars['Int']['input'];
};

export type CustomExpense = {
  __typename?: 'CustomExpense';
  description?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

export type CustomExpenseInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
};

export type Document = {
  __typename?: 'Document';
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  moodBoards?: Maybe<Array<MoodBoard>>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EditSupplierInput = {
  UTMTag?: InputMaybe<Scalars['String']['input']>;
  affiliate?: InputMaybe<Scalars['String']['input']>;
  affiliateUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  identifier?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['Upload']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parserKeepBG?: InputMaybe<Scalars['Boolean']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type EditUserInput = {
  userName?: InputMaybe<Scalars['String']['input']>;
};

export type FlaggedProductMessage = {
  __typename?: 'FlaggedProductMessage';
  Product?: Maybe<Product>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  user: User;
};

export type Floorplan = {
  __typename?: 'Floorplan';
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  json?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FloorplanEditInput = {
  id: Scalars['ID']['input'];
  image?: InputMaybe<Scalars['Upload']['input']>;
  json?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Folder = {
  __typename?: 'Folder';
  documents?: Maybe<Array<Document>>;
  galleryBoards?: Maybe<Array<GalleryBoard>>;
  id: Scalars['ID']['output'];
  materials?: Maybe<Array<Material>>;
  name: Scalars['String']['output'];
  paints?: Maybe<Array<Product>>;
  proItems?: Maybe<Array<ProItem>>;
  products?: Maybe<Array<Product>>;
  surfaces?: Maybe<Array<Surface>>;
  userItems?: Maybe<Array<UserItem>>;
};

export type GalleryBoard = {
  __typename?: 'GalleryBoard';
  createdBy: User;
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isTemplate?: Maybe<Scalars['Boolean']['output']>;
  json?: Maybe<Scalars['String']['output']>;
  likedBy?: Maybe<Array<User>>;
  locked?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paints?: Maybe<Array<MoodboardPaint>>;
  proItems?: Maybe<Array<MoodboardProItem>>;
  proSettings?: Maybe<BoardProSettings>;
  products?: Maybe<Array<MoodboardProduct>>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type GalleryBoardEditInput = {
  id: Scalars['ID']['input'];
  json?: InputMaybe<Scalars['String']['input']>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum GalleryBoardSort {
  Latest = 'LATEST',
  MostLiked = 'MOST_LIKED'
}

export type GalleryBoardsInput = {
  limit: Scalars['Int']['input'];
  notLocked?: InputMaybe<Scalars['Boolean']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip: Scalars['Int']['input'];
  sort?: InputMaybe<GalleryBoardSort>;
};

export type Material = {
  __typename?: 'Material';
  categories?: Maybe<Array<Maybe<MaterialCategory>>>;
  colors?: Maybe<Array<Maybe<Color>>>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MaterialCategory = {
  __typename?: 'MaterialCategory';
  children?: Maybe<Array<Maybe<MaterialCategory>>>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  parent?: Maybe<MaterialCategory>;
};

export type MaterialCategoryInput = {
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
};

export type MaterialEditInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  colors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  productImage?: InputMaybe<Scalars['Upload']['input']>;
  productImageNoBg?: InputMaybe<Scalars['Upload']['input']>;
};

export type MaterialInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  colors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Array<Scalars['Upload']['input']>>;
  name: Scalars['String']['input'];
};

export type MaterialResponse = {
  __typename?: 'MaterialResponse';
  data?: Maybe<Array<Material>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type MaterialsQueryInput = {
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  colorIds?: InputMaybe<Array<Scalars['String']['input']>>;
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
};

export type MoodBoard = {
  __typename?: 'MoodBoard';
  documentId?: Maybe<Scalars['ID']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  index?: Maybe<Scalars['Int']['output']>;
  json?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paints?: Maybe<Array<MoodboardPaint>>;
  proItems?: Maybe<Array<MoodboardProItem>>;
  proSettings?: Maybe<BoardProSettings>;
  products?: Maybe<Array<MoodboardProduct>>;
  sharedWith?: Maybe<Array<User>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type MoodBoardEditInput = {
  id: Scalars['ID']['input'];
  image?: InputMaybe<Scalars['Upload']['input']>;
  json?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  proSettings?: InputMaybe<BoardProSettingsInput>;
};

export type MoodboardPaint = {
  __typename?: 'MoodboardPaint';
  code: Scalars['String']['output'];
  hex?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
};

export type MoodboardProItem = {
  __typename?: 'MoodboardProItem';
  id: Scalars['String']['output'];
  notShowInMoodboard?: Maybe<Scalars['Boolean']['output']>;
  product?: Maybe<ProItem>;
};

export type MoodboardProduct = {
  __typename?: 'MoodboardProduct';
  id: Scalars['String']['output'];
  notShowInMoodboard?: Maybe<Scalars['Boolean']['output']>;
  product?: Maybe<Product>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activationAndVerificationStatus: Scalars['String']['output'];
  addAcceptedCategories: Supplier;
  addCategory: Category;
  addCategoryMeta: CategoryMeta;
  addMaterial: Material;
  addMaterialCategory: MaterialCategory;
  addMaterialToFavorites: Scalars['ID']['output'];
  addOutsideCategory: Category;
  addOutsideCategoryMeta: CategoryMeta;
  addProItem: ProItem;
  addProductToFavorites: Scalars['ID']['output'];
  addProducts: Array<Product>;
  addProfileToFavorites: Scalars['ID']['output'];
  addRoomElement?: Maybe<Surface>;
  addSupplier?: Maybe<Supplier>;
  addSurface: Surface;
  addSurfaceCategory: SurfaceCategory;
  addSurfaceToFavorites: Scalars['ID']['output'];
  addToFolder: Folder;
  addUserItem: UserItem;
  adminCleanUnusedImages: Scalars['String']['output'];
  adminDeleteParserService: Scalars['String']['output'];
  adminExecuteParserServiceAction: Supplier;
  adminParserServiceStartAll: Scalars['String']['output'];
  adminStartParserService: Scalars['String']['output'];
  adminVerifyUser: User;
  changeDocumentName: Document;
  copyDocument: Document;
  copyMoodboard: MoodBoard;
  createCheckoutSession: Scalars['String']['output'];
  createDocument: Document;
  createFolder: Folder;
  createMoodBoard: MoodBoard;
  createProfile: Profile;
  createShareDocument?: Maybe<ShareDocument>;
  createTemplateMoodBoard: GalleryBoard;
  createValidateLink: User;
  cropImage: Scalars['String']['output'];
  deleteAllRoomElements?: Maybe<Surface>;
  deleteDocument: Document;
  deleteFloorplan: Floorplan;
  deleteFolder: Folder;
  deleteGalleryBoard: GalleryBoard;
  deleteImage: Scalars['ID']['output'];
  deleteMaterial: Scalars['ID']['output'];
  deleteMoodBoard: MoodBoard;
  deleteProduct: Scalars['ID']['output'];
  deleteProfile: Scalars['ID']['output'];
  deleteSupplier: Scalars['ID']['output'];
  deleteSurface: Scalars['ID']['output'];
  editFloorplan: Floorplan;
  editFolder: Folder;
  editMaterial: Material;
  editMaterialCategory: MaterialCategory;
  editMoodBoard: MoodBoard;
  editProItem: ProItem;
  editProduct: Product;
  editProducts?: Maybe<Array<Maybe<Product>>>;
  editProfile: Profile;
  editSupplier?: Maybe<Supplier>;
  editSurface: Surface;
  editSurfaceCategory: SurfaceCategory;
  editUser?: Maybe<User>;
  fixProductImages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fixUserAndProItems?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  flagProducts?: Maybe<Array<Maybe<Product>>>;
  forgotPassword: Scalars['String']['output'];
  hideProduct: Product;
  hideProducts?: Maybe<Array<Maybe<Product>>>;
  likeGalleryBoard: GalleryBoard;
  login?: Maybe<Session>;
  logout: Scalars['Boolean']['output'];
  moveBoardInDocument: Document;
  projectiveImage: Scalars['String']['output'];
  publishMoodBoard: GalleryBoard;
  refreshTokens: TokensResponse;
  removeAcceptedCategories: Supplier;
  removeCategory?: Maybe<Scalars['Boolean']['output']>;
  removeCategoryMeta?: Maybe<Scalars['Boolean']['output']>;
  removeFromFolder: Folder;
  removeMaterialCategory?: Maybe<Scalars['Boolean']['output']>;
  removeMaterialFromFavorites: Scalars['ID']['output'];
  removeProItem: Scalars['ID']['output'];
  removeProItemBackground: Array<Maybe<Scalars['ID']['output']>>;
  removeProductBackground?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  removeProductFromFavorites: Scalars['ID']['output'];
  removeProfileFromFavorites: Scalars['ID']['output'];
  removeSurfaceCategory?: Maybe<Scalars['Boolean']['output']>;
  removeSurfaceFromFavorites: Scalars['ID']['output'];
  removeUploadedBackgroundImage?: Maybe<Scalars['Boolean']['output']>;
  removeUserItem?: Maybe<Scalars['Boolean']['output']>;
  removeUserItemBackground: Array<Maybe<Scalars['ID']['output']>>;
  resetPassword: Scalars['String']['output'];
  saveBoardSetting: BoardSetting;
  saveMoodboard: MoodBoard;
  sendShareDocument?: Maybe<Scalars['Boolean']['output']>;
  sendShareLink?: Maybe<Scalars['Boolean']['output']>;
  sendUserVerificationEmail: Scalars['String']['output'];
  signup?: Maybe<User>;
  unFlagProducts?: Maybe<Array<Maybe<Product>>>;
  unLikeGalleryBoard: GalleryBoard;
  updatePaymentDetails: Scalars['String']['output'];
  updateSetupStatus: Scalars['String']['output'];
  uploadProducts?: Maybe<Scalars['String']['output']>;
  uploadRemovedBackgroundImage?: Maybe<Scalars['String']['output']>;
  userCounterUpdate: User;
  validateSession?: Maybe<ValidateSession>;
  validateUser?: Maybe<Session>;
};


export type MutationActivationAndVerificationStatusArgs = {
  input?: InputMaybe<ActivationAndVerificationStatusInput>;
};


export type MutationAddAcceptedCategoriesArgs = {
  category: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationAddCategoryArgs = {
  name: Scalars['String']['input'];
  parent?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddCategoryMetaArgs = {
  label?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parent?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddMaterialArgs = {
  input: MaterialInput;
};


export type MutationAddMaterialCategoryArgs = {
  name: Scalars['String']['input'];
  parent?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddMaterialToFavoritesArgs = {
  id: Scalars['String']['input'];
};


export type MutationAddOutsideCategoryArgs = {
  input?: InputMaybe<CategoryInput>;
};


export type MutationAddOutsideCategoryMetaArgs = {
  input?: InputMaybe<CategoryInput>;
};


export type MutationAddProItemArgs = {
  input: ProItemInput;
};


export type MutationAddProductToFavoritesArgs = {
  id: Scalars['String']['input'];
};


export type MutationAddProductsArgs = {
  input: ProductsInput;
};


export type MutationAddProfileToFavoritesArgs = {
  id: Scalars['String']['input'];
};


export type MutationAddRoomElementArgs = {
  input?: InputMaybe<RoomElementsMutationInput>;
};


export type MutationAddSupplierArgs = {
  input: SupplierInput;
};


export type MutationAddSurfaceArgs = {
  input: SurfaceInput;
};


export type MutationAddSurfaceCategoryArgs = {
  name: Scalars['String']['input'];
  parent?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddSurfaceToFavoritesArgs = {
  id: Scalars['String']['input'];
};


export type MutationAddToFolderArgs = {
  documentId?: InputMaybe<Scalars['String']['input']>;
  galleryBoardId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  materialId?: InputMaybe<Scalars['String']['input']>;
  proItemId?: InputMaybe<Scalars['String']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  surfaceId?: InputMaybe<Scalars['String']['input']>;
  uploadId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddUserItemArgs = {
  image: Scalars['Upload']['input'];
  removeBG?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationAdminDeleteParserServiceArgs = {
  serviceArn: Scalars['String']['input'];
};


export type MutationAdminExecuteParserServiceActionArgs = {
  action: Scalars['String']['input'];
  serviceUrl: Scalars['String']['input'];
  supplierId: Scalars['String']['input'];
};


export type MutationAdminStartParserServiceArgs = {
  supplierId: Scalars['String']['input'];
};


export type MutationAdminVerifyUserArgs = {
  userId: Scalars['String']['input'];
};


export type MutationChangeDocumentNameArgs = {
  documentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationCopyDocumentArgs = {
  id: Scalars['String']['input'];
};


export type MutationCopyMoodboardArgs = {
  id: Scalars['String']['input'];
};


export type MutationCreateCheckoutSessionArgs = {
  type: PaymentSubscriptionType;
};


export type MutationCreateFolderArgs = {
  name: Scalars['String']['input'];
};


export type MutationCreateMoodBoardArgs = {
  documentId: Scalars['String']['input'];
  height?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  json?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCreateProfileArgs = {
  input: ProfileInput;
};


export type MutationCreateShareDocumentArgs = {
  documentId?: InputMaybe<Scalars['String']['input']>;
  hideProductList?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateTemplateMoodBoardArgs = {
  image: Scalars['Upload']['input'];
  moodBoardId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateValidateLinkArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationCropImageArgs = {
  crop: CropInput;
  image: Scalars['String']['input'];
  oldImage: Scalars['String']['input'];
};


export type MutationDeleteAllRoomElementsArgs = {
  surfaceId: Scalars['String']['input'];
};


export type MutationDeleteDocumentArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteFloorplanArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteFolderArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteGalleryBoardArgs = {
  galleryBoardId: Scalars['String']['input'];
};


export type MutationDeleteImageArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteMaterialArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteMoodBoardArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteProfileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSupplierArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteSurfaceArgs = {
  id: Scalars['String']['input'];
};


export type MutationEditFloorplanArgs = {
  input?: InputMaybe<FloorplanEditInput>;
};


export type MutationEditFolderArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationEditMaterialArgs = {
  input: MaterialEditInput;
};


export type MutationEditMaterialCategoryArgs = {
  input?: InputMaybe<MaterialCategoryInput>;
};


export type MutationEditMoodBoardArgs = {
  input: MoodBoardEditInput;
};


export type MutationEditProItemArgs = {
  input: ProItemEditInput;
};


export type MutationEditProductArgs = {
  input: ProductEditInput;
};


export type MutationEditProductsArgs = {
  categories: Array<InputMaybe<Scalars['String']['input']>>;
  lock?: InputMaybe<Scalars['Boolean']['input']>;
  products: Array<InputMaybe<Scalars['String']['input']>>;
};


export type MutationEditProfileArgs = {
  id: Scalars['ID']['input'];
  input: ProfileInput;
};


export type MutationEditSupplierArgs = {
  input: EditSupplierInput;
};


export type MutationEditSurfaceArgs = {
  input: SurfaceEditInput;
};


export type MutationEditSurfaceCategoryArgs = {
  input?: InputMaybe<SurfaceCategoryInput>;
};


export type MutationEditUserArgs = {
  input: EditUserInput;
};


export type MutationFixProductImagesArgs = {
  productId: Scalars['String']['input'];
};


export type MutationFixUserAndProItemsArgs = {
  id: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type MutationFlagProductsArgs = {
  message?: InputMaybe<Scalars['String']['input']>;
  productIds: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationHideProductArgs = {
  isHidden: Scalars['Boolean']['input'];
  productId: Scalars['String']['input'];
};


export type MutationHideProductsArgs = {
  isHidden: Scalars['Boolean']['input'];
  productIds: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationLikeGalleryBoardArgs = {
  galleryBoardId: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  scope: AuthScope;
};


export type MutationMoveBoardInDocumentArgs = {
  documentId: Scalars['String']['input'];
  index: Scalars['Int']['input'];
  moodboardId: Scalars['String']['input'];
  moveUp: Scalars['Boolean']['input'];
};


export type MutationProjectiveImageArgs = {
  cornerPoints: Array<Scalars['Float']['input']>;
  offSetX: Scalars['Float']['input'];
  offSetY: Scalars['Float']['input'];
  oldTransImage: Scalars['String']['input'];
  scaleFactor: Scalars['Float']['input'];
  sourceImage: Scalars['String']['input'];
  xRepeat?: InputMaybe<Scalars['Int']['input']>;
  yRepeat?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationPublishMoodBoardArgs = {
  image: Scalars['Upload']['input'];
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  moodBoardId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationRemoveAcceptedCategoriesArgs = {
  category: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationRemoveCategoryArgs = {
  categoryId: Scalars['String']['input'];
};


export type MutationRemoveCategoryMetaArgs = {
  categoryId: Scalars['String']['input'];
};


export type MutationRemoveFromFolderArgs = {
  documentId?: InputMaybe<Scalars['String']['input']>;
  galleryBoardId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  materialId?: InputMaybe<Scalars['String']['input']>;
  proItemId?: InputMaybe<Scalars['String']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  surfaceId?: InputMaybe<Scalars['String']['input']>;
  uploadId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRemoveMaterialCategoryArgs = {
  categoryId: Scalars['String']['input'];
};


export type MutationRemoveMaterialFromFavoritesArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveProItemArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveProItemBackgroundArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveProductBackgroundArgs = {
  id: Scalars['String']['input'];
  imageVariant: Scalars['Int']['input'];
};


export type MutationRemoveProductFromFavoritesArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveProfileFromFavoritesArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveSurfaceCategoryArgs = {
  categoryId: Scalars['String']['input'];
};


export type MutationRemoveSurfaceFromFavoritesArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveUploadedBackgroundImageArgs = {
  image: Scalars['String']['input'];
};


export type MutationRemoveUserItemArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveUserItemBackgroundArgs = {
  id: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationSaveBoardSettingArgs = {
  height: Scalars['Int']['input'];
  id: Scalars['String']['input'];
  json: Scalars['String']['input'];
  width: Scalars['Int']['input'];
};


export type MutationSaveMoodboardArgs = {
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['Upload']['input']>;
  json: Scalars['String']['input'];
};


export type MutationSendShareDocumentArgs = {
  email: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};


export type MutationSendShareLinkArgs = {
  email: Scalars['String']['input'];
  link: Scalars['String']['input'];
};


export type MutationSendUserVerificationEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationSignupArgs = {
  input: UserSignupInput;
};


export type MutationUnFlagProductsArgs = {
  productIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type MutationUnLikeGalleryBoardArgs = {
  galleryBoardId: Scalars['String']['input'];
};


export type MutationUpdateSetupStatusArgs = {
  input?: InputMaybe<UpdateSetupStatusInput>;
};


export type MutationUploadProductsArgs = {
  input: ProductUploadInput;
};


export type MutationUploadRemovedBackgroundImageArgs = {
  image: Scalars['Upload']['input'];
  key: Scalars['String']['input'];
};


export type MutationUserCounterUpdateArgs = {
  counter: Scalars['String']['input'];
};


export type MutationValidateUserArgs = {
  token: Scalars['String']['input'];
};

export type PaintVisibility = {
  __typename?: 'PaintVisibility';
  hide?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type PaintVisibilityInput = {
  hide?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ParserJob = {
  __typename?: 'ParserJob';
  jobType?: Maybe<Scalars['String']['output']>;
};

export type ParserMessage = {
  __typename?: 'ParserMessage';
  message?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ParserService = {
  __typename?: 'ParserService';
  arn: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ParserStatus = {
  __typename?: 'ParserStatus';
  job?: Maybe<ParserJob>;
  messages?: Maybe<Array<Maybe<ParserMessage>>>;
  parsedProducts?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
};

export type Pattern = {
  __typename?: 'Pattern';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type PaymentSubscription = {
  __typename?: 'PaymentSubscription';
  current_period_end?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  stripe_customer_id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<PaymentSubscriptionType>;
};

export enum PaymentSubscriptionType {
  Developer = 'DEVELOPER',
  Pro = 'PRO',
  ProYear = 'PRO_YEAR',
  Starter = 'STARTER'
}

export type PriceRange = {
  from?: InputMaybe<Scalars['Float']['input']>;
  to?: InputMaybe<Scalars['Float']['input']>;
};

export type ProBrand = {
  __typename?: 'ProBrand';
  brand?: Maybe<Scalars['String']['output']>;
  moodBoardProductId?: Maybe<Scalars['ID']['output']>;
  productId?: Maybe<Scalars['ID']['output']>;
};

export type ProBrandInput = {
  brand?: InputMaybe<Scalars['String']['input']>;
  moodBoardProductId?: InputMaybe<Scalars['ID']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProDesc = {
  __typename?: 'ProDesc';
  description?: Maybe<Scalars['String']['output']>;
  moodBoardProductId?: Maybe<Scalars['ID']['output']>;
  productId?: Maybe<Scalars['ID']['output']>;
};

export type ProDescInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  moodBoardProductId?: InputMaybe<Scalars['ID']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProItem = {
  __typename?: 'ProItem';
  brand?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  supplier?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type ProItemEditInput = {
  brand?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  image?: InputMaybe<Scalars['Upload']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  removeBG?: InputMaybe<Scalars['Boolean']['input']>;
  supplier?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type ProItemInput = {
  brand?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  image: Scalars['Upload']['input'];
  name: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
  removeBG?: InputMaybe<Scalars['Boolean']['input']>;
  supplier?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type ProItemsQueryInput = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
};

export type ProItemsResponse = {
  __typename?: 'ProItemsResponse';
  data?: Maybe<Array<ProItem>>;
  total: Scalars['Int']['output'];
};

export type ProPrice = {
  __typename?: 'ProPrice';
  clientPrice?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  designerPrice?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  markup?: Maybe<Scalars['Float']['output']>;
  moodBoardProductId?: Maybe<Scalars['ID']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  productId?: Maybe<Scalars['ID']['output']>;
  storePrice?: Maybe<Scalars['Float']['output']>;
};

export type ProPriceInput = {
  clientPrice?: InputMaybe<Scalars['Float']['input']>;
  count?: InputMaybe<Scalars['Int']['input']>;
  designerPrice?: InputMaybe<Scalars['Float']['input']>;
  discount?: InputMaybe<Scalars['Float']['input']>;
  markup?: InputMaybe<Scalars['Float']['input']>;
  moodBoardProductId?: InputMaybe<Scalars['ID']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  storePrice?: InputMaybe<Scalars['Float']['input']>;
};

export type ProSupplier = {
  __typename?: 'ProSupplier';
  moodBoardProductId?: Maybe<Scalars['ID']['output']>;
  productId?: Maybe<Scalars['ID']['output']>;
  supplier?: Maybe<Scalars['String']['output']>;
};

export type ProSupplierInput = {
  moodBoardProductId?: InputMaybe<Scalars['ID']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  supplier?: InputMaybe<Scalars['String']['input']>;
};

export type ProVisibility = {
  __typename?: 'ProVisibility';
  hide?: Maybe<Scalars['Boolean']['output']>;
  hideBrand?: Maybe<Scalars['Boolean']['output']>;
  hideClientPrice?: Maybe<Scalars['Boolean']['output']>;
  hideDesc?: Maybe<Scalars['Boolean']['output']>;
  hideLink?: Maybe<Scalars['Boolean']['output']>;
  hideStorePrice?: Maybe<Scalars['Boolean']['output']>;
  hideSupplier?: Maybe<Scalars['Boolean']['output']>;
  moodBoardProductId?: Maybe<Scalars['ID']['output']>;
  productId?: Maybe<Scalars['ID']['output']>;
};

export type ProVisibilityInput = {
  hide?: InputMaybe<Scalars['Boolean']['input']>;
  hideBrand?: InputMaybe<Scalars['Boolean']['input']>;
  hideClientPrice?: InputMaybe<Scalars['Boolean']['input']>;
  hideDesc?: InputMaybe<Scalars['Boolean']['input']>;
  hideLink?: InputMaybe<Scalars['Boolean']['input']>;
  hideStorePrice?: InputMaybe<Scalars['Boolean']['input']>;
  hideSupplier?: InputMaybe<Scalars['Boolean']['input']>;
  moodBoardProductId?: InputMaybe<Scalars['ID']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
};

export type Product = {
  __typename?: 'Product';
  additionalImages?: Maybe<Array<Scalars['String']['output']>>;
  brand?: Maybe<Scalars['String']['output']>;
  categories?: Maybe<Array<Maybe<Category>>>;
  categoriesMeta?: Maybe<Array<Maybe<CategoryMeta>>>;
  categoriesMetaNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  colors?: Maybe<Array<Maybe<Color>>>;
  depth?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  features3?: Maybe<Scalars['JSONObject']['output']>;
  featuresVectorIndex?: Maybe<Scalars['Boolean']['output']>;
  flagged?: Maybe<Scalars['Boolean']['output']>;
  haveDifferentTypes?: Maybe<Scalars['Boolean']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Scalars['String']['output']>>;
  isExpired?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  isLocked?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  related?: Maybe<Array<Scalars['ID']['output']>>;
  supplier?: Maybe<Supplier>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type ProductEditInput = {
  brand?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  categoriesMeta?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  colors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  depth?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  isLocked?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  productImage?: InputMaybe<Scalars['Upload']['input']>;
  productImageNoBg?: InputMaybe<Scalars['Upload']['input']>;
  related?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplier?: InputMaybe<Scalars['ID']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type ProductInput = {
  brand: Scalars['String']['input'];
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  categoriesMeta?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  colors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  depth?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  images?: InputMaybe<Array<Scalars['Upload']['input']>>;
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  related?: InputMaybe<Array<Scalars['ID']['input']>>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type ProductUploadInput = {
  reRunCategories?: InputMaybe<Scalars['Boolean']['input']>;
  supplierId: Scalars['ID']['input'];
  upload: Scalars['Upload']['input'];
};

export type ProductsInput = {
  products: Array<ProductInput>;
  supplier: Scalars['ID']['input'];
};

export type ProductsPriceMinMaxRespone = {
  __typename?: 'ProductsPriceMinMaxRespone';
  max: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
};

export type ProductsQueryInput = {
  brands?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  client?: InputMaybe<Scalars['String']['input']>;
  colorIds?: InputMaybe<Array<Scalars['String']['input']>>;
  flagged?: InputMaybe<Scalars['Boolean']['input']>;
  limit: Scalars['Int']['input'];
  numberOfCategories?: InputMaybe<Scalars['String']['input']>;
  priceRange?: InputMaybe<PriceRange>;
  products?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip: Scalars['Int']['input'];
  sortBy?: InputMaybe<SortOption>;
  supplierId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ProductsQueryInputMeta = {
  brands?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  client?: InputMaybe<Scalars['String']['input']>;
  colorIds?: InputMaybe<Array<Scalars['String']['input']>>;
  feature?: InputMaybe<Scalars['Int']['input']>;
  flagged?: InputMaybe<Scalars['Boolean']['input']>;
  limit: Scalars['Int']['input'];
  materialIds?: InputMaybe<Array<Scalars['String']['input']>>;
  numberOfCategories?: InputMaybe<Scalars['String']['input']>;
  patternIds?: InputMaybe<Array<Scalars['String']['input']>>;
  priceRange?: InputMaybe<PriceRange>;
  products?: InputMaybe<Array<Scalars['ID']['input']>>;
  roomIds?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  shapeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  skip: Scalars['Int']['input'];
  sortBy?: InputMaybe<SortOption>;
  styleIds?: InputMaybe<Array<Scalars['String']['input']>>;
  supplierId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  supplierIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ProductsResponse = {
  __typename?: 'ProductsResponse';
  data?: Maybe<Array<Product>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Profile = {
  __typename?: 'Profile';
  about?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instagram?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  subTitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  website?: Maybe<Scalars['String']['output']>;
};

export type ProfileInput = {
  about?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['Upload']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  subTitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  adminGetParserMessages?: Maybe<Array<Maybe<ParserMessage>>>;
  adminGetParserServices?: Maybe<Array<Maybe<ParserService>>>;
  adminGetParserStatus?: Maybe<ParserStatus>;
  affiliates: Array<Maybe<Affiliate>>;
  boardSetting: BoardSetting;
  brands: Array<Maybe<Scalars['String']['output']>>;
  categories: Array<Maybe<Category>>;
  categoriesMeta: Array<Maybe<CategoryMeta>>;
  category?: Maybe<Category>;
  categoryMeta?: Maybe<CategoryMeta>;
  colors?: Maybe<Array<Color>>;
  colorsMeta?: Maybe<Array<Color>>;
  currentUser: User;
  documentById: Document;
  flaggedProductMessages?: Maybe<Array<Maybe<FlaggedProductMessage>>>;
  floorplan?: Maybe<Floorplan>;
  floorplans?: Maybe<Array<Maybe<Floorplan>>>;
  folderById?: Maybe<Folder>;
  galleryBoardById: GalleryBoard;
  galleryBoards?: Maybe<Array<GalleryBoard>>;
  galleryCount: Scalars['Int']['output'];
  getRoomElements?: Maybe<RoomElements>;
  materialById: Material;
  materialCategories: Array<Maybe<MaterialCategory>>;
  materialCategory?: Maybe<MaterialCategory>;
  materials?: Maybe<MaterialResponse>;
  materialsMeta?: Maybe<Array<Material>>;
  moodBoardById: MoodBoard;
  patternsMeta?: Maybe<Array<Pattern>>;
  proItems?: Maybe<ProItemsResponse>;
  productById: Product;
  products?: Maybe<ProductsResponse>;
  productsCount: Scalars['Int']['output'];
  productsCountMeta: Scalars['Int']['output'];
  productsMeta?: Maybe<ProductsResponse>;
  productsMetaExtract?: Maybe<ProductsResponse>;
  productsPriceMinMax: ProductsPriceMinMaxRespone;
  profileById?: Maybe<Profile>;
  profiles?: Maybe<Array<Profile>>;
  roomsMeta?: Maybe<Array<Room>>;
  scanRoomElements?: Maybe<ScanRoomElementsResponse>;
  searchHistories?: Maybe<SearchHistoriesResponse>;
  shapesMeta?: Maybe<Array<Shape>>;
  shareDocumentById: ShareDocument;
  stylesMeta?: Maybe<Array<Style>>;
  supplierById: Supplier;
  supplierStats?: Maybe<SupplierStatsResponse>;
  suppliers: Array<Supplier>;
  surfaceById: Surface;
  surfaceCategories: Array<Maybe<SurfaceCategory>>;
  surfaceCategory?: Maybe<SurfaceCategory>;
  surfaces?: Maybe<SurfaceResponse>;
  templateBoards?: Maybe<Array<GalleryBoard>>;
  userDocuments?: Maybe<UserDocumentsResponse>;
  userItems?: Maybe<UserItemsResponse>;
  users?: Maybe<UsersResponse>;
  usersExports?: Maybe<UsersExportResponse>;
};


export type QueryAdminGetParserMessagesArgs = {
  supplierId: Scalars['String']['input'];
};


export type QueryAdminGetParserStatusArgs = {
  serviceUrl: Scalars['String']['input'];
  supplierId: Scalars['String']['input'];
};


export type QueryBoardSettingArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};


export type QueryBrandsArgs = {
  categoriesIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryCategoryArgs = {
  id: Scalars['String']['input'];
};


export type QueryCategoryMetaArgs = {
  id: Scalars['String']['input'];
};


export type QueryColorsMetaArgs = {
  input?: InputMaybe<WhichMeta>;
};


export type QueryDocumentByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryFlaggedProductMessagesArgs = {
  productId: Scalars['ID']['input'];
};


export type QueryFloorplanArgs = {
  id: Scalars['String']['input'];
};


export type QueryFolderByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGalleryBoardByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGalleryBoardsArgs = {
  input?: InputMaybe<GalleryBoardsInput>;
};


export type QueryGalleryCountArgs = {
  input?: InputMaybe<GalleryBoardsInput>;
};


export type QueryGetRoomElementsArgs = {
  surfaceId: Scalars['String']['input'];
};


export type QueryMaterialByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryMaterialCategoryArgs = {
  id: Scalars['String']['input'];
};


export type QueryMaterialsArgs = {
  input?: InputMaybe<MaterialsQueryInput>;
};


export type QueryMaterialsMetaArgs = {
  input?: InputMaybe<WhichMeta>;
};


export type QueryMoodBoardByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryPatternsMetaArgs = {
  input?: InputMaybe<WhichMeta>;
};


export type QueryProItemsArgs = {
  input?: InputMaybe<ProItemsQueryInput>;
};


export type QueryProductByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryProductsArgs = {
  input?: InputMaybe<ProductsQueryInput>;
};


export type QueryProductsCountArgs = {
  input?: InputMaybe<ProductsQueryInput>;
};


export type QueryProductsCountMetaArgs = {
  input?: InputMaybe<ProductsQueryInputMeta>;
};


export type QueryProductsMetaArgs = {
  input?: InputMaybe<ProductsQueryInputMeta>;
};


export type QueryProductsMetaExtractArgs = {
  input?: InputMaybe<ProductsQueryInputMeta>;
};


export type QueryProfileByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryProfilesArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRoomsMetaArgs = {
  input?: InputMaybe<WhichMeta>;
};


export type QueryScanRoomElementsArgs = {
  confidence?: InputMaybe<Scalars['Float']['input']>;
  dilate?: InputMaybe<Scalars['Int']['input']>;
  granuality?: InputMaybe<Scalars['Int']['input']>;
  image_max_size?: InputMaybe<Scalars['Int']['input']>;
  mask_max_size?: InputMaybe<Scalars['Int']['input']>;
  url: Scalars['String']['input'];
};


export type QuerySearchHistoriesArgs = {
  input?: InputMaybe<SearchHistoriesQueryInput>;
};


export type QueryShapesMetaArgs = {
  input?: InputMaybe<WhichMeta>;
};


export type QueryShareDocumentByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryStylesMetaArgs = {
  input?: InputMaybe<WhichMeta>;
};


export type QuerySupplierByIdArgs = {
  id: Scalars['String']['input'];
};


export type QuerySupplierStatsArgs = {
  input?: InputMaybe<SupplierStatsQueryInput>;
};


export type QuerySuppliersArgs = {
  categoriesIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QuerySurfaceByIdArgs = {
  id: Scalars['String']['input'];
};


export type QuerySurfaceCategoryArgs = {
  id: Scalars['String']['input'];
};


export type QuerySurfacesArgs = {
  input?: InputMaybe<SurfacesQueryInput>;
};


export type QueryUserDocumentsArgs = {
  input?: InputMaybe<UserDocumentsQueryInput>;
};


export type QueryUserItemsArgs = {
  input?: InputMaybe<UserItemsQueryInput>;
};


export type QueryUsersArgs = {
  input?: InputMaybe<UsersQueryInput>;
};


export type QueryUsersExportsArgs = {
  input?: InputMaybe<UsersQueryInput>;
};

export type Room = {
  __typename?: 'Room';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type RoomElements = {
  __typename?: 'RoomElements';
  height?: Maybe<Scalars['Int']['output']>;
  segments?: Maybe<Array<Maybe<RoomElementsDataType>>>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type RoomElementsDataType = {
  __typename?: 'RoomElementsDataType';
  name?: Maybe<Scalars['String']['output']>;
  projectiveBottomLeft?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  projectiveBottomRight?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  projectiveTopLeft?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  projectiveTopRight?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  svgs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type RoomElementsInput = {
  height?: InputMaybe<Scalars['Int']['input']>;
  segments?: InputMaybe<Array<InputMaybe<RoomElementsInputDataType>>>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type RoomElementsInputDataType = {
  name?: InputMaybe<Scalars['String']['input']>;
  projectiveBottomLeft?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  projectiveBottomRight?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  projectiveTopLeft?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  projectiveTopRight?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  svgs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type RoomElementsMutationInput = {
  roomElements: RoomElementsInput;
  surfaceId: Scalars['String']['input'];
};

export type ScanRoomElementsResponse = {
  __typename?: 'ScanRoomElementsResponse';
  error: Scalars['String']['output'];
  svg: Array<Maybe<Scalars['String']['output']>>;
};

export type SearchHistoriesQueryInput = {
  limit: Scalars['Int']['input'];
  moreCategories: Scalars['Boolean']['input'];
  skip: Scalars['Int']['input'];
};

export type SearchHistoriesResponse = {
  __typename?: 'SearchHistoriesResponse';
  data?: Maybe<Array<SearchHistory>>;
  totalSearchHistories?: Maybe<Scalars['Int']['output']>;
};

export type SearchHistory = {
  __typename?: 'SearchHistory';
  brands?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  categories?: Maybe<Array<Maybe<CategoryMeta>>>;
  colors?: Maybe<Array<Maybe<CategoryMeta>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  materials?: Maybe<Array<Maybe<CategoryMeta>>>;
  patterns?: Maybe<Array<Maybe<CategoryMeta>>>;
  priceRange?: Maybe<Scalars['JSONObject']['output']>;
  rooms?: Maybe<Array<Maybe<CategoryMeta>>>;
  searchPhrase?: Maybe<Scalars['String']['output']>;
  shapes?: Maybe<Array<Maybe<CategoryMeta>>>;
  styles?: Maybe<Array<Maybe<CategoryMeta>>>;
  suppliers?: Maybe<Array<Maybe<Supplier>>>;
  user: User;
};

export type SearchHistoryInput = {
  brands?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  colors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  materials?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  patterns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  priceRange?: InputMaybe<Scalars['JSONObject']['input']>;
  rooms?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  searchPhrase?: InputMaybe<Scalars['String']['input']>;
  shapes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  styles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  suppliers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Session = {
  __typename?: 'Session';
  user?: Maybe<User>;
};

export type SetupStatusResponse = {
  __typename?: 'SetupStatusResponse';
  createdFirstProject?: Maybe<Scalars['Boolean']['output']>;
  introVideoWatched?: Maybe<Scalars['Boolean']['output']>;
  visitedExampleProject?: Maybe<Scalars['Boolean']['output']>;
};

export type Shape = {
  __typename?: 'Shape';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ShareBoard = {
  __typename?: 'ShareBoard';
  createdBy: User;
  height?: Maybe<Scalars['Int']['output']>;
  hideProductList?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  index?: Maybe<Scalars['Int']['output']>;
  json?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paints?: Maybe<Array<MoodboardPaint>>;
  proItems?: Maybe<Array<MoodboardProItem>>;
  proSettings?: Maybe<BoardProSettings>;
  products?: Maybe<Array<MoodboardProduct>>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type ShareDocument = {
  __typename?: 'ShareDocument';
  createdBy: User;
  id: Scalars['ID']['output'];
  moodBoards?: Maybe<Array<ShareBoard>>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum SortOption {
  Name = 'name',
  PriceHigh = 'priceHigh',
  PriceLow = 'priceLow',
  UpdatedAt = 'updatedAt'
}

export type Style = {
  __typename?: 'Style';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  userChanged?: Maybe<SubscriptionResult>;
};

export type SubscriptionResult = {
  __typename?: 'SubscriptionResult';
  id?: Maybe<Scalars['String']['output']>;
};

export type Supplier = {
  __typename?: 'Supplier';
  UTMTag?: Maybe<Scalars['String']['output']>;
  affiliate?: Maybe<Affiliate>;
  affiliateUrl?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  isParsing?: Maybe<Scalars['Boolean']['output']>;
  lastParsed?: Maybe<Scalars['DateTime']['output']>;
  lastParsedCategory?: Maybe<Scalars['DateTime']['output']>;
  lastParsedColor?: Maybe<Scalars['DateTime']['output']>;
  lastParsedRemoveAi?: Maybe<Scalars['DateTime']['output']>;
  lastParsedUtm?: Maybe<Scalars['DateTime']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parserAcceptedCategories?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  parserAction?: Maybe<Scalars['String']['output']>;
  parserCategories?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  parserKeepBG?: Maybe<Scalars['Boolean']['output']>;
  products?: Maybe<Array<Product>>;
  website?: Maybe<Scalars['String']['output']>;
};

export type SupplierInput = {
  UTMTag?: InputMaybe<Scalars['String']['input']>;
  affiliate?: InputMaybe<Scalars['ID']['input']>;
  affiliateUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['Upload']['input']>;
  name: Scalars['String']['input'];
  parserKeepBG?: InputMaybe<Scalars['Boolean']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type SupplierStats = {
  __typename?: 'SupplierStats';
  supplier?: Maybe<Supplier>;
  totalActiveProducts: Scalars['Int']['output'];
  totalExpiredProducts: Scalars['Int']['output'];
  totalHiddenProducts: Scalars['Int']['output'];
  totalParsedProducts: Scalars['Int']['output'];
  totalProducts: Scalars['Int']['output'];
};

export type SupplierStatsQueryInput = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
  sortBy: Scalars['String']['input'];
};

export type SupplierStatsResponse = {
  __typename?: 'SupplierStatsResponse';
  supplierStats?: Maybe<Array<SupplierStats>>;
  totalStats: SupplierStats;
  totalSuppliers: Scalars['Int']['output'];
};

export type Surface = {
  __typename?: 'Surface';
  categories?: Maybe<Array<Maybe<SurfaceCategory>>>;
  colors?: Maybe<Array<Maybe<Color>>>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
  roomElements?: Maybe<RoomElements>;
};

export type SurfaceCategory = {
  __typename?: 'SurfaceCategory';
  children?: Maybe<Array<Maybe<SurfaceCategory>>>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  parent?: Maybe<SurfaceCategory>;
};

export type SurfaceCategoryInput = {
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
};

export type SurfaceEditInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  colors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  productImage?: InputMaybe<Scalars['Upload']['input']>;
  productImageNoBg?: InputMaybe<Scalars['Upload']['input']>;
};

export type SurfaceInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  colors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Array<Scalars['Upload']['input']>>;
  name: Scalars['String']['input'];
};

export type SurfaceResponse = {
  __typename?: 'SurfaceResponse';
  data?: Maybe<Array<Surface>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SurfacesQueryInput = {
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  colorIds?: InputMaybe<Array<Scalars['String']['input']>>;
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
};

export type TokensResponse = {
  __typename?: 'TokensResponse';
  accessToken?: Maybe<Scalars['Token']['output']>;
  refreshToken?: Maybe<Scalars['Token']['output']>;
};

export type UpdateSetupStatusInput = {
  createdFirstProject?: InputMaybe<Scalars['Boolean']['input']>;
  introVideoWatched?: InputMaybe<Scalars['Boolean']['input']>;
  visitedExampleProject?: InputMaybe<Scalars['Boolean']['input']>;
};

export type User = {
  __typename?: 'User';
  betaProgram?: Maybe<Array<Scalars['String']['output']>>;
  boardSetting?: Maybe<BoardSetting>;
  company?: Maybe<Scalars['String']['output']>;
  countCustomProducts?: Maybe<Scalars['Int']['output']>;
  countDesigns?: Maybe<Scalars['Int']['output']>;
  countSharedDesigns?: Maybe<Scalars['Int']['output']>;
  countUploadedImages?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  documents?: Maybe<Array<Document>>;
  email?: Maybe<Scalars['String']['output']>;
  favoriteBoards?: Maybe<Array<GalleryBoard>>;
  favoriteMaterials?: Maybe<Array<Material>>;
  favoriteProducts?: Maybe<Array<Product>>;
  favoriteProfiles?: Maybe<Array<Profile>>;
  favoriteSurfaces?: Maybe<Array<Surface>>;
  firstName?: Maybe<Scalars['String']['output']>;
  folders?: Maybe<Array<Folder>>;
  galleryBoards?: Maybe<Array<GalleryBoard>>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isValidated?: Maybe<Scalars['Boolean']['output']>;
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  moodBoards?: Maybe<Array<MoodBoard>>;
  phone?: Maybe<Scalars['String']['output']>;
  proItems?: Maybe<Array<ProItem>>;
  profile?: Maybe<Profile>;
  role?: Maybe<UserRole>;
  setupStatus?: Maybe<SetupStatusResponse>;
  subscription?: Maybe<PaymentSubscription>;
  termsAccepted?: Maybe<Scalars['Boolean']['output']>;
  trialActivatedAt?: Maybe<Scalars['DateTime']['output']>;
  trialEndViewShownAt?: Maybe<Scalars['DateTime']['output']>;
  userItems?: Maybe<Array<UserItem>>;
  userName?: Maybe<Scalars['String']['output']>;
  usingBoni?: Maybe<UsingBoniOption>;
  usingRole?: Maybe<UsingRole>;
  verificationLinkEmailedAt?: Maybe<Scalars['DateTime']['output']>;
  verificationLinkShownAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserDocumentsQueryInput = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
};

export type UserDocumentsResponse = {
  __typename?: 'UserDocumentsResponse';
  documents?: Maybe<Array<Document>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type UserItem = {
  __typename?: 'UserItem';
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Scalars['String']['output']>>;
  user: User;
};

export type UserItemsQueryInput = {
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
};

export type UserItemsResponse = {
  __typename?: 'UserItemsResponse';
  data?: Maybe<Array<UserItem>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum UserRole {
  Admin = 'ADMIN',
  User = 'USER'
}

export type UserSignupInput = {
  company?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['Upload']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  termsAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  urlProps?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  usingBoni?: InputMaybe<UsingBoniOption>;
  usingRole?: InputMaybe<UsingRole>;
};

export type UsersExportResponse = {
  __typename?: 'UsersExportResponse';
  data?: Maybe<Array<User>>;
  totalUsers?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type UsersQueryInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  skip: Scalars['Int']['input'];
};

export type UsersResponse = {
  __typename?: 'UsersResponse';
  data?: Maybe<Array<User>>;
  totalUsers?: Maybe<Scalars['Int']['output']>;
};

export enum UsingBoniOption {
  Noanswer = 'NOANSWER',
  Private = 'PRIVATE',
  Professionally = 'PROFESSIONALLY',
  Student = 'STUDENT'
}

export enum UsingRole {
  Interiorarchitect = 'INTERIORARCHITECT',
  Interiordesigner = 'INTERIORDESIGNER',
  Other = 'OTHER',
  Store = 'STORE',
  Student = 'STUDENT',
  Stylist = 'STYLIST'
}

export type ValidateSession = {
  __typename?: 'ValidateSession';
  id: Scalars['ID']['output'];
  role: UserRole;
};

export type WhichMeta = {
  feature?: InputMaybe<Scalars['Int']['input']>;
};

export type UserDocumentsQueryVariables = Exact<{
  input?: InputMaybe<UserDocumentsQueryInput>;
}>;


export type UserDocumentsQuery = { __typename?: 'Query', userDocuments?: { __typename?: 'UserDocumentsResponse', total?: number | null, documents?: Array<{ __typename?: 'Document', id: string, name?: string | null, image?: string | null, updatedAt?: any | null, moodBoards?: Array<{ __typename?: 'MoodBoard', id: string, name?: string | null, image?: string | null, updatedAt?: any | null }> | null }> | null } | null };

export type FloorplanQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type FloorplanQuery = { __typename?: 'Query', floorplan?: { __typename?: 'Floorplan', id: string, name?: string | null, json?: string | null, image?: string | null, thumbnail?: string | null } | null };

export type FloorplansQueryVariables = Exact<{ [key: string]: never; }>;


export type FloorplansQuery = { __typename?: 'Query', floorplans?: Array<{ __typename?: 'Floorplan', id: string, name?: string | null, json?: string | null, image?: string | null, thumbnail?: string | null } | null> | null };

export type EditFloorplanMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  json?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['Upload']['input']>;
}>;


export type EditFloorplanMutation = { __typename?: 'Mutation', editFloorplan: { __typename?: 'Floorplan', id: string, name?: string | null, json?: string | null, image?: string | null, thumbnail?: string | null } };

export type DeleteFloorplanMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteFloorplanMutation = { __typename?: 'Mutation', deleteFloorplan: { __typename?: 'Floorplan', id: string } };

export type ValidateSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type ValidateSessionMutation = { __typename?: 'Mutation', validateSession?: { __typename?: 'ValidateSession', id: string, role: UserRole } | null };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: string, email?: string | null, role?: UserRole | null } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type RefreshTokensMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshTokensMutation = { __typename?: 'Mutation', refreshTokens: { __typename?: 'TokensResponse', accessToken?: any | null, refreshToken?: any | null } };


export const UserDocumentsDocument = gql`
    query userDocuments($input: UserDocumentsQueryInput) {
  userDocuments(input: $input) {
    documents {
      id
      name
      image
      updatedAt
      moodBoards {
        id
        name
        image
        updatedAt
      }
    }
    total
  }
}
    `;

/**
 * __useUserDocumentsQuery__
 *
 * To run a query within a React component, call `useUserDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDocumentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<UserDocumentsQuery, UserDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserDocumentsQuery, UserDocumentsQueryVariables>(UserDocumentsDocument, options);
      }
export function useUserDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserDocumentsQuery, UserDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserDocumentsQuery, UserDocumentsQueryVariables>(UserDocumentsDocument, options);
        }
export function useUserDocumentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserDocumentsQuery, UserDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserDocumentsQuery, UserDocumentsQueryVariables>(UserDocumentsDocument, options);
        }
export type UserDocumentsQueryHookResult = ReturnType<typeof useUserDocumentsQuery>;
export type UserDocumentsLazyQueryHookResult = ReturnType<typeof useUserDocumentsLazyQuery>;
export type UserDocumentsSuspenseQueryHookResult = ReturnType<typeof useUserDocumentsSuspenseQuery>;
export type UserDocumentsQueryResult = Apollo.QueryResult<UserDocumentsQuery, UserDocumentsQueryVariables>;
export const FloorplanDocument = gql`
    query Floorplan($id: String!) {
  floorplan(id: $id) {
    id
    name
    json
    image
    thumbnail
  }
}
    `;

/**
 * __useFloorplanQuery__
 *
 * To run a query within a React component, call `useFloorplanQuery` and pass it any options that fit your needs.
 * When your component renders, `useFloorplanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFloorplanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFloorplanQuery(baseOptions: Apollo.QueryHookOptions<FloorplanQuery, FloorplanQueryVariables> & ({ variables: FloorplanQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FloorplanQuery, FloorplanQueryVariables>(FloorplanDocument, options);
      }
export function useFloorplanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FloorplanQuery, FloorplanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FloorplanQuery, FloorplanQueryVariables>(FloorplanDocument, options);
        }
export function useFloorplanSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FloorplanQuery, FloorplanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FloorplanQuery, FloorplanQueryVariables>(FloorplanDocument, options);
        }
export type FloorplanQueryHookResult = ReturnType<typeof useFloorplanQuery>;
export type FloorplanLazyQueryHookResult = ReturnType<typeof useFloorplanLazyQuery>;
export type FloorplanSuspenseQueryHookResult = ReturnType<typeof useFloorplanSuspenseQuery>;
export type FloorplanQueryResult = Apollo.QueryResult<FloorplanQuery, FloorplanQueryVariables>;
export const FloorplansDocument = gql`
    query Floorplans {
  floorplans {
    id
    name
    json
    image
    thumbnail
  }
}
    `;

/**
 * __useFloorplansQuery__
 *
 * To run a query within a React component, call `useFloorplansQuery` and pass it any options that fit your needs.
 * When your component renders, `useFloorplansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFloorplansQuery({
 *   variables: {
 *   },
 * });
 */
export function useFloorplansQuery(baseOptions?: Apollo.QueryHookOptions<FloorplansQuery, FloorplansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FloorplansQuery, FloorplansQueryVariables>(FloorplansDocument, options);
      }
export function useFloorplansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FloorplansQuery, FloorplansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FloorplansQuery, FloorplansQueryVariables>(FloorplansDocument, options);
        }
export function useFloorplansSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FloorplansQuery, FloorplansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FloorplansQuery, FloorplansQueryVariables>(FloorplansDocument, options);
        }
export type FloorplansQueryHookResult = ReturnType<typeof useFloorplansQuery>;
export type FloorplansLazyQueryHookResult = ReturnType<typeof useFloorplansLazyQuery>;
export type FloorplansSuspenseQueryHookResult = ReturnType<typeof useFloorplansSuspenseQuery>;
export type FloorplansQueryResult = Apollo.QueryResult<FloorplansQuery, FloorplansQueryVariables>;
export const EditFloorplanDocument = gql`
    mutation editFloorplan($id: ID!, $name: String, $json: String, $image: Upload) {
  editFloorplan(input: {id: $id, name: $name, json: $json, image: $image}) {
    id
    name
    json
    image
    thumbnail
  }
}
    `;
export type EditFloorplanMutationFn = Apollo.MutationFunction<EditFloorplanMutation, EditFloorplanMutationVariables>;

/**
 * __useEditFloorplanMutation__
 *
 * To run a mutation, you first call `useEditFloorplanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFloorplanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFloorplanMutation, { data, loading, error }] = useEditFloorplanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      json: // value for 'json'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useEditFloorplanMutation(baseOptions?: Apollo.MutationHookOptions<EditFloorplanMutation, EditFloorplanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditFloorplanMutation, EditFloorplanMutationVariables>(EditFloorplanDocument, options);
      }
export type EditFloorplanMutationHookResult = ReturnType<typeof useEditFloorplanMutation>;
export type EditFloorplanMutationResult = Apollo.MutationResult<EditFloorplanMutation>;
export type EditFloorplanMutationOptions = Apollo.BaseMutationOptions<EditFloorplanMutation, EditFloorplanMutationVariables>;
export const DeleteFloorplanDocument = gql`
    mutation deleteFloorplan($id: String!) {
  deleteFloorplan(id: $id) {
    id
  }
}
    `;
export type DeleteFloorplanMutationFn = Apollo.MutationFunction<DeleteFloorplanMutation, DeleteFloorplanMutationVariables>;

/**
 * __useDeleteFloorplanMutation__
 *
 * To run a mutation, you first call `useDeleteFloorplanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFloorplanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFloorplanMutation, { data, loading, error }] = useDeleteFloorplanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFloorplanMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFloorplanMutation, DeleteFloorplanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFloorplanMutation, DeleteFloorplanMutationVariables>(DeleteFloorplanDocument, options);
      }
export type DeleteFloorplanMutationHookResult = ReturnType<typeof useDeleteFloorplanMutation>;
export type DeleteFloorplanMutationResult = Apollo.MutationResult<DeleteFloorplanMutation>;
export type DeleteFloorplanMutationOptions = Apollo.BaseMutationOptions<DeleteFloorplanMutation, DeleteFloorplanMutationVariables>;
export const ValidateSessionDocument = gql`
    mutation validateSession {
  validateSession {
    id
    role
  }
}
    `;
export type ValidateSessionMutationFn = Apollo.MutationFunction<ValidateSessionMutation, ValidateSessionMutationVariables>;

/**
 * __useValidateSessionMutation__
 *
 * To run a mutation, you first call `useValidateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateSessionMutation, { data, loading, error }] = useValidateSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useValidateSessionMutation(baseOptions?: Apollo.MutationHookOptions<ValidateSessionMutation, ValidateSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateSessionMutation, ValidateSessionMutationVariables>(ValidateSessionDocument, options);
      }
export type ValidateSessionMutationHookResult = ReturnType<typeof useValidateSessionMutation>;
export type ValidateSessionMutationResult = Apollo.MutationResult<ValidateSessionMutation>;
export type ValidateSessionMutationOptions = Apollo.BaseMutationOptions<ValidateSessionMutation, ValidateSessionMutationVariables>;
export const CurrentUserDocument = gql`
    query currentUser {
  currentUser {
    id
    email
    role
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export function useCurrentUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserSuspenseQueryHookResult = ReturnType<typeof useCurrentUserSuspenseQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RefreshTokensDocument = gql`
    mutation RefreshTokens {
  refreshTokens {
    accessToken
    refreshToken
  }
}
    `;
export type RefreshTokensMutationFn = Apollo.MutationFunction<RefreshTokensMutation, RefreshTokensMutationVariables>;

/**
 * __useRefreshTokensMutation__
 *
 * To run a mutation, you first call `useRefreshTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokensMutation, { data, loading, error }] = useRefreshTokensMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokensMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokensMutation, RefreshTokensMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokensMutation, RefreshTokensMutationVariables>(RefreshTokensDocument, options);
      }
export type RefreshTokensMutationHookResult = ReturnType<typeof useRefreshTokensMutation>;
export type RefreshTokensMutationResult = Apollo.MutationResult<RefreshTokensMutation>;
export type RefreshTokensMutationOptions = Apollo.BaseMutationOptions<RefreshTokensMutation, RefreshTokensMutationVariables>;