import { invalidate, ThreeEvent, useThree } from "@react-three/fiber";
import { editorStore } from "../../store/editorStore";
import { floorplannerStore, FloorplannerStore } from "../../store/floorplannerStore";
import { WallConnectionEnd, WallConnectionStart, WallShapeType, WallType } from "../../types/wallTypes";
import { MeasurementLine } from "./MeasurementLine";
import { MiddleHHandle } from "./MiddleHHandle";
import { MiddleVHandle } from "./MiddleVHandle";
import { useCallback, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { updateDragHandles } from "./updateDragHandles";
import { projectToWorld } from "./projectToWorld";
import { action, set } from "mobx";
import { dragStartHandle } from "./dragStartHandle";
import { dragEndHandle } from "./dragEndHandle";
import { dragMiddleVHandle } from "./dragMiddleVHandle";
import { dragMiddleHHandle } from "./dragMiddleHHandle";
import { dragWallHandle } from "./dragWallHandle";
import { dragControlHandle } from "./dragControlHandle";
import { updateAlignmentLines } from "./updateAligmentLines";
import { updateWallClippings, updateWallConnections } from "./updateWallConnections";
import { handleSize, handleWidth } from "./createMiddleDragHandle";
import { useDragSelectedObjects } from "../../hooks/useDragSelectedObjects";
import { disposeMeshes } from "./disposeMeshes";
import { fillMeshWall } from "./fillMeshWalls";
import { observer } from "mobx-react-lite";
import { renderStore } from "../../store/renderStore";

interface WallShapeProps {
    wall: WallType;
}

export const WallShape = observer((props: WallShapeProps) => {
    const {
        wall,
    } = props;
    const {
        wallsMap,
        wallWidth,
        hoverColor,
        lineColor,
        fillColor,
    } = floorplannerStore;
    const { scene, camera, gl } = useThree();
    const dragHandle = useRef<{
        handle: string;
        startPosition: THREE.Vector3;
        endPosition: THREE.Vector3;
    }>({
        handle: "",
        startPosition: new THREE.Vector3(),
        endPosition: new THREE.Vector3(),
    });
    const dragWall = useRef<WallType | null>(null);
    const dragOffset = useRef<[number, number, number, number] | null>(null);
    const wallMinMidHandleSize = editorStore.zoomLevel < 3 ? Math.max(0.15, 0.15 / editorStore.zoomLevelDivisor()) : 0.1;
    const [dragEndFreeToConnect, setDragEndFreeToConnect] = useState(false);
    const [hoveredHandle, setHoveredHandle] = useState<string | null>(null);
    const isHovered = (handle: string) => hoveredHandle === handle;
    const noopRaycast = () => null;
    const { startDraggingGroupSelection, isDraggingGroupSelection } = useDragSelectedObjects(gl, camera);
    const [meshWall, setMeshWall] = useState<THREE.Group>(new THREE.Group());
    const aboutToDrag = useRef(false);

    const onWallSelectionPointerMove = useCallback(
        action((e: PointerEvent) => {
            if (dragWall.current !== null && dragOffset.current && editorStore.selections.length === 1) {
                if (aboutToDrag.current) {
                    floorplannerStore.pushToUndoStack();
                    aboutToDrag.current = false;
                }
                if (!editorStore.wallDragging) editorStore.setWallDragging(dragWall.current.id);
                let [newX, newY] = projectToWorld(e.clientX, e.clientY, gl, camera);
                const [startOffsetX, startOffsetY, endOffsetX, endOffsetY] =
                    dragOffset.current;
                const wall = dragWall.current;

                // Update alignment lines based on the handle type
                updateAlignmentLines(
                    wall.id,
                    newX,
                    newY,
                    dragHandle.current.handle,
                );

                let delta = new THREE.Vector2(0, 0);
                let snapped = false;
                if (dragHandle.current.handle === "start") {
                    snapped = dragStartHandle(wall, startOffsetX, startOffsetY, newX, newY, dragEndFreeToConnect, delta);
                } else if (dragHandle.current.handle === "end") {
                    snapped = dragEndHandle(wall, endOffsetX, endOffsetY, newX, newY, dragEndFreeToConnect, delta);
                } else if (dragHandle.current.handle === "middleV") {
                    snapped = dragMiddleVHandle(wall, startOffsetX, startOffsetY, newX, newY, delta);
                } else if (dragHandle.current.handle === "middleH") {
                    snapped = dragMiddleHHandle(wall, startOffsetX, startOffsetY, newX, newY, delta);
                } else if (dragHandle.current.handle === "wall") {
                    snapped = dragWallHandle(wall, startOffsetX, startOffsetY, endOffsetX, endOffsetY, newX, newY, delta);
                } else if (dragHandle.current.handle === "control") {
                    dragControlHandle(wall, newX, newY);
                }
                updateDragHandles(dragHandle.current?.handle || "", wall, dragHandle);

                if (!delta.equals(new THREE.Vector2(0, 0))) {
                    updateWallConnections(
                        wall.id,
                        dragHandle.current?.handle || null,
                        [wall.id],
                        floorplannerStore
                    );
                }
            }
        }),
        [editorStore.wallDragging, projectToWorld, updateDragHandles, wallsMap, camera.zoom, dragEndFreeToConnect],
    );

    const onWallSelectionPointerUp = useCallback(() => {
        aboutToDrag.current = false;
        // Update wall connections
        if (dragWall.current) {
            dragWall.current.connections?.forEach((c) => {
                const object = floorplannerStore.findObjectId(c.id);
                if (object) {
                    floorplannerStore.objectNeedsUpdate(object.id);
                }
            })
        }
        dragWall.current = null;
        dragOffset.current = null;
        dragHandle.current.handle = "";
        editorStore.setWallDragging(null);
        // Change cursor back to auto when dragging is done
        document.body.style.cursor = "auto";
        gl.domElement.removeEventListener("pointermove", onWallSelectionPointerMove);
        gl.domElement.removeEventListener("pointerup", onWallSelectionPointerUp);
        floorplannerStore.setBlockDirty(false);
        editorStore.setWhichEndToUpdate(undefined);
    }, [gl.domElement, onWallSelectionPointerMove]);

    const onWallSelectionPointerDown = useCallback(
        (
            event: ThreeEvent<PointerEvent>,
            handle: "start" | "end" | "middleV" | "middleH" | "control",
            id: string
        ) => {
            event.stopPropagation();
            const wall = wallsMap.get(id);
            if (!wall) return;
            aboutToDrag.current = true;
            dragWall.current = wall;
            updateDragHandles(handle, wall, dragHandle);
            const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
            let offsetX, offsetY;

            if (handle === "middleV" || handle === "middleH" || handle === "control") {
                const midpointX = (wall.start.x + wall.end.x) / 2;
                const midpointY = (wall.start.y + wall.end.y) / 2;
                offsetX = worldX - midpointX;
                offsetY = worldY - midpointY;
            } else {
                offsetX = worldX - (handle === "start" ? wall.start.x : wall.end.x);
                offsetY = worldY - (handle === "start" ? wall.start.y : wall.end.y);
            }
            if (handle === "start" || handle === "end") {
                const isWallEdgeConnected = wall.connections?.find(
                    (c) => (c.targetPosition === WallConnectionStart || c.targetPosition === WallConnectionEnd) &&
                        c.sourcePosition === (handle === 'start' ? WallConnectionStart : WallConnectionEnd),
                );
                setDragEndFreeToConnect(!isWallEdgeConnected);
            }

            dragOffset.current = [offsetX, offsetY, offsetX, offsetY];
            if (!editorStore.isShiftPressed && editorStore.selections.find((s) => s.id === wall.id) === undefined) {
                editorStore.clearSelections();
            }
            editorStore.addSelection(wall);
            floorplannerStore.selectWall(wall.id);
            gl.domElement.addEventListener("pointermove", onWallSelectionPointerMove);
            gl.domElement.addEventListener("pointerup", onWallSelectionPointerUp);
            floorplannerStore.setBlockDirty(true);
        },
        [
            wallsMap,
            updateDragHandles,
            projectToWorld,
            gl.domElement,
            onWallSelectionPointerMove,
            onWallSelectionPointerUp,
            camera.zoom
        ],
    );

    const onWallPointerEnter = useCallback(
        (event: ThreeEvent<PointerEvent>, id: string) => {
            event.stopPropagation();
            // Change cursor to grab when hovering over a wall
            if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.add("cursor-vector");;
            let updated = false;

            // Get the wall data to check if it's selected
            //const wallData = walls[Object.keys(walls)[index]];

            // Iterate through the wall's children and apply hoverColor to lines
            meshWall.children.forEach((child) => {
                const mesh = child as THREE.Mesh;
                if (child.userData.type === "line") {
                    const currentColor = (mesh.material as THREE.MeshBasicMaterial).color.getHex();

                    // Always apply hoverColor when hovered, whether selected or not
                    if (currentColor !== hoverColor) {
                        (mesh.material as THREE.MeshBasicMaterial).color.set(hoverColor);
                        updated = true;
                    }
                }
            });
            if (updated) {
                invalidate();
            }
        },
        [hoverColor, meshWall, wallsMap],
    );

    const onWallPointerLeave = useCallback(
        (event: ThreeEvent<PointerEvent>, id: string) => {
            event.stopPropagation();
            // Reset cursor when leaving a wall
            if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.remove("cursor-vector");;
            let updated = false;

            // Get the wall data to check if it's selected
            //const wallData = walls[Object.keys(walls)[index]];
            const wallData = wallsMap.get(id);

            meshWall.children.forEach((child) => {
                const mesh = child as THREE.Mesh;
                if (child.userData.type === "line") {
                    const currentColor = (mesh.material as THREE.MeshBasicMaterial).color.getHex();

                    // If the wall is selected, keep the hoverColor
                    // Otherwise, revert back to the default line color
                    //const newColor = wallData?.selected ? hoverColor : (walls[Object.keys(walls)[index]]?.lineColor || lineColor);
                    const newColor = wallData?.selected ? hoverColor : (wallData?.lineColor || lineColor);

                    if (currentColor !== newColor) {
                        (mesh.material as THREE.MeshBasicMaterial).color.set(newColor);
                        updated = true;
                    }
                }
            });
            if (updated) {
                invalidate();
            }
        },
        [meshWall, wallsMap, lineColor, hoverColor],
    );

    const onWallPointerDown = useCallback(
        (event: ThreeEvent<PointerEvent>, id: string) => {
            event.stopPropagation();
            aboutToDrag.current = true;
            if (editorStore.selections.length > 1) {
                startDraggingGroupSelection(event);
            }
            // Change cursor to grabbing when dragging a wall
            document.body.style.cursor = "grabbing";
            const wall = wallsMap.get(id);
            if (!wall) return
            dragWall.current = wall;
            updateDragHandles(dragHandle.current?.handle || "wall", wall, dragHandle);
            const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
            const startOffsetX = worldX - wall.start.x;
            const startOffsetY = worldY - wall.start.y;
            const endOffsetX = worldX - wall.end.x;
            const endOffsetY = worldY - wall.end.y;
            dragOffset.current = [startOffsetX, startOffsetY, endOffsetX, endOffsetY];
            if (!editorStore.isShiftPressed && editorStore.selections.find((s) => s.id === wall.id) === undefined) {
                editorStore.clearSelections();
            }
            editorStore.addSelection(wall);
            floorplannerStore.selectWall(wall.id);

            // Ensure the wall keeps hoverColor when selected or dragged
            meshWall.children.forEach((child) => {
                const mesh = child as THREE.Mesh;
                if (child.userData.type === "line") {
                    (mesh.material as THREE.MeshBasicMaterial).color.set(hoverColor);
                }
            });

            floorplannerStore.setBlockDirty(true);
            gl.domElement.addEventListener("pointermove", onWallSelectionPointerMove);
            gl.domElement.addEventListener("pointerup", onWallSelectionPointerUp);
        },
        [
            wallsMap,
            updateDragHandles,
            projectToWorld,
            gl.domElement,
            onWallSelectionPointerDown,
            onWallSelectionPointerUp,
            meshWall,
            hoverColor,
            camera.zoom
        ],
    );

    useEffect(() => {
        // Dispose the previous mesh walls as a post job to prevent blocking the main thread
        if ('requestIdleCallback' in window) {
            requestIdleCallback(() => {
                disposeMeshes(meshWall);
                meshWall.clear();
            });
        } else {
            // Fallback to setTimeout for older browsers
            setTimeout(() => {
                disposeMeshes(meshWall);
                meshWall.clear();
            }, 100);
        }

        // Fetch render cache for the wall
        const innerShapes = renderStore.updateShapes(wall.id, scene);
        const outline1 = renderStore.outline1WallShapesMap.get(wall.id);
        const outline2 = renderStore.outline2WallShapesMap.get(wall.id);
        const endCaps = renderStore.endCapsWallShapesMap.get(wall.id);
        if (!innerShapes || !outline1 || !outline2 || !endCaps) return
        
        // Create a new mesh wall group and fill it with the wall shapes
        const newMeshWall: THREE.Group = new THREE.Group();
        fillMeshWall(wall, innerShapes, outline1, outline2, endCaps, newMeshWall, floorplannerStore, scene);
        setMeshWall(newMeshWall);

        return () => {
            if ('requestIdleCallback' in window) {
                requestIdleCallback(() => {
                    disposeMeshes(meshWall);
                    meshWall.clear();
                });
            } else {
                setTimeout(() => {
                    disposeMeshes(meshWall);
                    meshWall.clear();
                }, 100);
            }
        };
    }, [wall, wall.start, wall.end, wallWidth, wall.lastUpdate, lineColor, fillColor, disposeMeshes, scene, camera.zoom]);

    return (
        <group
            key={`group-wall-${meshWall.userData.id}`}
            onPointerEnter={!editorStore.wallConstructionMode && !editorStore.lineConstructionMode && !editorStore.rulerConstructionMode ? (event) => onWallPointerEnter(event, wall.id) : undefined}
            onPointerLeave={!editorStore.wallConstructionMode && !editorStore.lineConstructionMode && !editorStore.rulerConstructionMode ? (event) => onWallPointerLeave(event, wall.id) : undefined}
            onPointerDown={!editorStore.wallConstructionMode && !editorStore.lineConstructionMode && !editorStore.rulerConstructionMode ? (event) => onWallPointerDown(event, wall.id) : undefined}
        >
            <primitive object={meshWall} />
            {!wall.hideMeasurement && (editorStore.showMeasures ||
                wall?.selected ||
                editorStore.wallEditingOuterLength === wall.id ||
                editorStore.wallEditingInnerLength === wall.id ||
                (editorStore.selections.length && editorStore.selections[0].id === wall.id)) && (
                <MeasurementLine
                    object={wall}
                />
            )}
            {(wall.selected && !editorStore.wallConstructionMode && !editorStore.lineConstructionMode && !editorStore.rulerConstructionMode && !editorStore.areaConstructionMode && editorStore.selections.length === 1) && (
                <group>
                    {/* Middle, start, and end handles */}
                    {(editorStore.wallDragging !== wall.id || dragHandle.current?.handle === "middleV") && (
                        <MiddleVHandle
                            wall={wall}
                            wallWidth={Math.max(wallMinMidHandleSize, wallWidth)}
                            onHandlePointerDown={(event: ThreeEvent<PointerEvent>) => onWallSelectionPointerDown(event, "middleV", wall.id)}
                            onHandlePointerEnter={(event: ThreeEvent<PointerEvent>) => {
                                event.stopPropagation();
                                document.body.classList.add("cursor-vector")
                                setHoveredHandle("middleV")
                            }}
                            onHandlePointerLeave={(event: ThreeEvent<PointerEvent>) => {
                                event.stopPropagation();
                                if (editorStore.wallDragging !== wall.id) document.body.classList.remove("cursor-vector")
                                setHoveredHandle(null)
                            }}
                            fillColor={isHovered("middleV") || dragHandle.current?.handle === "middleV" ? "blue" : "white"}
                            color={isHovered("middleV") || dragHandle.current?.handle === "middleV" ? "white" : "black"}
                        />
                    )}
                    {(editorStore.wallDragging !== wall.id || dragHandle.current?.handle === "middleH") && (
                        <MiddleHHandle
                            wall={wall}
                            wallWidth={Math.max(wallMinMidHandleSize, wallWidth)}
                            onHandlePointerDown={(event: ThreeEvent<PointerEvent>) => onWallSelectionPointerDown(event, "middleH", wall.id)}
                            onHandlePointerEnter={(event: ThreeEvent<PointerEvent>) => {
                                event.stopPropagation();
                                document.body.classList.add("cursor-vector")
                                setHoveredHandle("middleH")
                            }}
                            onHandlePointerLeave={(event: ThreeEvent<PointerEvent>) => {
                                event.stopPropagation();
                                if (editorStore.wallDragging !== wall.id) document.body.classList.remove("cursor-vector")
                                setHoveredHandle(null)
                            }}
                            fillColor={isHovered("middleH") || dragHandle.current?.handle === "middleH" ? "blue" : "white"}
                            color={isHovered("middleH") || dragHandle.current?.handle === "middleH" ? "white" : "black"}
                        />
                    )}
                    {(editorStore.wallDragging !== wall.id || dragHandle.current?.handle === "start") && (
                        <group
                            position={new THREE.Vector3(wall.start.x, wall.start.y, 0.001)}
                            onPointerDown={(event) => onWallSelectionPointerDown(event, "start", wall.id)}
                            onPointerEnter={(event) => {
                                event.stopPropagation();
                                document.body.style.cursor = "move";
                                setHoveredHandle("start");
                            }}
                            onPointerLeave={(event) => {
                                event.stopPropagation();
                                if (editorStore.wallDragging !== wall.id) document.body.style.cursor = "auto";
                                setHoveredHandle(null);
                            }}
                        >
                            <mesh>
                                <planeGeometry args={[(handleWidth * 2), (handleWidth * 2)]} />
                                <meshBasicMaterial
                                    // color="white"
                                    transparent
                                    opacity={0.0}
                                />
                            </mesh>
                            <mesh raycast={noopRaycast}>
                                <torusGeometry args={[handleSize / editorStore.zoomLevelDivisor() / 1.8, 0.003, 16, 100]} />
                                <meshBasicMaterial color="blue" />
                            </mesh>
                            <mesh
                                position={[0, 0, 0]}
                                rotation={[Math.PI / 2, 0, 0]}
                                raycast={noopRaycast}
                            >
                                <cylinderGeometry args={[handleSize / editorStore.zoomLevelDivisor() / 1.8, 0, 0, 32]} />
                                <meshBasicMaterial color={isHovered("start") ? "blue" : "white"} />
                            </mesh>
                        </group>
                    )}

                    {(editorStore.wallDragging !== wall.id || dragHandle.current?.handle === "end") && (
                        <group
                            position={new THREE.Vector3(0, 0, 0.001)}
                            onPointerDown={(event) => onWallSelectionPointerDown(event, "end", wall.id)}
                            onPointerEnter={(event) => {
                                event.stopPropagation();
                                document.body.style.cursor = "move";
                                setHoveredHandle("end")
                            }}
                            onPointerLeave={(event) => {
                                event.stopPropagation();
                                if (editorStore.wallDragging !== wall.id) document.body.style.cursor = "auto";
                                setHoveredHandle(null)
                            }}
                        >
                            {/* Draw a circle grab handle for the end */}
                            <group position={new THREE.Vector3(wall.end.x, wall.end.y, 0.001)}>
                                <mesh>
                                    <planeGeometry args={[handleSize * 2, handleSize * 2]} />
                                    <meshBasicMaterial
                                        //color="white"
                                        transparent
                                        opacity={0.0}
                                    />
                                </mesh>
                                <mesh raycast={noopRaycast}>
                                    <torusGeometry args={[handleSize / editorStore.zoomLevelDivisor() / 1.8, 0.003, 16, 100]} />
                                    <meshBasicMaterial color="blue" />
                                </mesh>
                                <mesh
                                    position={[0, 0, 0]}
                                    rotation={[Math.PI / 2, 0, 0]}
                                    raycast={noopRaycast}
                                >
                                    <cylinderGeometry args={[handleSize / editorStore.zoomLevelDivisor() / 1.8, 0, 0, 32]} />
                                    <meshBasicMaterial color={isHovered("end") ? "blue" : "white"} />
                                </mesh>
                            </group>
                        </group>
                    )}

                    {(wall.lineForm === "arc" && (editorStore.wallDragging === wall.id || dragHandle.current?.handle === "control")) && (
                        <group
                            position={new THREE.Vector3(0, 0, 0.001)}
                            onPointerDown={(event) => onWallSelectionPointerDown(event, "control", wall.id)}
                            onPointerEnter={(event) => {
                                event.stopPropagation();
                                document.body.style.cursor = "move";
                                setHoveredHandle("control")
                            }}
                            onPointerLeave={(event) => {
                                event.stopPropagation();
                                if (editorStore.wallDragging !== wall.id) document.body.style.cursor = "auto";
                                setHoveredHandle(null)
                            }}
                        >
                            {/* Draw a circle grab handle for the control point */}
                            <group
                                position={
                                    wall.controlPoint ? new THREE.Vector3(wall.controlPoint.x, wall.controlPoint.y, 0.001) :
                                        new THREE.Vector3(
                                            wall.start.x + (wall.end.x - wall.start.x) / 2,
                                            wall.start.y + (wall.end.y - wall.start.y) / 2,
                                            0.001)
                                }>
                                <mesh>
                                    <planeGeometry args={[(handleSize * 2) / editorStore.zoomLevelDivisor(), (handleSize * 2) / editorStore.zoomLevelDivisor()]} />
                                    <meshBasicMaterial
                                        //color="white"
                                        transparent
                                        opacity={0.0}
                                    />
                                </mesh>
                                <mesh raycast={noopRaycast}>
                                    <torusGeometry args={[handleSize / editorStore.zoomLevelDivisor(), 0.004, 16, 100]} />
                                    <meshBasicMaterial color="blue" />
                                </mesh>
                                <mesh
                                    position={[0, 0, 0]}
                                    rotation={[Math.PI / 2, 0, 0]}
                                    raycast={noopRaycast}
                                >
                                    <cylinderGeometry args={[handleSize / editorStore.zoomLevelDivisor(), 0, 0, 32]} />
                                    <meshBasicMaterial color={isHovered("control") ? "blue" : "white"} />
                                </mesh>
                            </group>
                        </group>
                    )}
                </group>
            )}
        </group>
    );
});