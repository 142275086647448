import React, { useRef, useState, useEffect } from 'react';
import { FiDownload } from 'react-icons/fi';
import { IoClose } from "react-icons/io5";
import { floorplannerStore } from '../../store/floorplannerStore';
import { renderStore } from '../../store/renderStore';
import { Tooltip } from 'flowbite-react';
import { PiMinus, PiPlus } from 'react-icons/pi';
import { FaArrowRight } from "react-icons/fa6";
import { observer } from 'mobx-react-lite';
import { ApolloQueryResult, gql } from '@apollo/client';
import { EditFloorplanDocument, EditFloorplanMutation, UserDocumentsQuery, useUserDocumentsQuery } from '../../gql';
import { dataUrlToFile } from '../../utils/dataUrlToFile';
import * as THREE from 'three';
import { set } from 'mobx';
import Clarity from '@microsoft/clarity';

interface DropdownProps {
    position: { top: number; left: number };
    onClose: () => void;
    dropdownRef: React.RefObject<HTMLDivElement>;
}

export const AddToPresentation: React.FC<DropdownProps> = observer(({ position, onClose, dropdownRef }) => {
    const [zoom, setZoom] = useState(1); // Default zoom level
    const [pan, setPan] = useState({ x: 0, y: 0 }); // Pan offset
    const [containerSize, setContainerSize] = useState({ width: 300, height: 300 }); // Default container size (calculated)
    const [wizardStep, setWizardStep] = useState(1); // Wizard step
    const maxDimension = 400; // Maximum width or height
    const imgRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [selectedDocument, setSelectedDocument] = useState<any | null>(null);
    // Fetch user documents
    const { data, error, refetch: refetchUserDocuments } = useUserDocumentsQuery({
        fetchPolicy: 'cache-and-network',
        onError: () =>
            console.error('Error fetching user documents:', error),
    })
    const [redirecting, setRedirecting] = useState(false);

    // Calculate the width and height of the image container based on the selected size's aspect ratio
    const handleSizeChange = (selectedWidth: number, selectedHeight: number) => {
        const aspectRatio = selectedWidth / selectedHeight;

        // Adjust width and height but ensure the larger dimension does not exceed maxDimension (400px)
        let calculatedWidth = maxDimension;
        let calculatedHeight = maxDimension;

        if (aspectRatio > 1) {
            // Landscape: width is greater than height
            calculatedHeight = maxDimension / aspectRatio;
        } else {
            // Portrait: height is greater than width
            calculatedWidth = maxDimension * aspectRatio;
        }

        setContainerSize({ width: calculatedWidth, height: calculatedHeight });
    };

    // Handle zoom with mouse wheel
    const handleWheelZoom = (e: React.WheelEvent,) => {
        const zoomFactor = e.deltaY > 0 ? 0.9 : 1.1; // Zoom out or in
        setZoom((prevZoom) => Math.max(0.5, Math.min(prevZoom * zoomFactor, 5))); // Clamp zoom between 0.5 and 5
    };

    // Handle zoom in/out using buttons
    const handleZoomChange = (factor: number) => {
        setZoom((prevZoom) => Math.max(0.5, Math.min(prevZoom + factor, 5))); // Clamp zoom
    };

    // Handle pan movement
    const handlePan = (e: React.MouseEvent) => {
        if (e.buttons !== 1) return; // Only allow panning with left mouse button
        setPan({
            x: pan.x + e.movementX,
            y: pan.y + e.movementY,
        });
    };

    // Capture snapshot with selected area and coordinates
    const handleNextStep = async (e: React.FormEvent) => {
        e.preventDefault();
        if (wizardStep === 1) {
            const target = e.currentTarget as any;
            const [orientationStr, formatStr, widthStr, heightStr] = target.resolution.value.split(",");
            const targetFormat = {
                orientation: orientationStr as 'portrait' | 'landscape',
                format: formatStr,
                width: parseInt(widthStr),
                height: parseInt(heightStr),
            }
            renderStore.setRendering(true);
            setTimeout(() => {
                if (renderStore.scene) {
                    const sceneBox = renderStore.computeBoundingBoxForScene(renderStore.scene);
                    const sceneCenter = new THREE.Vector3();
                    sceneBox.getCenter(sceneCenter);
                    const size = new THREE.Vector3();
                    sceneBox.getSize(size);

                    // Calculate a single unitsPerPixel value for both axes
                    const aspectX = size.x / containerSize.width;
                    const aspectY = size.y / containerSize.height;
                    const unitsPerPixel = Math.max(aspectX, aspectY) / zoom;

                    // Calculate capture dimensions in 3D space
                    const captureWidth = containerSize.width * unitsPerPixel;
                    const captureHeight = containerSize.height * unitsPerPixel;

                    // Adjust pan values to 3D space
                    const adjustX = pan.x * unitsPerPixel;
                    const adjustY = pan.y * unitsPerPixel;

                    // Define the capture box centered on the scene and adjusted by pan and zoom
                    const captureBox = new THREE.Box3(
                        new THREE.Vector3(
                            sceneCenter.x - captureWidth / 2 - adjustX,
                            sceneCenter.y - captureHeight / 2 + adjustY,
                            sceneBox.min.z
                        ),
                        new THREE.Vector3(
                            sceneCenter.x + captureWidth / 2 - adjustX,
                            sceneCenter.y + captureHeight / 2 + adjustY,
                            sceneBox.max.z
                        )
                    );

                    renderStore.captureSnapshot('upload', targetFormat, captureBox);
                }
                renderStore.setRendering(false);
                setWizardStep(2);
            }, 100);
        } else if (wizardStep === 2) {
            setRedirecting(true);
            setTimeout(async () => {
                if (renderStore.imageData) {
                    const file = await dataUrlToFile(renderStore.imageData, `floorplan_${Date.now()}.png`);
                    try {
                        const result = await renderStore.client?.mutate<EditFloorplanMutation>({
                            variables: { id: floorplannerStore.id, image: file },
                            mutation: EditFloorplanDocument,
                        });
                        if (result?.data?.editFloorplan) {
                            // Redirect to moodboard in app and add floorplan to selected moodboard
                            if (selectedDocument) {
                                Clarity.event("add_to_presentation");
                                window.location.href = process.env.REACT_APP_CLIENT_URI + `/editor/${selectedDocument.id}/add-to-page/${result.data.editFloorplan.image}`;
                            }
                        }
                    } catch (error) {
                        console.error('Error updating floorplan:', error);
                    }
                }
                // Add to presentation
                onClose();
            }, 100);
        }
    };

    useEffect(() => {
        const width = 1754;
        const height = 1240;
        renderStore.captureSnapshot('preview', {
            orientation: 'landscape',
            format: 'a4',
            width,
            height,
        }); // Reset snapshot
        // Initial size change based on default aspect ratio (Desktop format, landscape)
        handleSizeChange(width, height);
    }, []);

    return (
        <div
            className="fixed z-50 w-[600px] bg-[#EDEBE6] border border-gray-300 rounded shadow-md overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            ref={dropdownRef}
        >
            <div className="flex flex-cols items-center justify-between p-2">
                <div className="text-sm font-normal">Add to presentation</div>
                <div onClick={onClose} className="text-sm text-gray-500 cursor-pointer"><IoClose style={{ strokeWidth: 0.5 }} size={24} /></div>
            </div>
            <div className="border-b border-gray-300 w-full" />

            {/* Image holder with zoom and pan, wizard step 1 */}
            {wizardStep === 1 && (
                <>
                    <div className="flex flex-col items-center justify-between px-10 w-full mt-2">
                        <div className="text-md font-normal w-full">Select area</div>
                        <div className="text-sm text-gray-500 w-full">(Zoom and drag into the area you want to download)</div>

                        <div className="flex flex-row items-center justify-center w-full mt-2">
                            <div
                                ref={containerRef}
                                className="relative border bg-white rounded overflow-hidden rounded-sm border border-gray-300 shadow-md mt-4"
                                style={{ cursor: 'grab', width: `${containerSize.width}px`, height: `${containerSize.height}px` }}
                                onWheel={handleWheelZoom}
                                onMouseMove={handlePan}
                            >
                                <div
                                    className="absolute"
                                    ref={imgRef}
                                    style={{
                                        transform: `translate(${pan.x}px, ${pan.y}px) scale(${zoom})`,
                                        transformOrigin: 'center center',
                                        width: containerSize.width,
                                        height: containerSize.height,
                                    }}
                                >
                                    <img
                                        src={renderStore.imageData}
                                        alt={floorplannerStore.name}
                                        className="object-contain w-full h-full"
                                        draggable={false}  // Disable default image drag
                                    />
                                </div>
                            </div>

                            {/* Zoom controls */}
                            <div className="flex flex-col space-y-2 ml-2">
                                <Tooltip content="Zoom in" placement='right'>
                                    <button onClick={() => handleZoomChange(0.1)} className="w-full p-2 text-center bg-white hover:bg-gray-100 rounded-sm border border-gray-300 shadow-md">
                                        <PiPlus size={20} />
                                    </button>
                                </Tooltip>
                                <Tooltip content="Zoom out" placement='right'>
                                    <button onClick={() => handleZoomChange(-0.1)} className="w-full p-2 text-center bg-white hover:bg-gray-100 rounded-sm border border-gray-300 shadow-md">
                                        <PiMinus size={20} />
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                    </div>

                    <form className="flex flex-col items-center justify-between px-10 w-full h-full" onSubmit={handleNextStep}>
                        <div className="flex flex-cols items-center justify-between p-2 w-full">
                            <div className="text-sm text-gray-500 w-50">
                                <label htmlFor="resolution">Format:</label>
                                <br />
                                <select id="resolution" name="resolution" className="mt-2 w-full" onChange={(e) => {
                                    const [orientation, format, width, height] = e.target.value.split(",");
                                    renderStore.captureSnapshot('preview', {
                                        orientation: orientation as 'portrait' | 'landscape',
                                        format,
                                        width: parseInt(width),
                                        height: parseInt(height),
                                    }); // Reset snapshot
                                    handleSizeChange(parseInt(width), parseInt(height));  // Update container width and height based on aspect ratio
                                }}>
                                    <option value="landscape,a4,1754,1240">Desktop format</option>
                                    <option value="portrait,a4,600,600">Instagram format</option>
                                </select>
                            </div>
                        </div>

                        <button
                            id="capture"
                            type="submit"
                            className={
                                renderStore.rendering ? "bg-gray-600 text-white p-4 rounded-md w-full mt-4 mb-4 cursor-not-allowed" :
                                    "bg-black text-white p-4 rounded-md w-full mt-4 mb-4"
                            }
                            disabled={renderStore.rendering}
                        >
                            <div className="flex flex-row items-center justify-center">
                                {renderStore.rendering &&
                                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3" />}
                                {!renderStore.rendering && <>
                                    Choose presentation
                                </>}
                                &nbsp; <FaArrowRight className="text-xl transform scale-90" />
                            </div>
                        </button>
                    </form>
                </>
            )}

            {wizardStep === 2 && (
                <div className="flex flex-col items-center justify-between px-10 w-full mt-2">
                    <div className="text-md font-normal w-full">Choose presentation</div>
                    <div className="text-sm text-gray-500 w-full">Click on the one you want to add the floorplan to</div>

                    {/* A scrollable list of moodoards, 3 columns, 2 rows */}
                    <div className="grid gap-2  grid-cols-3 w-full mt-2 overflow-scroll" style={{ height: '350px' }}>
                            {data?.userDocuments?.documents?.map((document: any) => (
                                <DocumentItem
                                    key={document.id}
                                    document={document}
                                    selectDocument={(document) => setSelectedDocument(document)}
                                    selected={selectedDocument?.id === document.id}
                                    refetchUserDocuments={refetchUserDocuments}
                                />
                            ))}
                    </div>
                    <button
                        type="submit"
                        className={
                            (selectedDocument === null || redirecting) ? "bg-gray-600 text-white p-4 rounded-md w-full mt-4 mb-4 cursor-not-allowed" :
                            "bg-black text-white p-4 rounded-md w-full mt-4 mb-4"
                        }
                        disabled={selectedDocument === null || redirecting}
                        onClick={handleNextStep}
                    >
                        <div className="flex flex-row items-center justify-center">
                            {redirecting &&
                                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3" />}
                            {!redirecting && <>
                                Add to presentation
                            </>}
                            &nbsp; <FiDownload className="text-xl transform scale-90" />
                        </div>
                    </button>

                </div>
            )}

        </div>
    );
});

export const DocumentItem: React.FC<{
    document: any
    selectDocument?: (document: any) => void
    selected?: boolean
    refetchUserDocuments?: () => Promise<ApolloQueryResult<UserDocumentsQuery>>
}> = ({ document, refetchUserDocuments, selectDocument, selected }) => {

    return (
        <div
            className={
                "relative flex flex-col w-[165px] h-[105px] justify-center items-center rounded-md shadow-md cursor-pointer overflow-hidden" +
                (selected ? ' border-blue-600 border-2' : ' border-gray-300 border')}
            onClick={() => {
                selectDocument && selectDocument(document)
            }}
        >
            <div className="relative flex flex-col w-[165px] h-[81px]">
                <img
                    className={
                        'object-contain h-[148px] w-full bg-white hover:border-gray-700'
                    }
                    src={
                        document?.image && document?.image.length > 0
                            ? document?.image
                            : process.env.PUBLIC_URL + '/EmptyProjectBackground.png'
                    }
                    style={{ objectFit: 'cover' }}
                    alt="BONI Board Preview Snapshot"
                />
            </div>
            <div className="flex flex-row justify-between items-center w-full h-[22px] bg-[#F7F6F0CC] backdrop-blur-[2px] pl-[16px] py-[13px] font-dm text-[14px] font-[400]">
                <p>
                    {document.name === 'Name Your BONI Project'
                        ? 'Untitled'
                        : document.name}
                </p>
            </div>
        </div>
    )
}

// GraphQL queries
gql`
  query userDocuments($input: UserDocumentsQueryInput) {
    userDocuments(input: $input) {
      documents {
        id
        name
        image
        updatedAt
        moodBoards {
          id
          name
          image
          updatedAt
        }
      }
      total
    }
  }
`
