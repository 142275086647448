import React, { useEffect, useRef } from 'react';
import { useThree, useFrame, invalidate } from '@react-three/fiber';
import * as THREE from 'three';
import { editorStore } from '../../store/editorStore';
import { observer } from 'mobx-react-lite';
import { projectToWorld } from '../FloorPlan/projectToWorld';
import { floorplannerStore } from '../../store/floorplannerStore';
import { RulerLineType, WallConnectionEnd, WallConnectionStart, WallType } from '../../types/wallTypes';
import { runInAction } from 'mobx';
import { updateAlignmentLines } from '../FloorPlan/updateAligmentLines';
import { renderStore } from '../../store/renderStore';

const RulerConstructionPlane = observer(() => {
  const {
    rulerConstructionMode,
    rulerConstructionModeAddNewLine,
    view3D,
  } = editorStore;
  const { gl, camera } = useThree();
  const addNewLine = useRef(false);
  const currentLine = useRef(undefined as string | undefined);
  const lastClickPosition = useRef(undefined as THREE.Vector2 | undefined);
  const isDragging = useRef(false);  // Track dragging state

  const handlePointerDown = (event: PointerEvent) => {
    if (editorStore.rulerConstructionMode) {
      const { clientX, clientY } = event;
      const pos = projectToWorld(clientX, clientY, gl, camera);
      lastClickPosition.current = new THREE.Vector2(pos[0], pos[1]);
      addNewLine.current = true;
      isDragging.current = false;
      if (currentLine.current) {
        editorStore.setRulerConstructionMode(false);
      }
    }
  };

  const handlePointerMove = (event: PointerEvent) => {
    if (editorStore.rulerConstructionMode && !editorStore.view3D) {
      document.body.classList.remove("cursor-vector")
      document.body.style.cursor = 'crosshair';
      const { clientX, clientY } = event;
      const pos = projectToWorld(clientX, clientY, gl, camera);

      // Mark as dragging if the pointer moves a significant distance
      const distance = lastClickPosition.current?.distanceTo(new THREE.Vector2(pos[0], pos[1])) ?? 0;
      if (distance > 0.1) {
        isDragging.current = true;
      }

      if (addNewLine.current && !isDragging.current && lastClickPosition.current) {
        createNewLineSegment(pos);
      }

      // Update the line's position if it's already been created
      if (!addNewLine.current && currentLine.current) {
        const line = floorplannerStore.rulerLinesMap.get(currentLine.current);
        if (line) {
          const start = line.start;
          let end = new THREE.Vector2(pos[0], pos[1]);

          if (!event.shiftKey) {
            const deltaX = end.x - start.x;
            const deltaY = end.y - start.y;
            const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
            const currentDistance = start.distanceTo(end);
            const normalizedAngle = (angle + 360) % 360;
            const snapAngles = [0, 45, 90, 135, 180, 225, 270, 315, 360];
            let closestSnapAngle = snapAngles.reduce((prev, curr) => Math.abs(curr - normalizedAngle) < Math.abs(prev - normalizedAngle) ? curr : prev);
            const radianSnapAngle = closestSnapAngle * (Math.PI / 180);
            const snapEndX = start.x + currentDistance * Math.cos(radianSnapAngle);
            const snapEndY = start.y + currentDistance * Math.sin(radianSnapAngle);
            const snapDistance = end.distanceTo(new THREE.Vector2(snapEndX, snapEndY));

            if (snapDistance < 0.2) {
              end.set(snapEndX, snapEndY);
            }
          }

          // Test to snap the wall to other walls if distance is more than 0.2
          let snapped = false;
          // if (distance > 0.2) {
          //   runInAction(() => {
          //     floorplannerStore.removeConnectionEnd(line.id, WallConnectionEnd);
          //     snapped = floorplannerStore.snapToOtherObjects(line.id, WallConnectionEnd, end, false);
          //     if (!snapped && !editorStore.isShiftPressed) {
          //       floorplannerStore.updateObjectPosition(line.id, start, end);
          //       const updObject = floorplannerStore.findObjectId(line.id);
          //       if (updObject) {
          //         snapped = floorplannerStore.snapToAlignmentLines(line.id, WallConnectionEnd, updObject.end);
          //         if (snapped) {
          //           const updObject = floorplannerStore.findObjectId(line.id);
          //           if (updObject) {
          //             updateAlignmentLines(line.id, updObject.end.x, updObject.end.y, "end");
          //           }
          //         }
          //       }
          //     }
          //   });
          // }
          if (!snapped) {
            floorplannerStore.updateObjectPosition(currentLine.current, start, end);
            updateAlignmentLines(line.id, end.x, end.y, "end");
          }
          // Update the wall's outer length in property panel
          const rulerLength = floorplannerStore.rulerLineLength(line.id);
          // Set the length edit box with id="rulerLength" in focus in the property panel
          editorStore.setRulerEditingLengthPanel(rulerLength);

        }
      }
    }
  };

  const createNewLineSegment = (pos: [number, number]) => {
    if (lastClickPosition.current && currentLine) {
      const line = floorplannerStore.rulerLinesMap.get(currentLine.current as string);
      const start = currentLine.current && line ?
        line.end.clone() :
        lastClickPosition.current.clone();
      const end = new THREE.Vector2(pos[0], pos[1]);
      const newLine: RulerLineType = {
        id: floorplannerStore.generateId(),
        type: "rulerLine",
        start: start,
        end: end,
      };
      floorplannerStore.addRulerLine(newLine);
      editorStore.setRulerLineEditingLength(newLine.id);
      currentLine.current = newLine.id;
      addNewLine.current = false;

      // Select the new ruler line
      editorStore.clearSelections();
      editorStore.addSelection(newLine);
      invalidate();

    }
  };

  useEffect(() => {
    if (currentLine.current && rulerConstructionModeAddNewLine) {
      editorStore.setRulerConstructionModeAddNewLine(false);
      const line = floorplannerStore.rulerLinesMap.get(currentLine.current);
      if (line) {
        addNewLine.current = true;
        isDragging.current = false;
        editorStore.setRulerConstructionMode(false);
      }
    }
  }, [rulerConstructionModeAddNewLine]);

  useEffect(() => {
    renderStore.clearAlignmentLines();
    editorStore.setRulerLineEditingLength(undefined);
    editorStore.setRulerEditingLengthPanel(undefined);
    addNewLine.current = false;
    currentLine.current = undefined;
    lastClickPosition.current = undefined;
    if (rulerConstructionMode || view3D) {
      document.body.classList.remove("cursor-vector")
      document.body.style.cursor = 'crosshair';
      floorplannerStore.setBlockDirty(true);
    } else {
      document.body.style.cursor = 'auto';
      floorplannerStore.setBlockDirty(false);
    }
  }, [rulerConstructionMode]);

  return (
    <mesh
      position={[0, 0, -0.01]} 
      onPointerDown={(e) => handlePointerDown(e.nativeEvent)}
      onPointerMove={(e) => handlePointerMove(e.nativeEvent)}
      userData={{ id: 'ruler-construction-plane' }}
    >
      <planeGeometry args={[1000, 1000]} />
      <meshBasicMaterial transparent opacity={0} />
    </mesh>
  );
});

export default RulerConstructionPlane;
