import { observer } from "mobx-react-lite";
import { editorStore } from '../../store/editorStore';
import { renderStore } from "../../store/renderStore";
import AlignmentLine from "./AlignmentLine";

const AlignmentLineStructure = observer(() => {

  return (
    <>
      {(editorStore.wallDragging || editorStore.lineDragging || editorStore.rulerDragging || editorStore.wallConstructionMode || editorStore.lineConstructionMode || editorStore.rulerConstructionMode) &&
        Array.from(renderStore.alignmentLinesMap.values()).map((line) => (
          <AlignmentLine
            key={line.id}
            line={line}
          />
        ))
      }
    </>
  );
});

export default AlignmentLineStructure;
