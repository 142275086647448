import { makeAutoObservable, ObservableMap, set } from 'mobx';
import React from "react";
import { useContext } from "react";
import { ApolloClient } from "@apollo/client";
import { ClippingPolygonType, isDoorType, isDoubleDoorType, isWindowType, SymbolType, WallShapeType, WallType, otherModifiedPath, AlignmentLineType } from '../types/wallTypes';
import { floorplannerStore } from "./floorplannerStore";
import * as THREE from "three";
import { composePaths } from "../components/FloorPlan/composeLines";
import { trimIntersectingLines, trimIntersectingShapes } from "../components/FloorPlan/trimIntersectingLines";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { editorStore } from './editorStore';
import { invalidate } from '@react-three/fiber';
import { EditFloorplanDocument, EditFloorplanMutation } from '../gql';
import { dataUrlToFile } from '../utils/dataUrlToFile';
import { string } from 'prop-types';
import { BoundingBox } from '../components/FloorPlan/SelectableSymbol';
import { off } from 'process';

export type TargetFormatType = {
  format: string;
  orientation: 'portrait' | 'landscape';
  width: number;
  height: number;
  shiftX?: number;
  shiftY?: number;
  zoom?: number;
}

class RenderStore {
  client: ApolloClient<any> | undefined;
  scene: THREE.Scene | undefined;
  cameraZ: number = 20;
  wallClippingsMap = new Map<string, ClippingPolygonType[]>();
  innerWallShapesMap = new Map<string, WallShapeType[]>();
  outline1WallShapesMap = new Map<string, WallShapeType[]>();
  outline2WallShapesMap = new Map<string, WallShapeType[]>();
  endCapsWallShapesMap = new Map<string, WallShapeType[]>();
  alignmentLinesMap = new ObservableMap<string, AlignmentLineType>();
  alignmentLinesApproxMap = new ObservableMap<string, AlignmentLineType>();
  labelBackgroundColor: THREE.Color = new THREE.Color(0xffffff);
  imageData: string | undefined;
  rendering = false;

  constructor() {
    makeAutoObservable(this);
  }

  dispose() {
  }

  initialize(client: ApolloClient<any>) {
    this.client = client;
  }

  getClippingPolygon = (symbol: SymbolType, wall: WallType): ClippingPolygonType => {
    const objectWidth = isDoorType(symbol)
      ? symbol.doorWidth + ((symbol.doorFrameWidth || floorplannerStore.doorFrameWidth)) * 2
      : isDoubleDoorType(symbol)
        ? (symbol.doubleDoorWidth + (symbol.doorFrameWidth || floorplannerStore.doorFrameWidth)) * 2
        : isWindowType(symbol)
          ? symbol.windowLength
          : 0.8;
    const objectLength = objectWidth / 2;
    const wallAngle = Math.atan2(wall.end.y - wall.start.y, wall.end.x - wall.start.x);
    const objectDirection = new THREE.Vector2(Math.cos(wallAngle), Math.sin(wallAngle));
    // If it is a door or double door, offset the position by the door/ double door width
    const offset = isDoorType(symbol) ? objectWidth / 2 - (symbol.doorFrameWidth || floorplannerStore.doorFrameWidth) :
      isDoubleDoorType(symbol) ? objectWidth / 2 - (symbol.doorFrameWidth || floorplannerStore.doorFrameWidth) : 0;
    const wallDirection = wall.end.clone().sub(wall.start).normalize();
    const symbolDistance = symbol.position.clone().sub(wall.start).dot(wallDirection);
    const symbolPositionOnWall = wall.start.clone().add(wallDirection.clone().multiplyScalar(symbolDistance));
    const objectPositionOffset = symbolPositionOnWall.clone().add(objectDirection.clone().multiplyScalar(offset));
    const objectLengthVector = objectDirection.clone().multiplyScalar(objectLength);
    const objectWidthVector = objectDirection.clone().rotateAround(new THREE.Vector2(0, 0), Math.PI / 2).multiplyScalar(objectWidth / 2);
    const topLeft = objectPositionOffset.clone().add(objectLengthVector).add(objectWidthVector);
    const topRight = objectPositionOffset.clone().add(objectLengthVector).sub(objectWidthVector);
    const bottomRight = objectPositionOffset.clone().sub(objectLengthVector).sub(objectWidthVector);
    const bottomLeft = objectPositionOffset.clone().sub(objectLengthVector).add(objectWidthVector);
    const clippingPolygon: ClippingPolygonType = {
      belongsTo: symbol.id,
      polygon: [
        new THREE.Vector2(topLeft.x, topLeft.y),
        new THREE.Vector2(topRight.x, topRight.y),
        new THREE.Vector2(bottomRight.x, bottomRight.y),
        new THREE.Vector2(bottomLeft.x, bottomLeft.y),
      ],
      objectType: "symbol",
    };
    return clippingPolygon;
  };

  addWallClipping = (wallId: string, clipping: ClippingPolygonType) => {
    const wallClippings = this.wallClippingsMap.get(wallId) || [];
    const index = wallClippings.findIndex(c => c.belongsTo === clipping.belongsTo);
    if (index > -1) {
      wallClippings[index] = clipping;
    } else {
      wallClippings.push(clipping);
    }
    this.wallClippingsMap.set(wallId, wallClippings);
  }

  removeWallClipping = (wallId: string, belongsTo: string) => {
    const clippings = this.wallClippingsMap.get(wallId) || [];
    const index = clippings.findIndex(c => c.belongsTo === belongsTo);
    if (index > -1) {
      clippings.splice(index, 1);
    }
    this.wallClippingsMap.set(wallId, clippings);
  }

  clearWallClippings = (wallId: string) => {
    const allClippings = this.wallClippingsMap.get(wallId);
    if (allClippings) {
      allClippings.forEach((clipping) => {
        if (clipping.objectType === "wall") {
          this.removeWallClipping(wallId, clipping.belongsTo);
        }
      });
    }
  }

  clearSymbolClippings = (wallId: string) => {
    // Clean out all previous symbol clippings
    const allClippings = this.wallClippingsMap.get(wallId);
    if (allClippings) {
      allClippings.forEach((clipping) => {
        if (clipping.objectType === "symbol" || clipping.objectType === "door" || clipping.objectType === "doubleDoor" || clipping.objectType === "window") {
          renderStore.removeWallClipping(wallId, clipping.belongsTo);
        }
      });
    }
  }

  clearClippings = (wallId?: string) => {
    if (wallId) {
      this.wallClippingsMap.set(wallId, []);
      this.wallClippingsMap.delete(wallId);
    } else {
      this.wallClippingsMap.clear();
    }
  }

  getConnectedShapes = (wallId: string, scene?: THREE.Scene): WallShapeType[] => {
    const wall = floorplannerStore.wallsMap.get(wallId);
    if (!wall) return [];
    const connectedShapes = [] as WallShapeType[];
    const connectedWalls = wall.connections?.map(c => floorplannerStore.wallsMap.get(c.id));
    connectedWalls?.forEach(w => {
      if (w) {
        let innerShapes = this.innerWallShapesMap.get(w.id);
        if (!innerShapes) {
          this.composeWallShape(w.id, scene);
          innerShapes = this.innerWallShapesMap.get(w.id);
        }
        if (innerShapes) {
          connectedShapes.push(...innerShapes);
        }
      }
    });
    return connectedShapes;
  }

  composeWallShape = (wallId: string, scene?: THREE.Scene): WallShapeType[] => {
    const innerShapes: WallShapeType[] = [];
    const outerShapes1: WallShapeType[] = [];
    const outerShapes2: WallShapeType[] = [];
    const endCaps: WallShapeType[] = [];
    composePaths(wallId, innerShapes, outerShapes1, outerShapes2, endCaps);
    this.innerWallShapesMap.set(wallId, innerShapes);
    this.outline1WallShapesMap.set(wallId, outerShapes1);
    this.outline2WallShapesMap.set(wallId, outerShapes2);
    this.endCapsWallShapesMap.set(wallId, endCaps);
    return innerShapes;
  }

  composeAllShapes = () => {
    floorplannerStore.wallsMap.forEach(wall => {
      this.composeWallShape(wall.id);
    });
  }

  shapeChanged = (shape: WallShapeType, wall: WallType): boolean => {
    if (shape.wall.start.x !== wall.start.x || shape.wall.start.y !== wall.start.y) {
      return true;
    }
    if (shape.wall.end.x !== wall.end.x || shape.wall.end.y !== wall.end.y) {
      return true;
    }
    if (shape.wall.wallWidth !== wall.wallWidth) {
      return true;
    }
    return false;
  }

  updateShapes = (wallId: string, scene?: THREE.Scene): WallShapeType[] => {
    let innerShapes = this.innerWallShapesMap.get(wallId);
    if (innerShapes) {
      const wall = floorplannerStore.wallsMap.get(wallId);
      if (!wall) return [];
      innerShapes.forEach(innerShape => {
        if (this.shapeChanged(innerShape, wall)) {
          this.composeWallShape(wallId);
          const connectingWalls = wall.connections?.map(c => floorplannerStore.wallsMap.get(c.id));
          connectingWalls?.forEach(w => {
            if (w) {
              this.trimShapes(w, scene);
            }
          });
          this.trimShapes(wall, scene);
        }
      })
    } else {
      const wall = floorplannerStore.wallsMap.get(wallId);
      if (!wall) return [];
      this.composeWallShape(wallId);
      this.trimShapes(wall, scene);
      // We have to trim the connecting walls as well, deeply nested at least 2 levels
      const connectingWalls = wall.connections?.map(c => floorplannerStore.wallsMap.get(c.id));
      connectingWalls?.forEach(w => {
        if (w) {
          this.trimShapes(w, scene);
          const connectingWalls2 = w.connections?.map(c => floorplannerStore.wallsMap.get(c.id));
          connectingWalls2?.forEach(w2 => {
            if (w2) {
              this.trimShapes(w2, scene);
            }
          });
        }
      });
    }

    innerShapes = this.innerWallShapesMap.get(wallId);
    return innerShapes ?? [];
  };

  trimShapes = (wall: WallType, scene?: THREE.Scene) => {
    const otherModifiedPaths = [] as otherModifiedPath[];
    const innerShapes = this.innerWallShapesMap.get(wall.id);
    const outline1Shapes = this.outline1WallShapesMap.get(wall.id);
    const outline2Shapes = this.outline2WallShapesMap.get(wall.id);
    if (!innerShapes || !outline1Shapes || !outline2Shapes) {
      return;
    }

    // Add the new wall clippings and update the wall shapes on their intersections
    const connectedShapes = this.getConnectedShapes(wall.id, scene);
    const trimmed1 = trimIntersectingLines(outline1Shapes, connectedShapes);
    const trimmed2 = trimIntersectingLines(outline2Shapes, connectedShapes);
    const trimmed3 = trimIntersectingShapes(innerShapes, connectedShapes, otherModifiedPaths, scene);

    //if (trimmed1 || trimmed2 || trimmed3) {

    if (innerShapes && outline1Shapes && outline2Shapes) {
      this.outline1WallShapesMap.set(wall.id, outline1Shapes);
      this.outline2WallShapesMap.set(wall.id, outline2Shapes);
      this.innerWallShapesMap.set(wall.id, innerShapes);
      otherModifiedPaths.forEach(mods => {
        //console.log("mods: ", mods, wall.id, wall.fillColor);
        const wallShapes = this.innerWallShapesMap.get(mods.wallId);
        if (wallShapes) {
          const wallShape = wallShapes.find(ws => ws.lineSide === "inner");
          if (wallShape && wallShape.paths[mods.pathIndex] !== mods.point) {
            //console.log("Modified path: ", wallShape.paths[mods.pathIndex], " to ", mods.point, wallShape.wall.fillColor);
            wallShape.paths[mods.pathIndex].x = mods.point.x;
            wallShape.paths[mods.pathIndex].y = mods.point.y;
            this.innerWallShapesMap.set(mods.wallId, wallShapes);
            // Debug draw a dot at mods.point
            // const geometry1 = new THREE.CircleGeometry(0.01, 32);
            // const material1 = new THREE.MeshBasicMaterial({ color: wallShape.wall.fillColor });
            // const circle1 = new THREE.Mesh(geometry1, material1);
            // circle1.position.set(mods.point.x, mods.point.y, 0.01);
            // scene?.add(circle1);
          }
        }
      });
    }

  }

  clearWallShapes = (wallId: string) => {
    if (wallId) {
      this.innerWallShapesMap.set(wallId, []);
      this.innerWallShapesMap.delete(wallId);
      this.outline1WallShapesMap.set(wallId, []);
      this.outline1WallShapesMap.delete(wallId);
      this.outline2WallShapesMap.set(wallId, []);
      this.outline2WallShapesMap.delete(wallId);
      this.endCapsWallShapesMap.set(wallId, []);
      this.endCapsWallShapesMap.delete(wallId);
    } else {
      this.innerWallShapesMap.clear();
      this.outline1WallShapesMap.clear();
      this.outline2WallShapesMap.clear();
      this.endCapsWallShapesMap.clear();
    }
  }

  clearAlignmentLines = () => {
    this.alignmentLinesMap.clear();
    this.alignmentLinesApproxMap.clear();
  }

  addAlignmentLine = (line: AlignmentLineType, approx: boolean) => {
    if (approx) {
      this.alignmentLinesApproxMap.set(line.id, line);
    } else {
      this.alignmentLinesMap.set(line.id, line);
    }
  }

  setScene(scene: THREE.Scene) {
    this.scene = scene;
  }

  // Helper to clone the scene for off-screen rendering
  cloneScene(originalScene: THREE.Scene) {
    return originalScene.clone(true);
  }

  // Helper to duplicate the camera
  duplicateCamera(originalCamera: THREE.PerspectiveCamera | THREE.OrthographicCamera) {
    const newCamera = originalCamera.clone() as typeof originalCamera;
    newCamera.position.copy(originalCamera.position);
    newCamera.zoom = originalCamera.zoom;
    newCamera.updateProjectionMatrix();
    return newCamera;
  }

  captureSnapshot = async (
    operation = 'thumbnail',
    target = {
      format: 'pdf',
      orientation: 'portrait' as 'portrait' | 'landscape',
      width: 1024,
      height: 768,
    },
    areaToRender?: THREE.Box3,
  ) => {
    if (!this.scene || !editorStore.camera) return;
    this.setRendering(true);

    const outputWidth = target.width || 800;
    const outputHeight = target.height || 600;
    const outputAspect = outputWidth / outputHeight;
    const zoom = 1;

    // Clone the camera to avoid modifying the original
    const offScreenCamera = this.duplicateCamera(editorStore.camera) as THREE.PerspectiveCamera;
    offScreenCamera.aspect = outputAspect;

    // Clone the scene and filter out the grid and other non-renderable objects
    const offScreenScene = this.cloneScene(this.scene);
    offScreenScene.traverse((object) => {
      if (object instanceof THREE.GridHelper) { // || object.userData.isWallLength
        object.visible = false;
      }
    });

    // Renderer setup
    const offScreenRenderer = new THREE.WebGLRenderer({
      preserveDrawingBuffer: true,
      antialias: true,
    });
    offScreenRenderer.setSize(outputWidth, outputHeight);
    offScreenRenderer.setPixelRatio(window.devicePixelRatio);
    offScreenRenderer.setClearColor(0xffffff, 1);

    // Compute the bounding box of the scene
    if (!areaToRender) {
      areaToRender = this.computeBoundingBoxForScene(offScreenScene);
    }
    const center = areaToRender.getCenter(new THREE.Vector3());
    const size = areaToRender.getSize(new THREE.Vector3());

    // Get the vertical FOV in radians
    const fovY = THREE.MathUtils.degToRad(offScreenCamera.fov);

    // Calculate the required camera distances to fit the scene's dimensions
    const halfHeight = size.y / 2;
    const halfWidth = size.x / 2;

    const cameraDistanceY = halfHeight / Math.tan(fovY / 2);
    const cameraDistanceX = halfWidth / (Math.tan(fovY / 2) * offScreenCamera.aspect);

    // Choose the larger distance to ensure both dimensions fit
    let cameraDistance = Math.max(cameraDistanceY, cameraDistanceX);
    // Adjust camera distance inversely proportional to zoom
    cameraDistance = cameraDistance / zoom;

    // Set camera position
    offScreenCamera.position.set(
      center.x,
      center.y,
      center.z + cameraDistance
    );
    offScreenCamera.lookAt(
      new THREE.Vector3(
        center.x,
        center.y,
        center.z
      )
    );
    offScreenCamera.zoom = zoom;
    offScreenCamera.updateProjectionMatrix();

    // Render the scene
    offScreenRenderer.render(offScreenScene, offScreenCamera);

    // Get the image data
    const canvas = offScreenRenderer.domElement;
    this.imageData = canvas.toDataURL();

    // Handle download or upload
    if (operation === 'downloadPDF' || operation === 'downloadPNG') {
      this.downloadFile(operation, target);
    } else if (operation === 'upload') {
      const file = await dataUrlToFile(this.imageData, `floorplan_${Date.now()}.png`);
      try {
        await this.client?.mutate<EditFloorplanMutation>({
          variables: { id: floorplannerStore.id, image: file },
          mutation: EditFloorplanDocument,
        });
      } catch (error) {
        console.error('Error uploading snapshot: ', error);
      }
    }

    // Clean up
    offScreenRenderer.dispose();
    offScreenRenderer.forceContextLoss();
    this.setRendering(false);
  };

  computeBoundingBoxForScene(scene: THREE.Scene) {
    function getObjectBox(object: THREE.Object3D) {
      if (!object) return null;
      if ([
        'panning-plane',
        'selection-plane',
        'wall-construction-plane',
        'line-construction-plane',
        'ruler-construction-plane',
        'area-construction-plane'].includes(object.userData.id)) return null;
      const box = new THREE.Box3().setFromObject(object);
      if (!isNaN(box.min.x) && !isNaN(box.min.y) && !isNaN(box.min.z)) {
        return box;
      } else {
        // object does not have a position so calculate the bounding box manually
        const box = new THREE.Box3();
        object.traverse(child => {
          if (child instanceof THREE.Mesh) {
            const geometry = child.geometry;
            geometry.computeBoundingBox();
            const childBox = geometry.boundingBox?.clone();
            if (childBox) {
              childBox.applyMatrix4(child.matrixWorld);
              box.union(childBox);
            }
          }
        });
      }
      if (!isNaN(box.min.x) && !isNaN(box.min.y) && !isNaN(box.min.z)) return box;
      else return null;
    }
    const box = new THREE.Box3();
    scene.traverse(object => {
      if (object instanceof THREE.Mesh) {
        const objectBox = getObjectBox(object);
        if (objectBox) box.union(objectBox);
      } else if (object instanceof THREE.Group) {
        object.children.forEach(child => {
          if (child instanceof THREE.Mesh) {
            const objectBox = getObjectBox(child);
            if (objectBox) box.union(objectBox);
          } else if (child instanceof THREE.Group) {
            child.children.forEach(grandChild => {
              if (grandChild instanceof THREE.Mesh) {
                const objectBox = getObjectBox(grandChild);
                if (objectBox) box.union(objectBox);
              }
            });
          }
        });
      }
    });
    return box;
  }

  downloadFile = async (
    operation: string,
    resolution: TargetFormatType,
  ) => {
    if (this.imageData !== undefined) {
      const fileName = 'floorplan';
      const width = resolution.width;
      const height = resolution.height;
      const pdf = new jsPDF(
        resolution.orientation,
        'px',
        resolution.format
      );

      if (operation === 'downloadPDF') {
        const page = pdf.addPage([width, height], resolution.orientation);
        page.addImage(this.imageData, 'PNG', 0, 0, width, height, undefined, 'FAST');
        // deletes the first page of the pdf document jsPDF adds an empty first page :O
        pdf.deletePage(1)
        pdf.save(fileName + '.pdf')
      } else if (operation === 'downloadPNG') {
        const link = document.createElement('a')
        // Make background white
        const canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')
        if (ctx) {
          ctx.fillStyle = 'white'
          ctx.fillRect(0, 0, width, height)
          const img = new Image()
          img.src = this.imageData
          img.onload = () => {
            ctx.drawImage(img, 0, 0, width, height)
            link.href = canvas.toDataURL()
            link.download = fileName + '.png'
            link.click()
          }
        }
      }
    }
  }

  setRendering(rendering: boolean) {
    this.rendering = rendering;
  }

}

export const renderStore = new RenderStore();
export const renderStoreContext = React.createContext<RenderStore>(renderStore);
export const useRenderStore = () => useContext(renderStoreContext);
