
import { useRef, useCallback } from 'react';
import * as THREE from "three";
import { ThreeEvent } from "@react-three/fiber";
import { projectToWorld } from '../components/FloorPlan/projectToWorld';
import { editorStore } from '../store/editorStore';
import { floorplannerStore } from '../store/floorplannerStore';
import { isRulerLineType, isSingleLineType, isSymbolType, isWallType } from '../types/wallTypes';
import { runInAction } from 'mobx';
import { symbol } from 'prop-types';

/**
 * Hook to drag selected objects
 * @param gl The WebGLRenderer
 * @param camera The camera
 * @returns The startDragging function and isDragging boolean
 * @example
 * const { startDragging, isDragging } = useDragSelectedObjects(gl, camera);
 * <Canvas onPointerDown={startDragging} />
 * if (isDragging.current) {
 *    // Do something while dragging
 * }
 */
export const useDragSelectedObjects = (gl: THREE.WebGLRenderer, camera: THREE.Camera) => {
    const initialPointerPosition = useRef<[number, number] | null>(null);
    const initialPositions = useRef<{ id: string, type: string, position: any }[]>([]);
    const isDraggingGroupSelection = useRef(false);
    const aboutToDrag = useRef(true);

    const startDraggingGroupSelection = useCallback((event: ThreeEvent<PointerEvent>) => {
        event.stopPropagation();
        floorplannerStore.pushToUndoStack();

        const [initialWorldX, initialWorldY] = projectToWorld(
            event.clientX,
            event.clientY,
            gl,
            camera
        );

        initialPointerPosition.current = [initialWorldX, initialWorldY];

        // Store initial positions of all selected objects
        initialPositions.current = editorStore.selections.map((selection) => {
            return {
                id: selection.id,
                type: selection.type,
                position: { ...selection.position },
            };
        });

        const onPointerMove = (moveEvent: PointerEvent) => {
            if (aboutToDrag.current) {
                floorplannerStore.pushToUndoStack();
                aboutToDrag.current = false;
            }
            const [currentWorldX, currentWorldY] = projectToWorld(
                moveEvent.clientX,
                moveEvent.clientY,
                gl,
                camera
            );

            const deltaX = currentWorldX - initialPointerPosition.current![0];
            const deltaY = currentWorldY - initialPointerPosition.current![1];

            if (!isDraggingGroupSelection.current && (Math.abs(deltaX) > 0.01 || Math.abs(deltaY) > 0.01)) {
                isDraggingGroupSelection.current = true;
            }

            // For each selected object, update its position
            runInAction(() => {
                editorStore.selections.forEach((selection) => {
                    const initialPositionData = initialPositions.current.find((p) => p.id === selection.id);
                    if (initialPositionData) {
                        const initialPosition = initialPositionData.position;
                        const newX = initialPosition.x + deltaX;
                        const newY = initialPosition.y + deltaY;

                        // Update position in the floorplannerStore
                        if (isSymbolType(selection)) {
                            floorplannerStore.updateSymbolProperty(selection.id, "position", [
                                newX,
                                newY,
                            ]);
                        } else if (isWallType(selection) || isSingleLineType(selection) || isRulerLineType(selection)) {
                            // Update wall's start and end points accordingly
                            floorplannerStore.updateObjectPosition(selection.id, new THREE.Vector2(
                                selection.start.x + deltaX,
                                selection.start.y + deltaY,
                            ), new THREE.Vector2(
                                selection.end.x + deltaX,
                                selection.end.y + deltaY,
                            ));
                        }
                        // Add more cases if you have other object types
                    }
                });
            });
        }

        const onPointerUp = () => {
            // If any symbols were dragged, check if they are attached to a wall, then detach them and re-attach
            runInAction(() => {
                editorStore.selections.forEach((selection) => {
                    if (isSymbolType(selection)) {
                        const attachedWall = floorplannerStore.symbolIsAttached(selection.id);
                        if (attachedWall) {
                            const symbol = floorplannerStore.symbolsMap.get(selection.id);
                            if (!symbol) return;
                            floorplannerStore.detachSymbolFromWall(symbol.id);
                            floorplannerStore.attachSymbolToWall(selection.id, attachedWall, [symbol.position.x, symbol.position.y]);
                        }
                    } else if (isWallType(selection) || isSingleLineType(selection) || isRulerLineType(selection)) {
                        floorplannerStore.markObjectForRoomUpdate(selection.id);
                    }
                });
            });
            window.removeEventListener("pointermove", onPointerMove);
            window.removeEventListener("pointerup", onPointerUp);
            floorplannerStore.setBlockDirty(false);
            floorplannerStore.setDirty();
            isDraggingGroupSelection.current = false;
        };

        window.addEventListener("pointermove", onPointerMove);
        window.addEventListener("pointerup", onPointerUp);
        floorplannerStore.setBlockDirty(true);
    }, [gl, camera]);

    return { startDraggingGroupSelection, isDraggingGroupSelection };
};
