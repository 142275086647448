import * as THREE from "three";
import { floorplannerStore, FloorplannerStore } from '../../store/floorplannerStore';
import {
  isWallType,
  RulerLineType,
  SingleLineType,
  SymbolType,
  WallConnectionEnd,
  WallConnectionStart,
  WallType,
} from "../../types/wallTypes";
import { renderStore } from "../../store/renderStore";

export const updateWallClippings = (wallId: string, symbolId?: string) => {
  const wall = floorplannerStore?.wallsMap.get(wallId);
  if (!wall) return;

  renderStore.clearSymbolClippings(wall.id);
  const symbols = [] as SymbolType[];
  if (symbolId) {
    const symbol = floorplannerStore?.symbolsMap.get(symbolId);
    if (symbol) {
      symbols.push(symbol);
    }
  } else {
    wall.symbolAttachments?.forEach((symbolAttachment) => {
      const symbol = floorplannerStore?.symbolsMap.get(symbolAttachment.symbolId);
      if (symbol) {
        symbols.push(symbol);
      }
    });
  }
  // Add the new symbol clippings
  symbols.forEach((symbol) => {
    const clippingPolygon = renderStore.getClippingPolygon(
      symbol,
      wall
    );
    if (clippingPolygon) {
      renderStore.addWallClipping(wall.id, clippingPolygon);
    }
  })
}

export const updateWallConnections = (
  source: string,
  dragHandle: string | null,
  updatedWalls?: string[],
  floorplannerStore?: FloorplannerStore
) => {
  const wall = floorplannerStore?.findObjectId(source) as WallType | SingleLineType | RulerLineType;
  const newUpdatedWalls = updatedWalls || [];
  let updated = false;
  if (!wall || !wall.connections) return;
  wall.connections?.forEach((c) => {
    let object = floorplannerStore?.findObjectId(c.id);
    if (object) {
      if (c.sourcePosition === WallConnectionStart) {
        // A connection from the start of the wall
        if (dragHandle === "start" || dragHandle === "middleV" || dragHandle === "middleH") {
          if (
            c.targetPosition === WallConnectionStart &&
            (object.start.x !== wall.start.x ||
              object.start.y !== wall.start.y)
          ) {
            object.start = new THREE.Vector2(wall.start.x, wall.start.y);
            updated = true;
          } else if (
            c.targetPosition === WallConnectionEnd &&
            (object.end.x !== wall.start.x ||
              object.end.y !== wall.start.y)
          ) {
            object.end = new THREE.Vector2(wall.start.x, wall.start.y);
            updated = true;
          }
        }
      } else if (c.sourcePosition === WallConnectionEnd) {
        // A connection from the end of the wall
        if (dragHandle === "end" || dragHandle === "middleV" || dragHandle === "middleH") {
          if (
            c.targetPosition === WallConnectionStart &&
            (object.start.x !== wall.end.x ||
              object.start.y !== wall.end.y)
          ) {
            object.start = new THREE.Vector2(wall.end.x, wall.end.y);
            updated = true;
          } else if (
            c.targetPosition === WallConnectionEnd &&
            (object.end.x !== wall.end.x ||
              object.end.y !== wall.end.y)
          ) {
            object.end = new THREE.Vector2(wall.end.x, wall.end.y);
            updated = true;
          }
        }
      } else {
        // A connection from a custom position of the wall
        const wallDirection = new THREE.Vector2()
          .subVectors(wall.end, wall.start)
          .normalize();
        const newPosition = new THREE.Vector2().addVectors(
          wall.start,
          wallDirection.clone().multiplyScalar(c.sourcePosition),
        );
        if (
          c.targetPosition === WallConnectionStart &&
          (object.start.x !== newPosition.x ||
            object.start.y !== newPosition.y)
        ) {
          object.start = newPosition;
          updated = true;
        } else if (
          c.targetPosition === WallConnectionEnd &&
          (object.end.x !== newPosition.x ||
            object.end.y !== newPosition.y)
        ) {
          object.end = newPosition;
          updated = true;
        }
      }
    }
  });
  if (isWallType(wall)) {
    wall.symbolAttachments?.forEach((symbolAttachment) => {
      // Update symbol attachment positions
      const symbol = floorplannerStore?.symbolsMap.get(symbolAttachment.symbolId);
      if (!symbol) return;
      //const tempWall = floorplannerStore?.walls[wall.id];
      const tempWall = floorplannerStore?.wallsMap.get(wall.id);
      if (!tempWall) return;
      const wallDirection = new THREE.Vector2()
        .subVectors(tempWall.end, tempWall.start)
        .normalize();
      const newPosition = new THREE.Vector2().addVectors(
        tempWall.start,
        wallDirection.clone().multiplyScalar(symbolAttachment.positionFromStart),
      );
      if (newPosition.x !== symbol.position.x || newPosition.y !== symbol.position.y) {
        const newWallAngle = Math.atan2(
          tempWall.end.y - tempWall.start.y,
          tempWall.end.x - tempWall.start.x,
        );
        floorplannerStore?.updateSymbolProperty(
          symbolAttachment.symbolId,
          "position",
          newPosition.toArray(),
        );
        floorplannerStore?.updateSymbolProperty(
          symbolAttachment.symbolId,
          "rotation",
          newWallAngle,
        );
        updateWallClippings(wall.id, symbol.id);
      }
    });
  }
  // Check if any connection has been updated, and if so, recursively update connections of connected walls
  if (updated) {
    wall.connections?.forEach((c) => {
      if (!newUpdatedWalls.includes(c.id)) {
        newUpdatedWalls.push(c.id);
        updateWallConnections(
          c.id,
          null,
          newUpdatedWalls,
          floorplannerStore
        );
      }
    });
  }
};
