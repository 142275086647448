import React from 'react';
import { floorplannerStore } from '../../store/floorplannerStore';


import { RulerLineType, SingleLineType, WallType } from '../../types/wallTypes';
import { Vector2 } from 'three';
import { editorStore } from '../../store/editorStore';
import { PiLineSegmentsLight } from "react-icons/pi";
import { IoClose } from 'react-icons/io5';
import { observer } from 'mobx-react-lite';
import AreaIcon from '../../../src/assets/Measure area new-01.png'
import RulerIcon from '../../assets/symbols/Measure length_Rityta 1.png';
import DefaultIcon from '../../assets/defaultIcon.png';
interface DropdownProps {
  position: { top: number; left: number };
  onClose: () => void;
  dropdownRef: React.RefObject<HTMLDivElement>;
}

export const MeasurePanel: React.FC<DropdownProps> = observer(({ position, onClose, dropdownRef }) => {

  // Prevent clicks inside the panel from closing it
  const handlePanelClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();  // Prevents the event from bubbling up
  };

  const handleIconClick = (label: string, props?: any) => {
    // Retrieve the visible center from the editor store
    const [centerX, centerY] = editorStore.getVisibleCenter();
 if (label === "RulerLine") {
      // Set a default line length, for example 5 units (adjust based on your needs)
      const rulerLineLength = 2;
      // Calculate the start and end points of the wall, centered around the visible center
      const startX = centerX - rulerLineLength / 2;
      const endX = centerX + rulerLineLength / 2;
      const startY = centerY;
      const endY = centerY;

      const line: RulerLineType = {
        id: floorplannerStore.generateId(),
        type: "rulerLine",
        start: new Vector2(startX, startY), // Start point to the left of the center
        end: new Vector2(endX, endY), // End point to the right of the center
      }
      floorplannerStore.addRulerLine(line);
    }  else if (label === "RulerConstructionMode") {
      editorStore.setRulerConstructionMode(!editorStore.rulerConstructionMode);
      onClose();
    }  else if (label === "AreaConstructionMode") {
      editorStore.setAreaConstructionMode(!editorStore.areaConstructionMode);
      onClose();
    }  else if (label === "AreaSymbol") {
      floorplannerStore.addSymbol("area", [0, 0], {
        vertices: [
          new Vector2(0, 0),
          new Vector2(1, 0),
          new Vector2(1.5, 0.5),
          new Vector2(1, 1),
          new Vector2(0, 1),
        ],
      });
    }
  };

  return (
    <div
        className="fixed z-50 w-[388px] bg-white rounded shadow-lg mb-8 overflow-y-auto scrollbar"
      style={{ top: position.top, left: position.left, maxHeight: '70vh' }}
      ref={dropdownRef}
      onClick={handlePanelClick}
    >
      <div className="flex flex-cols items-center border-b border-[#edebe6] justify-between p-2 px-4 pr-2">
        <div className="text-sm font-normal">Measuring tools library</div>
        <div onClick={onClose} className="text-sm text-gray-500"><IoClose style={{ strokeWidth: 0.5 }} size={24} /></div>
      </div>
      {/* Group 1 */}
      <div className="mb-4 p-2 px-4">
        <div className=" border-gray-300 pb-2 mb-2 mt-4 text-sm font-medium ">Measuring tools</div>
       
        <div className="w-[364px] h-[239px] pr-2 grid grid-cols-3 gap-2">
         
       {/*  <div
             className="flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6]"
             onClick={() => handleIconClick("RulerLine")}
          >
             <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={DefaultIcon} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Ruler line</div>
         
          </div> */}
        
        
          <div
            className={`flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6] `}
            //${editorStore.areaConstructionMode ? "bg-gray-400" : "bg-gray-300"}
            
            onClick={() => handleIconClick("AreaConstructionMode")}
          >
            <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={AreaIcon} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Measure area</div>
          </div>
         
          <div
            className={`flex flex-col items-center cursor-pointer w-[114px] h-[114px] relative rounded border border-[#edebe6] `}
            //${editorStore.rulerConstructionMode ? "bg-gray-400" : "bg-gray-300"}
            
            onClick={() => handleIconClick("RulerConstructionMode")}
          >
            <img className="w-[108px] h-[87px] left-[3px] top-[3px] absolute rounded-sm" src={RulerIcon} /> 
            <div className="left-[6px] top-[94px] absolute text-black text-xs font-normal font-['DM Sans']">Measuring stick</div>
          </div>
        </div>
      </div>

    </div>

  );
});