import React, { useEffect, useState, useRef, useCallback, useLayoutEffect } from "react";
import { invalidate, ThreeEvent, useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { projectToWorld } from "./projectToWorld";
import { isCircleStairsType, isCircleSymbolType, isRectStairsType, isSquareSymbolType, isSvgType, isTriangleSymbolType, SymbolType } from "../../types/wallTypes";
import { editorStore } from "../../store/editorStore";
import { floorplannerStore } from "../../store/floorplannerStore";
import { Html, Line } from "@react-three/drei";
import { useDragSelectedObjects } from "../../hooks/useDragSelectedObjects";
import { runInAction, set } from "mobx";
import { FaRotate } from "react-icons/fa6";
export interface BoundingBox {
  topLeft: [number, number];
  topRight: [number, number];
  bottomLeft: [number, number];
  bottomRight: [number, number];
  width: number;
  height: number;
  depth: number;
}

export interface SelectableSymbolProps {
  symbol: SymbolType;
  children: React.ReactNode;
  handleSize: number;
  calculateBoundingBox: () => BoundingBox;
  onResizeStart?: (event: ThreeEvent<PointerEvent>) => void;
  onResize: (newWidth: number, newHeight: number, boundingBox: BoundingBox, handle: string) => void;
  onResizeEnd?: (event: ThreeEvent<PointerEvent>) => void;
  onPointerOver?: (event: ThreeEvent<PointerEvent>) => void;
  onPointerOut?: (event: ThreeEvent<PointerEvent>) => void;
  center?: [number, number];
  rotation?: number;
  pivot?: [number, number];
  drawHandles?: boolean;
  lockAspectRatio?: boolean;
  onRotate?: (angle: number) => void;
  isDragging?: boolean;
}

/**
 * A component that allows an object to be selected and resized.
 * 
 * @param symbol The symbol to render.
 * @param children The children to render.
 * @param handleSize The size of the handles.
 * @param calculateBoundingBox The function to calculate the bounding box that each symbol use.
 * @param onResizeStart The function to call when the object starts resizing.
 * @param onResize The function to call when the object is resized.
 * @param onResizeEnd The function to call when the object stops resizing.
 * @param center The center of the object.
 * @param rotation The rotation of the object.
 * @param pivot The pivot of the object.
 * @param drawHandles Whether to draw the handles.
 * @param lockAspectRatio Whether to lock the aspect ratio.
 * @param onRotate The function to call when the object is rotated.
 * @returns The SelectableSymbol component.
 * 
 * @see AttachableSymbol
 * @see DraggableObject
 **/
export const SelectableSymbol = React.forwardRef<THREE.Group, SelectableSymbolProps>(
  ({
    symbol,
    children,
    handleSize,
    calculateBoundingBox,
    onResizeStart = () => { },
    onResize,
    onResizeEnd = () => { },
    onPointerOver = () => { },
    onPointerOut = () => { },
    center = [0, 0],
    rotation = 0,
    pivot = [0, 0],
    drawHandles = true,
    lockAspectRatio = false, // Default to false
    onRotate = () => { }, // Default to no-op
    isDragging = false,
  }, ref) => {
    const [boundingBox, setBoundingBox] = useState<BoundingBox>({
      topLeft: [0, 0],
      topRight: [0, 0],
      bottomLeft: [0, 0],
      bottomRight: [0, 0],
      width: 0,
      height: 0,
      depth: 0,
    });

    const { gl, camera } = useThree();
    const handleRadius = handleSize / editorStore.zoomLevelDivisor();
    const handleDepth = 0.02; // Depth of the handles
    const initialPointerPosition = useRef<[number, number] | null>(null);
    const isResizing = useRef(false);
    const [isRotating, setIsRotating] = useState(false);
    const groupRef = useRef<THREE.Group>(null);
    const rotationStart = useRef<number>(rotation);
    const angleRef = useRef<number>(rotation);
    const [hoveredHandle, setHoveredHandle] = useState<string | null>(null);
    const rotatedPivot = new THREE.Vector3(pivot[0], pivot[1], 0).applyMatrix4(new THREE.Matrix4().makeRotationZ(rotation));
    const [prevCameraZoom, setPrevCameraZoom] = useState(camera.zoom);
    const showMiddleHandles = isSquareSymbolType(symbol) || isCircleSymbolType(symbol) || isTriangleSymbolType(symbol);
    const { startDraggingGroupSelection, isDraggingGroupSelection } = useDragSelectedObjects(gl, camera);
    const noopRaycast = () => null;
    const htmlRef = useRef(null);
    const [classHasCursor, setClassHasCursor] = useState<Boolean>();
    const aboutToModify = useRef(false);

    const rotateVector2 = (vector: THREE.Vector2, angle: number): THREE.Vector2 => {
      const sin = Math.sin(angle);
      const cos = Math.cos(angle);
      return new THREE.Vector2(
        vector.x * cos - vector.y * sin,
        vector.x * sin + vector.y * cos
      );
    };

    const handlePointerDown = (
      event: ThreeEvent<PointerEvent>,
      corner: string
    ) => {
      event.stopPropagation();
      onResizeStart?.(event);
      floorplannerStore.pushToUndoStack();

      const initialBoundingBox = calculateBoundingBox();
      const initialAspectRatio = initialBoundingBox.width / initialBoundingBox.height;

      // Helper functions
      const getCornerLocalPosition = (corner: string, width: number, height: number) => {
        const halfWidth = width / 2;
        const halfHeight = height / 2;
        switch (corner) {
          case 'topLeft':
            return new THREE.Vector2(-halfWidth, halfHeight);
          case 'topRight':
            return new THREE.Vector2(halfWidth, halfHeight);
          case 'bottomLeft':
            return new THREE.Vector2(-halfWidth, -halfHeight);
          case 'bottomRight':
            return new THREE.Vector2(halfWidth, -halfHeight);
          case 'midRight':
            return new THREE.Vector2(halfWidth, 0);
          case 'midLeft':
            return new THREE.Vector2(-halfWidth, 0);
          case 'midTop':
            return new THREE.Vector2(0, halfHeight);
          case 'midBottom':
            return new THREE.Vector2(0, -halfHeight);
          default:
            return new THREE.Vector2(0, 0);
        }
      };

      const oppositeHandle = (corner: string) => {
        switch (corner) {
          case 'topLeft':
            return 'bottomRight';
          case 'topRight':
            return 'bottomLeft';
          case 'bottomLeft':
            return 'topRight';
          case 'bottomRight':
            return 'topLeft';
          case 'midRight':
            return 'midLeft';
          case 'midLeft':
            return 'midRight';
          case 'midTop':
            return 'midBottom';
          case 'midBottom':
            return 'midTop';
          default:
            return '';
        }
      };

      const oppositeCornerName = oppositeHandle(corner);
      const initialWidth = initialBoundingBox.width;
      const initialHeight = initialBoundingBox.height;
      const initialSymbolPosition = new THREE.Vector2(
        symbol.position.x,
        symbol.position.y
      );

      // Get the local position of the opposite corner
      const oppositeCornerLocal = getCornerLocalPosition(oppositeCornerName, initialWidth, initialHeight);

      // Rotate to get world coordinates
      const oppositeCornerWorld = oppositeCornerLocal
        .clone()
        .rotateAround(new THREE.Vector2(0, 0), rotation)
        .add(initialSymbolPosition);

      const initialPointerPosition = new THREE.Vector2(event.point.x, event.point.y);

      const onPointerMove = (moveEvent: PointerEvent) => {
        runInAction(() => {
          isResizing.current = true;
          const [worldX, worldY] = projectToWorld(
            moveEvent.clientX,
            moveEvent.clientY,
            gl,
            camera
          );
          const currentPointerPosition = new THREE.Vector2(worldX, worldY);
          const pointerDelta = currentPointerPosition.clone().sub(initialPointerPosition);

          // Rotate the pointer delta into the symbol's local coordinate system
          const deltaLocal = rotateVector2(pointerDelta, -rotation);

          let deltaWidth = 0;
          let deltaHeight = 0;

          // Adjust deltaWidth and deltaHeight based on the corner
          if (corner === "topLeft") {
            deltaWidth = -deltaLocal.x;
            deltaHeight = deltaLocal.y;
          } else if (corner === "topRight") {
            deltaWidth = deltaLocal.x;
            deltaHeight = deltaLocal.y;
          } else if (corner === "bottomLeft") {
            deltaWidth = -deltaLocal.x;
            deltaHeight = -deltaLocal.y;
          } else if (corner === "bottomRight") {
            deltaWidth = deltaLocal.x;
            deltaHeight = -deltaLocal.y;
          } else if (corner === "midRight") {
            deltaWidth = deltaLocal.x;
          } else if (corner === "midLeft") {
            deltaWidth = -deltaLocal.x;
          } else if (corner === "midTop") {
            deltaHeight = deltaLocal.y;
          } else if (corner === "midBottom") {
            deltaHeight = -deltaLocal.y;
          }

          // Calculate new width and height (smaller than 0.06 will make grab handles on top of each other)
          let newWidth = Math.max(initialWidth + deltaWidth, 0.06);
          let newHeight = Math.max(initialHeight + deltaHeight, 0.06);

          // Lock aspect ratio if needed
          if (lockAspectRatio) {
            if (Math.abs(deltaWidth) > Math.abs(deltaHeight)) {
              newHeight = newWidth / initialAspectRatio;
            } else {
              newWidth = newHeight * initialAspectRatio;
            }
          }

          // A resize usally adjust the pivot so the symbol is resized from the center
          // However, when we drag from let say top right corner, we want the symbol to only resize towards top right
          // essentially locking the opposite corner in place
          // We cannot update the pivot directly as it will affect the rotation so we must reposiiton the symbol
          // based on the new width and height compared to the initial width and height
          // Initialize newSymbolPosition
          if (
            isSvgType(symbol) ||
            isTriangleSymbolType(symbol) ||
            isSquareSymbolType(symbol) ||
            isCircleSymbolType(symbol) ||
            isRectStairsType(symbol) ||
            isCircleStairsType(symbol)) {
            const newSymbolPosition = new THREE.Vector2(initialSymbolPosition.x, initialSymbolPosition.y);

            // Get the new local position of the opposite corner after resizing
            const newOppositeCornerLocal = getCornerLocalPosition(oppositeCornerName, newWidth, newHeight);

            // Rotate to get new world coordinates
            const newOppositeCornerWorld = newOppositeCornerLocal
              .clone()
              .rotateAround(new THREE.Vector2(0, 0), rotation)
              .add(newSymbolPosition);

            // Compute the delta between the initial and new opposite corner positions
            const oppositeCornerDelta = oppositeCornerWorld.clone().sub(newOppositeCornerWorld);

            // Adjust the symbol's position
            newSymbolPosition.add(oppositeCornerDelta);

            // Update the symbol's position in the store
            floorplannerStore.updateSymbolProperty(symbol.id, "position", [
              newSymbolPosition.x,
              newSymbolPosition.y,
            ]);
          }
          // Custom tweaks for specific symbol types
          onResize(newWidth, newHeight, initialBoundingBox, corner);
        });
      };

      const onPointerUp = (upEvent: PointerEvent) => {
        window.removeEventListener("pointermove", onPointerMove);
        window.removeEventListener("pointerup", onPointerUp);
        floorplannerStore.setBlockDirty(false);
        floorplannerStore.setDirty();
        setHoveredHandle(null);
        isResizing.current = false;
        onResizeEnd?.(event);
      };

      window.addEventListener("pointermove", onPointerMove);
      window.addEventListener("pointerup", onPointerUp);
      floorplannerStore.setBlockDirty(true);
    };


    const handleGroupPointerDown = (event: ThreeEvent<PointerEvent>) => {
      // Do not interact if in construction mode
      if (editorStore.areaConstructionMode || editorStore.rulerConstructionMode || editorStore.wallConstructionMode || editorStore.lineConstructionMode) {
        return;
      }
      if (editorStore.selections.length > 1) {
        startDraggingGroupSelection(event);
      }

      initialPointerPosition.current = [event.clientX, event.clientY];
      isResizing.current = false;
      setHoveredHandle(null);

      const onPointerUp = (upEvent: PointerEvent) => {
        const [initialX, initialY] = initialPointerPosition.current!;
        const movedX = Math.abs(upEvent.clientX - initialX);
        const movedY = Math.abs(upEvent.clientY - initialY);

        if (movedX < 5 && movedY < 5 && !isResizing.current) {
          // Select the object if it was not dragged
          if (!editorStore.isShiftPressed) {
            editorStore.clearSelections();
          }
          editorStore.addSelection(symbol);
          floorplannerStore.updateSymbolProperty(symbol.id, "selected", true);
        }

        window.removeEventListener("pointerup", onPointerUp);
        floorplannerStore.setBlockDirty(false);
        floorplannerStore.setDirty();
      };

      window.addEventListener("pointerup", onPointerUp);
      floorplannerStore.setBlockDirty(true);
    };

    const handleRotatePointerDown = (handle: string, event: ThreeEvent<PointerEvent>) => {
      event.stopPropagation();
      aboutToModify.current = true;
      const initialPointer = new THREE.Vector2(event.point.x, event.point.y);
      const centerVector = new THREE.Vector2(center[0], center[1]);
      floorplannerStore.setBlockDirty(true);

      const onPointerMove = (moveEvent: PointerEvent) => {
        if (aboutToModify.current) {
          aboutToModify.current = false;
          floorplannerStore.pushToUndoStack();
        }
        setIsRotating(true);
        // Convert the clientX/clientY (screen coordinates) to world coordinates using the projectToWorld function
        const [worldX, worldY] = projectToWorld(moveEvent.clientX, moveEvent.clientY, gl, camera);

        // Current pointer position in world coordinates
        const currentPointer = new THREE.Vector2(worldX, worldY);
        // Get the symbol position in world coordinates
        const symbolPosition = new THREE.Vector2(symbol.position.x, symbol.position.y);

        // The center of the symbol (in world coordinates) should be passed here instead of assuming (0, 0)
        const symbolCenter = new THREE.Vector2(
          symbolPosition.x + centerVector.x + rotatedPivot.x,
          symbolPosition.y + centerVector.y + rotatedPivot.y
        );

        // Calculate the distance between the current pointer and the symbol's center
        const distanceToCenter = currentPointer.distanceTo(symbolCenter);

        // Define a threshold distance to avoid jumpy behavior near the center
        const threshold = 0.1;

        // If the pointer is too close to the symbol's center, ignore angle changes to prevent jumping
        if (distanceToCenter < threshold) {
          return; // Skip angle update if the pointer is too close to the center
        }

        // Calculate the initial and current angles relative to the symbol's center (when the user starts dragging and moves the mouse)
        const initialAngle = Math.atan2(initialPointer.y - symbolCenter.y, initialPointer.x - symbolCenter.x);
        const currentAngle = Math.atan2(currentPointer.y - symbolCenter.y, currentPointer.x - symbolCenter.x);

        // Calculate the delta angle (change in angle) only if the angle has changed
        let deltaAngle = currentAngle - initialAngle;

        // Ignore mouse movements that don't change the angle (i.e., pure radial movement)
        if (deltaAngle === 0) {
          return; // No change in angle, no rotation needed
        }

        // Handle angle wrapping to ensure smooth rotation across ±π boundaries
        if (deltaAngle > Math.PI) {
          deltaAngle -= 2 * Math.PI;
        } else if (deltaAngle < -Math.PI) {
          deltaAngle += 2 * Math.PI;
        }

        // Compute the new rotation angle in radians
        let newAngle = rotationStart.current + deltaAngle;

        // Snap to 45-degree increments (convert to degrees for easier snapping)
        const newAngleInDegrees = THREE.MathUtils.radToDeg(newAngle);

        // Calculate the closest 45-degree angle
        const snapInterval = 45; // Snap to 45-degree increments
        const closestSnapAngle = Math.round(newAngleInDegrees / snapInterval) * snapInterval;

        // Define a snapping threshold (e.g., 0.1 radians or 5.73 degrees)
        const snapThreshold = 0.1; // in radians, ~5.73 degrees
        const angleDifference = Math.abs(newAngleInDegrees - closestSnapAngle);

        // Check if shift key is pressed in case we should ignore snapping
        if (!moveEvent.shiftKey) {
          // If the angle is close enough to a 45-degree step, snap to it
          if (angleDifference < THREE.MathUtils.radToDeg(snapThreshold)) {
            newAngle = THREE.MathUtils.degToRad(closestSnapAngle); // Snap to closest angle
          }
        }

        // Update the angle reference and trigger the rotation callback
        angleRef.current = newAngle;
        onRotate(newAngle);

        // Update the symbol's rotation in the store
        floorplannerStore.updateSymbolProperty(symbol.id, "rotation", angleRef.current);
      };

      const onPointerUp = () => {
        aboutToModify.current = false;
        setIsRotating(false);
        floorplannerStore.setBlockDirty(false);
        // Remove event listeners
        window.removeEventListener("pointermove", onPointerMove);
        window.removeEventListener("pointerup", onPointerUp);
        rotationStart.current = angleRef.current;

        // Force a re-render (invalidate() does not work inside an event)
        setBoundingBox(calculateBoundingBox());
      };

      window.addEventListener("pointermove", onPointerMove);
      window.addEventListener("pointerup", onPointerUp);
    };

    // Define the cursor for each handle based on the current rotation
    const cursorMapToQuadant = {
      topLeft: ["nwse-resize", "nesw-resize", "nwse-resize", "nesw-resize"],
      topRight: ["nesw-resize", "nwse-resize", "nesw-resize", "nwse-resize"],
      bottomRight: ["nwse-resize", "nesw-resize", "nwse-resize", "nesw-resize"],
      bottomLeft: ["nesw-resize", "nwse-resize", "nesw-resize", "nwse-resize"],
      midTop: ["ns-resize", "ew-resize", "ns-resize", "ew-resize"],
      midBottom: ["ns-resize", "ew-resize", "ns-resize", "ew-resize"],
      midLeft: ["ew-resize", "ns-resize", "ew-resize", "ns-resize"],
      midRight: ["ew-resize", "ns-resize", "ew-resize", "ns-resize"],
    };

    const handlePointerEnter = (handle: keyof typeof cursorMapToQuadant) => {
      const normalizedRotation = (rotation % (Math.PI * 2) + Math.PI * 2) % (Math.PI * 2); // Normalize rotation within [0, 2*PI)
      const quadrant = Math.floor((normalizedRotation + Math.PI / 4) / (Math.PI / 2)) % 4; // Adjust quadrant for a smoother transition

      // Set the cursor based on the handle and the current quadrant
      document.body.style.cursor = cursorMapToQuadant[handle][quadrant] || "auto";

      setHoveredHandle(handle);
    };
    
    const handlePointerLeave = () => {
      document.body.style.cursor = "auto";
      setHoveredHandle(null);
    };

    const rotationMatrix = new THREE.Matrix4().makeRotationZ(rotation);
    const transformedCenter = new THREE.Vector3(center[0], center[1], 0).applyMatrix4(rotationMatrix);

    const getTransformedCorner = (x: number, y: number) => {
      const corner = new THREE.Vector3(x, y, 0);
      corner.applyMatrix4(rotationMatrix);
      return corner;
    };

    const topLeft = getTransformedCorner(boundingBox.topLeft[0], boundingBox.topLeft[1]);
    const topRight = getTransformedCorner(boundingBox.topRight[0], boundingBox.topRight[1]);
    const bottomLeft = getTransformedCorner(boundingBox.bottomLeft[0], boundingBox.bottomLeft[1]);
    const bottomRight = getTransformedCorner(boundingBox.bottomRight[0], boundingBox.bottomRight[1]);

    const isHovered = (handle: string) => hoveredHandle === handle;
    // Calculate midpoints
    const midLeft = new THREE.Vector3(
      (topLeft.x + bottomLeft.x) / 2 + rotatedPivot.x,
      (topLeft.y + bottomLeft.y) / 2 + rotatedPivot.y,
      0
    );

    const midRight = new THREE.Vector3(
      (topRight.x + bottomRight.x) / 2 + rotatedPivot.x,
      (topRight.y + bottomRight.y) / 2 + rotatedPivot.y,
      0
    );
    const midTop = new THREE.Vector3(
      (topLeft.x + topRight.x) / 2 + rotatedPivot.x,
      (topLeft.y + topRight.y) / 2 + rotatedPivot.y,
      0
    );

    const midBottom = new THREE.Vector3(
      (bottomLeft.x + bottomRight.x) / 2 + rotatedPivot.x,
      (bottomLeft.y + bottomRight.y) / 2 + rotatedPivot.y,
      0
    );

    // Define the distance from the top center to the rotation handle
    const rotationHandleDistance = 0.28 / editorStore.zoomLevelDivisor();

    // Calculate the upward vector based on the symbol's rotation
    const upVector = new THREE.Vector3(0, 1, 0)
      .applyQuaternion(new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 0, 1), rotation));

    // Calculate the rotation handle position
    const rotationHandlePos = new THREE.Vector3(
      midTop.x + rotatedPivot.x + upVector.x * rotationHandleDistance,
      midTop.y + rotatedPivot.y + upVector.y * rotationHandleDistance,
      0
    );

    useFrame(() => {
      if (camera.zoom !== prevCameraZoom) {
        setPrevCameraZoom(camera.zoom);
        invalidate(); // Trigger a re-render
      }
    });

    // Effect to trigger the bounding box update
    useEffect(() => {
      if (groupRef.current) {
        const boundingBox = calculateBoundingBox();
        setBoundingBox(boundingBox);
      }

    }, [symbol.position, symbol.rotation, isResizing.current, isRotating, prevCameraZoom]);

    // Synchronous bounding box update with useLayoutEffect
    useLayoutEffect(() => {
      if (groupRef.current) {
        const newBoundingBox = calculateBoundingBox();
        setBoundingBox(newBoundingBox);
        invalidate(); // Ensure the canvas re-renders after updating the bounding box
      }
    }, [symbol.position, symbol.rotation, calculateBoundingBox, camera.zoom, rotation]);

    useEffect(() => {
      setTimeout(() => invalidate(), 1000); // Triggers a re-render shortly after initial load
    }, []);

    return (
      <group ref={groupRef}>
        {/* Draw a transparent plane to capture pointer events */}
        <mesh
          position={
            [
              transformedCenter.x + rotatedPivot.x,
              transformedCenter.y + rotatedPivot.y,
              -0.001,
            ]
          }
          rotation={[0, 0, rotation]}
          onPointerDown={handleGroupPointerDown}
          onPointerOver={onPointerOver}
          onPointerOut={onPointerOut}
        >
          <planeGeometry args={[boundingBox.width, boundingBox.height]} />
          <meshBasicMaterial

            color={symbol.selected ? "yellow" : "white"}
            transparent
            opacity={0}
          // color={symbol.selected ? "yellow" : "white"}
          />
        </mesh>
        {children}
        {(symbol.selected && editorStore.selections.length === 1 && drawHandles && !isResizing.current && !isDragging && !floorplannerStore.symbolIsAttached(symbol.id) && (
          <>
            {/* Rotation Handle */}
            <Line
              points={[
                new THREE.Vector3(midTop.x, midTop.y, 0),
                rotationHandlePos.sub(rotatedPivot),
              ]}
              color="gray"
              linewidth={1}
              raycast={noopRaycast}
            />
            <group
              position={[rotationHandlePos.x, rotationHandlePos.y, 0]}
              onPointerDown={(e) => handleRotatePointerDown("midTop", e)}
              onPointerEnter={() => {
                if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.add("cursor-vector");
              }}
              onPointerLeave={() => {
                if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.remove("cursor-vector");
              }}
            >
              {/* Transparent plane to capture events */}
              <mesh
                position={[0, 0, 0]} // Align with your Html content
              >
                <planeGeometry args={[0.3 / editorStore.zoomLevelDivisor(), 0.3 / editorStore.zoomLevelDivisor()]} />
                <meshBasicMaterial
                  //color={"green"}
                  transparent opacity={0}
                />
              </mesh>
              {/* Use Html component from drei to render the FaRotate icon */}
              <Html
                ref={htmlRef}
                center
                style={{
                  pointerEvents: "none",
                  transform: `rotate(${-THREE.MathUtils.radToDeg(symbol.rotation as number)}deg)`,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "fit-content",
                    height: "fit-content",
                  }}
                >
                  <FaRotate size={18} color={"black"} />
                </div>
              </Html>
            </group>
          </>
        ))}
        {(symbol.selected && drawHandles && !isResizing.current && !isRotating && !isDragging && editorStore.selections.length === 1) && (
          <>
            {/* Draw a thin blue line between all four corners (Using Line of drei) */}
            <Line
              points={[topLeft.clone().add(new THREE.Vector3(
                rotatedPivot.x,
                rotatedPivot.y,
                0)), topRight.clone().add(new THREE.Vector3(
                  rotatedPivot.x,
                  rotatedPivot.y,
                  0))]}
              color="blue"
              linewidth={0.4}
              raycast={noopRaycast}
            />
            <Line
              points={[topRight.clone().add(new THREE.Vector3(
                rotatedPivot.x,
                rotatedPivot.y,
                0)), bottomRight.clone().add(new THREE.Vector3(
                  rotatedPivot.x,
                  rotatedPivot.y,
                  0))]}
              color="blue"
              linewidth={0.4}
              raycast={noopRaycast}
            />
            <Line
              points={[bottomRight.clone().add(new THREE.Vector3(
                rotatedPivot.x,
                rotatedPivot.y,
                0)), bottomLeft.clone().add(new THREE.Vector3(
                  rotatedPivot.x,
                  rotatedPivot.y,
                  0))]}
              color="blue"
              linewidth={0.4}
              raycast={noopRaycast}
            />
            <Line
              points={[bottomLeft.clone().add(new THREE.Vector3(
                rotatedPivot.x,
                rotatedPivot.y,
                0)), topLeft.clone().add(new THREE.Vector3(
                  rotatedPivot.x,
                  rotatedPivot.y,
                  0))]}
              color="blue"
              linewidth={0.4}
              raycast={noopRaycast}
            />

            {/* Top Right Handle */}
            <group
              position={[
                topRight.x + rotatedPivot.x,
                topRight.y + rotatedPivot.y,
                0,
              ]}
              onPointerDown={(e) => handlePointerDown(e, "topRight")}
              onPointerOver={() => handlePointerEnter("topRight")}
              onPointerOut={handlePointerLeave}
            >
              <mesh>
                <torusGeometry args={[handleRadius - 0.002, 0.005, 16, 100]} />
                <meshBasicMaterial color="blue" />
              </mesh>
              <mesh
                position={[0, 0, handleDepth / 2]}
                rotation={[Math.PI / 2, 0, 0]}
              >
                <cylinderGeometry args={[handleRadius - 0.02, handleRadius - 0.006, handleDepth, 32]} />
                <meshBasicMaterial color={isHovered("topRight") ? "blue" : "white"} />
              </mesh>
            </group>


            {/* Top Left Handle */}
            <group
              position={[
                topLeft.x + rotatedPivot.x,
                topLeft.y + rotatedPivot.y,
                0,
              ]}
              onPointerDown={(e) => handlePointerDown(e, "topLeft")}
              onPointerOver={() => handlePointerEnter("topLeft")}
              onPointerOut={handlePointerLeave}
            >
              <mesh>
                <torusGeometry args={[handleRadius - 0.002, 0.005, 16, 100]} />
                <meshBasicMaterial color="blue" />
              </mesh>
              <mesh
                position={[0, 0, handleDepth / 2]}
                rotation={[Math.PI / 2, 0, 0]}
              >
                <cylinderGeometry args={[handleRadius - 0.02, handleRadius - 0.006, handleDepth, 32]} />
                <meshBasicMaterial color={isHovered("topLeft") ? "blue" : "white"} />
              </mesh>
            </group>

            {/* Bottom Right Handle */}
            <group
              position={[
                bottomRight.x + rotatedPivot.x,
                bottomRight.y + rotatedPivot.y,
                0,
              ]}
              onPointerDown={(e) => handlePointerDown(e, "bottomRight")}
              onPointerOver={() => handlePointerEnter("bottomRight")}
              onPointerOut={handlePointerLeave}
            >
              <mesh>
                <torusGeometry args={[handleRadius - 0.002, 0.005, 16, 100]} />
                <meshBasicMaterial color="blue" />
              </mesh>
              <mesh
                position={[0, 0, handleDepth / 2]}
                rotation={[Math.PI / 2, 0, 0]}
              >
                <cylinderGeometry args={[handleRadius - 0.02, handleRadius - 0.006, handleDepth, 32]} />
                <meshBasicMaterial color={isHovered("bottomRight") ? "blue" : "white"} />
              </mesh>
            </group>

            {/* Bottom Left Handle */}
            <group
              position={[
                bottomLeft.x + rotatedPivot.x,
                bottomLeft.y + rotatedPivot.y,
                0,
              ]}
              onPointerDown={(e) => handlePointerDown(e, "bottomLeft")}
              onPointerOver={() => handlePointerEnter("bottomLeft")}
              onPointerOut={handlePointerLeave}
            >
              <mesh>
                <torusGeometry args={[handleRadius - 0.002, 0.005, 16, 100]} />
                <meshBasicMaterial color="blue" />
              </mesh>
              <mesh
                position={[0, 0, handleDepth / 2]}
                rotation={[Math.PI / 2, 0, 0]}
              >
                <cylinderGeometry args={[handleRadius - 0.02, handleRadius - 0.006, handleDepth, 32]} />
                <meshBasicMaterial color={isHovered("bottomLeft") ? "blue" : "white"} />
              </mesh>
            </group>

            {showMiddleHandles && (
              <>
                {/* Middle Right Handle */}
                <group
                  position={[midRight.x, midRight.y, 0]}
                  onPointerDown={(e) => handlePointerDown(e, "midRight")}
                  onPointerOver={() => handlePointerEnter("midRight")}
                  onPointerOut={handlePointerLeave}
                >
                  <mesh>
                    <torusGeometry args={[handleRadius - 0.002, 0.005, 16, 100]} />
                    <meshBasicMaterial color="blue" />
                  </mesh>
                  <mesh
                    position={[0, 0, handleDepth / 2]}
                    rotation={[Math.PI / 2, 0, 0]}
                  >
                    <cylinderGeometry args={[handleRadius - 0.02, handleRadius - 0.006, handleDepth, 32]} />
                    <meshBasicMaterial color={isHovered("midRight") ? "blue" : "white"} />
                  </mesh>
                </group>

                {/* Middle Left Handle */}
                <group
                  position={[midLeft.x, midLeft.y, 0]}
                  onPointerDown={(e) => handlePointerDown(e, "midLeft")}
                  onPointerOver={() => handlePointerEnter("midLeft")}
                  onPointerOut={handlePointerLeave}
                >
                  <mesh>
                    <torusGeometry args={[handleRadius - 0.002, 0.005, 16, 100]} />
                    <meshBasicMaterial color="blue" />
                  </mesh>
                  <mesh
                    position={[0, 0, handleDepth / 2]}
                    rotation={[Math.PI / 2, 0, 0]}
                  >
                    <cylinderGeometry args={[handleRadius - 0.02, handleRadius - 0.006, handleDepth, 32]} />
                    <meshBasicMaterial color={isHovered("midLeft") ? "blue" : "white"} />
                  </mesh>
                </group>

                {/* Middle Top Handle */}
                <group
                  position={[midTop.x, midTop.y, 0]}
                  onPointerDown={(e) => handlePointerDown(e, "midTop")}
                  onPointerOver={() => handlePointerEnter("midTop")}
                  onPointerOut={handlePointerLeave}
                >
                  <mesh>
                    <torusGeometry args={[handleRadius - 0.002, 0.005, 16, 100]} />
                    <meshBasicMaterial color="blue" />
                  </mesh>
                  <mesh
                    position={[0, 0, handleDepth / 2]}
                    rotation={[Math.PI / 2, 0, 0]}
                  >
                    <cylinderGeometry args={[handleRadius - 0.02, handleRadius - 0.006, handleDepth, 32]} />
                    <meshBasicMaterial color={isHovered("midTop") ? "blue" : "white"} />
                  </mesh>
                </group>

                {/* Middle Bottom Handle */}
                <group
                  position={[midBottom.x, midBottom.y, 0]}
                  onPointerDown={(e) => handlePointerDown(e, "midBottom")}
                  onPointerOver={() => handlePointerEnter("midBottom")}
                  onPointerOut={handlePointerLeave}
                >
                  <mesh>
                    <torusGeometry args={[handleRadius - 0.002, 0.005, 16, 100]} />
                    <meshBasicMaterial color="blue" />
                  </mesh>
                  <mesh
                    position={[0, 0, handleDepth / 2]}
                    rotation={[Math.PI / 2, 0, 0]}
                  >
                    <cylinderGeometry args={[handleRadius - 0.02, handleRadius - 0.006, handleDepth, 32]} />
                    <meshBasicMaterial color={isHovered("midBottom") ? "blue" : "white"} />
                  </mesh>
                </group>
              </>
            )}

          </>
        )}
      </group>
    );
  }
);
