import * as THREE from "three";

export const createFirstWallSegment = (
  wallStart: THREE.Vector2,
  startPosition: number,
  endPosition: number,
  wallDirection: THREE.Vector2,
  perpendicular: THREE.Vector2,
  lineWeight: number,
  wallWidth: number,
  topLeft: THREE.Vector2,
  topRight: THREE.Vector2,
  shapeType: string,
): THREE.Shape => {
  const shape = new THREE.Shape();
  const segmentEnd = wallStart
    .clone()
    .add(wallDirection.clone().multiplyScalar(endPosition));
  const shiftDistance = wallWidth / 2;

  shape.moveTo(topLeft.x, topLeft.y); // övre
  shape.lineTo(topRight.x, topRight.y); // undre
  if (shapeType === "left") {
    shape.lineTo(
      segmentEnd.x - perpendicular.x * (shiftDistance + lineWeight),
      segmentEnd.y - perpendicular.y * (shiftDistance + lineWeight),
    );
    shape.lineTo(
      segmentEnd.x - perpendicular.x * shiftDistance,
      segmentEnd.y - perpendicular.y * shiftDistance,
    );
  } else if (shapeType === "right") {
    shape.lineTo(
      segmentEnd.x + perpendicular.x * shiftDistance,
      segmentEnd.y + perpendicular.y * shiftDistance,
    ); // undre
    shape.lineTo(
      segmentEnd.x + perpendicular.x * (shiftDistance + lineWeight),
      segmentEnd.y + perpendicular.y * (shiftDistance + lineWeight),
    ); // övre
  } else {
    shape.lineTo(
      segmentEnd.x - perpendicular.x * shiftDistance,
      segmentEnd.y - perpendicular.y * shiftDistance,
    ); // undre
    shape.lineTo(
      segmentEnd.x + perpendicular.x * shiftDistance,
      segmentEnd.y + perpendicular.y * shiftDistance,
    ); // övre
  }
  shape.closePath();

  return shape;
};
