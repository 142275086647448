import React, { useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { MAX_PLANE_SIZE } from '../Views/PanningPlane';

const Grid: React.FC = () => {
  const { scene } = useThree();

  useEffect(() => {
    const size = MAX_PLANE_SIZE * 1.5;

    const majorUnitSize = 1;   // Major grid lines every 1 unit
    const fineUnitSize = 0.1;  // Fine grid lines every 0.1 units

    // Calculate the number of major divisions (cells)
    const divisionsMajor = Math.ceil(size / majorUnitSize);

    // Calculate the number of fine divisions (cells)
    const divisionsFine = divisionsMajor * (majorUnitSize / fineUnitSize);

    // Adjust the grid size to align with the major units
    const adjustedSize = divisionsMajor * majorUnitSize;

    // Create the fine grid (lines every 0.1 units)
    const gridHelperFine = new THREE.GridHelper(
      adjustedSize,
      divisionsFine,
      0xdddddd, // Color of the center line (if any)
      0xdddddd  // Color of the grid lines
    );
    gridHelperFine.rotation.x = Math.PI / 2; // Align with XY plane
    gridHelperFine.position.z = -0.009;      // Slight Z offset
    scene.add(gridHelperFine);

    // Create the major grid (lines every 1 unit)
    const gridHelperMajor = new THREE.GridHelper(
      adjustedSize,
      divisionsMajor,
      0xbbbbbb, // Color of the center line (if any)
      0xbbbbbb  // Color of the grid lines
    );
    gridHelperMajor.rotation.x = Math.PI / 2; // Align with XY plane
    gridHelperMajor.position.z = -0.008;      // Slight Z offset to prevent z-fighting
    scene.add(gridHelperMajor);

    return () => {
      scene.remove(gridHelperFine);
      scene.remove(gridHelperMajor);
    };
  }, [scene]);

  return null;
};

export default Grid;
