import React from 'react';
import { IoClose } from "react-icons/io5";

interface DropdownProps {
    position: { top: number; left: number };
    onClose: () => void;
    dropdownRef: React.RefObject<HTMLDivElement>;
}

export const TemplatesPanel: React.FC<DropdownProps> = ({ position, onClose, dropdownRef }) => {
    const handleIconClick = (label: string) => {
        onClose(); // Close the dropdown after an icon is clicked
    };

    return (
        <div
            className="fixed z-50 w-80 min-h-40 bg-white border border-gray-300 rounded shadow-md overflow-y-auto scrollbar"
            style={{ top: position.top, left: position.left, maxHeight: '70vh' }}
            ref={dropdownRef}
        >
            <div className="flex flex-cols items-center justify-between p-2">
                <div className="text-sm font-normal">Templates</div>
                <div onClick={onClose} className="text-sm text-gray-500"><IoClose style={{ strokeWidth: 0.5 }} size={24} /></div>
            </div>

            {/* Divider line */}
            <div className="border-b border-gray-300 w-full" />

            {/* Content */}

        </div>

    );
};