import React, { useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Toolbar from "../components/Views/Toolbar";
import LeftPanel from "../components/Views/LeftPanel";
import FloorPlan from "../components/Views/FloorPlan";
import { userStoreContext } from "../store/userStore";
import { FloorplannerStoreContext } from "../store/floorplannerStore";
import { ApolloClient } from "@apollo/client";
import PropertyPanel from "../components/Views/PropertyPanel";
import { editorStoreContext } from '../store/editorStore';
import ZoomControls from "../components/FloorPlan/ZoomControls";
import { renderStore } from "../store/renderStore";
import { DownloadProject } from "../components/Views/DownloadProject";
import { AddToPresentation } from "../components/Views/AddToPresentation";
import Clarity from '@microsoft/clarity';
import HistoryControls from "../components/FloorPlan/HistoryControls";

const Main: React.FC<{
  client: ApolloClient<any>;
}> = observer(({ client }) => {
  const initializedRef = useRef(false);
  const userStore = useContext(userStoreContext);
  const editorStore = useContext(editorStoreContext);
  const floorplanStore = useContext(FloorplannerStoreContext);
  const { id } = useParams();
  const downloadProjectRef = useRef<HTMLDivElement>(null);
  const addToPresentationRef = useRef<HTMLDivElement>(null);
  const clarityProjectId = process.env.REACT_APP_CLARITY_PROJECT_ID;

  // Initialize Clarity project
  useEffect(() => {
    if (clarityProjectId) {
      Clarity.init(clarityProjectId);
    }
  }, [clarityProjectId]);

  useEffect(() => {
    if (!initializedRef.current) {
      try {
        userStore.initialize(client);
        editorStore.initialize(client);
        floorplanStore.initialize(client, id);
        renderStore.initialize(client);
      } catch (error) {
        console.error("Failed to initialize stores", error);
      }
      initializedRef.current = true;
    }
  }, [client, floorplanStore, userStore, editorStore]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // If click target is outside any of the popups, close them
      if (
        downloadProjectRef.current &&
        !downloadProjectRef.current.contains(event.target as Node)
      ) {
        editorStore.closeDownloadProjectPopup();
      }
      if (
        addToPresentationRef.current &&
        !addToPresentationRef.current.contains(event.target as Node)
      ) {
        editorStore.closeAddToPresentationPopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [downloadProjectRef, addToPresentationRef, editorStore]);

  return (
    <div className="w-screen h-screen flex flex-col">
      {/* Main Content */}
      <div
        className="flex flex-grow overflow-hidden"
      >
        {/* Floor Plan */}
        <div className="flex-grow bg-[#EAEAEA] overflow-hidden relative">
          <FloorPlan />
        </div>
        {/* Toolbar, make it a floatable bar wide as necessary, background of #FCFCFC and rounded corners
        with a frame and a drop shadow fixed on top 3 margin but centered in window */}
        <div className="absolute top-3 left-1/2 transform -translate-x-1/2 bg-toolbkg rounded-md border border-gray-300 shadow-md">
          <Toolbar />
        </div>

        {/* LeftPanel */}
        <div className="absolute top-3 left-3 w-40 bg-toolbkg rounded-md border border-gray-300 shadow-md">
          <LeftPanel />
        </div>

        {/* PropertyPanel, make it a floatable bar minimum width of w-56 and height scrollable background of custom color FCFCFC.  
        Rounded corners with a frame and a drop shadow fixed to top, right and bottom with 3 margin */}
        <div className={`absolute top-3 right-3 ${editorStore.minimized ? "" : "bottom-3"} bg-toolbkg rounded-md border border-gray-300 shadow-md overflow-y-auto w-[226px] select-none`}>
          <PropertyPanel />
        </div>
        <ZoomControls
          className={`absolute bottom-3 ${editorStore.minimized ? "right-3" : "right-[246px]"}`}
        />
        <HistoryControls className="absolute bottom-3 left-3" />
        {editorStore.downloadProjectVisible && (
          <DownloadProject
            position={editorStore.downloadProjectPosition}
            onClose={editorStore.closeDownloadProjectPopup}
            dropdownRef={downloadProjectRef}
          />
        )}
        {editorStore.addToPresentationVisible && (
          <AddToPresentation
            position={editorStore.addToPresentationPosition}
            onClose={editorStore.closeAddToPresentationPopup}
            dropdownRef={addToPresentationRef}
          />
        )}
      </div>
    </div>
  );
});

export default Main;
