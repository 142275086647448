import { computed, makeAutoObservable, ObservableMap, runInAction } from "mobx";
import * as THREE from "three";
import {
  CutoutType,
  WallType,
  ClippingPolygonType,
  DoorType,
  DoubleDoorType,
  WindowType,
  CircleStairsType,
  RectStairsType,
  SvgType,
  SymbolType,
  isDoorType,
  isDoubleDoorType,
  isWindowType,
  WallConnectionStart,
  WallConnectionEnd,
  WallConnectionType,
  isCircleStairsType,
  isRectStairsType,
  isSvgType,
  TextType,
  isTextType,
  isSquareSymbolType,
  SquareSymbolType,
  isCircleSymbolType,
  isTriangleSymbolType,
  SingleLineType,
  isWallType,
  isSingleLineType,
  RulerLineType,
  isRulerLineType,
  isAreaSymbolType,
  RoomType,
} from "../types/wallTypes";
import { createContext, useContext } from "react";
import { ApolloClient, ApolloError, gql } from "@apollo/client";
import {
  DeleteFloorplanDocument,
  EditFloorplanDocument,
  EditFloorplanMutation,
  FloorplanDocument,
  FloorplansDocument,
} from "../gql";
import { checkFloorplannerStoreIntegrity } from "./checkFloorplannerStoreIntegrity";
import { updateWallConnections } from "../components/FloorPlan/updateWallConnections";
import { editorStore } from "./editorStore";
import { snapToOtherObjects, snapToAlignmentLines } from './snapToOtherObjects';
import { renderStore } from "./renderStore";

const LOCAL_STORAGE_KEY = "floorplannerStoreState";
const UNDO_REDO_LIMIT = 50;
export class FloorplannerStore {
  private roomsFound: Set<string> = new Set<string>();
  private objectIdsInFoundRooms: Set<string> = new Set<string>();
  scene: THREE.Scene | undefined;

  wallsMap: ObservableMap<string, WallType> = new ObservableMap();
  symbolsMap: ObservableMap<string, SymbolType> = new ObservableMap();
  @computed get symbolKeys() { return Array.from(this.symbolsMap.keys()); } // used as a workaround for observable map keys not being reactive
  singleLinesMap: ObservableMap<string, SingleLineType> = new ObservableMap();
  rulerLinesMap: ObservableMap<string, RulerLineType> = new ObservableMap();
  roomsMap: ObservableMap<string, RoomType> = new ObservableMap();
  client: ApolloClient<any> | undefined;
  dirty: boolean = false;
  blockDirty: boolean = false;
  id: string = "";
  isSaving: boolean = false;
  initialized: boolean = false;
  undoStack: string[] = [];
  redoStack: string[] = [];
  boundingBox: THREE.Box3 | null = null;

  // Default properties
  wallWidth: number = 0.1;
  lineWeight: number = 0.5;
  lineColor: number = 0x000000;
  fillColor: number = 0xffffff; //0x888888;
  hoverColor: number = 0x0000ff;
  wallLineWeight = 1.5;
  singleLineWeight = 1.5;
  rulerLineWeight = 0.5;
  doorWidth = 0.9;
  doorFrameWidth = 0.05;
  doorLineWeight = 1;
  doorBladeThickness = 0.05;
  doubleDoorWidth = this.doorWidth;
  openAngle = Math.PI / 2;
  windowWidth = 0.1;
  windowFrameWidth = 0.05;
  windowLength = 1.2;
  windowLineWeight = 1;
  circleStairsWidth = 0.9;
  rectStairsWidth = 0.9;
  rectStairsHeight = 2 * this.rectStairsWidth;
  symbolHandleSize = 0.09;
  symbolLineWeight = 0.5;
  svgLength = 2;
  svgHeight = 1;
  svgPath = '';
  lockAspectRatio: boolean = false;
  stairStepSize = 0.32;
  textBoxWidth = 3;
  textBoxHeight = 1;
  fontSize = 0.5;
  fontStyle = "normal";
  fontWeight = "normal"; // "normal", or "bold"
  lineHeight = 1
  text = "Add Text";
  squareWidth = 0.9;
  squareHeight = 0.9;
  triangleHeight = 0.9;
  triangleWidth = 0.9;
  circleWidth = 0.9;
  circleHeight = 0.9;
  circleRadius = 0.9;
  name: string = "Untitled";
  objectToRoomsMap: Map<string, Set<string>> = new Map(); // Map to keep track of objects and the rooms they belong to
  objectsNeedingRoomUpdate: Set<string> = new Set(); // Set to keep track of walls whose rooms need to be recalculated
  processRoomUpdates = debounce(() => { this.updateAffectedRooms(); }, 100); // Debounced method to process room updates

  constructor() {
    makeAutoObservable(this);
    // Restore persisted state during hot reload
    this.rehydrateStore();
  }

  rehydrateStore = () => {

    const persistedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    const undoStack = localStorage.getItem(`${LOCAL_STORAGE_KEY}_undoStack`);
    const redoStack = localStorage.getItem(`${LOCAL_STORAGE_KEY}_redoStack`);

    if (persistedState) {
      try {
        this.fromJSON(persistedState);        
      } catch (error) {
        console.error("Error rehydrating store from local storage", error);
        // Clear the local storage if an error occurs
        localStorage.removeItem(LOCAL_STORAGE_KEY);
        localStorage.removeItem(`${LOCAL_STORAGE_KEY}_undoStack`);
        localStorage.removeItem(`${LOCAL_STORAGE_KEY}_redoStack`);
      }
    }
    if (undoStack) {
      this.undoStack = JSON.parse(undoStack);
    }
    if (redoStack) {
      this.redoStack = JSON.parse(redoStack);
    }
  };

  persistStore = () => {
    const state = this.toJSON();
    try {
      localStorage.setItem(LOCAL_STORAGE_KEY, state);
      localStorage.setItem(`${LOCAL_STORAGE_KEY}_undoStack`, JSON.stringify(this.undoStack));
      localStorage.setItem(`${LOCAL_STORAGE_KEY}_redoStack`, JSON.stringify(this.redoStack));
    } catch (e) {
      console.error("Error persisting store to local storage, clearing history", e);
      // Clear the local storage if an error occurs
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      localStorage.removeItem(`${LOCAL_STORAGE_KEY}_undoStack`);
      localStorage.removeItem(`${LOCAL_STORAGE_KEY}_redoStack`);
    }
  }

  async initialize(client: ApolloClient<any>, id?: string) {
    this.client = client;
    if (id) {
      this.setId(id);
    } else {
      this.loadDefaultFloorplan();
    }
    this.initialized = true;
  }

  loadDefaultFloorplan = (filterList?: string) => {
    runInAction(() => {
      if (filterList === "symbols") {
        this.symbolsMap.clear();
      } else {
        this.wallsMap.clear();
        this.symbolsMap.clear();
        this.singleLinesMap.clear();
        this.rulerLinesMap.clear();
        this.roomsMap.clear()
        this.objectToRoomsMap.clear();
      }
      this.name = "Untitled";
      this.dirty = true;
      this.blockDirty = false;
      this.integrityCheck();

      this.persistStore();
      this.resetUndoRedoStacks();
      this.saveToCloud(false, true)
    });
  };

  integrityCheck = () => {
    checkFloorplannerStoreIntegrity(this);
  }

  generateId = () => {
    return Math.random().toString(36).substr(2, 9)
  }

  setId = (id: string) => {
    this.id = id;
    // Load the floorplan from the cloud
    this.loadFromCloud(id);
  }

  setDirty = (flag?: boolean) => {
    this.dirty = flag === undefined ? true : flag;
    if (this.blockDirty) return;
  };

  setBlockDirty = (block: boolean) => {
    this.blockDirty = block;
  }

  pushToUndoStack = () => {
    this.redoStack = [];  // Clear the redo stack as new changes invalidate redo history
    const currentState = this.toJSON();
    this.undoStack.push(currentState);

    // Ensure undo stack doesn't exceed the limit
    if (this.undoStack.length > UNDO_REDO_LIMIT) {
      this.undoStack.shift();  // Remove the oldest state to maintain the limit
    }

    this.persistStore();  // Persist the history along with the store
  };

  undo = () => {
    if (this.undoStack.length > 0) {
      renderStore.clearWallShapes("");
      const currentState = this.toJSON();
      this.redoStack.push(currentState);  // Save current state to redo stack
      const previousState = this.undoStack.pop();  // Get the last state from undo stack
      this.fromJSON(previousState!);  // Revert to the previous state
      this.persistStore();
      this.setDirty();
    }
  };

  redo = () => {
    if (this.redoStack.length > 0) {
      const currentState = this.toJSON();
      this.undoStack.push(currentState);  // Save current state to undo stack

      // Ensure undo stack doesn't exceed the limit
      if (this.undoStack.length > UNDO_REDO_LIMIT) {
        this.undoStack.shift();  // Remove the oldest state to maintain the limit
      }

      const nextState = this.redoStack.pop();  // Get the last state from redo stack
      this.fromJSON(nextState!);  // Revert to the next state
      this.persistStore();
      this.setDirty();
    }
  };

  resetUndoRedoStacks = () => {
    this.undoStack = [];
    this.redoStack = [];
    this.persistStore();  // Persist the reset history along with the store
  };

  setName = (name: string) => {
    if (name === this.name) return;
    this.pushToUndoStack();
    this.name = name;
    this.setDirty();
  }

  setLineWeight = (lineWeight: number) => {
    this.lineWeight = lineWeight;
  };

  convertLineWeightToWorld = (lineWeight: number) => {
    // Adjust for camera zoom level as well
    const zoomLevel = editorStore.camera?.zoom || 1;
    // Return lineWeight in world units based on the zoom level
    return lineWeight / zoomLevel / 44;
  }

  setLineColor = (color: number) => {
    this.lineColor = color;
  };

  setFillColor = (color: number) => {
    this.fillColor = color;
  };

  setHoverColor = (color: number) => {
    this.hoverColor = color;
  };

  findObjectId = (id: string): WallType | SingleLineType | RulerLineType | undefined => {
    let object = this.wallsMap.get(id) as WallType | SingleLineType | RulerLineType | undefined;
    if (!object) {
      object = this.singleLinesMap.get(id);
    }
    if (!object) {
      object = this.rulerLinesMap.get(id);
    }
    return object;
  }

  setWalls = (walls: { [key: string]: WallType }) => {
    this.pushToUndoStack();
    this.wallsMap = new ObservableMap(walls);
    this.setDirty();
  };

  addWall = (wall: WallType) => {
    this.pushToUndoStack();
    const newWall: WallType = {
      ...wall,
      type: "wall",
    };
    this.wallsMap.set(wall.id, newWall);
    this.markObjectForRoomUpdate(wall.id);
    this.setDirty();
  };

  removeWall = (id: string) => {
    this.wallsMap.get(id)?.symbolAttachments?.forEach((attachment) => {
      this.detachSymbolFromWall(attachment.symbolId);
    });
    const wall = this.wallsMap.get(id);
    if (!wall) return;
    this.pushToUndoStack();
    // Remove the wall from any rooms that include it
    if (wall?.roomIds) {
      wall.roomIds.forEach(roomId => {
        const room = this.roomsMap.get(roomId);
        if (room) {
          // Remove the wall from the room's wallIds
          room.objectIds = room.objectIds.filter(objectId => objectId !== id);

          // If the room has less than 3 walls, remove it
          if (room.objectIds.length < 3) {
            this.roomsMap.delete(roomId);
          } else {
            // Recalculate room properties (area, centroid)
            this.updateRoomProperties(room);
          }
        }
      });
    }
    // Remove the wall from the connections of other walls
    wall?.connections?.forEach((connection) => {
      const connectedWall = this.wallsMap.get(connection.id);
      if (connectedWall) {
        connectedWall.connections = connectedWall.connections?.filter((c) => c.id !== id);
      }
    });
    // Remove any clipping polygons on other walls that belong to this wall through connections
    wall?.connections?.forEach((connection) => {
      const connectedWall = this.wallsMap.get(connection.id);
      if (connectedWall) {
        renderStore.removeWallClipping(connection.id, id);
      }
    });
    // Remove the wall
    this.wallsMap.delete(id);
    this.markObjectForRoomUpdate(id);
    this.setDirty();
  };

  addCutout = (wallId: string, cutout: CutoutType) => {
    this.wallsMap.get(wallId)?.cutouts?.push(cutout);
    this.setDirty();
  };

  removeCutout = (wallId: string, cutoutIndex: number) => {
    this.wallsMap.get(wallId)?.cutouts?.splice(cutoutIndex, 1);
    this.setDirty();
  };

  updateObjectPosition = (source: string, start: THREE.Vector2, end: THREE.Vector2) => {
    const object = this.findObjectId(source);
    if (object) {
      if (isWallType(object)) {
        const updatedWall = {
          ...object,
          start,
          end,
        }
        this.wallsMap.set(object.id, updatedWall);
      } else if (isSingleLineType(object)) {
        this.updateSingleLine({ ...object, start, end });
      } else if (isRulerLineType(object)) {
        this.updateRulerLine({ ...object, start, end });
      }
      this.setDirty();
    }
  };

  objectNeedsUpdate = (objectId: string) => {
    const object = this.findObjectId(objectId);
    let lastUpdate = 0;
    if (object) {
      // Update the object lastUpdate
      lastUpdate = (object.lastUpdate || 0) + 1;
      floorplannerStore.setObjectProperty(object.id, "lastUpdate", lastUpdate);
      // If object have connections also update the connected objects lastUpdate
      if (isWallType(object)) {
        object.connections?.forEach((connection) => {
          const connectedObject = this.findObjectId(connection.id);
          if (connectedObject) {
            setTimeout(() => {
              const connectedLastUpdate = (connectedObject.lastUpdate || 0) + 1;
              floorplannerStore.setObjectProperty(connectedObject.id, "lastUpdate", connectedLastUpdate);
            }, 0);
          }
        });
      }
    }
    return lastUpdate
  }
  setSymbolsMap = (symbols: { [key: string]: SymbolType }) => {
    this.symbolsMap = new ObservableMap(symbols);
  }

  updateSymbolProperty = (
    symbolId: string,
    property: string,
    value: any,
  ) => {
    const symbol = this.symbolsMap.get(symbolId);

    if (symbol) {
      // Check if property has changed
      if (symbol[property as keyof SymbolType] === value) return;
      else if (property === "position") {
        if (symbol.position.x === value[0] && symbol.position.y === value[1]) return;
      }
      // Update symbol property
      const updatedSymbol = {
        ...symbol,
        [property]: property === "position" ?
          new THREE.Vector2(value[0], value[1]) :
          property === "rotation" && value === 0 ? 0.0001 :
            value,
      }
      this.symbolsMap.set(symbolId, updatedSymbol);

      if (property !== "selected") {
        this.setDirty();
      }
    }
  };

  addSymbol = (
    type: "symbol" | "window" | "door" | "doubleDoor" | "circleStairs" | "rectStairs" | "square" | "circle" | "triangle" | "svg" | "text" | "area",
    position: [number, number],
    props?: any
  ): string => {
    this.pushToUndoStack();
    const id = Math.random().toString(36).substr(2, 9);
    try {
      this.blockDirty = true;
      // Place the symbol at the visible center if no position is provided
      const [centerX, centerY] = editorStore.getVisibleCenter();
      const posX = position[0] || centerX;
      const posY = position[1] || centerY;
      const newSymbol: SymbolType = {
        id,
        type,
        position: new THREE.Vector2(posX, posY),
        zIndex: 0.01 + (this.symbolsMap.size / 1000), // Default zIndex within 0.01-0.02
      };
      if (props?.name) newSymbol.name = props.name;
      // If the object is a door, set some default properties
      if (isDoorType(newSymbol)) {
        newSymbol.doorWidth = this.doorWidth;
        newSymbol.openAngle = this.openAngle;
      }
      // If the object is a Double door, set some default properties
      if (isDoubleDoorType(newSymbol)) {
        newSymbol.doubleDoorWidth = this.doubleDoorWidth;
        newSymbol.openAngle = this.openAngle;
      }
      // If the object is a window, set some default properties
      if (isWindowType(newSymbol)) {
        newSymbol.windowWidth = this.windowWidth;
        newSymbol.windowLength = this.windowLength;
      }
      // If the object is a circleStairs, set some default properties
      if (isCircleStairsType(newSymbol)) {
        newSymbol.circleStairsWidth = this.circleStairsWidth;
        newSymbol.openAngle = this.openAngle;
        newSymbol.stairStepSize = this.stairStepSize;
      }
      // If the object is a Rect stairs, set some default properties
      if (isRectStairsType(newSymbol)) {
        newSymbol.rectStairsWidth = this.rectStairsWidth;
        newSymbol.rectStairsHeight = this.rectStairsHeight;
        newSymbol.openAngle = this.openAngle;
        newSymbol.stairStepSize = this.stairStepSize;
      }
      // If the object is a Square, set some default properties
      if (isSquareSymbolType(newSymbol)) {
        newSymbol.squareWidth = this.squareWidth;
        newSymbol.squareHeight = this.squareHeight;
        newSymbol.openAngle = this.openAngle;
      }
      // If the object is a Circle, set some default properties
      if (isCircleSymbolType(newSymbol)) {
        newSymbol.circleWidth = this.circleWidth;
        newSymbol.circleHeight = this.circleHeight;
        newSymbol.circleRadius = this.circleRadius;
        newSymbol.openAngle = this.openAngle;
      }
      // If the object is a Triangle, set some default properties
      if (isTriangleSymbolType(newSymbol)) {
        newSymbol.triangleWidth = this.triangleWidth;
        newSymbol.triangleHeight = this.triangleHeight;
        newSymbol.openAngle = this.openAngle;
      }
      // If the object is an SVG, set some default properties
      if (isSvgType(newSymbol)) {
        newSymbol.svgPath = props?.svgPath || this.svgPath;
        newSymbol.svgLength = props?.svgLength || this.svgLength;
        newSymbol.svgHeight = props?.svgHeight || this.svgHeight;
        newSymbol.lockAspectRatio = props?.lockAspectRatio || this.lockAspectRatio;
        newSymbol.lineWeight = props?.lineWeight || this.lineWeight;
      }
      // If the object is a Text, set some default properties
      if (isTextType(newSymbol)) {
        newSymbol.textBoxWidth = this.textBoxWidth;
        newSymbol.textBoxHeight = this.textBoxHeight;
        newSymbol.fontSize = this.fontSize;
        newSymbol.fontStyle = this.fontStyle;
        newSymbol.lineHeight = this.lineHeight;
        newSymbol.fontWeight = this.fontWeight;
        newSymbol.text = this.text;
      }
      if (isAreaSymbolType(newSymbol)) {
        newSymbol.vertices = props?.vertices || [];
      }
      newSymbol.zIndex = this.symbolsMap.size + 1;
      this.symbolsMap.set(id, newSymbol);
    } finally {
      this.blockDirty = false;
      this.setDirty();
    }
    return id;
  };

  removeSymbol = (id: string) => {
    this.pushToUndoStack();
    this.detachSymbolFromWall(id);
    this.symbolsMap.delete(id);
    this.setDirty();
  };

  symbolIsAttached = (symbolId: string) => {
    const symbol = this.symbolsMap.get(symbolId);
    if (symbol) return symbol.attachedTo;
    else return null;
  }

  detachSymbolFromWall = (symbolId: string) => {
    const symbol = this.symbolsMap.get(symbolId);
    if (symbol && symbol.attachedTo) {
      runInAction(() => {
        // Remove the attachment from the wall
        const wallId = symbol.attachedTo as string;
        const wall = this.wallsMap.get(wallId);
        if (!wall) {
          console.error("Wall not found when detachSymbolFromWall ran", wallId);
          return;
        }
        const wallCopy = { ...wall };
        wallCopy.symbolAttachments = wallCopy.symbolAttachments?.filter(attachment => attachment.symbolId !== symbolId);
        this.wallsMap.set(wallId, wallCopy);
        renderStore.removeWallClipping(wallId, symbolId);
        const symbolCopy = { ...symbol };
        delete symbolCopy.attachedTo;
        delete symbolCopy.attachedDistance;
        this.symbolsMap.set(symbolId, symbolCopy);
        // If the object is a window, restore the window width to the default value
        if (isWindowType(symbol)) {
          floorplannerStore.updateSymbolProperty(symbolId, "windowWidth", floorplannerStore.windowWidth);
        }
      });
      this.setDirty();
    }
  }

  attachSymbolToWall = (symbolId: string, wallId: string, position: [number, number]) => {
    const symbol = this.symbolsMap.get(symbolId);

    if (symbol) {
      if (symbol.attachedTo) {
        console.error("Trying to attach a symbol to a new wall while it is already attached to a wall, please detach first", symbolId);
        return;
      }
      runInAction(() => {
        // Attach the object to the wall
        const wall = this.wallsMap.get(wallId);
        if (!wall) {
          console.error("Wall not found when attachSymbolToWall ran", wallId);
          return;
        }
        const wallCopy = { ...wall };
        // Find the closest point on the wall to the symbol
        if (!wallCopy.start || !wallCopy.end) {
          console.error("Wall does not have start or end points", wallId);
          return;
        }
        const wallStart = new THREE.Vector2(wallCopy.start.x, wallCopy.start.y);
        const wallEnd = new THREE.Vector2(wallCopy.end.x, wallCopy.end.y);
        const wallDirection = wallEnd.clone().sub(wallStart).normalize();
        const symbolPosition = new THREE.Vector2(position[0], position[1]);
        const symbolDistance = symbolPosition.clone().sub(wallStart).dot(wallDirection);
        const symbolPositionOnWall = wallStart.clone().add(wallDirection.clone().multiplyScalar(symbolDistance));
        const symbolDistanceFromStart = symbolPositionOnWall.distanceTo(wallStart);
        // Add the symbol attachment to the wall
        if (!wallCopy.symbolAttachments) wallCopy.symbolAttachments = [];
        wallCopy.symbolAttachments.push({
          symbolId,
          positionFromStart: symbolDistanceFromStart,
        });
        // Add a clipping shape in the wall for the symbol
        const clippingPolygon = renderStore.getClippingPolygon(symbol, wallCopy);
        renderStore.addWallClipping(wallId, clippingPolygon);
        this.wallsMap.set(wallId, wallCopy);

        // Attach the object to the wall
        const updatedObject = {
          ...symbol,
          attachedTo: wallId,
          attachedPosition: position,
        }
        // Mutate the symbols with the new attachment
        this.symbolsMap.set(symbolId, updatedObject);

        // Update the object position
        this.updateSymbolProperty(symbolId, "position", symbolPositionOnWall.toArray());

        // Update the object rotation to align with the wall
        let wallAngle = Math.atan2(wallCopy.end.y - wallCopy.start.y, wallCopy.end.x - wallCopy.start.x);

        this.updateSymbolProperty(symbolId, "rotation", wallAngle);

        // If the object is a window, update the window width to match the wall width if wall is smaller than 0.1
        if (isWindowType(symbol)) {
          const windowWidth = (wallCopy.wallWidth || floorplannerStore.wallWidth) < 0.1 ? (wallCopy.wallWidth || floorplannerStore.wallWidth) : 0.1;
          this.updateSymbolProperty(symbolId, "windowWidth", windowWidth);
        }
      });

      this.setDirty();
    }
  }

  getSymbolPositionOfWall = (symbolId: string, wallId: string) => {
    const symbol = this.symbolsMap.get(symbolId);
    const wall = this.wallsMap.get(wallId);
    if (symbol && wall) {
      const symbolAttachment = wall.symbolAttachments?.find(attachment => attachment.symbolId === symbolId);
      if (symbolAttachment) {
        const wallStart = new THREE.Vector2(wall.start.x, wall.start.y);
        const wallEnd = new THREE.Vector2(wall.end.x, wall.end.y);
        const wallDirection = wallEnd.clone().sub(wallStart).normalize();
        const symbolPosition = wallStart.clone().add(wallDirection.clone().multiplyScalar(symbolAttachment.positionFromStart));
        return symbolPosition;
      }
    }
    return null;
  }

  cloneSymbol = (symbolId: string, newPosition?: THREE.Vector2) => {
    const symbol = this.symbolsMap.get(symbolId);
    if (symbol) {
      this.pushToUndoStack();
      const newSymbol = {
        ...symbol,
        id: this.generateId(),
        attachedTo: undefined,
        attachedPosition: undefined,
        attachedDistance: undefined,
        clipWall: undefined,
        position: newPosition || symbol.position.clone().add(new THREE.Vector2(0.2, 0.2)),
        zIndex: this.symbolsMap.size + 1,
      };
      this.symbolsMap.set(newSymbol.id, newSymbol);
      this.setDirty();
    }
  }

  updateSymbolPosition = (symbolId: string, newPosition: THREE.Vector2) => {
    const symbol = this.symbolsMap.get(symbolId);
    if (symbol) {
      const updatedSymbol = {
        ...symbol,
        position: newPosition,
      };
      this.symbolsMap.set(symbolId, updatedSymbol);
      this.setDirty();
    }
  }

  setSingleLines = (lines: { [key: string]: SingleLineType }) => {
    this.pushToUndoStack();
    this.singleLinesMap = new ObservableMap(lines);
    this.setDirty();
  };
  setRulerLines = (lines: { [key: string]: RulerLineType }) => {
    this.pushToUndoStack();
    this.rulerLinesMap = new ObservableMap(lines);
    this.setDirty();
  };
  addSingleLine = (line: SingleLineType) => {
    this.pushToUndoStack();
    this.singleLinesMap.set(line.id, line);
    this.markObjectForRoomUpdate(line.id);
    this.setDirty();
  };
  addRulerLine = (line: RulerLineType) => {
    this.pushToUndoStack();
    this.rulerLinesMap.set(line.id, line);
    this.setDirty();
  };
  removeSingleLine = (id: string) => {
    const line = this.singleLinesMap.get(id);
    if (line) {
      this.pushToUndoStack();
      // Remove the line from any rooms that include it
      if (line?.roomIds) {
        line.roomIds.forEach(roomId => {
          const room = this.roomsMap.get(roomId);
          if (room) {
            // Remove the line from the room's wallIds
            room.objectIds = room.objectIds.filter(objectId => objectId !== id);

            // If the room has less than 3 lines, remove it
            if (room.objectIds.length < 3) {
              this.roomsMap.delete(roomId);
            } else {
              // Recalculate room properties (area, centroid)
              this.updateRoomProperties(room);
            }
          }
        });
      }
      // Remove the line from the connections of other lines
      line?.connections?.forEach((connection) => {
        const connectedWall = this.wallsMap.get(connection.id);
        if (connectedWall) {
          connectedWall.connections = connectedWall.connections?.filter((c) => c.id !== id);
        }
      });
      this.singleLinesMap.delete(id);
      this.markObjectForRoomUpdate(id);
      this.setDirty();
    }
  };
  removeRulerLine = (id: string) => {
    this.pushToUndoStack();
    this.rulerLinesMap.delete(id);
    this.setDirty();
  };
  updateSingleLine = (line: SingleLineType) => {
    this.singleLinesMap.set(line.id, line);
    this.markObjectForRoomUpdate(line.id);
    this.setDirty();
  }
  updateRulerLine = (line: RulerLineType) => {
    this.rulerLinesMap.set(line.id, line);
    this.setDirty();
  }
  updateSingleLineProp = <K extends keyof SingleLineType>(id: string, prop: K, value: SingleLineType[K]) => {
    const line = this.singleLinesMap.get(id);
    if (line) {
      line[prop] = value;
      this.singleLinesMap.set(id, line);
      this.setDirty();
    }
  };
  updateRulerLineProp = <K extends keyof RulerLineType>(id: string, prop: K, value: RulerLineType[K]) => {
    const line = this.rulerLinesMap.get(id);
    if (line) {
      line[prop] = value;
      this.rulerLinesMap.set(id, line);
      this.setDirty();
    }
  };
  setSingleLineLength = (id: string, length: number, lineEndToAdjust: "start" | "end" | undefined) => {
    const line = this.singleLinesMap.get(id);
    if (line) {
      this.pushToUndoStack();
      if (lineEndToAdjust === "start") {
        line.start = line.end.clone().sub(line.start).normalize().multiplyScalar(length).add(line.start);
        updateWallConnections(line.id, "start", [line.id], this);
      } else if (lineEndToAdjust === "end" || !lineEndToAdjust) {
        line.end = line.end.clone().sub(line.start).normalize().multiplyScalar(length).add(line.start);
        updateWallConnections(line.id, "end", [line.id], this);
      }

      this.singleLinesMap.set(id, line);
      this.objectNeedsUpdate(id);
      this.markObjectForRoomUpdate(id);
      this.setDirty();
    }
  };
  setRulerLineLength = (id: string, length: number, lineEndToAdjust: "start" | "end" | undefined) => {
    const line = this.rulerLinesMap.get(id);
    if (line) {
      this.pushToUndoStack();
      if (lineEndToAdjust === "start") {
        line.start = line.end.clone().sub(line.start).normalize().multiplyScalar(length).add(line.start);
      } else if (lineEndToAdjust === "end" || !lineEndToAdjust) {
        line.end = line.end.clone().sub(line.start).normalize().multiplyScalar(length).add(line.start);
      }
      this.rulerLinesMap.set(id, line);
      this.setDirty();
    }
  };
  singleLineLength = (id: string): number => {
    const line = this.singleLinesMap.get(id);
    if (!line || !line.start || !line.end) return 0;
    const lengthOfLine = new THREE.Vector2().subVectors(line.end, line.start).length();
    return lengthOfLine;
  }
  rulerLineLength = (id: string): number => {
    const line = this.rulerLinesMap.get(id);
    if (!line) return 0;
    const lengthOfLine = new THREE.Vector2().subVectors(line.end, line.start).length();
    return lengthOfLine;
  }

  getConnectedLine = (wall: SingleLineType, position: number): SingleLineType | undefined => {
    let connectedWall: SingleLineType | undefined = undefined;
    const tempWall = this.singleLinesMap.get(wall.id);
    if (!tempWall) return undefined;
    const connectedWallId = tempWall.connections?.find((connection) => connection.sourcePosition === position)?.id;
    if (connectedWallId) {
      connectedWall = this.singleLinesMap.get(connectedWallId);
    }
    return connectedWall;
  }
  getConnectedRuler = (wall: RulerLineType, position: number): RulerLineType | undefined => {
    let connectedWall: RulerLineType | undefined = undefined;
    const tempWall = this.rulerLinesMap.get(wall.id);
    if (!tempWall) return undefined;
    const connectedWallId = tempWall.connections?.find((connection) => connection.sourcePosition === position)?.id;
    if (connectedWallId) {
      connectedWall = this.rulerLinesMap.get(connectedWallId);
    }
    return connectedWall;
  }
  disconnectLineEnd = (lineId: string, connectionPos: number) => {
    const tempLine = this.singleLinesMap.get(lineId);
    if (!tempLine) return;
    const connectedLineId = tempLine.connections?.find((connection) => connection.sourcePosition === connectionPos)?.id;
    if (connectedLineId) {
      const connectedLine = this.singleLinesMap.get(connectedLineId);
      if (connectedLine) {
        const updatedLine = {
          ...connectedLine,
          connections: connectedLine.connections?.filter((connection) => connection.id !== lineId),
        };
        this.singleLinesMap.set(connectedLineId, updatedLine);
      }
      const updatedWall = {
        ...tempLine,
        connections: tempLine.connections?.filter((connection) => connection.sourcePosition !== connectionPos),
      };
      this.singleLinesMap.set(lineId, updatedWall);
      this.markObjectForRoomUpdate(lineId);
    }
  }

  sendToFront = (symbolId: string) => {
    const symbol = this.symbolsMap.get(symbolId);
    if (symbol) {
      this.pushToUndoStack();
      const maxZIndex = Math.max(...Array.from(this.symbolsMap.values()).map(s => s.zIndex));
      symbol.zIndex = maxZIndex + 1;
      this.normalizeZIndexes();
      this.setDirty();
    }
  };

  sendToBack = (symbolId: string) => {
    const symbol = this.symbolsMap.get(symbolId);
    if (symbol) {
      this.pushToUndoStack();
      const minZIndex = Math.min(...Array.from(this.symbolsMap.values()).map(s => s.zIndex));
      symbol.zIndex = minZIndex - 1;
      this.normalizeZIndexes();
      this.setDirty();
    }
  };

  bringForward = (symbolId: string) => {
    const symbol = this.symbolsMap.get(symbolId);
    if (symbol) {
      const symbolsArray = Array.from(this.symbolsMap.values());
      const sortedSymbols = symbolsArray.sort((a, b) => a.zIndex - b.zIndex);
      const index = sortedSymbols.findIndex(s => s.id === symbolId);

      if (index < sortedSymbols.length - 1) {
        this.pushToUndoStack();
        const nextSymbol = sortedSymbols[index + 1];
        const tempZIndex = symbol.zIndex;
        symbol.zIndex = nextSymbol.zIndex;
        nextSymbol.zIndex = tempZIndex;
        this.setDirty();
      }
    }
  };

  bringBackward = (symbolId: string) => {
    const symbol = this.symbolsMap.get(symbolId);
    if (symbol) {
      const symbolsArray = Array.from(this.symbolsMap.values());
      const sortedSymbols = symbolsArray.sort((a, b) => a.zIndex - b.zIndex);
      const index = sortedSymbols.findIndex(s => s.id === symbolId);

      if (index > 0) {
        this.pushToUndoStack();
        const prevSymbol = sortedSymbols[index - 1];
        const tempZIndex = symbol.zIndex;
        symbol.zIndex = prevSymbol.zIndex;
        prevSymbol.zIndex = tempZIndex;
        this.setDirty();
      }
    }
  };

  // Normalize zIndex values to be sequential
  normalizeZIndexes = () => {
    const sortedSymbols = Array.from(this.symbolsMap.values()).sort((a, b) => a.zIndex - b.zIndex);
    sortedSymbols.forEach((symbol, index) => {
      symbol.zIndex = index;
    });
  };

  isFront = (symbolId: string) => {
    const symbol = this.symbolsMap.get(symbolId);
    if (symbol) {
      const maxZIndex = Math.max(...Array.from(this.symbolsMap.values()).map(s => s.zIndex));
      return symbol.zIndex === maxZIndex;
    }
    return false;
  };

  isBack = (symbolId: string) => {
    const symbol = this.symbolsMap.get(symbolId);
    if (symbol) {
      const minZIndex = Math.min(...Array.from(this.symbolsMap.values()).map(s => s.zIndex));
      return symbol.zIndex === minZIndex;
    }
    return false;
  };

  wallLength = (wall: WallType): number => {
    if (!wall?.start || !wall?.end) return 0
    const lengthOfWall = new THREE.Vector2().subVectors(wall.end, wall.start).length();
    return lengthOfWall;
  }

  setWallLength = (
    wallId: string,
    newLength: number,
    whichEndToUpdate: number | null = null,
    whatLength: "outer" | "inner" = "outer"
  ) => {
    const wall = this.wallsMap.get(wallId);
    if (!wall) return;
    if (editorStore.wallConstructionMode) {
      whichEndToUpdate = WallConnectionEnd;
    }
    // Validate newLength so it is a positive number
    if (newLength <= 0) {
      console.error("New length must be a positive number");
      return;
    }
    this.pushToUndoStack();
    // Pick an end to extend if not specified
    if (whichEndToUpdate === null) {
      const endsAvailable = this.getAvailableWallConnectionPositions(wall);
      if (endsAvailable.size === 0) {
        // No ends available, so we look at what end that points towards right or bottom
        const direction = wall.end.clone().sub(wall.start);
        const deltaX = direction.x;
        const deltaY = direction.y;

        if (
          (deltaX > 0 && Math.abs(deltaX) >= Math.abs(deltaY)) ||
          (deltaY < 0 && Math.abs(deltaY) >= Math.abs(deltaX))
        ) {
          // Wall is pointing towards right or down
          whichEndToUpdate = WallConnectionEnd;
        } else {
          whichEndToUpdate = WallConnectionStart;
        }
      } else {
        whichEndToUpdate = endsAvailable.values().next().value as number;
      }
    }

    // Deduct the start and end cap lengths from the new length
    newLength -= whatLength === "outer" ?
      this.wallEndCapLength(wall) + this.wallStartCapLength(wall) :
      -(this.wallEndCapLength(wall) + this.wallStartCapLength(wall));
    // Update the wall length
    if (whichEndToUpdate === WallConnectionEnd) {
      const wallDirection = new THREE.Vector2().subVectors(wall.end, wall.start).normalize();
      const updatedEnd = wall.start.clone().add(wallDirection.clone().multiplyScalar(newLength));
      this.setWallEndPoints(wall.id, wall.start, updatedEnd);
      updateWallConnections(wall.id, "end", [wall.id], this);
      if (wall.end !== updatedEnd) {
        this.setWallEndPoints(wall.id, wall.start, updatedEnd);
      }
    } else if (whichEndToUpdate === WallConnectionStart) {
      const wallDirection = new THREE.Vector2().subVectors(wall.start, wall.end).normalize();
      const updatedStart = wall.end.clone().add(wallDirection.clone().multiplyScalar(newLength));
      this.setWallEndPoints(wall.id, updatedStart, wall.end);
      updateWallConnections(wall.id, "start", [wall.id], this);
      if (wall.start !== updatedStart) {
        this.setWallEndPoints(wall.id, updatedStart, wall.end);
      }
    }
    updateWallConnections(wall.id, null, [wall.id], this);
    this.objectNeedsUpdate(wall.id);
    this.markObjectForRoomUpdate(wall.id);
    this.setDirty();
  }

  setWallEndPoints = (wallId: string, newStart: THREE.Vector2, newEnd: THREE.Vector2) => {
    const wall = this.wallsMap.get(wallId);
    if (wall) {
      this.pushToUndoStack();
      const updatedWall = {
        ...wall,
        start: newStart,
        end: newEnd,
      }
      this.wallsMap.set(wallId, updatedWall);
      this.markObjectForRoomUpdate(wallId);
      this.setDirty();
    }
  }

  setObjectProperty = (source: string, property: string, value: any) => {
    const object = this.findObjectId(source);
    if (object) {
      const updatedObject = {
        ...object,
        [property]: value,
      }
      if (isWallType(updatedObject)) {
        this.wallsMap.set(object.id, updatedObject);
        if (property !== "lastUpdate") {
          this.objectNeedsUpdate(object.id);
        }
        this.markObjectForRoomUpdate(object.id);
      } else if (isSingleLineType(updatedObject)) {
        this.singleLinesMap.set(object.id, updatedObject);
      } else if (isRulerLineType(updatedObject)) {
        this.rulerLinesMap.set(object.id, updatedObject);
      }
      this.setDirty();
    }
  }

  angleBetweenTwoWalls = (wall1: WallType, wall2: WallType): number => {
    const wall1Direction = new THREE.Vector2().subVectors(wall1.end, wall1.start).normalize();
    const wall2Direction = new THREE.Vector2().subVectors(wall2.end, wall2.start).normalize();
    const angle = wall1Direction.angleTo(wall2Direction);
    return angle;
  }

  wallStartCapLength = (source: WallType): number => {
    if (!source) return 0;
    const wall = this.wallsMap.get(source.id);
    if (!wall) return 0;
    let startConnectedWall: WallType | undefined = undefined;
    const startConnection = wall.connections?.find((connection) => connection.sourcePosition === WallConnectionStart);
    if (startConnection) {
      startConnectedWall = this.wallsMap.get(startConnection.id);
    }
    if (!startConnectedWall) {
      return 0;
    }
    // Get the angle between the connected wall and the current wall
    const angle = Math.abs(this.angleBetweenTwoWalls(startConnectedWall, wall));
    // When the angle 180 degrees, the start cap length is 0 and increases as the angle decreases
    return ((startConnectedWall.wallWidth || this.wallWidth) / 2) * Math.sin(angle)
  }

  wallEndCapLength = (source: WallType): number => {
    if (!source) return 0;
    const wall = this.wallsMap.get(source.id);
    if (!wall) return 0;
    let endConnectedWall: WallType | undefined = undefined;
    const endConnection = wall.connections?.find((connection) => connection.sourcePosition === WallConnectionEnd);
    if (endConnection) {
      endConnectedWall = this.wallsMap.get(endConnection.id);
    }
    if (!endConnectedWall) {
      return 0;
    }
    // Get the angle between the connected wall and the current wall
    const angle = Math.abs(this.angleBetweenTwoWalls(endConnectedWall, wall));
    // When the angle 180 degrees, the end cap length is 0 and increases as the angle decreases
    return ((endConnectedWall.wallWidth || this.wallWidth) / 2) * Math.sin(angle)
  }

  wallOuterLength = (wall: WallType): number => {
    const lengthOfWall = this.wallLength(wall);
    const startCapLength = this.wallStartCapLength(wall);
    const endCapLength = this.wallEndCapLength(wall);
    const wallLength = lengthOfWall + startCapLength + endCapLength;
    return wallLength;
  }

  wallInnerLength = (wall: WallType): number => {
    const lengthOfWall = this.wallLength(wall);
    const startCapLength = this.wallStartCapLength(wall);
    const endCapLength = this.wallEndCapLength(wall);
    const wallLength = lengthOfWall - startCapLength - endCapLength;
    return wallLength;
  }

  /**
   * Returns the perpendicular vector to the object that points towards the inner side of the room.
   * 
   * To decide which side of the object is the inner side, we must look at the connected walls and their directions.
   * If the connected line crosses the wall direction, the inner side is the side where the connected line is pointing towards.
   * 
   * @param object The wall to calculate the perpendicular vector for.
   * @returns The perpendicular vector to the wall that points towards the inner side of the room.
   * 
   */
  objectPerpendicularInner = (object: WallType | SingleLineType | RulerLineType): THREE.Vector2 => {
    // Step 1: Get the direction of the wall (normalized)
    const objectDirection = new THREE.Vector2().subVectors(object.end, object.start).normalize();

    // Step 2: Find the connected wall at the start or end (find does not work so let's use forEach)
    let startConnection: WallConnectionType | undefined = undefined as WallConnectionType | undefined;
    let endConnection: WallConnectionType | undefined = undefined as WallConnectionType | undefined;
    let connectedWallStart: THREE.Vector2 | null = null;
    let connectedWallEnd: THREE.Vector2 | null = null;
    let connectionPoint: THREE.Vector2 | null = null;

    object.connections?.forEach((connection) => {
      if (connection.sourcePosition === WallConnectionStart) {
        startConnection = connection;
      } else if (connection.sourcePosition === WallConnectionEnd) {
        endConnection = connection;
      }
    });

    // Step 3: Get the connected wall's start and end points and determine the connection point
    if (isWallType(object)) {
      if (startConnection && (startConnection.targetPosition === WallConnectionStart || startConnection.targetPosition === WallConnectionEnd) && this.wallsMap.get(startConnection.id)) {
        const connectedWall = this.wallsMap.get(startConnection.id);
        connectedWallStart = new THREE.Vector2(connectedWall?.start.x, connectedWall?.start.y);
        connectedWallEnd = new THREE.Vector2(connectedWall?.end.x, connectedWall?.end.y);
        connectionPoint = object.start;
      } else if (endConnection && this.wallsMap.get(endConnection.id)) {
        const connectedWall = this.wallsMap.get(endConnection.id);
        connectedWallStart = new THREE.Vector2(connectedWall?.start.x, connectedWall?.start.y);
        connectedWallEnd = new THREE.Vector2(connectedWall?.end.x, connectedWall?.end.y);
        connectionPoint = object.end;
      }
    } else if (isSingleLineType(object)) {
      if (startConnection && this.singleLinesMap.get(startConnection.id)) {
        const connectedLine = this.singleLinesMap.get(startConnection.id);
        connectedWallStart = new THREE.Vector2(connectedLine?.start.x, connectedLine?.start.y);
        connectedWallEnd = new THREE.Vector2(connectedLine?.end.x, connectedLine?.end.y);
        connectionPoint = object.start;
      } else if (endConnection && this.singleLinesMap.get(endConnection.id)) {
        const connectedLine = this.singleLinesMap.get(endConnection.id);
        connectedWallStart = new THREE.Vector2(connectedLine?.start.x, connectedLine?.start.y);
        connectedWallEnd = new THREE.Vector2(connectedLine?.end.x, connectedLine?.end.y);
        connectionPoint = object.end;
      }
    }
    else if (isRulerLineType(object)) {
      if (startConnection && this.rulerLinesMap.get(startConnection.id)) {
        const connectedLine = this.rulerLinesMap.get(startConnection.id);
        connectedWallStart = new THREE.Vector2(connectedLine?.start.x, connectedLine?.start.y);
        connectedWallEnd = new THREE.Vector2(connectedLine?.end.x, connectedLine?.end.y);
        connectionPoint = object.start;
      } else if (endConnection && this.rulerLinesMap.get(endConnection.id)) {
        const connectedLine = this.rulerLinesMap.get(endConnection.id);
        connectedWallStart = new THREE.Vector2(connectedLine?.start.x, connectedLine?.start.y);
        connectedWallEnd = new THREE.Vector2(connectedLine?.end.x, connectedLine?.end.y);
        connectionPoint = object.end;
      }
    }
    // Step 4: If no connected wall is found, return the default perpendicular vector
    if (!connectedWallStart || !connectedWallEnd || !connectionPoint) {
      return new THREE.Vector2(-objectDirection.y, objectDirection.x); // Default perpendicular vector
    }

    // Step 5: Calculate the vector from the connection point to the connected wall
    // If it's a start connection, calculate from the start point of the wall to the connected wall's start point
    // If it's an end connection, calculate from the end point of the wall to the connected wall's start point
    let toConnectedWall: THREE.Vector2;
    if (connectedWallStart.distanceTo(connectionPoint) > connectedWallEnd.distanceTo(connectionPoint)) {
      toConnectedWall = new THREE.Vector2().subVectors(connectedWallStart, connectionPoint);
    } else {
      toConnectedWall = new THREE.Vector2().subVectors(connectedWallEnd, connectionPoint);
    }

    // Step 6: Calculate the cross product between the wall direction and the vector to the connected wall
    const crossProduct = objectDirection.x * toConnectedWall.y - objectDirection.y * toConnectedWall.x;

    // Step 7: Based on the sign of the cross product, decide the perpendicular direction
    if (crossProduct < 0) {
      // The connected wall is on one side of the wall, so return the flipped perpendicular vector
      return new THREE.Vector2(objectDirection.y, -objectDirection.x);
    } else {
      // The connected wall has crossed the source wall, so return the default perpendicular vector
      return new THREE.Vector2(-objectDirection.y, objectDirection.x);
    }
  }

  objectPerpendicularOuter = (object: WallType | SingleLineType | RulerLineType): THREE.Vector2 => {
    // the opposite direction of the inner perpendicular
    return this.objectPerpendicularInner(object).negate();
  }

  objectDirection = (object: WallType | SingleLineType | RulerLineType): THREE.Vector2 => {
    return new THREE.Vector2().subVectors(object.end, object.start).normalize();
  }

  objectOppositeDirection = (object: WallType | SingleLineType | RulerLineType): THREE.Vector2 => {
    return this.objectDirection(object).negate();
  }

  // Return a set containing WallConnectionStart or WallConnectionEnd that is available for connection
  getAvailableWallConnectionPositions = (wall: WallType): Set<number> => {
    const availablePositions = new Set([WallConnectionStart, WallConnectionEnd]);
    wall.connections?.forEach((connection) => {
      availablePositions.delete(connection.sourcePosition);
    });
    return availablePositions;
  }

  wallIsConnected(wall: WallType, onlyCheckEnds: boolean = false): boolean {
    let connected = false;
    wall.connections?.forEach((connection) => {
      if (onlyCheckEnds) {
        if (connection.sourcePosition === WallConnectionStart || connection.sourcePosition === WallConnectionEnd) {
          connected = true;
        }
        //} else if (this.walls[connection.id]) {
      } else if (this.wallsMap.get(connection.id)) {
        connected = true;
      }
    });
    return connected;
  };

  objectConnectedTo = (objectId: string, atPosition: number): string | undefined => {
    const object = this.findObjectId(objectId);
    if (!object) return undefined;
    const connection = object.connections?.find((connection) => connection.sourcePosition === atPosition);
    if (connection) {
      return connection.id;
    }
    return undefined;
  }

  addConnection = (source: string, target: string, sourcePosition: number, targetPosition: number) => {
    const sourceObject = this.findObjectId(source);
    const targetObject = this.findObjectId(target);
    if (sourceObject && targetObject) {
      // Remove any existing connections between the objects
      this.removeConnection(source, target);
      // Update the connections of the source and target objects
      const updatedSourceObject = {
        ...sourceObject,
        connections: sourceObject.connections ? [...sourceObject.connections, { id: target, sourcePosition, targetPosition }] : [{ id: target, sourcePosition, targetPosition }],
      };
      const updatedTargetObject = {
        ...targetObject,
        connections: targetObject.connections ? [...targetObject.connections, { id: source, sourcePosition: targetPosition, targetPosition: sourcePosition }] : [{ id: source, sourcePosition: targetPosition, targetPosition: sourcePosition }],
      };
      // Update the objects in the store
      runInAction(() => {
        if (isWallType(sourceObject)) {
          this.wallsMap.set(source, updatedSourceObject as WallType);
          this.objectNeedsUpdate(source);
        } else if (isSingleLineType(sourceObject)) {
          this.singleLinesMap.set(source, updatedSourceObject as SingleLineType);
        } else if (isRulerLineType(sourceObject)) {
          this.rulerLinesMap.set(source, updatedSourceObject as RulerLineType);
        }
        if (isWallType(targetObject)) {
          this.wallsMap.set(target, updatedTargetObject as WallType);
          this.objectNeedsUpdate(target);
        } else if (isSingleLineType(targetObject)) {
          this.singleLinesMap.set(target, updatedTargetObject as SingleLineType);
        } else if (isRulerLineType(targetObject)) {
          this.rulerLinesMap.set(target, updatedTargetObject as RulerLineType);
        }
        // If we are connecting two walls, maybe adjust the wall lengths
        if (isWallType(sourceObject) && isWallType(targetObject)) {
          // // Check if the source wall connects any endpoints to the target wall
          // if (sourcePosition === WallConnectionStart || sourcePosition === WallConnectionEnd) {
          //   // Check that the opposite end of the source wall is not connected to another object
          //   const connection = targetObject.connections?.find(
          //     (c) => c.sourcePosition === (sourcePosition === WallConnectionEnd ? WallConnectionStart : WallConnectionEnd),
          //   );

          //   if (!connection) {
          //     console.log("Adjusting wall length");
          //     // Remove the connected end wallWidth / 2 from the wall length
          //     const wallWidth = (targetObject.wallWidth || floorplannerStore.wallWidth);
          //     const newWallLength = floorplannerStore.wallOuterLength(sourceObject) - wallWidth / 2;
          //     floorplannerStore.setWallLength(sourceObject, newWallLength, (sourcePosition === WallConnectionEnd ? WallConnectionStart : WallConnectionEnd), "outer");
          //   }
          // }
          // // Check if the target wall connects any endpoints to the source wall
          // if (targetPosition === WallConnectionStart || targetPosition === WallConnectionEnd) {
          //   // Check that the opposite end of the target wall is not connected to another object
          //   const connection = sourceObject.connections?.find(
          //     (c) => c.sourcePosition === (targetPosition === WallConnectionEnd ? WallConnectionStart : WallConnectionEnd),
          //   );
          //   if (!connection) {
          //     // Remove the connected end wallWidth / 2 from the wall length
          //     const wallWidth = (sourceObject.wallWidth || floorplannerStore.wallWidth);
          //     const newWallLength = floorplannerStore.wallOuterLength(targetObject) - wallWidth / 2;
          //     floorplannerStore.setWallLength(targetObject, newWallLength, (targetPosition === WallConnectionEnd ? WallConnectionStart : WallConnectionEnd), "outer");
          //   }
          // }

          // Check that the opposite end of the source wall is not connected to another object
          // if (sourcePosition !== WallConnectionStart && sourcePosition !== WallConnectionEnd) {
          //   const connection = sourceObject.connections?.find(
          //     (c) => c.sourcePosition === (sourcePosition === WallConnectionEnd ? WallConnectionStart : WallConnectionEnd),
          //   );
          //   if (!connection) {
          //     const wallWidth = (targetObject.wallWidth || floorplannerStore.wallWidth);
          //     const newWallLength = floorplannerStore.wallOuterLength(sourceObject) - wallWidth / 2;
          //     floorplannerStore.setWallLength(sourceObject.id, newWallLength, sourcePosition, "outer");
          //   }
          // }
        }
      });
      this.markObjectForRoomUpdate(source);
      this.markObjectForRoomUpdate(target);
      this.setDirty();
    }
  }

  removeConnectionEnd = (source: string, connectionPos: number) => {
    const object = this.findObjectId(source);
    if (object) {
      object.connections?.forEach((connection) => {
        if (connection.sourcePosition === connectionPos) {
          this.removeConnection(object.id, connection.id);
        }
      });
      this.setDirty();
    }
  }

  removeConnection = (source: string, target: string) => {
    const sourceObject = this.findObjectId(source);
    const targetObject = this.findObjectId(target);
    if (sourceObject && targetObject) {
      runInAction(() => {
        // Remove cutouts from the walls
        if (isWallType(sourceObject)) {
          sourceObject.cutouts = sourceObject.cutouts?.filter(
            (c) => c.belongsTo !== targetObject.id,
          );
        }
        if (isWallType(targetObject)) {
          targetObject.cutouts = targetObject.cutouts?.filter(
            (c) => c.belongsTo !== sourceObject.id,
          );
        }
        // Remove any generated clipping regions from the rendering cache
        renderStore.removeWallClipping(sourceObject.id, targetObject.id);
        renderStore.removeWallClipping(targetObject.id, sourceObject.id);
        // Remove render cache for the walls
        renderStore.clearWallShapes(sourceObject.id);
        renderStore.clearWallShapes(targetObject.id);
        // Save the sourceConnection to be removed
        const sourceConnection = sourceObject.connections?.find(connection => connection.id === targetObject.id);
        // Remove the connection from the source object
        const updatedSourceWall = {
          ...sourceObject,
          connections: sourceObject.connections?.filter(connection => connection.id !== targetObject.id),
        };
        // Save the targetConnection to be removed
        const targetConnection = targetObject.connections?.find(connection => connection.id === sourceObject.id);
        // Remove the connection from the target object
        const updatedTargetWall = {
          ...targetObject,
          connections: targetObject.connections?.filter(connection => connection.id !== sourceObject.id),
        };
        // Update the objects in the store
        if (isWallType(sourceObject)) {
          this.wallsMap.set(sourceObject.id, updatedSourceWall as WallType);
          this.objectNeedsUpdate(sourceObject.id);
        } else if (isSingleLineType(sourceObject)) {
          this.singleLinesMap.set(sourceObject.id, updatedSourceWall as SingleLineType);
        } else if (isRulerLineType(sourceObject)) {
          this.rulerLinesMap.set(sourceObject.id, updatedSourceWall as RulerLineType);
        }
        if (isWallType(targetObject)) {
          this.wallsMap.set(targetObject.id, updatedTargetWall as WallType);
          this.objectNeedsUpdate(targetObject.id);
        } else if (isSingleLineType(targetObject)) {
          this.singleLinesMap.set(targetObject.id, updatedTargetWall as SingleLineType);
        } else if (isRulerLineType(targetObject)) {
          this.rulerLinesMap.set(targetObject.id, updatedTargetWall as RulerLineType);
        }
        // If we are disconnecting two walls, maybe adjust the wall lengths
        if (isWallType(sourceObject) && isWallType(targetObject)) {
          // // Check if the source wall do not connect the other end to another object
          // if (sourceConnection) {
          //   if (!sourceObject.connections?.find((c) => c.sourcePosition === (sourceConnection.sourcePosition === WallConnectionEnd ? WallConnectionStart : WallConnectionEnd))) {
          //     // Deduct previous connected wallWidth / 2 from length
          //     const wallWidth = (targetObject.wallWidth || floorplannerStore.wallWidth);
          //     const newWallLength = floorplannerStore.wallOuterLength(sourceObject) - wallWidth / 2;
          //     floorplannerStore.setWallLength(sourceObject, newWallLength, sourceConnection.sourcePosition, "outer");
          //   }
          // }
          // // Check if the target wall do not connect the other end to another object
          // if (targetConnection) {
          //   if (!targetObject.connections?.find((c) => c.sourcePosition === (targetConnection.sourcePosition === WallConnectionEnd ? WallConnectionStart : WallConnectionEnd))) {
          //     // Deduct previous connected wallWidth / 2 from length
          //     const wallWidth = (sourceObject.wallWidth || floorplannerStore.wallWidth);
          //     const newWallLength = floorplannerStore.wallOuterLength(targetObject) - wallWidth / 2;
          //     floorplannerStore.setWallLength(targetObject, newWallLength, targetConnection.sourcePosition, "outer");
          //   }
          // }

          // if (sourceConnection && sourceConnection.sourcePosition !== WallConnectionStart && sourceConnection.sourcePosition !== WallConnectionEnd) {
          //   // Check if the source wall do not connect the other end to another object
          //   const connection = sourceObject.connections?.find((c) => c.sourcePosition === (sourceConnection?.sourcePosition === WallConnectionEnd ? WallConnectionStart : WallConnectionEnd))
          //   if (!connection) {
          //     const wallWidth = (targetObject.wallWidth || floorplannerStore.wallWidth);
          //     const newWallLength = floorplannerStore.wallOuterLength(sourceObject) + wallWidth / 2;
          //     floorplannerStore.setWallLength(sourceObject.id, newWallLength, null, "outer");
          //   }
          // }
        }
        // If we do not have any connections any more, remove all wall clippings for consistency
        if (isWallType(sourceObject) && !sourceObject.connections?.length) {
          renderStore.clearWallClippings(sourceObject.id);
        }
      });
      this.markObjectForRoomUpdate(source);
      this.markObjectForRoomUpdate(target);
      this.setDirty();
    }
  }

  clearConnections = (source: string) => {
    const object = this.findObjectId(source);
    if (object) {
      // Remove all connections from the object
      object.connections?.forEach((connection) => {
        this.removeConnection(object.id, connection.id);
      });
      this.setDirty();
    }
  }

  // Flag a wall's rooms for update
  markObjectForRoomUpdate = (objectId: string) => {
    this.objectsNeedingRoomUpdate.add(objectId);

    // Debounce the actual room update to avoid excessive computation during dragging
    this.processRoomUpdates();
  }

  // Update only the rooms affected by changes
  updateAffectedRooms = () => {
    const objectsToUpdate = Array.from(this.objectsNeedingRoomUpdate);
    this.objectsNeedingRoomUpdate.clear();
    this.roomsFound.clear();
    this.objectIdsInFoundRooms.clear();
    const roomsToRecalculate = new Set<string>();

    // Collect rooms that include the objects needing update
    objectsToUpdate.forEach(objectId => {
      const object = this.findObjectId(objectId);
      if (object && (isWallType(object) || isSingleLineType(object))) {
        if (object.roomIds) {
          object.roomIds.forEach(roomId => {
            roomsToRecalculate.add(roomId);
          });
        }
      }
    });

    // Remove affected rooms
    roomsToRecalculate.forEach(roomId => {
      const room = this.roomsMap.get(roomId);
      if (room) {
        // Remove room reference from wallIds
        room.objectIds.forEach(objectId => {
          const object = this.findObjectId(objectId);
          if (object && (isWallType(object) || isSingleLineType(object))) {
            object.roomIds = object.roomIds?.filter(id => id !== roomId);
          }
        });
        this.roomsMap.delete(roomId);
      }
    });

    // Recalculate rooms starting from affected walls
    objectsToUpdate.forEach(objectId => {
      const object = this.findObjectId(objectId);
      if (object && (isWallType(object) || isSingleLineType(object))) {
        this.findRoomsFromObject(object);
      }
    });
  }

  // Find rooms starting from a wall
  findRoomsFromObject = (startObject: WallType | SingleLineType) => {
    const path: (WallType | SingleLineType)[] = [];

    if (this.isObjectInFoundRooms(startObject.id)) {
      // This wall is already part of a found room, skip DFS
      return;
    }

    const visitedEdges = new Set<string>();

    // DFS (Depth First Search) to find rooms
    const dfs = (
      currentObject: WallType | SingleLineType,
      startVertex: THREE.Vector2,
      currentVertex: THREE.Vector2
    ) => {
      path.push(currentObject);
      const edgeKey = `${currentObject.id}-${currentVertex.x},${currentVertex.y}`;
      if (visitedEdges.has(edgeKey)) {
        path.pop();
        return;
      }
      visitedEdges.add(edgeKey);

      const nextWalls = this.getConnectedObjectsAtVertex(currentObject, currentVertex);
      for (const nextWall of nextWalls) {
        const nextVertex = this.getOppositeVertex(nextWall, currentVertex);
        const nextEdgeKey = `${nextWall.id}-${nextVertex.x},${nextVertex.y}`;

        if (this.vectorsEqual(nextVertex, startVertex) && path.length >= 3) {
          // Found a cycle (room)
          const roomObjects = [...path, nextWall];
          const roomObjectIds = roomObjects.map((w) => w.id).sort().join(",");
          if (!this.roomsFound.has(roomObjectIds)) {
            this.roomsFound.add(roomObjectIds);
            this.addObjectsToFoundRooms(roomObjects);
            this.createRoomFromPath(roomObjects);
          }
        } else if (!visitedEdges.has(nextEdgeKey)) {
          dfs(nextWall, startVertex, nextVertex);
        }
      }

      path.pop();
      // Do not delete from visitedEdges to prevent revisiting the same edge
    };

    // Start DFS from both vertices of the startWall
    dfs(startObject, startObject.start, startObject.end);
    dfs(startObject, startObject.end, startObject.start);
  };

  normalizeCycle = (vertices: THREE.Vector2[]): string => {
    // Create a list of vertex positions
    const positions = vertices.map(v => `${v.x.toFixed(6)},${v.y.toFixed(6)}`);

    // Find the index of the vertex with the lowest x (and then y)
    let minIndex = 0;
    for (let i = 1; i < positions.length; i++) {
      if (positions[i] < positions[minIndex]) {
        minIndex = i;
      }
    }

    // Rotate the list so that it starts from the vertex with lowest x,y
    const rotatedPositions = positions.slice(minIndex).concat(positions.slice(0, minIndex));

    // Create two representations: one in the original order, one in reversed order
    const clockwise = rotatedPositions.join(";");
    const counterClockwise = rotatedPositions.slice().reverse().join(";");

    // Return the lexicographically smaller one
    return clockwise < counterClockwise ? clockwise : counterClockwise;
  }

  vectorsEqual = (v1: THREE.Vector2, v2: THREE.Vector2, tolerance = 1e-6): boolean => {
    return v1.distanceToSquared(v2) < tolerance * tolerance;
  }
  isObjectInFoundRooms = (objectId: string): boolean => {
    return this.objectIdsInFoundRooms.has(objectId);
  }

  addObjectsToFoundRooms = (objects: (WallType | SingleLineType)[]) => {
    objects.forEach(object => this.objectIdsInFoundRooms.add(object.id));
  }

  // Helper method to get walls connected at a vertex
  getConnectedObjectsAtVertex = (object: WallType | SingleLineType, vertex: THREE.Vector2): (WallType | SingleLineType)[] => {
    const connectedObjects: (WallType | SingleLineType)[] = [];
    const vertexKey = `${vertex.x},${vertex.y}`;

    if (isWallType(object)) {
      this.wallsMap.forEach(otherWall => {
        if (otherWall.id === object.id) return;
        if (this.vectorsEqual(otherWall.start, vertex) || this.vectorsEqual(otherWall.end, vertex)) {
          connectedObjects.push(otherWall);
        }
      });
    } else if (isSingleLineType(object)) {
      this.singleLinesMap.forEach(otherLine => {
        if (otherLine.id === object.id) return;
        if (this.vectorsEqual(otherLine.start, vertex) || this.vectorsEqual(otherLine.end, vertex)) {
          connectedObjects.push(otherLine);
        }
      });
    }

    return connectedObjects;
  }

  // Helper method to get the opposite vertex of a wall given one vertex
  getOppositeVertex = (object: WallType | SingleLineType, vertex: THREE.Vector2): THREE.Vector2 => {
    if (this.vectorsEqual(object.start, vertex)) {
      return object.end;
    } else {
      return object.start;
    }
  }

  // Method to create a room from a path of objects
  createRoomFromPath = (objects: (WallType | SingleLineType)[]) => {
    const roomId = Math.random().toString(36).substr(2, 9);

    // Extract the ordered list of vertices defining the room's polygon
    const roomVertices = this.getRoomVertices(objects, "inner");

    // Compute the centroid of the polygon
    const centroid = this.computeRoomCentroid(roomVertices);

    // Compute the area of the polygon
    const areaWorldUnits = this.computeRoomArea(roomVertices);

    const objectIds = objects.map(object => object.id);

    const room: RoomType = {
      id: roomId,
      type: 'room',
      objectIds: objectIds,
      centroid: centroid,
      areaWorldUnits: areaWorldUnits,
    };

    this.roomsMap.set(roomId, room);

    // Update object.roomIds
    objects.forEach(object => {
      const w = this.findObjectId(object.id);
      if (w && (isWallType(w) || isSingleLineType(w))) {
        if (!w.roomIds) w.roomIds = [];
        if (!w.roomIds.includes(roomId)) w.roomIds.push(roomId);
      }
    });
    this.setDirty();
  };

  getObjectsOfRoom = (room: RoomType): (WallType | SingleLineType)[] => {
    if (!room.objectIds || room.objectIds.length === 0) return [];
    const objects: (WallType | SingleLineType)[] = [];
    room.objectIds.forEach(objectId => {
      const object = this.findObjectId(objectId);
      if (object && (isWallType(object) || isSingleLineType(object))) {
        objects.push(object);
      }
    });
    return objects;
  };

  getRoomVertices = (objects: (WallType | SingleLineType)[], side: "inner" | "outer" | "center" = "center"): THREE.Vector2[] => {
    const vertices: THREE.Vector2[] = [];
    if (objects.length === 0) return vertices;

    const offsetLines: { start: THREE.Vector2, end: THREE.Vector2 }[] = [];

    // Step 1: Create offset lines for each wall
    for (let i = 0; i < objects.length; i++) {
      const currentObject = objects[i];
      const wallWidth = isWallType(currentObject) ? (currentObject?.wallWidth || this.wallWidth) - 0.008 : 0;
      const offset = side === "center" ? 0 : side === "inner" ? wallWidth : -wallWidth;

      // Get direction vector of the wall
      const wallDir = new THREE.Vector2().subVectors(currentObject.end, currentObject.start).normalize();

      // Compute perpendicular vector towards the "side" of the room
      let perpendicular
      if (side === "center") {
        perpendicular = new THREE.Vector2(-wallDir.y, wallDir.x);
      } else if (side === "inner") {
        perpendicular = this.objectPerpendicularInner(currentObject);
      } else {
        perpendicular = this.objectPerpendicularOuter(currentObject);
      }

      // Create the offset line by shifting the wall inwards by half the wall width
      const startOffset = currentObject.start.clone().add(perpendicular.multiplyScalar(offset));
      const endOffset = currentObject.end.clone().add(perpendicular.multiplyScalar(offset));

      // Extend the lines to ensure they intersect
      const extendBy = 1000;
      startOffset.add(wallDir.clone().multiplyScalar(extendBy));
      endOffset.add(wallDir.clone().multiplyScalar(-extendBy));

      // Store the offset line
      offsetLines.push({ start: startOffset, end: endOffset });
    }

    // Step 2: Find intersection points between adjacent offset lines
    for (let i = 0; i < offsetLines.length; i++) {
      const currentLine = offsetLines[i];
      const nextLine = offsetLines[(i + 1) % offsetLines.length]; // Ensure we wrap around to close the polygon

      const intersectionPoint = this.getIntersection(
        currentLine.start, currentLine.end,
        nextLine.start, nextLine.end
      );

      if (intersectionPoint) {
        vertices.push(intersectionPoint);
      } else {
        console.warn(`No intersection found between wall ${i} and ${i + 1} while creating room polygon`);
      }
    }

    // Ensure the polygon is closed
    if (vertices.length > 1 && !vertices[vertices.length - 1].equals(vertices[0])) {
      vertices.push(vertices[0].clone());
    }

    return vertices;
  };

  // Helper function to calculate the intersection of two lines
  getIntersection = (
    line1Start: THREE.Vector2, line1End: THREE.Vector2,
    line2Start: THREE.Vector2, line2End: THREE.Vector2
  ): THREE.Vector2 | null => {
    const denominator = (line2End.y - line2Start.y) * (line1End.x - line1Start.x) -
      (line2End.x - line2Start.x) * (line1End.y - line1Start.y);

    if (denominator === 0) return null; // Lines are parallel or coincident

    const a = line1Start.y - line2Start.y;
    const b = line1Start.x - line2Start.x;
    const numerator1 = (line2End.x - line2Start.x) * a - (line2End.y - line2Start.y) * b;
    const numerator2 = (line1End.x - line1Start.x) * a - (line1End.y - line1Start.y) * b;

    const r = numerator1 / denominator;
    const s = numerator2 / denominator;

    if (r >= 0 && r <= 1 && s >= 0 && s <= 1) {
      return new THREE.Vector2(
        line1Start.x + r * (line1End.x - line1Start.x),
        line1Start.y + r * (line1End.y - line1Start.y)
      );
    }

    return null; // No valid intersection
  };


  computeRoomCentroid = (vertices: THREE.Vector2[]): THREE.Vector2 => {
    let area = 0;
    let Cx = 0;
    let Cy = 0;
    const N = vertices.length;

    for (let i = 0; i < N - 1; i++) {
      const current = vertices[i];
      const next = vertices[i + 1];
      const cross = current.x * next.y - next.x * current.y;
      area += cross;
      Cx += (current.x + next.x) * cross;
      Cy += (current.y + next.y) * cross;
    }
    area = area / 2;
    if (area === 0) {
      // Avoid division by zero
      return new THREE.Vector2(0, 0);
    }
    Cx = Cx / (6 * area);
    Cy = Cy / (6 * area);
    return new THREE.Vector2(Cx, Cy);
  };

  computeRoomArea = (vertices: THREE.Vector2[]): number => {
    let area = 0;
    // Compute the area of the polygon
    const N = vertices.length;
    for (let i = 0; i < N - 1; i++) {
      const current = vertices[i];
      const next = vertices[i + 1];
      area += (current.x * next.y) - (next.x * current.y);
    }
    area = Math.abs(area) / 2;
    return area;
  };

  updateRoomProperties = (room: RoomType) => {
    const objects = this.getObjectsOfRoom(room);
    if (objects.length < 3) {
      // Not enough objects to form a valid room
      this.roomsMap.delete(room.id);
      return;
    }

    const roomVertices = this.getRoomVertices(objects, "inner");
    room.centroid = this.computeRoomCentroid(roomVertices);
    room.areaWorldUnits = this.computeRoomArea(roomVertices);
  };

  snapToOtherObjects = (
    source: string,
    snappingEnd: number,
    edge: THREE.Vector2,
    updateOtherEnd: boolean = true,
  ): boolean => {
    return snapToOtherObjects(
      source,
      snappingEnd,
      edge,
      updateOtherEnd,
    );
  }

  snapToAlignmentLines = (
    source: string,
    snappingEnd: number,
    edge: THREE.Vector2,
  ): boolean => {
    return snapToAlignmentLines(
      source,
      snappingEnd,
      edge,
    );
  }

  cloneWall = (id: string, newPosition?: THREE.Vector2) => {
    const wall = this.wallsMap.get(id);
    if (wall) {
      // Make a deep copy of the wall
      const newWall = JSON.parse(JSON.stringify(wall));
      newWall.id = Math.random().toString(36).substr(2, 9);
      newWall.start = newPosition || wall.start.clone().add(new THREE.Vector2(0.2, 0.2));
      newWall.end = newPosition ? newPosition.clone().add(wall.end.clone().sub(wall.start)) : wall.end.clone().add(new THREE.Vector2(0.2, 0.2));
      newWall.connections = [];
      newWall.symbolAttachments = [];
      newWall.clippingPolygons = [];
      this.addWall(newWall);
    }
  };
  cloneLine = (id: string, newPosition?: THREE.Vector2) => {
    const line = this.singleLinesMap.get(id);
    if (line) {
      // Make a deep copy of the wall
      const newLine = JSON.parse(JSON.stringify(line));
      newLine.id = Math.random().toString(36).substr(2, 9);
      newLine.start = newPosition || line.start.clone().add(new THREE.Vector2(0.2, 0.2));
      newLine.end = newPosition ? newPosition.clone().add(line.end.clone().sub(line.start)) : line.end.clone().add(new THREE.Vector2(0.2, 0.2));
      newLine.connections = [];
      newLine.symbolAttachments = [];
      newLine.clippingPolygons = [];
      this.addSingleLine(newLine);
    }
  }
  cloneRuler = (id: string, newPosition?: THREE.Vector2) => {
    const line = this.rulerLinesMap.get(id);
    if (line) {
      // Make a deep copy of the wall
      const newLine = JSON.parse(JSON.stringify(line));
      newLine.id = Math.random().toString(36).substr(2, 9);
      newLine.start = newPosition || line.start.clone().add(new THREE.Vector2(0.2, 0.2));
      newLine.end = newPosition ? newPosition.clone().add(line.end.clone().sub(line.start)) : line.end.clone().add(new THREE.Vector2(0.2, 0.2));
      newLine.connections = [];
      newLine.symbolAttachments = [];
      newLine.clippingPolygons = [];
      this.addRulerLine(newLine);
    }
  }
  getClippingPolygon = (symbol: SymbolType, wall: WallType): ClippingPolygonType => {
    const objectWidth = isDoorType(symbol)
      ? symbol.doorWidth + ((symbol.doorFrameWidth || this.doorFrameWidth) + this.convertLineWeightToWorld(wall.lineWeight || floorplannerStore.wallLineWeight)) * 2
      : isDoubleDoorType(symbol)
        ? (symbol.doubleDoorWidth + (symbol.doorFrameWidth || this.doorFrameWidth)) * 2
        : isWindowType(symbol)
          ? symbol.windowLength
          : 0.8;
    const objectLength = objectWidth / 2;
    const wallAngle = Math.atan2(wall.end.y - wall.start.y, wall.end.x - wall.start.x);
    const objectDirection = new THREE.Vector2(Math.cos(wallAngle), Math.sin(wallAngle));
    // If it is a door or Doubel door, offset the position by the door/ double door width
    const offset = isDoorType(symbol) ? objectWidth / 2 - (symbol.doorFrameWidth || this.doorFrameWidth) - this.convertLineWeightToWorld(wall.lineWeight || floorplannerStore.wallLineWeight) :
      isDoubleDoorType(symbol) ? objectWidth / 2 - (symbol.doorFrameWidth || this.doorFrameWidth) : 0;
    // If it is a door and the door is flipped, offset the position by the door width
    // if ((isDoorType(symbol) || isDoubleDoorType(symbol)) && (symbol.flipHorizontal)) {
    //   objectDirection.negate();
    // }
    const wallDirection = wall.end.clone().sub(wall.start).normalize();
    const symbolDistance = symbol.position.clone().sub(wall.start).dot(wallDirection);
    const symbolPositionOnWall = wall.start.clone().add(wallDirection.clone().multiplyScalar(symbolDistance));
    const objectPositionOffset = symbolPositionOnWall.clone().add(objectDirection.clone().multiplyScalar(offset));
    const objectLengthVector = objectDirection.clone().multiplyScalar(objectLength);
    const objectWidthVector = objectDirection.clone().rotateAround(new THREE.Vector2(0, 0), Math.PI / 2).multiplyScalar(objectWidth / 2);
    const topLeft = objectPositionOffset.clone().add(objectLengthVector).add(objectWidthVector);
    const topRight = objectPositionOffset.clone().add(objectLengthVector).sub(objectWidthVector);
    const bottomRight = objectPositionOffset.clone().sub(objectLengthVector).sub(objectWidthVector);
    const bottomLeft = objectPositionOffset.clone().sub(objectLengthVector).add(objectWidthVector);
    const clippingPolygon: ClippingPolygonType = {
      belongsTo: symbol.id,
      polygon: [
        new THREE.Vector2(topLeft.x, topLeft.y),
        new THREE.Vector2(topRight.x, topRight.y),
        new THREE.Vector2(bottomRight.x, bottomRight.y),
        new THREE.Vector2(bottomLeft.x, bottomLeft.y),
      ],
      objectType: "symbol",
    };
    return clippingPolygon;
  }

  unSelectAll = () => {
    runInAction(() => {
      Array.from(this.wallsMap.values()).forEach((wall) => {
        if (wall.selected) {
          wall.selected = false;
          this.wallsMap.set(wall.id, wall);
        }
      });

      Array.from(this.symbolsMap.values()).forEach((symbol) => {
        if (symbol.selected) {
          symbol.selected = false;
          this.symbolsMap.set(symbol.id, symbol);
        }
      })

      Array.from(this.singleLinesMap.values()).forEach((line) => {
        if (line.selected) {
          line.selected = false;
          this.singleLinesMap.set(line.id, line);
        }
      });
      Array.from(this.rulerLinesMap.values()).forEach((line) => {
        if (line.selected) {
          line.selected = false;
          this.rulerLinesMap.set(line.id, line);
        }
      });
    });
  }

  selectWall = (id: string) => {
    const wall = this.wallsMap.get(id);
    if (wall) {
      wall.selected = true;
      this.wallsMap.set(id, wall);
    }
  }

  selectSymbol = (id: string) => {
    const symbol = this.symbolsMap.get(id);
    if (symbol) {
      symbol.selected = true;
      this.symbolsMap.set(id, symbol);
    }
  }

  selectLine = (id: string) => {
    const line = this.singleLinesMap.get(id);
    if (line) {
      line.selected = true;
      this.singleLinesMap.set(id, line);
    }
  }

  selectRuler = (id: string) => {
    const line = this.rulerLinesMap.get(id);
    if (line) {
      line.selected = true;
      this.rulerLinesMap.set(id, line);
    }
  }

  toJSON = (excludeProperties: string[] | undefined = undefined) => {
    if (!excludeProperties) excludeProperties = [];
    excludeProperties.push("groupRef", "lastUpdate", "objectToRoomsMap", "objectsNeedingRoomUpdate");
    const walls = Array.from(this.wallsMap.values()).map((wall) => {
      const wallCopy: Partial<WallType> = { ...wall };
      delete wallCopy.selected;
      if (excludeProperties) {
        excludeProperties.forEach((property) => {
          delete wallCopy[property as keyof WallType];
        });
      }
      return wallCopy;
    });
    const symbols = Array.from(this.symbolsMap.values()).map((symbol) => {
      const symbolCopy: Partial<SymbolType | DoorType | DoubleDoorType | WindowType | CircleStairsType | RectStairsType | SquareSymbolType | SvgType | TextType> = { ...symbol };
      delete symbolCopy.selected;
      if (excludeProperties) {
        excludeProperties.forEach((property) => {
          delete symbolCopy[property as keyof (SymbolType | DoorType | DoubleDoorType | WindowType | CircleStairsType | RectStairsType | SquareSymbolType | SvgType | TextType)];
        });
      }
      return symbolCopy;
    });
    const singleLines = Array.from(this.singleLinesMap.values()).map((line) => {
      const lineCopy: Partial<SingleLineType> = { ...line };
      delete lineCopy.selected;
      if (excludeProperties) {
        excludeProperties.forEach((property) => {
          delete lineCopy[property as keyof SingleLineType];
        });
      }
      return lineCopy;
    });
    const rulerLines = Array.from(this.rulerLinesMap.values()).map((line) => {
      const lineCopy: Partial<RulerLineType> = { ...line };
      delete lineCopy.selected;
      if (excludeProperties) {
        excludeProperties.forEach((property) => {
          delete lineCopy[property as keyof RulerLineType];
        });
      }
      return lineCopy;
    });
    const rooms = Array.from(this.roomsMap.values()).map((room) => {
      const roomCopy: Partial<RoomType> = { ...room };
      delete roomCopy.selected;
      if (excludeProperties) {
        excludeProperties.forEach((property) => {
          delete roomCopy[property as keyof RoomType];
        });
      }
      return {
        ...roomCopy,
        centroid: { x: room.centroid.x, y: room.centroid.y },
      };
    });
    return JSON.stringify({
      id: this.id,
      name: this.name,
      walls: walls,
      symbols: symbols,
      singleLines: singleLines,
      rulerLines: rulerLines,
      rooms: rooms,
    });
  };

  fromJSON = (json: string) => {
    const parsedStructure = JSON.parse(json);
    if (!parsedStructure) {
      return;
    }

    // Make mobx observable changes but batch them for all properties
    runInAction(() => {
      this.wallsMap.clear();
      this.symbolsMap.clear();
      this.singleLinesMap.clear();
      this.rulerLinesMap.clear();
      this.roomsMap.clear();
      this.objectToRoomsMap.clear();
      this.dirty = false;
      this.blockDirty = false;
      this.name = "Untitled";
      if (parsedStructure.name) {
        this.name = parsedStructure.name;
      }
      if (parsedStructure.walls) {
        parsedStructure.walls.forEach((wall: WallType) => {
          this.wallsMap.set(wall.id, {
            ...wall,
            type: "wall",
            start: new THREE.Vector2(wall.start.x, wall.start.y),
            end: new THREE.Vector2(wall.end.x, wall.end.y),
            controlPoint: wall.controlPoint ? new THREE.Vector2(wall.controlPoint.x, wall.controlPoint.y) : undefined,
            cutouts: wall.cutouts ? wall.cutouts.map((cutout: CutoutType) => {
              return {
                ...cutout,
                appliesTo: new Set(cutout.appliesTo),
              };
            }) : [],
          });
        });
        Array.from(this.wallsMap.values()).forEach((wall) => {
          Object.keys(wall).forEach((key) => {
            if (!Object.keys(wall).includes(key)) {
              delete wall[key as keyof WallType];
            }
          });
        });
      }

      if (parsedStructure.symbols) {
        parsedStructure.symbols.forEach((symbol: SymbolType | DoorType | DoubleDoorType | CircleStairsType | RectStairsType | SquareSymbolType | WindowType | SvgType | TextType) => {
          this.symbolsMap.set(symbol.id, {
            ...symbol,
            position: symbol.position
              ? new THREE.Vector2(symbol.position.x, symbol.position.y)
              : new THREE.Vector2(0, 0),
          });
          // If the object is a door, set some default properties
          if (isDoorType(symbol)) {
            if (!(symbol as DoorType).doorWidth) {
              (symbol as DoorType).doorWidth = this.doorWidth;
            }
            if (!(symbol as DoorType).openAngle) {
              (symbol as DoorType).openAngle = this.openAngle;
            }
          }
          // If the object is a Double door, set some default properties
          if (isDoubleDoorType(symbol)) {
            if (!(symbol as DoubleDoorType).doubleDoorWidth) {
              (symbol as DoubleDoorType).doubleDoorWidth = this.doubleDoorWidth;
            }
            if (!(symbol as DoubleDoorType).openAngle) {
              (symbol as DoubleDoorType).openAngle = this.openAngle;
            }
          }
          // If the object is a window, set some default properties
          if (isWindowType(symbol)) {
            if (!(symbol as WindowType).windowWidth) {
              (symbol as WindowType).windowWidth = 0.2;
            }
            if (!(symbol as WindowType).windowLength) {
              (symbol as WindowType).windowLength = 1;
            }
          }
          // If the object is a Circle Stairs, set some default properties
          if (isCircleStairsType(symbol)) {
            if (!(symbol as CircleStairsType).circleStairsWidth) {
              (symbol as CircleStairsType).circleStairsWidth = this.circleStairsWidth;
            }
            if (!(symbol as CircleStairsType).openAngle) {
              (symbol as CircleStairsType).openAngle = this.openAngle;
            }
            if (!(symbol as CircleStairsType).stairStepSize) {
              (symbol as CircleStairsType).stairStepSize = this.stairStepSize;
            }
          }
          // If the object is a Rectangle Stairs, set some default properties
          if (isRectStairsType(symbol)) {
            if (!(symbol as RectStairsType).rectStairsWidth) {
              (symbol as RectStairsType).rectStairsWidth = this.rectStairsWidth;
            }
            if (!(symbol as RectStairsType).rectStairsHeight) {
              (symbol as RectStairsType).rectStairsHeight = this.rectStairsHeight;
            }
            if (!(symbol as RectStairsType).openAngle) {
              (symbol as RectStairsType).openAngle = this.openAngle;
            }
            if (!(symbol as RectStairsType).stairStepSize) {
              (symbol as RectStairsType).stairStepSize = this.stairStepSize;
            }
          }
          // If the object is a Square, set some default properties
          if (isSquareSymbolType(symbol)) {
            if (!(symbol as SquareSymbolType).squareWidth) {
              (symbol as SquareSymbolType).squareWidth = this.squareWidth;
            }
            if (!(symbol as SquareSymbolType).squareHeight) {
              (symbol as SquareSymbolType).squareHeight = this.squareHeight;
            }
            if (!(symbol as SquareSymbolType).openAngle) {
              (symbol as SquareSymbolType).openAngle = this.openAngle;
            }
          }

          // If the object is a Rectangle Stairs, set some default properties
          if (isTextType(symbol)) {
            if (!(symbol as TextType).text) {
              (symbol as TextType).text = this.text;
            }
            if (!(symbol as TextType).fontSize) {
              (symbol as TextType).fontSize = this.fontSize;
            }
            if (!(symbol as TextType).fontStyle) {
              (symbol as TextType).fontStyle = this.fontStyle;
            }
            if (!(symbol as TextType).fontWeight) {
              (symbol as TextType).fontWeight = this.fontWeight;
            }
            if (!(symbol as TextType).lineHeight) {
              (symbol as TextType).lineHeight = this.lineHeight;
            }
          }
          // If the object is an SVG, set some default properties
          if (isSvgType(symbol)) {
            const svgSymbol = symbol as SvgType;
            svgSymbol.svgPath = svgSymbol.svgPath || this.svgPath;
            svgSymbol.svgLength = svgSymbol.svgLength || this.svgLength;
            svgSymbol.svgHeight = svgSymbol.svgHeight || this.svgHeight;
          }
        });

        // Remove all properties that are not in the symbol type
        Array.from(this.symbolsMap.values()).forEach((symbol) => {
          Object.keys(symbol).forEach((key) => {
            if (!Object.keys(symbol).includes(key)) {
              delete symbol[key as keyof SymbolType];
            }
          });
        })
      }

      if (parsedStructure.singleLines) {
        parsedStructure.singleLines.forEach((line: SingleLineType) => {
          this.singleLinesMap.set(line.id, {
            ...line,
            type: "singleLine",
            start: new THREE.Vector2(line.start?.x, line.start?.y),
            end: new THREE.Vector2(line.end?.x, line.end?.y),
          });
        });
        // Remove all properties that are not in the single line type
        Array.from(this.singleLinesMap.values()).forEach((line) => {
          Object.keys(line).forEach((key) => {
            if (!Object.keys(line).includes(key)) {
              delete line[key as keyof SingleLineType];
            }
          });
        })
      }
      if (parsedStructure.rulerLines) {
        parsedStructure.rulerLines.forEach((line: RulerLineType) => {
          this.rulerLinesMap.set(line.id, {
            ...line,
            type: "rulerLine",
            start: new THREE.Vector2(line.start?.x, line.start?.y),
            end: new THREE.Vector2(line.end?.x, line.end?.y),
          });
        });
        // Remove all properties that are not in the ruler line type
        Array.from(this.rulerLinesMap.values()).forEach((line) => {
          Object.keys(line).forEach((key) => {
            if (!Object.keys(line).includes(key)) {
              delete line[key as keyof RulerLineType];
            }
          });
        })
      }
      // Deserialize rooms
      if (parsedStructure.rooms) {
        parsedStructure.rooms.forEach((room: RoomType) => {
          if (room.objectIds) {
            // Filter out objectIds that do not exist in wallsMap or singleLinesMap
            const validObjectIds = room.objectIds.filter(objectId => {
              return this.wallsMap.has(objectId) || this.singleLinesMap.has(objectId);
            });

            if (validObjectIds.length < 3) {
              // Not enough walls to form a valid room, skip this room
              return;
            }

            const deserializedRoom: RoomType = {
              ...room,
              centroid: new THREE.Vector2(room.centroid.x, room.centroid.y),
              objectIds: validObjectIds,
              areaWorldUnits: room.areaWorldUnits,
            };

            // Recalculate centroid and area
            this.updateRoomProperties(deserializedRoom);

            this.roomsMap.set(room.id, deserializedRoom);
          }
        });
      }

      if (this.roomsMap.size === 0) {
        this.wallsMap.forEach(wall => {
          this.markObjectForRoomUpdate(wall.id);
        });
        this.singleLinesMap.forEach(line => {
          this.markObjectForRoomUpdate(line.id);
        });
        this.processRoomUpdates();
      }

      // Reconstruct objectToRoomsMap
      this.wallsMap.forEach(wall => {
        if (wall.roomIds) {
          wall.roomIds = wall.roomIds.filter(roomId => this.roomsMap.has(roomId));
          wall.roomIds.forEach(roomId => {
            if (!this.objectToRoomsMap.has(wall.id)) {
              this.objectToRoomsMap.set(wall.id, new Set<string>());
            }
            this.objectToRoomsMap.get(wall.id)!.add(roomId);
          });
        }
      });
      this.singleLinesMap.forEach(line => {
        if (line.roomIds) {
          line.roomIds = line.roomIds.filter(roomId => this.roomsMap.has(roomId));
          line.roomIds.forEach(roomId => {
            if (!this.objectToRoomsMap.has(line.id)) {
              this.objectToRoomsMap.set(line.id, new Set<string>());
            }
            this.objectToRoomsMap.get(line.id)!.add(roomId);
          });
        }
      });
      // Update clipping polygons
      setTimeout(() => {
        renderStore?.clearClippings();
        Array.from(this.wallsMap.values()).forEach((wall) => {
          wall.symbolAttachments?.forEach((symbolAttachment) => {
            const symbol = this.symbolsMap.get(symbolAttachment.symbolId);
            if (!symbol) return;
            const clippingPolygon = renderStore.getClippingPolygon(
              symbol,
              wall
            );
            if (clippingPolygon) {
              renderStore.addWallClipping(wall.id, clippingPolygon);
            }
          });
        });
      }, 0);
      // Reconstruct objectToRoomsMap
      this.wallsMap.forEach(wall => {
        if (wall.roomIds) {
          wall.roomIds.forEach(roomId => {
            if (!this.objectToRoomsMap.has(wall.id)) {
              this.objectToRoomsMap.set(wall.id, new Set<string>());
            }
            this.objectToRoomsMap.get(wall.id)!.add(roomId);
          });
        }
      });
    });
  };

  saveToCloud = async (
    withSnapshotImage: boolean = false,
    force: boolean = false,
    retryCount: number = 3,
    retryDelay: number = 1000, // 1 second initial delay
  ) => {
    if (!force && (!this.dirty || this.blockDirty)) {
      return;
    }

    // Prevent overlapping executions
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;
    try {
      const j = this.toJSON();
      const id = this.id;
      const file = null;

      for (let attempt = 1; attempt <= retryCount; attempt++) {
        try {
          // maybe this mutation could silently fail if the user is not authenticated
          if (!this.client) {
            if (editorStore.client) {
              this.client = editorStore.client;
            } else {
              this.isSaving = false;
              console.error("Failed to save floorplan: client not initialized");
              return;
            }
          }
          const result = await this.client?.mutate<EditFloorplanMutation>({
            variables: { name: this.name, json: j, id: id, image: file },
            mutation: EditFloorplanDocument,
          });
          this.id = result?.data?.editFloorplan.id || "";
          this.setDirty(false);
          this.persistStore();
          this.isSaving = false;
          const path = window.location.pathname;
          if (path === "/floorplan/") {
            window.location.href = "/floorplan/" + this.id;
          }
          return; // Successfully saved, exit the function
        } catch (error: ApolloError | any) {
          // If the error is user not authenticated, redirect to login
          if (error.message === "User not authenticated" || error.message === "invalid_token") {
            const app_url = process.env.REACT_APP_CLIENT_URI || "";
            window.location.href = app_url + "/login" + "?redirect=" + window.location.href;
            return;
          }
          if (attempt < retryCount) {
            console.warn(`Save failed, retrying (${attempt}/${retryCount})...`);
            await new Promise(res => setTimeout(res, retryDelay));
            retryDelay *= 2; // Exponential backoff
          } else {
            console.error("Failed to save after multiple attempts:", error);
            this.isSaving = false;
            // Optionally notify the user of the failure
            // this.notifyUser("Failed to save floorplan. Please try again later.");
            throw error; // Re-throw the error after exhausting retries
          }
        }
      }
    } catch (error) {
      console.error("Failed to save floorplan:", error);
      this.isSaving = false;
    }
  };

  loadFromCloud = async (id: string) => {
    try {
      const { data, error } = (await this.client?.query({
        query: FloorplanDocument,
        variables: { id },
      })) || { data: { floorplan: null } };
      // Check if error is user not authenticated on initial load
      if (error?.message === "User not authenticated" || error?.message === "invalid_token") {
        // Redirect to login
        const app_url = process.env.REACT_APP_CLIENT_URI || "";
        window.location.href = app_url + "/login" + "?redirect=" + window.location.href;
        return;
      }
      if (!data.floorplan) {
        return;
      }
      runInAction(() => {
        this.fromJSON(data.floorplan.json);
        this.id = data.floorplan.id;
        this.name = data.floorplan.name
        this.dirty = false;
        this.blockDirty = false;
        this.integrityCheck();
        this.persistStore();
        this.resetUndoRedoStacks();
        this.pushToUndoStack();
      });
      
    } catch (error: ApolloError | any) {
      // Check if error is user not authenticated on initial load
      if (error?.message === "User not authenticated" || error?.message === "invalid_token") {
        // Redirect to login
        const app_url = process.env.REACT_APP_CLIENT_URI || "";
        window.location.href = app_url + "/login" + "?redirect=" + window.location.href;
        return;
      } else if (error?.message === "The floorplan was not found") {
        window.location.href = "/floorplan/";
        return;
      }
      console.error("Failed to load floorplan:", error);
    }
  };

  deleteFromCloud = async (id: string) => {
    await this.client?.mutate({
      mutation: DeleteFloorplanDocument,
      variables: { id },
    });
    const floorplans = await this.findFloorplans();
    if (floorplans.length > 0) {
      await this.loadFromCloud(floorplans[0].id);
    } else {
      this.loadDefaultFloorplan();
    }
  };

  findFloorplans = async () => {
    const { data } = (await this.client?.query({
      query: FloorplansDocument,
    })) || { data: { floorplans: [] } };
    return data.floorplans;
  };

  /* Duplicate a floorplan 
  * Will copy current floorplan and create a new floorplan with the same name + "copy" suffix then reload the new floorplan
  * @param id - id of the floorplan to duplicate
  */
  duplicateProject = async () => {
    try {
      if (!this.client) {
        console.error("Failed to duplicate floorplan: client not initialized");
        return;
      }
      const { data, error } = (await this.client?.query({
        query: FloorplanDocument,
        variables: { id: this.id },
      })) || { data: { floorplan: null } };
      if (error) {
        console.error("Failed to load current floorplan:", error);
        return;
      }
      const id = ""
      const floorplan = data.floorplan;
      const name = floorplan.name + " copy";
      const json = floorplan.json;
      const result = await this.client?.mutate({
        mutation: EditFloorplanDocument,
        variables: { id, name, json },
      });
      if (result?.data?.editFloorplan) {
        window.location.href = "/floorplan/" + result.data.editFloorplan.id;
      }
    } catch (error: any) {
      console.error("Failed to duplicate floorplan:", error);
    }
  }
}

// Utility to debounce function calls
const debounce = (func: any, wait: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

gql`
  query Floorplan($id: String!) {
    floorplan(id: $id) {
      id
      name
      json
      image
      thumbnail
    }
  }
`;

gql`
  query Floorplans {
    floorplans {
      id
      name
      json
      image
      thumbnail
    }
  }
`;

gql`
  mutation editFloorplan(
    $id: ID!
    $name: String
    $json: String
    $image: Upload
  ) {
    editFloorplan(input: {
      id: $id
      name: $name
      json: $json
      image: $image
    }) {
      id
      name
      json
      image
      thumbnail
    }
  }
`;

gql`
  mutation deleteFloorplan(
    $id: String!
  ) {
    deleteFloorplan(id: $id) {
      id
    }
  }
`;

export const floorplannerStore = new FloorplannerStore();
export const FloorplannerStoreContext = createContext(floorplannerStore);
export const useFloorplannerStore = () => useContext(FloorplannerStoreContext);
