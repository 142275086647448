import { useThree } from '@react-three/fiber';
import * as THREE from 'three';

// Project screen coordinates to world coordinates
export const projectToWorld = (
    clientX: number,
    clientY: number,
    gl: THREE.WebGLRenderer,
    camera: THREE.Camera,
    zPlane: number = 0, // Define the Z-plane where you want the projection to happen
): [number, number] => {
    const raycaster = new THREE.Raycaster();
    const rect = gl.domElement.getBoundingClientRect();

    // Convert mouse position to normalized device coordinates (NDC)
    const x = ((clientX - rect.left) / rect.width) * 2 - 1;
    const y = -((clientY - rect.top) / rect.height) * 2 + 1;

    // Set ray from the camera using the NDC
    raycaster.setFromCamera(new THREE.Vector2(x, y), camera);

    // Create a plane at z = zPlane (for example, the XY plane at z = 0)
    const plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), -zPlane); // Z-plane

    // Find the intersection point of the ray and the plane
    const intersectPoint = new THREE.Vector3();
    raycaster.ray.intersectPlane(plane, intersectPoint);

    return [intersectPoint.x, intersectPoint.y];
};
