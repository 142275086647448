import { ThreeEvent } from "@react-three/fiber";
import { isWallType, RulerLineType, SingleLineType, WallType } from "../../types/wallTypes";
import * as THREE from 'three';
import { calculateObjectPositionAndRotation } from "./calculateObjectPositionAndRotation";
import { editorStore } from "../../store/editorStore";
import { UpDownArrow } from "./UpDownArrow";
import { floorplannerStore } from "../../store/floorplannerStore";
import { createMiddleDragHandle } from "./createMiddleDragHandle";

const calculateRotatedPosition = (position: THREE.Vector3, origin: THREE.Vector3, angle: number): THREE.Vector3 => {
    const cosTheta = Math.cos(angle);
    const sinTheta = Math.sin(angle);

    const x = cosTheta * (position.x - origin.x) - sinTheta * (position.y - origin.y) + origin.x;
    const y = sinTheta * (position.x - origin.x) + cosTheta * (position.y - origin.y) + origin.y;

    return new THREE.Vector3(x, y, position.z); // Ensure to preserve z position
};

export const MiddleVHandle = (props: {
    wall: WallType | SingleLineType | RulerLineType;
    wallWidth: number;
    fillColor: string;
    color: string;
    onHandlePointerDown: (event: ThreeEvent<PointerEvent>, id: string) => void;
    onHandlePointerEnter: (event: ThreeEvent<PointerEvent>, id: string) => void;
    onHandlePointerLeave: (event: ThreeEvent<PointerEvent>, id: string) => void;
}) => {
    const {
        wall,
        wallWidth,
        fillColor,
        color,
        onHandlePointerDown,
        onHandlePointerEnter,
        onHandlePointerLeave,
    } = props;

    const { position, rotation, textRotation } = calculateObjectPositionAndRotation(
        wall.start,
        wall.end
    );
    const wallMinMidHandleSize = editorStore.zoomLevel < 3 ? Math.max(0.15, 0.15 / editorStore.zoomLevelDivisor()) : 0.1; // Minimum size of the mid-handle
    const wallMidHandleGap = 0.09 / editorStore.zoomLevelDivisor(); // Gap between the mid-handles
    const customLineWeight = floorplannerStore.convertLineWeightToWorld(floorplannerStore.lineWeight)
    const noopRaycast = () => null;
    const customWallWidth = isWallType(wall) ? wall.wallWidth || wallWidth : wallWidth;

    return (
        <group
            position={
                calculateRotatedPosition(
                    position.clone().add(new THREE.Vector3(-((customWallWidth / 2) + wallMidHandleGap), 0, 0.001)),
                    position,
                    rotation
                )}
            rotation={[0, 0, rotation]}
            onPointerDown={(event) => onHandlePointerDown(event, "middleV")}
            onPointerEnter={(event) => onHandlePointerEnter(event, "middleV")}
            onPointerLeave={(event) => onHandlePointerLeave(event, "middleV")}
        >
            <mesh>
                <planeGeometry args={[
                    Math.max(wallMinMidHandleSize + 0.03, customWallWidth + customLineWeight * 5),
                    Math.max(wallMinMidHandleSize + 0.03, customWallWidth + customLineWeight * 5)
                ]} />
                <meshBasicMaterial color={fillColor} />
            </mesh>
            {/* Draw an up-down-arrow using Line */}
            <UpDownArrow
                wall={wall}
                wallWidth={Math.max(wallMinMidHandleSize, wallWidth)}
                position={[0, 0, 0]}
                color={color}
            />
            <mesh raycast={noopRaycast}>
                <primitive object={createMiddleDragHandle(wall, Math.max(wallMinMidHandleSize, wallWidth))} />
                <meshBasicMaterial color={color} transparent opacity={0.8} />
            </mesh>
        </group>
    );
}