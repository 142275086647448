import * as THREE from 'three';

export const calculateObjectPositionAndRotation = (start: THREE.Vector2, end: THREE.Vector2) => {
    const position = new THREE.Vector3(
        (start.x + end.x) / 2,
        (start.y + end.y) / 2,
        0.001 // Slightly above the floor
    );

    const angle = Math.atan2(end.y - start.y, end.x - start.x);

    // Calculate the perpendicular offset vector (normalized)
    const perpendicular = new THREE.Vector2(
        -(end.y - start.y),
        end.x - start.x
    ).normalize();

    // Calculate the correct text rotation
    const textRotation = angle > Math.PI / 2 || angle < -Math.PI / 2
        ? angle + Math.PI // Flip the text if it's upside down
        : angle;

    return { position, rotation: angle, perpendicular, textRotation };
};
