import React, { useRef } from 'react';
import { useThree, invalidate } from '@react-three/fiber';
import * as THREE from 'three';
import { editorStore } from '../../store/editorStore';

export const MAX_PLANE_SIZE = 300;

const PanningPlane: React.FC = () => {
    const { camera, viewport } = useThree();
    const isPanning = useRef(false);
    const startPoint = useRef<THREE.Vector2>(new THREE.Vector2());
    const cameraStart = useRef<THREE.Vector3>(new THREE.Vector3());

    const handlePointerDown = (event: PointerEvent) => {
        // Only process the pointerdown if it's the middle mouse button
        if (event.button === 1 && !editorStore.view3D && !editorStore.wallConstructionMode && !editorStore.lineConstructionMode && !editorStore.areaConstructionMode && !editorStore.rulerConstructionMode) {
            editorStore.setPanning(true);
            isPanning.current = true;
            startPoint.current.set(event.clientX, event.clientY);
            cameraStart.current.copy(camera.position);
            document.body.style.cursor = 'grabbing';
            invalidate();  // Invalidate the render to reflect changes
        } else if (event.button === 0 && editorStore.panning) {
            isPanning.current = true;
            startPoint.current.set(event.clientX, event.clientY);
            cameraStart.current.copy(camera.position);
            document.body.style.cursor = 'grabbing';
            invalidate();  // Invalidate the render to reflect changes
        }
    };

    const handlePointerMove = (event: PointerEvent) => {
        if (isPanning.current && editorStore.panning) {
            const deltaX = (event.clientX - startPoint.current.x) / camera.zoom / 44;
            const deltaY = (event.clientY - startPoint.current.y) / camera.zoom / 44;

            let newX = cameraStart.current.x - deltaX;
            let newY = cameraStart.current.y + deltaY;

            const { width: viewportWidth, height: viewportHeight } = viewport.getCurrentViewport(camera);

            const halfViewportWidth = viewportWidth / 2;
            const halfViewportHeight = viewportHeight / 2;

            const planeHalfSize = MAX_PLANE_SIZE / 2;

            let minX = -planeHalfSize + halfViewportWidth;
            let maxX = planeHalfSize - halfViewportWidth;

            if (viewportWidth >= MAX_PLANE_SIZE) {
                minX = maxX = 0;
                newX = 0;
            } else {
                newX = Math.max(minX, Math.min(maxX, newX));
            }

            let minY = -planeHalfSize + halfViewportHeight;
            let maxY = planeHalfSize - halfViewportHeight;

            if (viewportHeight >= MAX_PLANE_SIZE) {
                minY = maxY = 0;
                newY = 0;
            } else {
                newY = Math.max(minY, Math.min(maxY, newY));
            }

            camera.position.set(newX, newY, camera.position.z);
            invalidate(); // Invalidate the render to reflect changes
        }
    };

    const handlePointerUp = (event: PointerEvent) => {
        if (event.button === 1) {
            editorStore.setPanning(false);
        }

        if (isPanning.current) {
            isPanning.current = false;
            document.body.style.cursor = 'auto';
        }
    };

    return (
        <mesh
            position={[0, 0, -0.01]}
            onPointerDown={(e) => handlePointerDown(e.nativeEvent)}
            onPointerMove={(e) => handlePointerMove(e.nativeEvent)}
            onPointerUp={(e) => handlePointerUp(e.nativeEvent)}
            userData={{ id: 'panning-plane' }}
        >
            <planeGeometry args={[MAX_PLANE_SIZE * 1.5, MAX_PLANE_SIZE * 1.5]} />
            <meshBasicMaterial transparent opacity={0} />
        </mesh>
    );
};

export default PanningPlane;
